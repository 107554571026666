
import Icon from '@/components/Icon.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import { AccountType } from '@/state'
import { changeAccountType } from '@/state/accountType'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DemoAccountTip',
  components: { Icon, AccountRelated },
  setup () {
    return {
      toLive () {
        changeAccountType(AccountType.REAL)
      },
    }
  },
})
