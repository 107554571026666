/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/30
 * @description
 *   xhr.exe.ts of FastTradeV3
 */
import * as R from 'ramda'
import { server } from '@/config'
import { RequestPayload, ResponseResult } from 'essential/net/http/makeRequestBy'
import jsBridge from '@/common/jsBridge'

const conjUrl = (a: string, b: string) => `${a.replace(/\/*$/, '')}/${b.replace(/^\/*/, '')}`

const SUCCESS_CODE = '0'
const ERROR_CODE = '-0'

type RespData = {
  code: string;
  data: null | Data;
  msg: string;
}

const parseResponse = <T> (resp: RespData): ResponseResult<T> => {
  const success = resp?.code === SUCCESS_CODE
  return {
    s: success,
    c: resp?.code ?? ERROR_CODE,
    d: resp?.data as T,
    m: resp?.msg ?? (!success ? 'Server Response Error [10001]' : 'OK'),
  }
}

const nativeExe = <T> ({ url: path, data }: RequestPayload) => new Promise<ResponseResult<T>>(
  (resolve, reject) => {
    const url = conjUrl(server.api, R.defaultTo('', path))
    jsBridge.call('xhr', { url, data }, (error, resp) => {
      const message = parseResponse<T>(resp as RespData)
      if (!error && message.s) {
        resolve(message)
      } else {
        reject(message)
      }
    })
  })

export default nativeExe
