export default [
  'angry',
  'anguished',
  'astonished',
  'cry',
  'disappointed',
  'disappointed_relieved',
  'dizzy_face',
  'expressionless',
  'flushed',
  'frowning',
  'grimacing',
  'grin',
  'grinning',
  'heart_eyes',
  'hushed',
  'joy',
  'kissing',
  'mask',
  'sleeping',
  'sleepy',
  'smiley',
  'smirk',
  'stuck_out_tongue_closed_eyes',
  'stuck_out_tongue_winking_eye',
  'triumph',
  'worried',
  '+1',
  '-1',
  'airplane',
  'alarm_clock',
  'ambulance',
  'angel',
  'apple',
  'arrow_backward',
  'arrow_double_down',
  'arrow_double_up',
  'arrow_down',
  'arrow_down_small',
  'arrow_forward',
  'arrow_heading_down',
  'arrow_heading_up',
  'arrow_left',
  'arrow_lower_left',
  'arrow_lower_right',
  'arrow_right',
  'arrow_right_hook',
  'arrow_up',
  'arrow_upper_left',
  'arrow_upper_right',
  'battery',
  'beer',
  'beers',
  'bell',
  'birthday',
  'black_joker',
  'blue_book',
  'blue_car',
  'boar',
  'boat',
  'bomb',
  'book',
  'broken_heart',
  'bulb',
  'bus',
  'busts_in_silhouette',
  'bust_in_silhouette',
  'calling',
  'camera',
  'chart_with_downwards_trend',
  'chart_with_upwards_trend',
  'clap',
  'couple',
  'couplekiss',
  'couple_with_heart',
  'cow',
  'crying_cat_face',
  'cyclone',
  'dancer',
  'dancers',
  'dango',
  'dart',
  'ear',
  'earth_africa',
  'earth_americas',
  'earth_asia',
  'euro',
  'facepunch',
  'fire',
  'fireworks',
  'fire_engine',
  'first_quarter_moon_with_face',
  'fish',
  'fist',
  'ghost',
  'gift',
  'golf',
  'guitar',
  'gun',
  'hamburger',
  'hand',
  'heart_eyes_cat',
  'heavy_check_mark',
  'heavy_division_sign',
  'heavy_dollar_sign',
  'heavy_exclamation_mark',
  'heavy_minus_sign',
  'heavy_multiplication_x',
  'heavy_plus_sign',
  'horse_racing',
  'hourglass',
  'hourglass_flowing_sand',
  'jack_o_lantern',
  'kiss',
  'lollipop',
  'poop',
  'pray',
  'rainbow',
  'rocket',
  'santa',
]
