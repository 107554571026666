/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/7 17:28
 * @description
 *   Line.ts of FAST
 */
import AbstractDrawing, { DrawCallback, OptionalDrawingOptions } from '../core/AbstractDrawing'
import { defaultLineStyle } from '../defaults'
import { extend } from '../helper'
import IChart, { ChartPointer } from '../interface/IChart'
import { ISelection, LineStyle, OptionsOf, TickPoint } from '../types'
import * as R from 'ramda'

type RequiredLineOptions = {
  container: string;
  id: string;
}

type Endpoint = TickPoint & Partial<{
  x: number;
  y: number;
}>

export type OptionalArrowOptions = {
  ardir?: string;
  arrowPoint?: Endpoint[];
  formatLabel?: (c: number | string) => string | number
} & Partial<LineStyle> & OptionalDrawingOptions

export type LineOptions = OptionsOf<OptionalArrowOptions, RequiredLineOptions>

const defaultLineOptions: OptionalArrowOptions = {
  formatLabel: v => v,
  ...defaultLineStyle,
}

export default class Arrow extends AbstractDrawing {
  private readonly options: LineOptions['define']
  private readonly arrowEle: ISelection<SVGImageElement>

  private keyPoints: Endpoint[] = []

  constructor (chart: IChart, options: LineOptions['call'], callback?: DrawCallback) {
    super({
      chart,
      pointsCount: 1,
      ...options,
      callback,
    })

    this.options = extend(defaultLineOptions, options)
    this.keyPoints = this.options.arrowPoint ?? []

    this.el_d3.attr('class', 'drawing image')

    const imgSrc = options.ardir === 'up' ? '/img/icons/arrow_up@2x.png' : '/img/icons/arrow_down@2x.png'

    this.arrowEle = this.el_d3.append('image')
      .attr('width', '16px')
      .attr('height', '16px')
      .attr('x', '-8px')
      .attr('y', '-8px')
      .attr('xlink:href', imgSrc)
  }

  private transform (point?: ChartPointer) {
    if (point) {
      this
        .el_d3
        .attr('transform', `translate(${[point.x, point.y]})`)
    }
  }

  style () {
    return this
  }

  /**
   * 更新节点信息 并计算两个节点之间的所有点的x坐标
   * @param points
   */
  syncEndpoints (points: ChartPointer[]) {
    this.keyPoints = R.map(R.pick(['t', 'c']), points)
    return this
  }

  highlight () {
    return this
  }

  locate (): ChartPointer[] | undefined {
    return this.endpoints().length ? this.endpoints() : undefined
  }

  parseEndpoints (): ChartPointer[] {
    if (this.keyPoints.length) {
      return this.keyPoints.map(p => ({
        t: p.t,
        c: p.c,
        sc: p.c + '',
        x: this.chart.x(p.t) ?? NaN,
        y: this.chart.y(p.c),
      }))
    }

    return []
  }

  renderDrawing (init = false) {
    const keyPoint = this.endpoints(0)

    if (keyPoint) {
      if (init) {
        this.style()
      }

      if (Number.isNaN(keyPoint.x)) {
        this.el_d3.style('display', 'none')
        return this
      } else {
        this.el_d3.style('display', '')
      }

      this.transform(keyPoint)
    }

    return this
  }

  create () {
    this.style()
      .draw(
        p => {
          this.transform(p)
        },
        this.transform.bind(this),
      )

    return this
  }

  updateLabel () {
    return this
  }

  updateKeyPoints () {
    return this
  }

  dragStart () {
    this.keyPoints = R.clone(this.endpoints())
    return this
  }

  drag (e: Event, p: ChartPointer) {
    this.updateEndpoints([p])
    this.renderDrawing()
    return this
  }

  remove () {
    super.remove()

    this.keyPoints = []
  }
}
