import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fill bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheMissionCard = _resolveComponent("TheMissionCard")
  const _component_TheRecommend = _resolveComponent("TheRecommend")
  const _component_TheNews = _resolveComponent("TheNews")
  const _component_Scroller = _resolveComponent("Scroller")
  const _component_TheBuoy = _resolveComponent("TheBuoy")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Scroller, {
      class: "fill px-16",
      onBottomTouched: _ctx.loadMore
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_TheMissionCard, {
          class: "mt-8 mb-20",
          onShow: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMissionCard = true)),
          onDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showMissionCard = false))
        }, null, 512), [
          [_vShow, _ctx.showMissionCard]
        ]),
        _createVNode(_component_TheRecommend),
        _createVNode(_component_TheNews, {
          class: "mt-24",
          list: _ctx.loaded,
          refresh: _ctx.refresh
        }, null, 8, ["list", "refresh"])
      ]),
      _: 1
    }, 8, ["onBottomTouched"]),
    _createVNode(_component_TheBuoy)
  ]))
}