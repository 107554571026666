
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import SymbolLink from '@/provider/SymbolLink.vue'
import GuideTarget from '@/components/userGuide/GuideTarget.vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import Scroller from 'common/providers/Scroller.vue'
import { useSpeedWatchList, SpAllTab } from '@/modules/market/market'
import { MkItSpProductList, MarketItemSp, MkItSpSub } from '@/modules/market/market.api'
import { defineComponent, shallowRef, onActivated, onBeforeUnmount, reactive } from 'vue'
import { events } from '@/config'
import { emit, on, off } from 'essential/tools/event'
import datafeed from '@/common/datafeed'
import Holder from '@/provider/Holder.vue'
import * as R from 'ramda'
import { MarketState } from '@/types'
import { getSpeedAc } from '@/common/goSpeed'

type MklISp = Partial<MkItSpProductList> & { code: string }

export default defineComponent({
  name: 'TheWatchListSp',
  components: {
    SymbolLink,
    Scroller,
    RealTimeQuote,
    GuideTarget,
    AsyncNullableList,
    Holder
  },
  props: {
    isAll: Boolean
  },
  setup(props) {
    const { read, all } = useSpeedWatchList()
    const list = shallowRef<MklISp[]>([])
    const tabs = reactive<{ d: MkItSpSub[]; sel: number; p: number; f: boolean }>({
      d: [],
      sel: 0,
      p: 1,
      f: true
    })

    const refresh = async () => {
      const param = props.isAll
        ? {
          productType: tabs.d[tabs.sel]?.productType || 2,
          subType: tabs.d[tabs.sel]?.subType
        }
        : {
          productType: 103,
          subType: tabs.d[tabs.sel]?.productType || 104,
          source: 3
        }
      return (props.isAll ? all : read)({ ...param, pageSize: 20, page: tabs.p }).then(res => {
        const d =
          res instanceof Array
            ? res
            : (res as MarketItemSp).productList.map(x => ({ ...x, code: x.contract }))
        if (d.length && tabs.p === 1) {
          list.value = []
          datafeed.subscribe(R.pluck('code', d))
          emit(events.symbolChange, d[0].code, true)
        }
        if (res?.subTypes) {
          tabs.d = res.subTypes
        }

        if (props.isAll && getSpeedAc('uuid')) {
          tabs.d = SpAllTab
        }

        list.value = [...list.value, ...d]
        tabs.f = true
        return d
      })
    }

    onActivated(refresh)

    on([events.watchListUpdate, events.login, events.activated], refresh)

    onBeforeUnmount(() => {
      off([events.watchListUpdate, events.login, events.activated], refresh)
    })

    const change = (id: number) => {
      if (id !== tabs.sel) {
        tabs.sel = id
        tabs.p = 1
        tabs.f = true
        refresh()
      }
    }

    const bottom = () => {
      if (tabs.f) {
        tabs.f = false
        tabs.p += 1
        refresh()
      }
    }

    return {
      list,
      bottom,
      tabs,
      MarketState,
      SpAllTab,
      change,
      load(done: () => void) {
        return refresh().then(done)
      }
    }
  }
})
