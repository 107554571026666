/**
 *  Order.ts of project WeTradeWeb
 *  @date 2022/5/27 19:56
 *  @author 阿佑[ayooooo@petalmail.com]
 */
import { showAlert } from '@/components/popup/popup'
import { leversSchema, Position, Product } from '@/modules/trade/trade.api'
import { TradeDirection, YesOrNo } from '@/types'
import { add, div, mul } from 'essential/tools/math'
import { computed, shallowRef, watch } from 'vue'

class Order {
  /**
   * 挂单预期仓位
   */
  entrustPosition = shallowRef('')

  orderPosition = shallowRef('')

  /**
   * 交易方向
   */
  type = shallowRef(TradeDirection.BUY)

  orderId = shallowRef(NaN)
  useCoupon = shallowRef(false)
  voucherIds = shallowRef('')

  /**
   * 止损追踪
   */
  trailingStop = shallowRef(false)

  /**
   * 手数
   */
  leverage = shallowRef('') // 杠杆
  quantity = shallowRef('') // 手数
  multiple = shallowRef(1)

  takeProfit = shallowRef(0)

  stopLoss = shallowRef(0)

  productPrice = computed(() => {
    return mul(this.multiple.value, this.product.value?.price ?? 0)
  })

  isFree = computed(() => this.useCoupon.value)

  cost = computed(() => {
    // if (this.isFree.value) return 0

    if (!this.product.value) return 0

    const { specs, marginFormula } = this.product.value

    if (specs?.length) {
      const temp = div(specs[0]?.weight, specs[0]?.minSl)
      const { marginIsMultiply, marginSymbol } = marginFormula ?? {}
      // return div(mul(temp, this.quantity.value ?? 0, marginIsMultiply === 1 ? 100 : 1), this.leverage.value)
      return div(mul(temp, this.quantity.value ?? 0), this.leverage.value)
    }
    return add(this.product.value.price, this.product.value.sxf)
  })

  private product = shallowRef<null | Product>(null)

  /**
   * 挂单偏移量
   */
  offset = shallowRef(0)

  // offset = computed(() => {
  //   if (!this.product.value) return 30
  //
  //   return Math.ceil(div(div(this.entrustPosition.value, 1000), this.product.value.pointStep))
  // })

  entrustMinOffset = computed(() => {
    if (!this.product.value) return 0
    const { entrustMinOffset, entrustOffset } = this.product.value
    if (this.offset.value) {
      // console.log(this.offset.value, '===')
    } else {
      this.offset.value = entrustOffset ?? entrustMinOffset ?? 0
    }
    return entrustMinOffset
  })

  entrustFinalPrice (type: number) {
    if (!this.product.value) return 0
    const { calculatePoint, pointStep } = this.product.value
    return add(this.entrustPosition.value, calculatePoint * Number(pointStep) * this.offset.value * type)
  }

  entrustPositionUpdate (value: number) {
    if (this.entrustMinOffset.value && value < this.entrustMinOffset.value) {
      this.offset.value = this.entrustMinOffset.value
    } else {
      this.offset.value = value
    }
  }

  constructor (type: TradeDirection, order ?: Partial<Position>) {
    if (order) {
      this.orderId.value = order.orderId ?? NaN
      this.type.value = order.type ?? TradeDirection.BUY
      this.multiple.value = order.orderNumber ?? 1
      this.useCoupon.value = order.isJuan === YesOrNo.YES
      this.takeProfit.value = order.stopProfit ?? 0
      this.stopLoss.value = order.stopLoss ?? 0
      this.trailingStop.value = order.isTrackSt === YesOrNo.YES
      this.entrustPosition.value = order.price ?? ''
      this.orderPosition.value = order.createPrice ?? ''
    }

    this.type.value = type

    watch(this.product, () => {
      this.multiple.value = this.product.value?.orderNumber ?? 1
      if (this.product.value) {
        const { calculatePoint, pointStep, buy, sell } = this.product.value
        if (!this.entrustPosition.value) this.entrustPosition.value = add(this.type.value === TradeDirection.BUY ? Number(buy) : Number(sell), calculatePoint * Number(pointStep) * 20 * (this.type.value === TradeDirection.BUY ? -1 : 1)).toString()
      }
    })
  }

  setProduct (product: Product) {
    if (this.product.value === null) {
      this.product.value = product
    }
  }

  updateProduct (product: Product) {
    this.product.value = product
  }

  updateProductQuantity (quantity: string) {
    this.quantity.value = quantity
  }

  updateProductLeverage (leverage: leversSchema | null) {
    this.leverage.value = leverage?.lever || ''
  }

  getProduct () {
    return this.product
  }

  getValue () {
    if (!this.product.value) {
      showAlert('No Product provide!')
      return
    }

    return {
      // type: this.type.value,
      // deferred: YesOrNo.YES,
      // offset: this.offset.value,
      // orderNumber: this.multiple.value,
      // productId: this.product.value?.productId,
      // isJuan: Number(this.useCoupon.value),
      // isTrackSt: Number(this.trailingStop.value),
      stopLoss: this.stopLoss.value ?? null,
      stopProfit: this.takeProfit.value ?? null,
      // price: this.entrustPosition.value,
      orderId: this.orderId.value,
    }
  }

  getSpeedValue () {
    if (!this.product.value) {
      showAlert('No Product provide!')
      return
    }

    const tempSpecs = this.product.value?.specs

    return {
      type: this.type.value,
      number: this.quantity.value,
      productId: tempSpecs?.length ? tempSpecs[0].productId : '',
      stopLoss: this.stopLoss.value ?? null,
      stopProfit: this.takeProfit.value ?? null,
      lever: this.leverage.value,
      price: this.entrustPosition.value,
      isJuan: Number(this.useCoupon.value),
      deferred: YesOrNo.YES,
      offset: this.offset.value,
      orderId: this.orderId.value,
      voucherIds: this.voucherIds.value,
    }
  }
}

export default Order
