/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   tagPrivate.ts of WeTrade
 */
import * as R from 'ramda'
import { RouteRecordRaw } from 'vue-router'

export const markPrivate = R.assocPath<boolean, RouteRecordRaw>(['meta', 'private'], true)

export const markUseRoute = R.assocPath<boolean, RouteRecordRaw>(['meta', 'useRoute'], true)

export const markBlank = R.assocPath<boolean, RouteRecordRaw>(['meta', 'isBlank'], true)
