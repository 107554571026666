import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RangeInput = _resolveComponent("RangeInput")

  return (_openBlock(), _createBlock(_component_RangeInput, {
    max: _ctx.max,
    min: _ctx.min,
    step: _ctx.step,
    value: _ctx.value,
    class: "bg-white br-lg p-8 range c-text f-bold",
    style: {"width":"165px"}
  }, {
    default: _withCtx(({ value }) => [
      _renderSlot(_ctx.$slots, "default", { value: value }, () => [
        _createTextVNode(" -" + _toDisplayString(value) + "% ", 1)
      ])
    ]),
    _: 3
  }, 8, ["max", "min", "step", "value"]))
}