
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'Matrix',
  emits: ['update:select'],
  props: {
    list: {
      type: Array,
      required: true,
    },
    column: {
      type: Number,
      default: 4,
    },
    colGap: {
      type: Number,
      default: 2,
    },
    identify: {
      type: Function,
      default: (v: unknown) => v,
    },
  },
  setup (props) {
    const max = computed(() => {
      return Math.floor(100 / props.column) - props.colGap
    })
    const metaWithStyle = computed(() => {
      return {
        'min-width': props.column === 1 ? '100%' : `${max.value - 1}%`,
        'max-width': props.column === 1 ? '100%' : `${max.value}%`,
      }
    })

    const remainder = computed(() => Math.ceil(props.list.length % props.column))
    const additions = computed(() => remainder.value ? (props.column - remainder.value) : 0)

    return {
      metaWithStyle,
      additions,
    }
  },
})
