
import TheMarketList from '@/modules/market/components/TheMarketList.vue'
import { useMarket } from '@/modules/market/market'
import { MarketItem } from '@/modules/market/market.api'
import { computed, defineComponent, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'TheSymbolCollection',
  components: { TheMarketList },
  props: {},
  setup () {
    const { tabs, index, switchTab } = useMarket()
    const read = computed(() => tabs.value[index.value].load)
    const items = shallowRef<MarketItem[]>([])

    watchEffect(() => {
      tabs.value[index.value].load().then(resp => {
        items.value = resp
      })
    })

    return {
      tabs,
      read,
      active: index,
      items,
      change: switchTab,
    }
  },
})
