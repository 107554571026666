
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import TheSelectBar from '@/modules/market/components/chart/TheSelectBar.vue'
import { defineComponent } from 'vue'
import { StudyConfig } from 'happychart/core/AbstractStudy'

export default defineComponent({
  name: 'TheStudyBar',
  components: { TheSelectBar, Icon, Pic },
  emits: ['change'],
  props: {
    studies: Array,
    selected: Number,
  },
  setup (props, ctx) {
    const change = (item: StudyConfig, apply: (item: StudyConfig) => void) => {
      ctx.emit('change', item, () => {
        apply(item)
      })
    }

    return {
      change,
    }
  },
})
