/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   private.ts of WeTrade
 */
import { keymap } from '@/config'
import { markPrivate } from '@/router/helper'
import { localGet } from 'essential/store/localStore'
import * as R from 'ramda'
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { StudyType } from 'happychart/types'

const privateRoutes: Array<RouteRecordRaw> = R.map(markPrivate, [
  {
    name: 'create',
    path: '/create/:code/:type',
    component: () => import('@/modules/trade/Create.vue'),
  },
  {
    path: '/in',
    name: 'deposit',
    redirect: () => `/in/${localGet(keymap.user.countryCode) ?? '*'}`,
  },
  {
    path: '/in/91',
    name: 'depositFor91',
    component: () => import('@/modules/in/Channel.91.vue'),
  },
  {
    path: '/in/63',
    name: 'depositFor61',
    component: () => import('@/modules/in/Channel.63.vue'),
  },
  {
    path: '/in/:cc',
    name: 'depositForCommon',
    component: () => import('@/modules/in/Channel.common.vue'),
  },
  {
    path: '/in/fill',
    name: 'fillInBaseForm',
    redirect: () => `/in/fill/${localGet(keymap.user.countryCode) ?? '*'}`,
  },
  {
    path: '/in/fill/63',
    name: 'fillInBaseFormFor63',
    component: () => import('@/modules/in/FillInUp.63.vue'),
  },
  {
    path: '/in/fill/:cc',
    name: 'fillInBaseFormForCommon',
    component: () => import('@/modules/in/FillInUp.common.vue'),
  },
  {
    path: '/symbolRotate/:code',
    name: 'symbolRotate',
    component: () => import('@/modules/market/symbolRotate/SymbolRotate.vue'),
    meta: {
      noCache: true,
    },
  },
  {
    path: '/edit/:code/:orderId',
    name: 'edit',
    component: () => import('@/modules/trade/Edit.vue'),
  },
  {
    path: '/user/interest',
    name: 'interest',
    component: () => import('@/pages/interest/Interest.vue'),
  },
  {
    path: '/me/coupons',
    name: 'coupons',
    component: () => import('@/pages/coupons/Coupons.vue'),
  },
  {
    path: '/me/setting',
    name: 'setting',
    component: () => import('@/pages/setting/Setting.vue'),
  },
  {
    path: '/me/setting/nick',
    name: 'nickname',
    component: () => import('@/pages/setting/components/TheNickname.vue'),
  },
  {
    path: '/me/io',
    name: 'transaction',
    component: () => import('@/pages/transaction/Transactions.vue'),
  },
  {
    path: '/me/history',
    name: 'history',
    component: () => import('@/pages/history/History.vue'),
  },
  {
    path: '/out/bindBank',
    name: 'bindBank',
    component: () => import('@/modules/out/BindBankAccount.vue'),
  },
  {
    path: '/out/bindUpi',
    name: 'bindUpi',
    component: () => import('@/modules/out/BindUPIAccount.vue'),
  },
  {
    path: '/membership/level',
    name: 'level',
    component: () => import('@/modules/membership/LevelPoints.vue'),
  },
  {
    path: '/membership/tournament',
    name: 'tournament',
    component: () => import('@/modules/membership/tournament/Tournament.vue'),
  },
  {
    path: '/membership/level/points',
    name: 'points',
    component: () => import('@/modules/membership/Points.vue'),
  },
  {
    path: '/membership/store',
    name: 'store',
    component: () => import('@/modules/membership/PointsStore.vue'),
  },
  {
    path: '/membership/tournament/:tournamentId',
    name: 'participate',
    component: () => import('@/modules/membership/tournament/TournamentDetail.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/live/:liveId/:chatId',
    name: 'live',
    component: () => import('@/modules/membership/Live.vue'),
    meta: {
      immersive: '#181D24',
    },
  },
  {
    path: `/membership/signal/${String(StudyType.TREND)}`,
    name: 'signalTrend',
    component: () => import('@/modules/membership/signal/Trend.vue'),
  }, {
    path: `/membership/signal/${String(StudyType.TRENDPRO)}`,
    name: 'signalTrendPro',
    component: () => import('@/modules/membership/signal/TrendPro.vue'),
  },
  {
    path: '/trade/fast',
    name: 'fastTrade',
    component: () => import('@/modules/fastTrade/FastTrade.index.vue'),
  },
  {
    path: '/share/invite',
    name: 'invite',
    component: () => import('@/pages/invite/InviteShare.vue'),
    meta: {
      immersive: '#326BFE',
    },
  },
  {
    path: '/trade/copy',
    name: 'copyTrade',
    component: () => import('@/modules/copyTrade/CopyTrade.index.vue'),
  },
  {
    path: '/trade/copy/:orderId',
    name: 'copyDetail',
    component: () => import('@/modules/copyTrade/Detail.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/events/chrismas',
    name: 'chrismas',
    component: () => import('@/events/christmas/Christmas.index.vue'),
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('@/events/christmas/AddressEdit.vue'),
  },
  {
    path: '/cardGame',
    name: 'cardGame',
    component: () => import('@/events/cardGame/CardGame.vue'),
  },
  {
    name: 'leverage',
    path: '/user/leverage',
    component: () => import('@/pages/leverage/Leverage.vue'),
  },
  {
    path: '/cup/wt/myprize',
    name: 'myWTCup',
    component: () => import('@/modules/weTradeCup/MyPrize.vue'),
  },
  {
    path: '/me/setting/address',
    name: 'myAddress',
    component: () => import('@/pages/setting/AddressEdit.vue'),
  },
])

export default privateRoutes
