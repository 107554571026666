
import Pic from '@/components/Pic.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LimitDialogTemplate',
  components: { Pic, DialogTemplate },
  props: {
    i18nPath: String,
    i18nArgs: Object,
    defaultText: String,
  },
  methods: {
    close () {
      this.$emit('close')
      this.$emit('confirm')
    },
  },
})
