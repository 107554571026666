import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheToggleStateButton = _resolveComponent("TheToggleStateButton")

  return (_openBlock(), _createBlock(_component_TheToggleStateButton, {
    progress: _ctx.progress,
    on: _ctx.added,
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggle(_ctx.marketItem.code)))
  }, {
    suffix: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.prop('tips', _ctx.marketItem)), 1)
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.prop('code', _ctx.marketItem)) + " ", 1)
    ]),
    _: 1
  }, 8, ["progress", "on"]))
}