
import { usePositionCount } from '@/modules/user/user'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import SymbolLink from '@/provider/SymbolLink.vue'
import { MarketState } from '@/types'
import { computed, defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'TheMarketItem',
  components: {
    SymbolLink,
    RealTimeQuote,
    Pic,
    Icon,
    // TheFlashBlock,
  },
  props: {
    marketItem: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const symbol = computed(() => {
      return {
        ...props.marketItem.realTimeMicroQuotationBean,
        icon: props.marketItem.icon,
      }
    })
    return {
      symbol,
      isClosed: computed(() => symbol.value && symbol.value.isClosed === MarketState.CLOSED),
      count: usePositionCount(props.marketItem.code),
    }
  },
})
