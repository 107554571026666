/**
 * @author zjc[beica1@outook.com]
 * @date 2021/9/17 20:45
 * @description
 *   IStudy.ts of FAST
 */
import { Selection } from 'd3'
import AbstractShape, { ExtendShapeOptions } from './AbstractShape'
import IChart from '../interface/IChart'
import { Bar, ISelection } from '../types'

export type ExtendStudyOptions<T> = Omit<ExtendShapeOptions<T>, 'container'>

export type StudyConfig = Partial<{
  mountType: 'g' | 'path' | 'rect',
  name: string;
  field: 'c' | 'h' | 'o' | 'l'
}>

abstract class AbstractStudy<T, DT = Bar[], ST extends Selection<any, any, any, any> = ISelection<SVGGElement>> extends AbstractShape<T, DT, ST> {
  config: Required<StudyConfig>
  cached: DT[] = []
  secondLast: Bar | null = null

  constructor (
    chart: IChart,
    inputs: Omit<ExtendStudyOptions<T>, 'container'>,
    config?: StudyConfig,
  ) {
    super(
      chart,
      {
        container: chart.id,
        mountType: config?.mountType,
        ...inputs,
      } as ExtendShapeOptions<T>,
    )

    this.config = {
      field: 'c',
      mountType: 'g',
      name: '',
      ...config,
    }
  }

  abstract slice (from: number, to: number): DT[]

  abstract calcInitStudy (quotes: Bar[]): DT[]

  abstract calcCandidateStudy (quotes: Bar[]): DT[]

  abstract render (g: ST, paths: DT[], extend?: boolean): void

  /**
   * @implements drawInit
   * @param all
   * @param sliceFrom
   * @param sliceTo
   */
  drawInit (all: Bar[], sliceFrom: number, sliceTo: number) {
    if (!all.length) return this

    /**
     * !!! 修改前请阅读以下说明文字
     * 这里利用倒数第二个数据来判断 一次可以排除 相同品种不同resolution的情况
     *  比如：同一品种的30M和60M的最后一条数据可能相同 单倒数第二个数据的时间不同
     */
    const secondLast = all[sliceTo - 2]

    /**
     * 缓存 避免重复计算
     * 产生新的历史数据后 重新计算
     * @todo 抽象指标计算过程 实现可以 追加 重算 截取等操作的公共模型
     */
    if (
      this.cached.length === 0 ||
      !this.secondLast ||
      (
        sliceTo >= all.length && // 位置不变表示 无拖动操作
        (
          secondLast?.t !== this.secondLast?.t || // 使用时间和价格的双重特征标识一个交易品种
          secondLast?.c !== this.secondLast?.c
        )
      )
    ) {
      this.cached = this.calcInitStudy(all)
      this.secondLast = secondLast
    }

    const rendered = this.slice(sliceFrom, sliceTo)

    if (rendered.length === 0) {
      this.initD3El.selectChildren().remove()
      return this
    }

    this.render(this.initD3El, rendered)

    return this
  }

  /**
   * @implements drawCandidate
   * @param candidate
   * @param history
   */
  drawCandidate (candidate: Bar, history: Bar[]) {
    const data = this.calcCandidateStudy(history.concat(candidate))

    if (data.length === 0) {
      this.d3El.selectChildren().remove()
      return this
    }

    this.render(this.d3El, data, true)

    return this
  }
}

export default AbstractStudy
