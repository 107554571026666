
import Button from '@/components/Button.vue'
import { generateLimitList, LimitType } from '@/modules/trade/trade'
import { Product } from '@/modules/trade/trade.api'
import List from '@/components/AsyncNullableList.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheTradeLimitList',
  components: { PageWithHeader, List, Button },
  emits: ['close', 'change'],
  props: {
    type: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  setup (props, ctx) {
    const choice = shallowRef(props.value)
    const options = generateLimitList(props.type, props.product as Product)

    return {
      choice,
      confirm () {
        if (choice.value !== props.value) {
          ctx.emit('change', choice.value)
        }
        ctx.emit('close')
      },
      options,
      title: props.type === LimitType.LOSS
        ? { label: 'placeorder_30', lableDefault: 'Stop-loss' }
        : { label: 'placeorder_26', lableDefault: 'Take-profit' },
    }
  },
})
