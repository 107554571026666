
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

// @todo z-index 引起的弹窗title bar层级问题
export default defineComponent({
  name: 'PageHeader',
  components: { Icon },
  props: {
    noTitle: Boolean,
    noRight: Boolean,
    noLeft: Boolean,
  },
})
