/**
 *  rotuers.blank.ts of project WeTradeWeb
 *  @date 2022/6/1 16:49
 *  @author 阿佑[ayooooo@petalmail.com]
 */
import { markBlank, markUseRoute } from '@/router/helper'
import { RouteRecordRaw } from 'vue-router'
import * as R from 'ramda'

const transducer = R.compose(
  R.map(markUseRoute),
  R.map(markBlank),
)

const blankRoutes: RouteRecordRaw[] = R.transduce(
  transducer,
  (acc, r) => acc.concat(r),
  [],
  [
    {
      name: 'fullscreenLive',
      path: '/blank/live',
      components: {
        blank: () => import('@/modules/membership/FullscreenLive.vue'),
      },
    },
  ],
)

export default blankRoutes
