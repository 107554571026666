
import { computed, defineComponent } from 'vue'
import CommonPic from 'common/Pic.vue'

export default defineComponent({
  name: 'Pic',
  components: { CommonPic },
  props: {
    src: {
      type: String,
      required: true,
    },
    ratio: {
      type: Number,
      default: 2,
    },
    baseUrl: {
      type: String,
      default: '/img/',
    },
    exact: Boolean,
    type: {
      type: String,
      default: 'png',
    },
  },
  setup (props) {
    const ratioModify = props.type !== 'svg' ? `@${props.ratio}x` : ''

    const path = computed(() => {
      return props.exact
        ? props.src
        : (
          /^(\/|(http(s)?:\/\/))/.test(props.src)
            ? props.src
            : `/img/${props.src}${ratioModify}.${props.type}`
        )
    })

    return {
      path,
    }
  },
})
