/**
 * @author ayou[beica1@outook.com]
 * @date 2022/3/1 10:38
 * @description
 *   transform.mouse.ts of WeTrade
 */
const transform = () => {
  return () => {}
}

export default transform
