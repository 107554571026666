/**
 * @author zjc[beica1@outook.com]
 * @date 2021/9/13 13:25
 * @description
 *   Candle.ts of WeTrade
 */
import { Selection } from 'd3'
import AbstractGraph from '../core/AbstractGraph'
import { ISelection, Candle as DCandle } from '../types'

class Candle extends AbstractGraph {
  render (g: ISelection<SVGGElement>, quotes: DCandle[]) {
    g
      .selectAll('g')
      .data(quotes, d => (d as DCandle).t)
      // .call(this.drawCandle.bind(this))
      .join(
        enter => this.drawCandle(enter),
        update => this.drawCandle(update, true),
        exit => exit.remove(),
      )
  }

  private drawLine (line: ISelection<SVGLineElement, DCandle>) {
    line
      .attr('y1', d => this.fy(d.h ?? d.c))
      .attr('y2', d => this.fy(d.l ?? d.c))
      .attr('stroke', d => d.c > d.o ? this.options.riseColor : this.options.fallColor)
      .attr('stroke-width', 1)
  }

  private drawBar (bar: ISelection<SVGLineElement, DCandle>) {
    bar
      .attr('y1', d => this.fy(d.o ?? d.c))
      .attr('y2', d => {
        const y = this.fy(d.c)
        return y === this.fy(d.o) ? (y + 1) : y
      })
      .attr('stroke', d => d.c > d.o ? this.options.riseColor : this.options.fallColor)
      .attr('stroke-width', this.fx.bandwidth())
  }

  private drawCandle (g: Selection<any, DCandle, any, unknown>, update = false) {
    let candle = g

    if (!update) {
      candle = candle.append('g')
    }

    candle
      .attr('transform', d => {
        return `translate(${this.fx(d.t) ?? 0}, 0)`
      })

    this.drawLine(update ? candle.select('line:nth-child(1)') : candle.append('line'))
    this.drawBar(update ? candle.select('line:nth-child(2)') : candle.append('line'))

    return candle
  }

  drawCandidate (latest: DCandle) {
    this.render(this.d3El, [latest])
    return this
  }
}

export default Candle
