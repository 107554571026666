/**
 * @author zjc[beica1@outook.com]
 * @date 2021/9/16 16:40
 * @description
 *   RendererMaster.ts of FAST
 */
import { MonitorType } from './dataMaster/AbstractDataMaster'
import DataMaster from './dataMaster/DataMaster'
import IChart from '../interface/IChart'
import IRenderer from '../interface/IRenderer'

export type RendererRequiredOptions = {
  dataMaster: DataMaster
}

/**
 * 简易版 一次事件循环中只执行一次的逻辑控制
 * @param done
 */
const onceALoop = (done: () => void) => {
  let pending = false

  return () => {
    if (!pending) {
      pending = true

      Promise.resolve().then(() => {
        pending = false
        done()
      })
    }
  }
}

abstract class RendererMaster<T extends RendererRequiredOptions> {
  options: T
  chart: IChart
  dataMaster: DataMaster
  renderer: IRenderer

  constructor (chart: IChart, options?: T, renderOptions?: unknown[]) {
    this.chart = chart

    this.options = options ?? ({} as T)

    this.dataMaster = this.options.dataMaster

    this.init()

    this.renderer = this.render(...(renderOptions ?? []))

    this.monitorDataMaster()
  }

  init () {
    return this
  }

  abstract render (...args: unknown[]): IRenderer

  monitorDataMaster () {
    /**
     * promise确保图形绘制在坐标重新计算后执行
     * 同时  一个事件循环只刷新一次
     */
    const draw = onceALoop(() => {
      this.renderer.draw(...this.dataMaster.value())
    })

    this.dataMaster.monitor([MonitorType.DATA, MonitorType.EXTENT], draw)

    this.dataMaster.monitor(MonitorType.TICK, () => {
      const [init, candidate] = this.dataMaster.value()

      if (candidate) {
        this.renderer.drawCandidate(candidate, init)
      }
    })
  }

  remove () {
    this.renderer.remove()
  }
}

export default RendererMaster
