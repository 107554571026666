
import * as R from 'ramda'
import Pic from '@/components/Pic.vue'
import { UTC2local } from 'essential/tools/format'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'TheMarketClosedTip',
  components: { Pic },
  props: {
    symbol: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const isCC = computed(() => {
      return R.includes(props.symbol?.code, ['BTCUSD', 'ETHUSD'])
    })

    const times = computed(() => {
      const utcTimes = props.symbol?.closeTimes
      if (utcTimes?.length) {
        const times = utcTimes[0]
        return [
          UTC2local(times.startTime),
          UTC2local(times.endTime),
        ]
      }
      return []
    })

    return {
      isCC,
      times,
    }
  },
})
