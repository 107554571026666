/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/7 17:28
 * @description
 *   Line.ts of FAST
 */
import AbstractDrawing, { DrawCallback, OptionalDrawingOptions } from '../core/AbstractDrawing'
import { defaultLineStyle } from '../defaults'
import { extend } from '../helper'
import IChart, { ChartPointer } from '../interface/IChart'
import { ISelection, LineStyle, OptionsOf, TickPoint, Point } from '../types'
import * as R from 'ramda'

type RequiredLineOptions = {
  container: string;
  id: string;
}

type Endpoint = TickPoint & Partial<{
  x: number;
  y: number;
}>

export type OptionalTextOptions = {
  text?: string;
  textPoint?: Endpoint[];
  formatLabel?: (c: number | string) => string | number
} & Partial<LineStyle> & OptionalDrawingOptions

export type LineOptions = OptionsOf<OptionalTextOptions, RequiredLineOptions>

const defaultLineOptions: OptionalTextOptions = {
  formatLabel: v => v,
  text: '',
  ...defaultLineStyle,
}

const alineWordsNum = 20
const textLineHeight = 16

export default class Text extends AbstractDrawing {
  private readonly options: LineOptions['define']
  private readonly textEle: ISelection<SVGTextElement>

  private keyPoints: Endpoint[] = []
  private touchOffset: Point = [0, 0]

  constructor (chart: IChart, options: LineOptions['call'], callback?: DrawCallback) {
    super({
      chart,
      pointsCount: 1,
      ...options,
      callback,
    })
    this.options = extend(defaultLineOptions, options)
    this.keyPoints = this.options.textPoint ?? []

    this.el_d3.attr('class', 'drawing text')

    this.textEle = this.el_d3.append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('fill', '#000')
      .attr('font-size', '14px')
      .attr('cursor', 'hand')

    this.wordbreak(options.text as string)
  }

  private wordbreak (str: string) {
    this.textEle.selectAll('tspan').remove()
    if (str.split(' ').length > 1) {
      const isEmpty = (s: string) => s !== ''
      const strList = R.filter(isEmpty, str.split(' '))
      let eveLine = ''
      const tempArr = []
      for (let i = 0; i < strList.length; i++) {
        eveLine = eveLine + strList[i] + ' '
        if (eveLine.length < alineWordsNum && (eveLine + strList[i + 1]).length >= alineWordsNum) {
          // console.log(eveLine)
          tempArr.push(eveLine)
          eveLine = ''
        }
        if (i === strList.length - 1 && eveLine.length <= alineWordsNum) {
          tempArr.push(eveLine)
          eveLine = ''
        }
      }
      this.handleBreak(tempArr)
    } else {
      const strList = R.splitEvery(alineWordsNum, str.split(''))
      const tempArr = [] as string[]
      for (let i = 0; i < strList.length; i++) {
        tempArr.push(strList[i].join(''))
      }
      this.handleBreak(tempArr)
    }
  }

  private handleBreak (stArr: string[]) {
    for (let i = 0; i < stArr.length; i++) {
      this.textEle.append('tspan')
        .text(stArr[i])
        .attr('x', 0)
        .attr('y', i * textLineHeight)
    }
  }

  private transform (point?: ChartPointer) {
    if (point) {
      this
        .el_d3
        .attr('transform', `translate(${[point.x, point.y]})`)
    }
  }

  style (color?: string) {
    if (color) {
      this.options.lineColor = color
      this.textEle.attr('fill', color)
    } else {
      this.textEle
        .attr('fill', this.options.lineColor)
        .attr('font-size', '14px')
    }

    return this
  }

  /**
   * 更新节点信息 并计算两个节点之间的所有点的x坐标
   * @param points
   */
  syncEndpoints (points: ChartPointer[]) {
    this.keyPoints = R.map(R.pick(['t', 'c']), points)
    return this
  }

  highlight () {
    return this
  }

  locate (): ChartPointer[] | undefined {
    return this.endpoints().length ? this.endpoints() : undefined
  }

  parseEndpoints (): ChartPointer[] {
    if (this.keyPoints.length) {
      return this.keyPoints.map(p => ({
        t: p.t,
        c: p.c,
        sc: p.c + '',
        x: this.chart.x(p.t) ?? NaN,
        y: this.chart.y(p.c),
      }))
    }

    return []
  }

  renderDrawing (init = false) {
    const keyPoint = this.endpoints(0)

    if (keyPoint) {
      if (init) {
        this.style()
      }

      if (Number.isNaN(keyPoint.x)) {
        this.el_d3.style('display', 'none')
        return this
      } else {
        this.el_d3.style('display', '')
      }

      this.transform(keyPoint)
      this.wordbreak(this.options.text)
    }

    return this
  }

  create () {
    this.style()
      .draw(
        p => {
          this.transform(p)
        },
        this.transform.bind(this),
      )

    return this
  }

  updateLabel () {
    return this
  }

  updateText (t: string) {
    this.options.text = t
    this.wordbreak(t)
    return this
  }

  updateKeyPoints () {
    return this
  }

  dragStart (p: Point) {
    this.keyPoints = R.clone(this.endpoints())
    this.touchOffset = [p[0] - (this.keyPoints[0].x || 0), 0]
    return this
  }

  drag (e: Event, p: ChartPointer) {
    const time = this.chart.invertX(p.x - this.touchOffset[0]) as number
    p.t = time
    p.x = this.chart.x(time) as number
    this.updateEndpoints([p])
    this.renderDrawing()
    return this
  }

  dragEnd () {
    super.dragEnd()
    return this
  }

  remove () {
    super.remove()

    this.keyPoints = []
  }
}
