/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/15
 * @description
 *   user.ts of WeTrade
 */
import {
  firebaseTrack,
  login as syncLoginToNative,
  logout as syncLogOutToNative,
} from '@/common/jsBridge.api'
import { showAlert } from '@/components/popup/popup'
import { events, keymap } from '@/config'
import { verifyLogin } from '@/modules/app/app'
import { User } from '@/modules/user/user.api'
import state from '@/state'
import { logout as syncLogoutState } from '@/state/actions'
import { localRemove, localSet } from 'essential/store/localStore'
import { emit } from 'essential/tools/event'
import * as R from 'ramda'
import { computed } from 'vue'

export const syncLocalUser = (user?: User) => {
  if (!user) showAlert('No login user information respond')
  else {
    const keys = keymap.user
    R.mapObjIndexed((storeKey, key: keyof typeof keys) => {
      // 先清除，再赋值,防止串号
      localRemove(storeKey)

      const value = user[key]
      if (R.complement(R.isNil)(value)) {
        localSet(storeKey, String(value))
      }
    }, keys)
  }
}

/**
 * 准备登录
 * @param resp
 */
export const login = (resp?: User) => {
  if (resp) {
    // 同步缓存
    syncLocalUser(resp)
    // 给App同步登录信号
    syncLoginToNative()
    // 广告追踪
    firebaseTrack()

    verifyLogin()
  }
}

const easeLocalUser = () => {
  const process = R.mapObjIndexed(key => {
    if (R.is(Object, key)) {
      process(key as Data)
    } else {
      if (key === keymap.user.mobile) return
      if (key === keymap.label.uuid) return
      localRemove(key as string)
    }
  })
  process(R.mergeRight(keymap.user, keymap.label))
}

export const syncLogout = () => {
  // 擦除缓存用户
  easeLocalUser()

  // 重置内存用户
  syncLogoutState()

  // 广播
  emit(events.logout)
  emit(events.accountChanged)

  // 通知native
  syncLogOutToNative()
}

export const usePositionCount = (code?: string) => computed(
  () => state.account.holdList?.length ? R.length(code ? R.filter(R.propEq('code', code), state.account.holdList) : state.account.holdList) : 0)
