/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/2 13:42
 * @description
 *   XAxis.ts of FAST
 */
import { ScaleBand } from 'd3'
import { MonitorType } from '../core/dataMaster/AbstractDataMaster'
import { defaultAxisDisplay } from '../defaults'
import DataMaster from '../core/dataMaster/DataMaster'
import { adjustBarTime, extend, msOf } from '../helper'
import { AxisDisplay, ISelection, OptionsOf, Whitespace, Periodicity } from '../types'
import * as d3 from 'd3'
import * as R from 'ramda'
import renderer from './renderer'
import zoomBandScale from './zoomBandScale'

type XAxisOptionalOptions = {
  precision: number;
  height: number;
} & AxisDisplay

type XAxisRequiredOptions = {
  container: string;
  canvasWidth: number;
  canvasHeight: number;
  whitespace: Whitespace;
  periodicity: Periodicity;
  dataMaster: DataMaster;
  candleWidth: number;
}

export type XAxisOptions = OptionsOf<XAxisOptionalOptions, XAxisRequiredOptions>

const defaultXAxisOptions: XAxisOptionalOptions = {
  height: 20,
  precision: 0.01,
  ...defaultAxisDisplay,
}

class XAxis {
  private readonly options: XAxisOptions['define']

  private readonly el_d3
  private readonly renderer
  private userDomain: number[] | null = null

  private readonly dataMaster: DataMaster

  private tickInterval

  fx: ScaleBand<number>
  xRound
  zoom: ReturnType<typeof zoomBandScale>

  constructor (options: XAxisOptions['call']) {
    this.options = extend(defaultXAxisOptions, options)

    this.dataMaster = this.options.dataMaster

    /**
     * 一个tick代表的时长
     */
    this.tickInterval = msOf(this.options.periodicity)

    this.el_d3 = d3
      .select(`#${this.options.container}`)
      .append('g')
      .attr('class', 'axis x-axis') as ISelection<SVGGElement>

    this.fx = d3
      .scaleBand<number>()
      .paddingInner(0.2)
      .paddingOuter(0)
      // .round(true)
      /**
       * x轴的range以后端为基准，向左计算 左端的range <=0
       */
      .range([0, this.options.canvasWidth])
    // .align(0)

    this.xRound = adjustBarTime(this.options.periodicity)

    this.renderer = renderer

    this.zoom = zoomBandScale(
      this.fx,
      this.options.canvasWidth,
      this.options.whitespace.right,
    )

    // this.transform(d3.zoomIdentity, this.options.candleWidth)
    this.restore()

    this.monitorDataMaster()
  }

  // @todo 排查为什么会出现emitter的domain中tick丢失的问题解决后这里的while循环可以移除
  fxRound (t: number) {
    const mostLeft = this.fx.domain()[0]
    let roundTime = this.xRound(t)

    do {
      const roundX = this.fx(roundTime)
      if (roundX) {
        return roundX
      }
      roundTime -= this.tickInterval
    } while (roundTime >= mostLeft)
  }

  render () {
    if (this.options.display) {
      this.el_d3.call(this.renderer, this.fx, this.options)
    }
  }

  fixedDomain (domain: number[]) {
    this.userDomain = domain
    this.scale(domain)
  }

  scale (domain: number[]) {
    this.fx.domain(domain)
    // console.log('jojo axis domain', domain.length, 'first', domain[0], 'x', this.fx(domain[0]))
    this.render()
  }

  /**
   * 重置xAxis视图
   */
  restore () {
    const transformation = this.transform(d3.zoomIdentity, this.options.candleWidth)
    this.dataMaster?.restrict(transformation.renderSize, transformation.paddingSize)
    // this.transformedSize = 0
  }

  transform (transform: d3.ZoomTransform, step?: number) {
    const transformation = this.zoom(transform, step)
    this.fx.range(transformation.range)
    return transformation
  }

  private extend (domain: number[], size: number) {
    const whiteFrom = domain.slice(-1)[0]
    const fill: number[] = []

    for (let i = 1; i <= size; i++) {
      fill.push(whiteFrom + this.tickInterval * i)
    }

    return domain.concat(fill)
  }

  scaleLatest () {
    if (this.userDomain) {
      return
    }

    const domain = R.pluck('t', this.dataMaster.rendered[0])
    const extendedDomain = this.extend(domain, this.dataMaster.rendered[1])
    this.scale(extendedDomain)
  }

  monitorDataMaster () {
    this.dataMaster.monitor(MonitorType.DATA, () => {
      this.scaleLatest()
    })
  }

  config (periodicity: Periodicity) {
    this.options.periodicity = periodicity as Required<Periodicity>
    this.tickInterval = msOf(periodicity)
    this.xRound.config(periodicity)
  }

  destroy () {
    console.log('x-axis destroy')
  }
}

export default XAxis
