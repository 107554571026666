/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/22
 * @description
 *   reducer.ts of FastTradeV3
 */
import { parseToJson } from '@/common/format'
import { showAlert } from '@/components/popup/popup'
import { emit } from 'essential/tools/event'
import { events } from '@/config'

const updateTransaction = () => {
  setTimeout(() => emit(events.transactionUpdate), 2000)
}

const transactionUpdateCommission = () => {
  setTimeout(() => emit('transactionUpdateCommission'), 2000)
}

const limitOrder = (broadcast: any) => {
  const result = parseToJson(broadcast.data).json
  const { data } = result
  if (data.entrustPrice) {
    transactionUpdateCommission()
    if (data.status === 1) {
      updateTransaction()
      showAlert('Limit order executed')
    } else {
      showAlert('Limit order failed')
    }
  }
}

const tradeNotificationReducer = (broadcast: any) => {
  console.log(broadcast, '==========broadcast===========')

  // 挂单
  if (broadcast.msgType === 100) {
    return limitOrder(broadcast)
  }
  // trade 平仓
  if (broadcast.msgType === 101 && broadcast.category === 4) {
    return updateTransaction()
  }
  if (broadcast.msgType === 8 && broadcast.category === 4) {
    return updateTransaction()
  }
}

export default tradeNotificationReducer
