/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/9
 * @description
 *   types.d.ts of WeTrade
 */
export enum YesOrNo {
  NO,
  YES,
}

export type Data = Record<string, unknown>

export type Broadcast<T> = {
  message: string;
  cmd: T;
}

export enum TradeFlag {
  UNKNOWN = -1, // 老用户
  ROOKIE, // 新手
  VETERAN, // 老鸟
  UNSET // 未设置
}

export enum MarketState {
  OPEN = '1',
  CLOSED = '2'
}

export enum TradeDirection {
  SELL = 1,
  BUY = 2,
}
