
import Icon from '@/components/Icon.vue'
import Donut from 'common/loading/Donut.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheToggleStateButton',
  components: { Donut, Icon },
  props: {
    progress: Boolean,
    on: Boolean,
  },
})
