/**
 *  Gesture.ts of project wetrade
 *  @date 2022/5/17 11:37
 *  @author 阿佑[ayooooo@petalmail.com]
 */
import * as d3 from 'd3'
import * as R from 'ramda'
import { MonitorType } from '../core/dataMaster/AbstractDataMaster'
import { resolvePrecision } from '../helper'
import { CrosshairHandler, IChart, PointerHandler, PointerHandlerType } from '../interface/IChart'
import { Bar } from '../types'
import { Transformation } from '../xAxis/zoomBandScale'
import Base, { ChartOptions } from './Base'

export const like: (ref: string | number, n: string | number) => string = R.useWith(
  R.invoker(1, 'toFixed'),
  [
    R.pipe(
      String,
      R.split('.'),
      R.nth(1),
      R.propOr(0, 'length'),
    ),
    Number,
  ],
)

class GestureChart extends Base {
  private pointerMonitor: Record<PointerHandlerType, PointerHandler[]> = {
    click: [],
    move: [],
  }

  private readonly subscribers: IChart[] = []

  // 十字线激活标识
  private crosshairActivated = false
  private crosshairCanceled = false
  private crosshairMoveHandler: CrosshairHandler | null = null
  private precision = 0

  inReview = false

  syncedTransform: d3.ZoomTransform = d3.zoomIdentity
  zoomRestore: (() => void) | null = null

  constructor (options: ChartOptions['call']) {
    super(options)

    if (this.options.pan) {
      const zoom = this.supportZoom()

      this.zoomRestore = () => {
        this.chart.call(zoom.transform, d3.zoomIdentity) // 重置ZoomTransform
      }

      this.chart.call(zoom)
    }

    if (this.options.crosshair) {
      this.supportCrosshair()
    }

    this.on('pointermove.global', e => this.emitPointerMove('move', e))

    this.on('click.global', e => this.emitPointerMove('click', e))

    this.dataMaster.monitor(MonitorType.EXTENT, () => {
      this.precision = resolvePrecision(this.yAxis.fy.domain())
    })
  }

  private emitPointerMove (type: PointerHandlerType, e: Event) {
    if (this.pointerMonitor[type].length === 0) {
      return
    }

    const [x, y] = this.pointer(e)

    const step = this.xAxis.fx.step()
    const left = this.xAxis.fx.range()[0]
    const X = x - left
    const index = Math.floor(X / step)

    const bar = R.clone(this.dataMaster.index(index) ?? this.dataMaster.last())

    const close = this.yAxis.fy.invert(y) ?? 0

    const pointer = {
      x: this.xAxis.fx(bar.t) ?? 0,
      y,
      c: close,
      t: bar.t,
      sc: this.fix(close),
      bar,
    }

    R.map(fn => fn?.(pointer), this.pointerMonitor[type])
  }

  private supportCrosshair () {
    this.on('pointerdown.ch', e => {
      this.crosshairActivated = false
      this.crosshairCanceled = false
      this.crosshairMoveHandler?.(null)

      setTimeout(() => {
        this.crosshairActivated = !this.crosshairCanceled

        if (this.crosshairActivated) {
          this.trackPointer('move', this.crosshairMoveHandler)
          this.emitPointerMove('move', e)
        }
      }, 1000 * 0.25)
    })

    this.on('pointerup', () => {
      this.stopTrackPointer('move', this.crosshairMoveHandler)
      this.crosshairCanceled = true
      this.crosshairActivated = false
    })
  }

  private supportZoom () {
    const zoom = d3
      .zoom<SVGSVGElement, Bar>()
      .scaleExtent([0.2, 5])
      .translateExtent([[-2400, -Infinity], [this.width, Infinity]])
      .on('start', () => {
        this.chart.call(zoom.transform, this.syncedTransform)
      })
      .on('zoom', e => {
        // console.log(e.transform)
        if (this.crosshairActivated) { // 十字线打开 忽略平移操作
          return
        }

        if (JSON.stringify(e.transform) !== JSON.stringify(this.syncedTransform)) {
          this.crosshairCanceled = true
        } else {
          return
        }

        // 保证动画的流畅
        requestAnimationFrame(() => {
          if (e?.sourceEvent?.isTrusted) {
            this.transform(e.transform)

            if (!this.inReview) {
              this.inReview = true
              this.addRestoreButton()
            }
          }
        })
      })

    return zoom
  }

  private addRestoreButton () {
    const userButton: HTMLButtonElement | null = document.querySelector(this.options.restoreButton)

    let button: HTMLButtonElement = document.createElement('button')

    if (userButton) {
      button = userButton
    } else {
      button.innerText = '<-'

      button.style.cssText = `
        display: none;
        position: absolute;right: 40px;bottom: 40px;
        border:none;
        font-weight: bold;
      `
      document.querySelector(`#${this.options.container}`)?.parentElement?.append(button)
    }

    button.style.display = 'initial'

    button.onclick = null

    button.onclick = () => {
      this.restore()

      if (userButton) {
        button.style.display = 'none'
      } else {
        button.remove()
      }
    }
  }

  private syncTransform (transform: d3.ZoomTransform, transformation: Transformation) {
    this.syncedTransform = transform
    this.subscribers.map(chart => {
      chart.inReview = true
      chart.syncedTransform = transform
      chart.xAxis.fx.range(transformation.range)
    })
  }

  private syncRestore () {
    this.subscribers.map(chart => {
      chart.xAxis.restore()
      chart.inReview = false
      chart.syncedTransform = d3.zoomIdentity
    })
  }

  fix (n: number) {
    return n.toFixed(this.precision)
  }

  pointer (e: Event) {
    return d3.pointer(e, this.chart.node())
  }

  onCrosshairMove (handler: CrosshairHandler) {
    this.crosshairMoveHandler = handler
  }

  transform (transform: d3.ZoomTransform) {
    const transformation = this.xAxis.transform(transform)
    // 计算
    this.syncTransform(transform, transformation)
    this.dataMaster.transform(transformation, () => {
      console.log('NO MORE DATA')
    })
  }

  trackPointer (type: PointerHandlerType, handler: PointerHandler | null) {
    if (handler) this.pointerMonitor[type].push(handler)
  }

  stopTrackPointer (type: PointerHandlerType, handler: PointerHandler | null) {
    if (handler) this.pointerMonitor[type] = R.reject(R.equals(handler), this.pointerMonitor[type])
  }

  sync (chart: IChart) {
    this.subscribers.push(chart)
  }

  restore () {
    if (this.inReview) {
      this.inReview = false

      this.zoomRestore?.()

      this.syncedTransform = d3.zoomIdentity

      this.syncRestore()

      this.xAxis.restore()

      this.dataMaster.restore()
    }
  }
}

export default GestureChart
