
import { events } from '@/config'
import { emit } from 'essential/tools/event'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SymbolLink',
  props: {
    code: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
    withHistoryBack: {
      type: Boolean,
    },
  },
  setup (props) {
    return {
      linkSymbol () {
        if (!props.code) return

        emit(events.symbolChange, props.code)

        if (props.withHistoryBack) {
          history.back()
        }
      },
    }
  },
})
