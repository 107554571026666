
import RangeInput from '@/components/RangeInput.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheLimitRangeInput',
  components: { RangeInput },
  props: {
    min: {
      type: Number,
      default: 10,
    },
    max: {
      type: Number,
      default: 200,
    },
    step: {
      type: Number,
      default: 5,
    },
    value: Number,
  },
})
