
import { provide, shallowRef, defineComponent } from 'vue'

export default defineComponent(
  {
    name: 'TransitionProvider',
    props: {
      provide: {
        type: String,
        default: '_transition_in_state_',
      },
    },
    setup (props) {
      const ready = shallowRef(false)

      provide(props.provide, ready)

      return { ready }
    },
  },
)
