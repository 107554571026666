
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TransitionPic',
  components: { Pic },
  props: {
    path: {
      type: String,
      required: true,
    },
    active: Boolean,
  },
})
