
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import SymbolLink from '@/provider/SymbolLink.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ForStock',
  components: { SymbolLink, Icon, Pic },
  props: {
    stocks: {
      type: Array,
      required: true,
    },
  },
})
