/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   popup.ts of WeTrade
 */
import { popup, PopupPublicOptions } from 'common/popout/popup'
import { ComponentOptions, h } from 'vue'
import TouchableAlert from '@/components/popup/TouchableAlert.vue'

type AlertOption = {
  zIndex?: number;
  autoClose?: boolean;
  wrapperClass?: string;
  bodySlot?: boolean;
  closeTime?: number;
  clickDisable?: boolean;
}

export const showAlert = (msg: string | ComponentOptions, options?: AlertOption) => {
  const close = popup({
    component: {
      setup () {
        const child = (typeof msg === 'string') ? msg : h(msg, {
          onClose: close,
        })

        return () => h(TouchableAlert, {
          class: `alert__wrapper ${options?.wrapperClass}`,
          autoClose: options?.autoClose,
          zIndex: options?.zIndex,
          closeTime: options?.closeTime,
          clickDisable: options?.clickDisable,
        }, {
          [options?.bodySlot ? 'body' : 'default']: () => child,
        })
      },
    },
    class: 'alert p-r',
    transition: 'slide-down',
  })
  return close
}

export const openDialog = <P> (
  Component: ComponentOptions<P>,
  props?: P & Data | null,
  options?: Partial<PopupPublicOptions>,
) => {
  const closePopup = popup({
    component: {
      setup () {
        return () => h('div', {
          name: 'Dialog',
          class: ['dialog__wrapper', options?.wrapperClass ?? 'notification'],
        }, h(Component, {
          ...props,
          onClose (cb?: () => void) {
            closePopup()

            if (typeof props?.onClose === 'function') {
              props.onClose()
            }

            if (typeof cb === 'function') {
              cb()
            }
          },
        } as unknown as P))
      },
    },
    backToClose: true,
    class: 'dialog',
    transition: 'slide-up',
    ...options,
    onClose: props?.onClose as () => void,
  })

  return closePopup
}

export const openFullscreenList = <P> (
  Component: ComponentOptions<P>,
  props?: P & Data,
  options?: Partial<PopupPublicOptions>,
) => {
  openDialog(Component, props, {
    ...options,
    wrapperClass: 'operation',
  })
}
