
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { computed, defineComponent, shallowRef, watch } from 'vue'
import { LineType } from '@/modules/market/market.api'

const colors = [
  '#FF1600', '#FF6800', '#FFCB00', '#00FF15', '#00FFFE', '#00B4FF',
  '#0047FF', '#810093', '#A61FFF', '#FF00EF', '#999999', '#000000',
]

export default defineComponent({
  name: 'TheDrawingDialog',
  components: {
    Pic,
    Icon,
  },
  emits: ['color', 'dismiss', 'toggleAlert', 'remove', 'editText'],
  props: {
    y: Number,
    x: Number,
    alertOn: Boolean,
    color: String,
    type: Number,
  },
  setup (props, ctx) {
    const iColor = shallowRef('')
    const cColor = computed(() => iColor.value || props.color)
    const isPicking = shallowRef(false)
    const progress = shallowRef(false)

    watch(() => props.y, () => iColor.value = '')

    const pickColor = (c: string) => {
      iColor.value = c
      isPicking.value = false
      ctx.emit('color', c)
    }

    const toggleAlert = () => {
      if (progress.value) return

      progress.value = true

      ctx.emit('toggleAlert', () => {
        progress.value = false
      })
    }

    const dismiss = () => {
      isPicking.value = false

      if (!progress.value) ctx.emit('dismiss')
    }

    const editText = () => {
      ctx.emit('editText')
    }

    return {
      cColor,
      colors,
      isPicking,
      progress,
      pickColor,
      toggleAlert,
      dismiss,
      editText,
      LineType,
    }
  },
})
