/**
 * @author zjc[beica1@outook.com]
 * @date 2021/7/30 13:56
 * @description
 *   line.ts of FAST
 */
import AbstractGraph from '../core/AbstractGraph'
import { Bar, ISelection } from '../types'

class Line extends AbstractGraph {
  render (g: ISelection<SVGGElement>, quotes: Bar[]) {
    g
      .selectAll('path')
      .data([quotes])
      .join('path')
      .attr('fill', 'none')
      .attr('stroke', this.options.lineColor())
      .attr('stroke-width', this.options.lineWidth)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('d', this.defaultLineRenderer)
  }
}

export default Line
