/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/4/13
 * @description
 *   notification.parser.ts of WeTrade
 */

import { parseToJson } from '@/common/format'
import { RouteLocationOptions, Router } from 'vue-router'

enum MessageType {
  ANNOUNCEMENT, // 公告
  NEWS, // 咨询
  BUY, // 买入挂单执行成功
  SELL, // 卖出挂单执行成功
  STOP_LOSS, // 止损
  TAKE_PROFIT, // 止盈
  SYSTEM_CLOSE, // 爆仓
  AUTO_CLOSE, // 平仓
  MARGIN_CALL, // 仓位预警

  BATCH_PUSH = 13, // 批量推送
  POST, // 梯子推送

  DEMO_BUY = 22,
  DEMO_SELL,
  DEMO_STOP_LOSS,
  DEMO_TAKE_PROFIT,
  DEMO_SYSTEM_CLOSE,
  DEMO_AUTO_CLOSE,
  DEMO_MARGIN_CALL,
}

type Message = {
  title: string,
  body: string;
  type: MessageType;
  metalType: string;
  jumpagreement: string;
  url: string;
  postId?: number;
}

const redirect = (message: Message) => {
  switch (message.type) {
    case MessageType.POST:
      return {
        name: 'post',
        params: {
          postId: message.postId,
        },
      }
    case MessageType.MARGIN_CALL:
    case MessageType.DEMO_MARGIN_CALL:
      return {
        name: 'trade',
      }
    case MessageType.NEWS:
      return {
        name: 'community',
      }
    case MessageType.ANNOUNCEMENT:
    case MessageType.BATCH_PUSH:
      return null
    default:
      return {
        name: 'history',
      }
  }
}

const parsePushMessage = (message: string, router: Router) => {
  const messageObj = parseToJson(message).json as Message
  if (!messageObj) return
  const route = redirect(messageObj) as RouteLocationOptions
  if (route) {
    setTimeout(() => {
      router.push(route)
    }, 0)
  }
  // else openDialog()
}

export default parsePushMessage
