
import { defineComponent, shallowRef } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import TheScoreItem from '@/modules/home/components/TheScoreItem.vue'
import { submitEvaluate } from '@/modules/home/home.api'
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'TheEvaluateDialog',
  components: { DialogTemplate, TheScoreItem },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  setup (props, ctx) {
    const submitAble = shallowRef(false)
    const list = [{
      title: 'The speed of response',
      label: 'home_47',
      score: 0,
    }, {
      title: 'The ability to ptoblem-solving',
      label: 'home_48',
      score: 0,
    }, {
      title: 'The ease of communication',
      label: 'home_49',
      score: 0,
    }]
    const submit = () => {
      if (!submitAble.value) {
        return
      }

      submitEvaluate({
        speedResponse: list[0].score,
        abilitySolving: list[1].score,
        easeCommunication: list[2].score,
      }).then(() => {
        showAlert(translate('toast_18', 'Thank you for your support'))
      })
      ctx.emit('close')
    }

    const checkSubmiAble = () => {
      let status = true
      list.forEach(item => {
        if (item.score === 0) {
          status = false
        }
      })
      return status
    }

    const handleScoreChange = (index: number, val: number) => {
      list[index].score = val
      submitAble.value = checkSubmiAble()
    }
    return {
      list,
      submitAble,
      submit,
      handleScoreChange,
    }
  },
})
