
import { defineComponent, shallowRef } from 'vue'
import { RecommendTag } from '@/modules/home/home.api'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import Pic from 'common/Pic.vue'
import Icon from '@/components/Icon.vue'
import TheRecommendChart from '@/modules/home/components/recommendChart/TheRecommendChart.vue'
import SymbolLink from '@/provider/SymbolLink.vue'

export default defineComponent({
  name: 'TheRecommendItem',
  components: {
    Pic,
    CurrentQuote,
    RealTimeQuote,
    Icon,
    TheRecommendChart,
    SymbolLink,
  },
  props: {
    item: Object,
  },
  setup () {
    const reLoadDate = shallowRef(0)

    const riseTurn = () => {
      reLoadDate.value = new Date().valueOf()
    }
    return {
      RecommendTag,
      reLoadDate,
      riseTurn,
    }
  },
})
