/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   store.ts of WeTrade
 */
import * as R from 'ramda'

export const createStore = (key: string, initialValue: Data) => {
  const store: Data = {}

  if (key) {
    store[key] = initialValue

    return Object.freeze(
      {
        get () {
          return store[key]
        },
        set (v: unknown) {
          store[key] = v
        },
      },
    )
  }

  return Object.freeze(
    {
      get: R.prop(R.__, store),
      set: R.curry((k, v) => {
        store[k] = v
      }),
    },
  )
}
