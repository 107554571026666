
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'
import { useAccounts } from '@/state/accountType'
import { AccountType } from '@/state'
import { useRouter } from 'vue-router'
import DialogTemplate from '@/views/DialogTemplate.vue'

export default defineComponent({
  name: 'DepositDialog',
  components: { Icon, Pic, Button, DialogTemplate },
  emits: ['close'],
  setup (props, ctx) {
    const router = useRouter()
    const { accounts, change } = useAccounts()
    return {
      deposit () {
        change(accounts[AccountType.REAL])
        router.push('/in')
        ctx.emit('close')
      },
    }
  },
})
