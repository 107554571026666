import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransitionProvider = _resolveComponent("TransitionProvider")

  return (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.widgets, (widget) => {
    return (_openBlock(), _createBlock(_Teleport, {
      key: widget.id,
      to: "body"
    }, [
      _createVNode("div", {
        class: ['popup', widget.class],
        onClick: ($event: any) => (widget.touchToClose ? $setup.close(widget.id) : () => {})
      }, [
        _createVNode(_component_TransitionProvider, {
          name: widget.transition,
          appear: "",
          onAfterLeave: ($event: any) => ($setup.remove(widget.id))
        }, {
          default: _withCtx(() => [
            (widget.render)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(widget.component), {
                  id: widget.id,
                  key: widget.id,
                  onClose: ($event: any) => ($setup.close(widget.id))
                }, null, 8, ["id", "onClose"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["name", "onAfterLeave"])
      ], 10, ["onClick"])
    ]))
  }), 128))
}