
import Icon from '@/components/Icon.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'RangeInput',
  components: { Icon },
  props: {
    min: {
      type: Number,
      default: -Infinity,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    value: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  emits: ['change', 'update:value', 'exceed'],
  setup (props, ctx) {
    const output = shallowRef(props.value)
    const inputMode = shallowRef(false)

    const update = (value: number) => {
      inputMode.value = false

      if (value < props.min || value > props.max) {
        ctx.emit('exceed')
      }

      const next = Math.max(props.min, Math.min(props.max, value))

      if (output.value !== next) {
        output.value = next

        ctx.emit('change', output.value)
        ctx.emit('update:value', output.value)
      }
    }

    const reduce = () => {
      update(output.value - props.step)
    }

    const increase = () => {
      update(output.value + props.step)
    }

    const correct = (v: string | number) => {
      const next = Number(v)

      if (Number.isNaN(next)) return

      update(next)
    }

    const isBound = (left = false) => {
      return left ? output.value <= props.min : output.value >= props.max
    }

    correct(props.value)

    return {
      output,
      inputMode,
      reduce,
      increase,
      correct,
      isBound,
    }
  },
})
