
import { TradeDirection } from '@/types'
import { computed, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import Time from '@/components/Time.vue'
import { formatDateTime } from '@/common/format'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheAnalysisItem',
  components: { Time, Icon, Pic },
  props: {
    advice: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const today = formatDateTime('MM-dd-yy', new Date().getTime())

    const createDay = computed(() => {
      return formatDateTime('MM-dd-yy', props.advice.createTime)
    })

    const isUp = computed(() => {
      return props.advice.direction === TradeDirection.BUY
    })

    return {
      today,
      createDay,
      isUp,
    }
  },
})

