
import Nullable from '@/components/Nullable.vue'
import { events } from '@/config'
import TheCommissionItem from '@/modules/trade/components/TheCommissionItem.vue'
import TheTradeGroup from '@/modules/trade/components/TheTradeGroup.vue'
import { Commission, readCommissions } from '@/modules/trade/trade.api'
import Holder from '@/provider/Holder.vue'
import List from '@/components/AsyncNullableList.vue'
import { off, on } from 'essential/tools/event'
import * as R from 'ramda'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'
import withUserContext from '@/decorators/withUserContext'

export default defineComponent({
  name: 'TheCommissions',
  components: { Nullable, TheTradeGroup, TheCommissionItem, List, Holder },
  props: {
    code: String,
  },
  setup (props) {
    const commissions = shallowRef<Commission[]>([])
    const leverage = ['', 'transactionhistory_26', 'transactionhistory_27', 'transactionhistory_28']

    const refresh = () => readCommissions().then(resp => {
      resp = resp?.map(item => {
        if (item.levelType && item.levelType > 0) {
          item.lever = leverage[item.levelType]
        }
        item.weight = String(Number(item.weight))
        return item
      })
      commissions.value = props.code
        ? R.filter(R.propEq('code', props.code), resp)
        : resp
    })

    on(['transactionUpdateCommission', events.accountChanged], refresh)

    onBeforeUnmount(() => {
      off(['transactionUpdateCommission', events.accountChanged], refresh)
    })

    withUserContext(() => refresh())()
    // refresh()

    return {
      showAll: !props.code,
      list: commissions,
    }
  },
})
