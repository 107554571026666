
import Icon from '@/components/Icon.vue'
import { events, keymap } from '@/config'
import { cachePage, cancelCachePage } from '@/features/dynamicAlive'
import TheChart from '@/modules/market/components/chart/TheChart.vue'
import TheMarketClosedTip from '@/modules/market/components/TheMarketClosedTip.vue'
import TheSymbolActions from '@/modules/market/components/TheSymbolActions.vue'
import TheSymbolMarketState from '@/modules/market/components/TheSymbolMarketState.vue'
import { useQuote } from '@/modules/market/market'
import { MarketState } from '@/types'
import Donut from 'common/loading/Donut.vue'
import { on } from 'essential/tools/event'
import { computed, defineComponent, shallowRef } from 'vue'

import { ESpecialType } from '@/modules/market/market.api'
import { localGet, localSet } from 'essential/store/localStore'

let FROM_PATH = ''

export default defineComponent({
  name: 'Symbol',
  components: {
    Donut,
    Icon,
    TheChart,
    TheMarketClosedTip,
    TheSymbolMarketState,
    TheSymbolActions
  },
  beforeRouteEnter(to, from) {
    FROM_PATH = FROM_PATH === '' ? from.path : FROM_PATH
  },
  beforeRouteLeave(to) {
    if (to.path === FROM_PATH || to.meta.noCache) {
      cancelCachePage('Symbol')
    } else {
      cachePage('Symbol')
    }
  },
  setup() {
    const code = shallowRef('')
    const symbol = useQuote(code, true)
    const fullscreen = shallowRef(false)

    const showSpecialTip = shallowRef(false)
    const sepcialType = shallowRef(ESpecialType.NORMAL)

    const haveShow = localGet(keymap.guide.specialTips)
      ? (localGet(keymap.guide.specialTips) as string).split(',')
      : ['0', '0']

    // const handleCheck = (code: string) => {
    //   checkIsSpecial({
    //     contract: code
    //   }).then(res => {
    //     sepcialType.value = res.specialIdentification
    //     if (haveShow[sepcialType.value - 1] === '0') {
    //       showSpecialTip.value = true
    //     }
    //   })
    // }

    const handleCloseTip = () => {
      haveShow[sepcialType.value - 1] = '1'
      localSet(keymap.guide.specialTips, haveShow.join().toString())
      showSpecialTip.value = false
    }

    on(events.symbolChange, (newCode, forDefault) => {
      if ((forDefault && !code.value) || !forDefault) {
        code.value = newCode
        showSpecialTip.value = false
        // handleCheck(newCode)
      }
    })

    // const { toggle, added, progress } = useWatchState(code)

    return {
      isClosed: computed(() => symbol.value?.isClosed === MarketState.CLOSED),
      symbol,
      // added,
      // progress,
      fullscreen,
      // toggle,
      code,
      showSpecialTip,
      handleCloseTip,
      sepcialType,
      ESpecialType
    }
  }
})
