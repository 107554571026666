
import Pic from '@/components/Pic.vue'
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import { DrawingState } from 'happychart/core/AbstractDrawing'
import * as R from 'ramda'
import { defineComponent, shallowRef } from 'vue'

const description = {
  line: {
    [DrawingState.DRAW]: 'symbol_88',
    [DrawingState.COMPLETE]: 'symbol_89',
  },
  segment: {
    [DrawingState.DRAW]: 'symbol_90',
    [DrawingState.STEP]: 'symbol_91',
    [DrawingState.COMPLETE]: 'symbol_92',
  },
  text: {
    [DrawingState.DRAW]: 'symbol_95',
    [DrawingState.COMPLETE]: 'symbol_96',
  },
  arrow: {
    [DrawingState.DRAW]: 'symbol_93',
    [DrawingState.COMPLETE]: 'symbol_94',
  },
}

export default defineComponent({
  name: 'TheGuide',
  components: { Pic },
  setup () {
    const textPath = shallowRef('')

    const onDrawingStateChange = (type: string, state: DrawingState) => {
      if (type === 'line') {
        if (!localGet(keymap.guide.drawLine)) {
          if (R.includes(state, [DrawingState.DRAW, DrawingState.COMPLETE])) {
            textPath.value = description.line[state as keyof typeof description['line']]
          }

          if (state === DrawingState.ACTIVE) {
            localSet(keymap.guide.drawLine, '1')
            textPath.value = ''
          }
        }
      }

      if (type === 'segment') {
        if (!localGet(keymap.guide.drawSegment)) {
          if (R.includes(state, [DrawingState.DRAW, DrawingState.COMPLETE, DrawingState.STEP])) {
            textPath.value = description.segment[state as keyof typeof description['segment']]
          }

          if (state === DrawingState.ACTIVE) {
            localSet(keymap.guide.drawSegment, '1')
            textPath.value = ''
          }
        }
      }

      if (type === 'text') {
        if (!localGet(keymap.guide.drawText)) {
          if (R.includes(state, [DrawingState.DRAW, DrawingState.COMPLETE])) {
            textPath.value = description.line[state as keyof typeof description['text']]
          }

          if (state === DrawingState.ACTIVE) {
            localSet(keymap.guide.drawText, '1')
            textPath.value = ''
          }
        }
      }

      if (type === 'up' || type === 'down') {
        if (!localGet(keymap.guide.drawArrow)) {
          if (R.includes(state, [DrawingState.DRAW, DrawingState.COMPLETE])) {
            textPath.value = description.line[state as keyof typeof description['arrow']]
          }

          if (state === DrawingState.ACTIVE) {
            localSet(keymap.guide.drawArrow, '1')
            textPath.value = ''
          }
        }
      }
    }

    return {
      textPath,
      onDrawingStateChange,
    }
  },
})
