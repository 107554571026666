
import * as R from 'ramda'
import { defineComponent, shallowRef } from 'vue'

type Options = Record<string, unknown> & {
  value: number;
  default: boolean;
}

export default defineComponent({
  name: 'TheSelectBar',
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: Number,
  },
  setup: function (props) {
    const value = shallowRef(-1)

    const select = (item: Options) => {
      value.value = item.value
    }

    const dft = R.find(
      o => props.selected ? props.selected === o.value : o.default, props.options as Options[])

    if (dft) {
      select(dft)
    }

    return {
      value,
      select,
    }
  },
})
