/**
 * assert.js of HappyPera
 * Created by beica on 2019/11/5
 */
import * as R from 'ramda'

export const assertions = {
  isNotNil: R.complement(R.isNil),
  isNil: R.isNil,
  isTrue: R.identity,
  isNumber: R.is(Number),
  isString: R.is(String),
}

const match = (rule, rules) => R.ifElse(R.has(rule), R.flip(R.invoker(1, rule)), R.F)(rules)

const raise = (error = 'assert error') => {
  throw new Error(error)
}

export default (rule, value, error) => {
  R.when(R.complement(match(rule, assertions)), R.thunkify(raise)(error))(value)
}
