/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/18 10:39
 * @description
 *   Trend.ts of WeTrade
 */
import AbstractStudy from '../../core/AbstractStudy'
import { Bar, ISelection } from '../../types'
import { calcTrend, TrendDescriber, TrendState, TrendStudyInputs } from '../formulas'

type TrendArea = {
  from: number;
  to: number;
  trend: number;
}

export type TrendOptions = TrendStudyInputs & {
  fillColor2: string;
}

class Trend extends AbstractStudy<TrendOptions, TrendDescriber> {
  state: TrendState | null = null

  slice (from: number, to: number): TrendDescriber[] {
    return this.cached.slice(from, to)
  }

  calcInitStudy (quotes: Bar[]): TrendDescriber[] {
    const { state, value } = calcTrend(quotes, this.options)
    this.state = state
    return value
  }

  calcCandidateStudy (quotes: Bar[]): TrendDescriber[] {
    if (this.state) {
      // this.state.preFactors = quotes.slice(-1 - (13 /* 默认值 */), -1)
      const { value } = calcTrend(quotes, this.options, this.state)
      return value
    }
    return []
  }

  merge (data: TrendDescriber[]): TrendArea[] {
    const merged: TrendArea[] = []
    const count = data.length

    let trend = NaN
    let from = NaN
    let to = NaN

    for (let i = 0; i < count; i++) {
      const quote = data[i]
      const nextTrend = quote.trend

      if (!nextTrend) continue

      to = quote.t

      // trend变化
      if (trend !== nextTrend) {
        if (trend) {
          merged.push({
            trend,
            from,
            to,
          })
        }

        trend = nextTrend
        from = quote.t
      }
    }

    merged.push({
      trend,
      from,
      to,
    })

    return merged
  }

  render (g: ISelection<SVGGElement>, shapes: TrendDescriber[]) {
    const fx = this.chart.xAxis.fx
    const halfWidth = fx.step() / 2 + fx.padding() * 4

    const data = (this.merge(shapes))

    g
      .selectAll('rect')
      .data(data)
      .join('rect')
      .attr('x', d => (fx(d.from) ?? 0) - halfWidth)
      .attr('width', d => (fx(d.to) ?? 0) - (fx(d.from) ?? 0) + halfWidth * 2)
      .attr('y', 0)
      .attr('height', this.chart.height)
      .attr('fill', d => d.trend > 0 ? this.options.fillColor() : this.options.fillColor2)

    this.containerD3El.lower()
  }
}

export default Trend
