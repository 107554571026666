
import { defineComponent, onUnmounted, watch } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'ReConnect',
  emits: ['close'],
  components: { Pic },
  props: {
    state: Object,
  },
  setup (props, ctx) {
    const stateList = [
      {
        text: 'Connecting to quote server',
        path: 'home_18',
      }, {
        text: 'Connected',
        path: 'home_19',
      }, {
        text: 'Connection failed',
        path: 'home_20',
      },
    ]
    let time = 0

    watch(() => props.state?.value, () => {
      if (props.state?.value > 0) {
        time = window.setTimeout(() => {
          ctx.emit('close')
        }, 800)
      }
    })

    onUnmounted(() => {
      clearTimeout(time)
    })

    return {
      stateList,
    }
  },
})
