import * as d3 from 'd3'
import DataMaster, { DataMasterOptionalOptions } from '../core/dataMaster/DataMaster'
import { defaultShapeStyle } from '../defaults'
import Defines from '../define/Defines'
import { extend, isInRange, randomString } from '../helper'
import { ChartType, ISelection, OptionsOf, Periodicity, ShapeStyle } from '../types'
import XAxis, { XAxisOptions } from '../xAxis/XAxis'
import YAxis, { YAxisOptions } from '../yAxis/YAxis'
import createLoadingSegment from './createLoadingSegment'

/**
 *  Base.ts of project wetrade
 *  @date 2022/5/17 11:37
 *  @author 阿佑[ayooooo@petalmail.com]
 */
export type ChartOptionalOptions = {
  restoreButton: string;
  pan: boolean;
  crosshair: boolean;
  showChart: boolean;
  type: ChartType;
  yAxis?: YAxisOptions['partial'];
  xAxis?: XAxisOptions['partial'];
  layout: {
    candleWidth: number;
    whitespace: Partial<{
      top: number;
      right: number;
      bottom: number;
      left: number;
    }>;
  },
  style: Partial<ShapeStyle>;
  /**
   * 这里和HappyChart中的配置有重叠
   */
} & Partial<DataMasterOptionalOptions>

export type ChartRequiredOptions = {
  container: string;
  symbol: string;
  dataMaster: DataMaster;
  periodicity: Periodicity;
}

export type ChartOptions = OptionsOf<ChartOptionalOptions, ChartRequiredOptions>

const defaultChartOptions: ChartOptionalOptions = {
  restoreButton: '#no_btn',
  pan: true,
  crosshair: false,
  showChart: true,
  type: ChartType.LINE,
  layout: {
    candleWidth: 2,
    whitespace: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  style: defaultShapeStyle,
}

class BaseChart {
  private loadingId = ''

  readonly options: ChartOptions['define']
  readonly id = randomString('c')
  readonly chart: ISelection<SVGSVGElement>
  readonly width
  readonly height

  xAxis: XAxis
  yAxis: YAxis
  defines: Defines
  dataMaster: DataMaster

  constructor (options: ChartOptions['call']) {
    this.options = extend(defaultChartOptions, options)

    const root = document.querySelector(`#${this.options.container}`)

    if (!root) throw new ReferenceError('NO CHART MOUNT POINT')

    const {
      width,
      height,
    } = root.getBoundingClientRect()

    this.width = width
    this.height = height

    // 创建chart ns
    this.chart = d3
      .create('svg')
      .attr('id', this.id)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height].toString()) as unknown as ISelection<SVGSVGElement>

    // chart.append('g').attr('id', this.id)

    root.appendChild(this.chart.node() as Node)

    this.defines = new Defines(this.id)

    this.dataMaster = this.options.dataMaster

    const axisOptions = {
      container: this.id,
      dataMaster: this.dataMaster,
      periodicity: this.options.periodicity,
      canvasWidth: width,
      canvasHeight: height,
      whitespace: this.options.layout.whitespace,
    }

    this.xAxis = new XAxis({
      candleWidth: this.options.layout.candleWidth,
      ...this.options.xAxis,
      ...axisOptions,
    })

    this.yAxis = new YAxis({
      ...this.options.yAxis,
      ...axisOptions,
    })
  }

  x (time: number) {
    return this.xAxis.fx(time)
  }

  y (close: number) {
    return this.yAxis.fy(close)
  }

  /**
   * 因为x轴的不连续性 需要根据偏移量计算所在的bar
   * @param x svg可视坐标的x轴
   */
  invertX (x: number) {
    const range = this.xAxis.fx.range()

    // 取范围内最接近的一个bar
    if (range && isInRange(range, [x, x])) {
      const step = this.xAxis.fx.step()
      const X = range[0] + x
      const floorIndex = Math.floor(X / step)
      const ceilIndex = Math.ceil(X / step)
      const floorTime = this.dataMaster.index(floorIndex).t
      const ceilTime = this.dataMaster.index(ceilIndex).t
      const floorOffset = Math.abs(this.x(floorTime) ?? 0 - x)
      const ceilOffset = Math.abs(this.x(ceilTime) ?? 0 - x)

      return floorOffset < ceilOffset ? floorTime : ceilTime
    }

    // 左边界
    if (x < range[0]) {
      return this.dataMaster.index(0).t
    }

    // 右边界
    return this.dataMaster.last()?.t
  }

  invertY (y: number) {
    return this.yAxis.fy.invert(y)
  }

  loading (state = false) {
    if (state) {
      if (this.loadingId) return
      this.loadingId = randomString('l')
      document.querySelector(`#${this.options.container}`)?.appendChild(
        createLoadingSegment(this.loadingId))
    } else if (this.loadingId) {
      document.querySelector(`#${this.loadingId}`)?.remove()
      this.loadingId = ''
    }
  }

  on (
    type: string,
    listener: ((this: Element, e: Event, d: unknown) => void) | null,
    options?: EventListenerOptions,
  ) {
    if (listener) this.chart.on(type, listener, options)
  }

  off (type: string) {
    this.chart.on(type, null)
  }
}

export default BaseChart
