
import * as R from 'ramda'
import Icon from '@/components/Icon.vue'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Checkbox',
  components: { Icon },
  emits: ['toggle', 'update:modelValue'],
  props: {
    checked: Boolean,
    value: String,
    modelValue: Array,
  },
  setup (props, ctx) {
    const isChecked = computed(() => {
      if (props.modelValue) {
        return R.includes(props.value, props.modelValue)
      } else return props.checked
    })

    const toggle = (checked: boolean) => {
      ctx.emit('toggle', checked)
      if (props.modelValue) {
        const next = checked ? [...props.modelValue, props.value] : R.reject(
          R.equals(props.value), props.modelValue as Array<string>)
        ctx.emit('update:modelValue', next)
      }
    }

    return {
      isChecked,
      toggle,
    }
  },
})
