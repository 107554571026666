import { renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _renderSlot(_ctx.$slots, "default", {
    code: _ctx.symbolCode,
    price: _ctx.price,
    buy: _ctx.buy,
    spread: _ctx.spread,
    margin: _ctx.margin,
    mp: _ctx.mp,
    isRise: _ctx.isRise
  })
}