/**
 * @author 阿佑[ayooooo@petalmail.com]
 * @date 2022/4/15 15:38
 * @description
 *   useHashChange2.ts of WeTrade
 */
import * as R from 'ramda'

const parseQueryString: (query: string) => string[][] = R.pipe(
  R.split('?'), // 分割query字符串
  R.nth(-1),
  R.ifElse(
    R.contains('='), // 判断是否存在键值对
    R.pipe(
      R.replace(/(^|^\?)([^?]*)(&$|$)/, '$2'), // 去除开头的?和结尾的&
      R.split('&'), // 拆分组
      R.map(R.split('=')), // 拆分键值对
    ),
    R.always([]),
  ),
)

const toQueryString = R.pipe(
  R.map(R.join('=')),
  R.join('&'),
  R.ifElse(
    R.identity,
    R.concat('?'),
    R.always(''),
  ),
)

const modifyHash = (hash: string, modifier: (query: string[][]) => string[][]) => {
  const [hashString, queryString = ''] = R.split('?', hash)
  const queryArray = modifier(parseQueryString(queryString))
  return hashString + toQueryString(queryArray)
}

const dialogQueryName = '__d1g'

const findDialogQueryPosition = (query: string[][]) => query.findIndex(([name]) => name === dialogQueryName)

const useHashChange = () => {
  let reuseLastHash = false
  let binding: { id: number; dismiss: () => void }[] = []

  const updateHash = (type?: 'pushState' | 'replaceState') => {
    const hashArray = parseQueryString(location.hash)
    const id = Math.random().toString(36).slice(2, 7)
    const method = type ?? location.hash.indexOf(dialogQueryName) === -1 ? 'pushState' : 'replaceState'

    history[method](
      null,
      document.title,
      modifyHash(
        location.hash,
        query => R.insert(findDialogQueryPosition(query), [dialogQueryName, id], query)
      ),
    )
  }

  const onHashChange = () => {
    if (findDialogQueryPosition(parseQueryString(location.hash)) === -1) {
      binding.pop()?.dismiss()

      if (binding.length) updateHash('replaceState')
      else stop()
    }
  }

  const stop = () => {
    window.removeEventListener('popstate', onHashChange)
    window.removeEventListener('hashchange', onHashChange)
  }

  const monitor = () => {
    window.addEventListener('popstate', onHashChange)
    window.addEventListener('hashchange', onHashChange)
  }

  const bindHashChange = (id: number, dismiss: () => void) => {
    binding.push({ id, dismiss })

    updateHash()

    if (binding.length === 1) {
      monitor()
    }
  }

  const unbindHashChange = (id: number) => {
    const dialog = R.find(R.propEq('id', id), binding)

    binding = R.reject(R.propEq('id', id), binding)

    if (dialog && binding.length === 0) {
      setTimeout(() => {
        if (binding.length === 0) {
          history.back()
        } else {
          dialog.dismiss()
        }
      }, 0)
    }
  }

  return { bindHashChange, unbindHashChange }
}

export default useHashChange
