
import { marketFeed } from '@/common/datafeed'
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import { events } from '@/config'
import { Feedback, useDrawing } from '@/modules/market/components/chart/chart'
import {
  BarTypes,
  panelStudies,
  readResolutions,
  ResolutionConfig,
  StudyConfig
} from '@/modules/market/components/chart/chartConfig'
import datafeed from '@/modules/market/components/chart/datafeed'
import TheCrosshair from '@/modules/market/components/chart/TheCrosshair.vue'
import TheDrawingDialog from '@/modules/market/components/chart/TheDrawingDialog.vue'
import TheGuide from '@/modules/market/components/chart/TheGuide.vue'
import TheHorizontalScan from '@/modules/market/components/chart/TheHorizontalScan.vue'
import ThePositionLines from '@/modules/market/components/chart/ThePositionLines.vue'
import ThePriceLabel from '@/modules/market/components/chart/ThePriceLabel.vue'
import TheResolutionArea from '@/modules/market/components/chart/TheResolutionArea.vue'
import TheStudyBar from '@/modules/market/components/chart/TheStudyBar.vue'
import TheTrend from '@/modules/market/components/chart/TheTrend.vue'
import { useOverlayStudies } from '@/modules/market/market'
import { off, on } from 'essential/tools/event'
import { nextLoop } from 'essential/util'
import HappyChart, { ChartType } from 'happychart'
import Chart from 'happychart/chart/Chart'
import { DrawingOptions } from 'happychart/drawing/Drawing'
import { ChartPointer } from 'happychart/interface/IChart'
import * as R from 'ramda'
import { openDialog } from '@/components/popup/popup'
import TheDrawTextDialog from '@/modules/market/components/TheDrawTextDialog.vue'
import { defineComponent, onBeforeUnmount, onMounted, shallowRef, watchEffect } from 'vue'
type TDrawTypes = 'line' | 'segment' | 'text'

export default defineComponent({
  name: 'TheChart',
  components: {
    TheGuide,
    TheResolutionArea,
    TheDrawingDialog,
    TheTrend,
    ThePositionLines,
    TheHorizontalScan,
    TheCrosshair,
    Pic,
    ThePriceLabel,
    TheStudyBar,
    Line
  },
  props: {
    code: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const price = shallowRef('')
    const position = shallowRef<{ top: string; left: string }>({
      top: '0px',
      left: '0px'
    })
    const crosshair = shallowRef<ChartPointer | null>(null)
    const chartGridColor = 'rgba(244,246, 248, 0.5)'
    const fullscreen = shallowRef(false)
    const modifyDrawing = shallowRef(false)
    const modifyDrawingPosition = shallowRef(0)
    const resolutionValue = shallowRef(BarTypes.K_1)

    let hc: HappyChart | null = null
    let mainChart: Chart | null = null
    let studyChart: Chart | null = null

    const {
      read: readLines,
      drawingBindings,
      draw,
      create,
      style,
      toggleAlert,
      remove,
      editText
    } = useDrawing(state => (modifyDrawing.value = state))

    const drawingListeners = {
      color: (color: string) => style(color),
      toggleAlert,
      remove: () => remove(mainChart),
      editText: () => {
        openDialog(TheDrawTextDialog, {
          oldVal: drawingBindings.value.currentMsg,
          onOk: (val: string) => {
            editText(val)
          }
        })
      }
    }

    watchEffect(() => {
      if (props.code) {
        hc?.changeSymbol(props.code)
      }
    })

    const [overlayStudies, defaultOverlayStudy, changeOverlayStudy] = useOverlayStudies(config => {
      mainChart?.removeAllStudies()
      if (!R.isEmpty(config.input)) {
        mainChart?.addStudy(config.value, config.input)
      }
    })

    const fy = (price: number) => {
      return mainChart?.yAxis.fy(price) ?? 0
    }

    const setup = (resolution: ResolutionConfig) => {
      resolutionValue.value = resolution.value
      hc = new HappyChart({
        crosshair: true,
        restoreButton: '#restore',
        periodicity: resolution.period,
        container: 'hc_main_chart',
        datafeed: datafeed,
        yAxis: {
          borderColor: chartGridColor,
          lineColor: chartGridColor
        },
        xAxis: {
          borderColor: chartGridColor,
          lineColor: chartGridColor
        }
      })

      mainChart = hc.loadChart(props.code, {
        type: ChartType.CANDLE,
        layout: {
          whitespace: {
            right: 80
          },
          candleWidth: 8
        }
      })

      mainChart.onCrosshairMove((data: ChartPointer | null) => {
        crosshair.value = data
      })

      mainChart.onTick((bar, x, y) => {
        price.value = bar.c.toString()
        position.value = {
          top: `${y - 7}px`,
          left: '0px'
        }
      })

      if (defaultOverlayStudy) {
        mainChart.addStudy(defaultOverlayStudy.value, defaultOverlayStudy.input)
      }

      studyChart = hc.loadChart(props.code, [], {
        container: 'ch_study_chart',
        showChart: false,
        layout: {
          whitespace: {
            right: 80
          },
          candleWidth: 8
        },
        yAxis: {
          showBorder: false,
          showTicks: false
        },
        xAxis: {
          display: false
        }
      })

      const defaultPanelStudy = R.find(s => s?.default ?? false, panelStudies) ?? panelStudies[0]
      studyChart.addStudy(defaultPanelStudy.value, defaultPanelStudy.input)

      mainChart.sync(studyChart)
      studyChart.sync(mainChart)

      // watchEffect(() => {
      //   if (props.code && resolutionValue) {
      //     readLines(props.code, resolutionValue.value).then(resp => draw(mainChart, resp))
      //   }
      // })
    }

    const changeResolution = (r: ResolutionConfig) => {
      resolutionValue.value = r.value
      if (hc) {
        hc.setPeriodicity(r.period)
      } else {
        setup(r)
      }
    }

    const changePanelStudy = (study: StudyConfig, done: (item: StudyConfig) => void) => {
      studyChart?.removeAllStudies()
      studyChart?.addStudy(study.value, study.input)
      done(study)
    }

    const connect = () => {
      marketFeed.subscribe([props.code])
      hc?.loadChartData()
    }

    onMounted(() => {
      const defaultPeriodicity = R.find(r => r?.default ?? false, readResolutions())

      if (!defaultPeriodicity) {
        return
      }

      nextLoop(setup, defaultPeriodicity)
    })

    on(events.activated, connect)

    onBeforeUnmount(() => {
      off(events.activated, connect)
      hc?.destroy()
    })

    const createDraw = (type: TDrawTypes, feedback?: Feedback, opts?: DrawingOptions) => {
      create(
        mainChart,
        {
          type,
          kLineType: resolutionValue.value,
          ...opts
        },
        props.code,
        feedback
      )
    }

    const startDraw = (type: TDrawTypes, feedback?: Feedback) => {
      if (type === 'text') {
        openDialog(TheDrawTextDialog, {
          onOk: (val: string) => {
            createDraw(type, feedback, {
              text: val
            } as DrawingOptions)
          }
        })
      } else {
        createDraw(type, feedback, {
          ardir: type
        } as DrawingOptions)
      }
    }

    return {
      defaultOverlayStudy: defaultOverlayStudy.value,
      overlayStudies,
      panelStudies,
      price,
      crosshair,
      position,
      fullscreen,
      modifyDrawing,
      modifyDrawingPosition,
      drawingBindings,
      drawingListeners,
      changeResolution,
      changeOverlayStudy,
      changePanelStudy,
      fy,
      draw: startDraw
    }
  }
})
