
import { defineComponent, shallowRef } from 'vue'
import { checkIsFirstDeposit } from '@/modules/trade/trade.api'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheFirstDepositCard',
  components: {
    Pic,
  },
  // props: {
  //   info: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  setup () {
    const isFirst = shallowRef(false)
    const recipients = shallowRef(0)
    // checkIsFirstDeposit().then(res => {
    //   isFirst.value = Boolean(res.first)
    //   recipients.value = res.recipients
    // })
    return {
      isFirst,
      recipients,
    }
  },
})
