import requestResult from '@/common/request/request'
import useRequest from '@/hooks/useRequest'

export type LEVER = {
  type: number;
  selected: number;
}

export const readLever = requestResult<LEVER[]>('/api/lever/get')

export const setLever = requestResult('/api/lever/update')

export const useSetLever = () => {
  const [commit, progress] = useRequest(setLever)
  const request = async (data: Data) => {
    await commit(data)
  }
  return { request, progress }
}
