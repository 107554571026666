/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/18 10:40
 * @description
 *   KDJ.ts of WeTrade
 */
import * as d3 from 'd3'
import * as R from 'ramda'
import AbstractStudy, { ExtendStudyOptions, StudyConfig } from '../core/AbstractStudy'
import IChart from '../interface/IChart'
import { Bar, ISelection, NumberTuple } from '../types'
import { calcStochastic, StochasticState, StochasticStudyInputs } from './formulas'

export type KDJStudyOptions = StochasticStudyInputs & {
  lineColor2: string;
}

class KDJ extends AbstractStudy<KDJStudyOptions> {
  state: StochasticState | null = null

  constructor (
    chart: IChart,
    inputs: ExtendStudyOptions<KDJStudyOptions>,
    config?: StudyConfig,
  ) {
    super(chart, inputs, config)

    this.defaultLineFy = d3.scaleLinear().domain([80, 100]).range([this.chart.height, 0])
  }

  slice (from: number, to: number): Bar[][] {
    return this.cached.map(d => d.slice(from, to))
  }

  calcInitStudy (quotes: Bar[]) {
    const { value, state } = calcStochastic(quotes, this.options)
    this.state = state
    return value
  }

  calcCandidateStudy (quotes: Bar[]): Bar[][] {
    if (this.state) {
      this.state.maAddends = R.pluck('c', this.cached[0].slice(-4, -1))
      const { value } = calcStochastic(quotes, this.options, this.state)
      return R.zipWith((cache, value) => cache.slice(-1).concat(value), this.cached, value)
    }
    return []
  }

  render (g: ISelection<SVGGElement>, data: Bar[][], append = false) {
    if (!append) {
      const extent = d3.extent(R.flatten(data), d => d.c) as NumberTuple
      this.defaultLineFy.domain(extent)
    }

    g
      .selectAll('path')
      .data(data)
      .join('path')
      .attr('fill', 'none')
      .attr('stroke', (d, i) => i ? this.options.lineColor2 : this.options.lineColor())
      .attr('stroke-width', this.options.lineWidth)
      .attr('d', this.defaultLineRenderer)
  }
}

export default KDJ
