/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/11
 * @description
 *   app.ts of WeTrade
 */
import jsBridge from '@/common/jsBridge'
import { firebaseTrack, getIds, ready as appReady } from '@/common/jsBridge.api'
import { requestByAccountType } from '@/common/request/request'
import { openDialog, openFullscreenList } from '@/components/popup/popup'
import { events, flag, interval, keymap } from '@/config'
import i18n, { DEFAULT_LANG, SUPPORTED_LANGS } from '@/i18n'
import {
  checkAndroidUpdate,
  loadLocaleFile,
  readDepositRateList,
  readPrime,
  readWithdrawalRateList
} from '@/modules/app/app.api'
import copyTradeReducer from '@/modules/copyTrade/notification/reducer'
import quickPlayReducer from '@/modules/fastTrade/notification/reducer'
import tradeNotificationReducer from '@/modules/trade/notification/reducer'
import state, { isLogged, isNewLogin, UserAccount } from '@/state'
import { useAccountChange } from '@/state/accountType'
import { login, logout, setPrime } from '@/state/actions'
import { TradeFlag } from '@/types'
import ExperienceSelect from '@/views/ExperienceSelect.vue'
import UpdateDialog from '@/views/UpdateDialog.vue'
import connect from '@/worker/notification.client'
import parsePushMessage from '@/worker/notification.parser'
import { localGet, localRemove, localSet } from 'essential/store/localStore'
import { emit, off, on } from 'essential/tools/event'
import { getQueryParam, uuidV4 } from 'essential/util'
import * as R from 'ramda'
import { nextTick, onBeforeUnmount, shallowRef } from 'vue'
import { Router, useRouter } from 'vue-router'
import datafeed from '@/common/datafeed'

export const setupNewSession = (force = false) => {
  R.mapObjIndexed(localRemove, keymap.label.session)

  if (force || !localGet(keymap.label.uuid)) {
    localSet(keymap.label.uuid, uuidV4())
  }
}

/**
 * 循环机
 * @param action
 * @param interval
 * @param loopCheck
 * @param onStop
 */
const makeLoopEngine = <T>(
  action: () => Promise<T> | T,
  interval = 3,
  loopCheck: (error: Error | null, result: T | null, preResult: T | null, count: number) => boolean,
  onStop?: Noop
) => {
  const minInterval = R.max(interval, 1000)
  let running = false
  let count = 0
  let preResult: T | null = null

  // 检查边界并继续/停止循环
  async function run(loop: () => void, error: Error | null, result: T | null) {
    const forward = await loopCheck(error, result, preResult, count)
    preResult = result
    if (forward) {
      setTimeout(loop, minInterval)
    } else {
      count = 0
      if (typeof onStop === 'function') onStop()
    }
  }

  async function exe() {
    if (running) return

    running = true
    count += 1

    try {
      // 执行循环的动作
      const result = await action()
      run(exe, null, result)
    } catch (e) {
      run(exe, e || new Error('loop action error occurred'), null)
    } finally {
      running = false
    }
  }

  return exe
}

// 边界检查
const readyForNext = R.allPass([R.is(Array), R.complement(R.isEmpty)])

/*
const syncToChart = (pre: StockOrder[], val: StockOrder[]) => {
  let type = events.closeOrder
  let diff = []
  if (pre.length > val.length) {
    // 取平仓单
    diff = R.differenceWith(R.eqProps('orderId'), pre, val)
  } else {
    type = events.createOrder
    // 取建仓单(区别在于pre, val传入的顺序)
    diff = R.differenceWith(R.eqProps('orderId'), val, pre)
  }
  // 如果大于3个订单存在差异则刷新处理
  if (diff.length > 3) {
    type = events.udpateOrders
    emit(type)
  } else {
    R.map(order => emit(type, order), diff)
  }
}
 */

const _updateRequest = requestByAccountType<UserAccount>('read')
// const _updateRequest = () => new Promise(resolve => resolve(null))

export const refreshAccount = (silent = false) =>
  _updateRequest().then(resp => {
    if (resp) state.account = resp as UserAccount
    setTimeout(() => datafeed.subscribe(R.pluck('code', state.account.holdList ?? [])), 500)
    return state.account
  })
/**
 * 循环准备
 */
const makeLoopMachine = () => {
  let machineHasStarted = false

  const startEngine = makeLoopEngine<UserAccount>(
    () => refreshAccount(true),
    interval.accountRefreshInterval,
    (error, result) => {
      if (error) return false
      // 订单数量有变化 同步更新到chart
      // syncToChart(defaultToArray(preResult?.list), defaultToArray(result?.list))
      return readyForNext(result?.holdList) || false
    },
    () => {
      machineHasStarted = false
    }
  )

  return () => {
    // if (machineHasStarted) return

    // startEngine()
    // if (state.account.holdList.length) {
    //   machineHasStarted = true
    // }
  }
}

export const setupTransactionLoopJob = makeLoopMachine()

/**
 * 更新提示
 * @param resp
 */
const showUpdatePrompt = (resp: Data) => {
  openDialog(UpdateDialog, { config: resp, wrapperClass: 'center' }, { sync: true })
}

/**
 * 检查更新
 */
const checkUpdate = async () => {
  if (!flag.isIOS) {
    return checkAndroidUpdate().then(resp =>
      R.when(R.propEq('update', true), showUpdatePrompt)(resp as { update: boolean })
    )
  }
  return Promise.resolve()
}

/**
 * 链接推送服务器
 */
const connectPushServer = async () => {
  try {
    const server = await connect()
    server.reduce([tradeNotificationReducer, quickPlayReducer, copyTradeReducer])
    on(events.activated, () => {
      if (isLogged()) {
        // 切到前台强制重连
        server.socket.retry(true)
      }
    })
  } catch (e) {
    // showAlert('[000002]')
  }
}

/**
 * 标记用户
 */
export const tagUser = () => {
  openFullscreenList(
    ExperienceSelect,
    {},
    {
      sync: true
    }
  )
}
/**
 * 根据经验标记用户
 * @param userAccount
 */
const tryTagUser = (userAccount: UserAccount) => {
  setTimeout(() => {
    if (userAccount.tradeExpFlg === TradeFlag.UNSET) {
      tagUser()
    }
  }, 0)
}

/**
 * 用户登录之后的任务处理
 * @param user
 */
const afterLogin = (user?: UserAccount) => {
  // refreshPrime()

  // 检查android更新
  // checkUpdate()

  // 开启交易查询轮询任务
  setupTransactionLoopJob()

  // 链接推送服务器
  connectPushServer()

  if (user) tryTagUser(user)
}

/**
 * 利用一次账户信息的获取来验证用户登录
 */
export const verifyLogin = () => {
  refreshAccount()
    .then(user => {
      login()
      emit(events.login, user)
    })
    .catch(logout)
}

/**
 * app准备
 * @param router
 */
export const ready = (router: Router) => {
  // 上报开打时间
  // reportOpenTime()
  // 通知app
  appReady()
  // firebase追踪
  firebaseTrack()

  // 更新用户账户信息并启动相关处理进程
  if (!isNewLogin()) {
    verifyLogin()
  }

  const p = getQueryParam(keymap.search.pushMessage)
  if (p) {
    parsePushMessage(p, router)
  }
}

/**
 * 全局事件处理
 */
export const monitorGlobalEvents = () => {
  const router = useRouter()
  const toLogin = () => {
    // @todo 对于主动退出的用户这个的logout调用是多余的
    logout()
    return router.push({
      name: 'login',
      query: {
        isLogin: '1'
      }
    })
  }
  on(events.login, afterLogin)
  on([events.tokenExpired, events.logout], toLogin)
  // 切换账户 刷新账户信息
  useAccountChange(() => {
    setupTransactionLoopJob()
    // refreshAccount()
  })
  on(events.transactionUpdate, setupTransactionLoopJob)

  jsBridge.onActivated(() => {
    emit(events.activated)
  })

  onBeforeUnmount(() => {
    off(events.transactionUpdate, setupTransactionLoopJob)
    off([events.tokenExpired, events.logout], toLogin)
    off(events.login, afterLogin)
  })
}

export const speedLogin = () => {
  if (!isNewLogin()) {
    verifyLogin()
    afterLogin()
  }
}

/**
 * 系统语言切换
 * @param lang
 */
export const switchLanguage = async (lang?: string) => {
  const usedLang = lang || getQueryParam('lang') || localGet(keymap.label.language)
  let targetLang = usedLang || navigator.language?.split('-')[0]
  const isSupport = R.includes(targetLang, SUPPORTED_LANGS)
  if (isSupport) {
    const loaded = i18n.getLocaleMessage(targetLang)
    if (R.isEmpty(loaded)) {
      const message = await loadLocaleFile(targetLang)
      i18n.setLocaleMessage(targetLang, message.data)
    }
  } else {
    targetLang = DEFAULT_LANG
  }
  await nextTick()
  i18n.locale.value = targetLang
  localSet(keymap.label.language, targetLang)
}

/**
 * @ignore
 * 重试
 * @param max
 * @param interval
 */
/*
const makeRetryMachine = (max: number, interval = 10) => {
  let running = false
  let count = 0
  const maxTry = R.min(max, 10)
  const minInterval = R.max(interval, 1)

  return async function exe (action: () => Promise<any>, renew?: Noop, error?: Noop) {
    if (running) return
    running = true

    if (count >= maxTry) {
      if (error) error()
      running = false
      count = 0
      return
    }

    count += 1

    try {
      await action()
      if (renew) renew()
      running = false
      count = 0
    } catch (e) {
      running = false
      setTimeout(exe, 1000 * minInterval, action, renew, error)
    }
  }
}
 */

/**
 * 获取实时汇率
 */
export const useRate = (isOut = false) => {
  const rate = shallowRef({ value: 0, unit: '', rate: 1 })

  const refresh = (data?: Data) =>
    (isOut ? readWithdrawalRateList : readDepositRateList)(data).then(resp => {
      rate.value = {
        rate: Number(resp.rate),
        value: Number((1 / Number(resp.rate)).toFixed(2)),
        unit: resp.code
      }
    })

  return {
    rate,
    refresh
  }
}

/**
 *
 * 获取设备唯一性信息
 */
export const withDeviceIdentities = async (action: (data: Data) => void) => {
  const defaults = {
    deviceInformation: getQueryParam(keymap.search.deviceInfo),
    udid: getQueryParam(keymap.search.imei)
  }
  // if (flag.isDevMode) return action(defaults)
  const ids = (await getIds()) as Data
  return action({
    ...ids,
    ...defaults
  })
}

/**
 * 判断欧洲国家
 */
export const isEURCountry = () =>
  R.includes(localGet(keymap.user.countryCode), ['33', '34', '351', '39', '49'])

export const refreshPrime = () =>
  readPrime().then(resp => {
    setPrime(resp)
    return resp
  })
