
import useRequest from '@/hooks/useRequest'
import Donut from 'common/loading/Donut.vue'
import { defineComponent } from 'vue'
import { uploadImg } from './imgGallery'
import ImgPreviewer from './ImgPreviewer.vue'
import Pic from 'common/Pic.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent(
  {
    name: 'TheImgGallery',
    components: { Donut, Icon, Pic, ImgPreviewer },
    emits: ['update', 'update:images'],
    props: {
      mutate: {
        type: [Function, Boolean],
        default: false,
      },
      images: {
        type: Array,
        default: () => ([]),
      },
      max: {
        type: Number,
        default: 3,
      },
    },
    computed: {
      editable (): boolean {
        return typeof this.mutate === 'function'
      },
      showIndicator (): boolean {
        return this.editable && this.images?.length < this.max
      },
    },
    setup (props) {
      const [commit, progress] = useRequest(uploadImg)

      return {
        progress,
        useImg () {
          commit(false).then(url => {
            if (typeof props.mutate === 'function') props.mutate(url as string)
          })
        },
      }
    },
  },
)
