
import TheTradeLimitDisplay from '@/modules/trade/components/TheTradeLimitDisplay.vue'
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePositionEditResultDialog',
  components: { TheTradeLimitDisplay, ResultDialogTemplate },
  props: {
    top: Number,
    bottom: Number,
  },
})
