/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/10
 * @description
 *   jsBridge.ts of WeTrade
 */
import { request } from '@/config'
import * as R from 'ramda'
import Bridge, { CallParams } from 'essential/tools/jsBridge'

const jsBridge = new Bridge({
  injectObject: 'tf_js_bridge',
  injectMethod: 'emit',
  nativeCallbackName: '$emit',
  nativeActivatedName: '$activated',
})

export default jsBridge

const getPublicRequestParams = () => ({
  ...request.staticRequestData,
  ...request.dynamicRequestData,
})

const onlyFunction = R.both(R.propEq('length', 1), R.propSatisfies(R.is(Function), '0'))

const attachedPublicData = (rest: CallParams): CallParams => {
  const data = getPublicRequestParams()
  const modify = R.cond(
    [
      [R.propEq('length', 0), R.append(data)],
      [onlyFunction, R.prepend(data)],
      [R.T, R.over<Array<Data>, Data>(R.lensIndex(0), R.mergeRight(data))],
    ],
  )
  return modify(rest)
}

export const thunkCall = (type: string) =>
  (...params: CallParams) => {
    return jsBridge.call(type, ...attachedPublicData(params))
  }

/**
 * 将回调转换为异步的形式获取数据
 * @param type
 */
export const thunkCallForResult = (type: string) =>
  (data?: Data) =>
    new Promise((resolve, reject) => {
      const params = R.mergeRight(getPublicRequestParams(), data || {})
      jsBridge.call(type, params, (error, result) => {
        if (error) {
          reject(result)
        } else resolve(result?.data ?? result)
      })
    })
