/**
 *  Segment.ts of project wetrade
 *  @date 2022/5/15 22:00
 *  @author 阿佑[ayooooo@petalmail.com]
 */
import AbstractDrawing, { DrawCallback, OptionalDrawingOptions } from '../core/AbstractDrawing'
import { defaultLineStyle } from '../defaults'
import { extend, intersect } from '../helper'
import IChart, { ChartPointer } from '../interface/IChart'
import { ISelection, LineStyle, OptionsOf, Point, TickPoint } from '../types'
import * as R from 'ramda'

export type RequiredSegmentOptions = {
  id: string;
  container: string;
}

type Endpoint = TickPoint & Partial<{
  x: number;
  y: number;
}>

export type OptionalSegmentOptions = {
  points?: Endpoint[]
} & Partial<LineStyle> & OptionalDrawingOptions

export type SegmentOptions = OptionsOf<OptionalSegmentOptions, RequiredSegmentOptions>

export default class Segment extends AbstractDrawing {
  private readonly options: SegmentOptions['define']
  private readonly line: ISelection<SVGLineElement>
  private readonly start: ISelection<SVGCircleElement>
  private readonly end: ISelection<SVGCircleElement>
  private tweenPoints: Record<number | 'init', number> = { init: 0 }

  private keyPoints: Endpoint[] = []

  constructor (chart: IChart, options: SegmentOptions['call'], callback?: DrawCallback) {
    super({
      chart,
      pointsCount: 2,
      ...options,
      callback,
    })

    this.options = extend(defaultLineStyle, options)
    this.keyPoints = options.points ?? []

    this.el_d3.attr('class', 'drawing segment')

    this.line = this.el_d3
      .append('line')
      .attr('class', 'line')
      .attr('stroke', this.options.lineColor)

    this.start = this.el_d3
      .append('circle')
      .attr('class', 'start')

    this.end = this.el_d3
      .append('circle')
      .attr('class', 'end')
  }

  private x (p: Endpoint): number {
    let x: number | undefined

    if (p.t > this.chart.dataMaster.last().t) {
      x = this.chart.x(this.chart.dataMaster.last().t)
    } else if (p.t < this.chart.dataMaster.first().t) {
      x = this.chart.x(this.chart.dataMaster.first().t)
    }

    return x ?? p.x ?? this.chart.x(p.t) ?? NaN
  }

  private y (p: Endpoint): number {
    let y: number | undefined

    if (p.t > this.chart.dataMaster.last().t) {
      y = this.chart.y(this.tweenPoints[this.chart.dataMaster.last().t])
    } else if (p.t < this.chart.dataMaster.first().t) {
      y = this.chart.y(this.tweenPoints[this.chart.dataMaster.first().t])
    }

    return y ?? p.y ?? this.chart.y(p.c) ?? NaN
  }

  private drawLine () {
    if (this.endpoints().length !== 2) return

    this.line
      .attr('x1', this.x(this.endpoints(0)!))
      .attr('y1', this.y(this.endpoints(0)!))
      .attr('x2', this.x(this.endpoints(1)!))
      .attr('y2', this.y(this.endpoints(1)!))
  }

  private drawPoint (target: ISelection<SVGCircleElement>, pointIndex: 0 | 1) {
    const point = this.endpoints(pointIndex)

    if (!point) return

    const x = this.x(point)
    const y = this.y(point)

    target
      .attr('r', 4)
      .attr('fill', this.options.lineColor)
      .attr('cx', x)
      .attr('cy', y)

    return [x, y]
  }

  private calcPointsOnLine (force = false) {
    if (this.keyPoints.length !== 2) return

    if (force) {
      this.tweenPoints = { init: 0 }
    }

    if (this.tweenPoints.init !== 0) return

    const points = R.sortBy(R.prop('t'), this.keyPoints)

    /**
     * 线段超出可视范围 端点无法计算
     */
    if (
      points[0].t < this.chart.dataMaster.first().t ||
      points[1].t > this.chart.dataMaster.last().t
    ) {
      return
    }

    const segment: [Point, Point] = [
      [this.x(points[0]), this.y(points[0])], [this.x(points[1]), this.y(points[1])],
    ]

    let haveError = false
    let count = 0

    this.chart.dataMaster.rendered[0].map(bar => {
      const x = this.x(bar)
      const line: [Point, Point] = [[x, 0], [x, this.chart.height]]
      const point = intersect(segment, line)
      if (point) {
        count++
        const y = this.chart.invertY(point[1])
        this.tweenPoints[bar.t] = y

        if (!y) {
          haveError = true
        }
      }
    })

    this.tweenPoints.init = +(!haveError && count !== 0)
  }

  create () {
    this.draw(this.renderDrawing.bind(this), this.renderDrawing.bind(this))

    return this
  }

  highlight () {
    this.el_d3.select('.line')
      .attr('stroke-width', 2)
    return this
  }

  locate (): ChartPointer[] | undefined {
    if (this.endpoints().length < 2) return

    const start = this.endpoints(0)

    if (!start) return

    return [
      {
        x: 0,
        y: this.chart.yAxis.fy((start.c + this.endpoints(1)!.c) / 2) ?? 0,
        t: start.t,
        c: start.c,
        sc: String(start.c),
      },
    ]
  }

  parseEndpoints (): ChartPointer[] {
    if (this.keyPoints.length !== 2) return []

    return this.keyPoints.map(p => ({
      t: p.t,
      c: p.c,
      sc: p.c + '',
      x: this.chart.x(p.t) ?? 0,
      y: this.chart.y(p.c),
    }))
  }

  renderDrawing () {
    this.calcPointsOnLine()

    const p0 = this.drawPoint(this.start, 0)
    const p1 = this.drawPoint(this.end, 1)

    if (p0?.[0] === p1?.[0] && this.endpoints(0)?.t !== this.endpoints(1)?.t) {
      this.el_d3.style('display', 'none')
    } else {
      this.el_d3.style('display', '')
      this.drawLine()
      this.style()
    }

    return this
  }

  style (color?: string) {
    if (color) {
      this.options.lineColor = color
    }

    this.el_d3.selectAll('circle')
      .attr('fill', this.options.lineColor)
    this.line
      .attr('stroke', this.options.lineColor)
      .attr('stroke-width', 1)

    return this
  }

  /**
   * 更新节点信息 并计算两个节点之间的所有点的x坐标
   * @param points
   */
  syncEndpoints (points: ChartPointer[]) {
    this.keyPoints = R.map(R.pick(['t', 'c']), points)

    this.calcPointsOnLine(true)

    return this
  }

  updateLabel () {
    return this
  }

  /**
   * 根据偏移量更新两个端点的坐标以及对应的价格和时间
   * @param x
   * @param y
   * @private
   */
  private offset ([x, y]: Point) {
    this.updateEndpoints(this.keyPoints.map(p => {
      const nextX = (p.x as number) + x
      const nextY = (p.y as number) + y

      const close = this.chart.invertY(nextY)

      return {
        x: nextX,
        y: nextY,
        t: this.chart.invertX(nextX) ?? 0,
        c: close,
        sc: this.chart.fix(close),
      }
    }))

    return this
  }

  dragStart () {
    this.keyPoints = R.clone(this.endpoints())

    return this
  }

  drag (e: Event, p: ChartPointer, offset: Point) {
    if (e.target === this.start.node()) {
      this.updateEndpoints(p, 0)
    } else if (e.target === this.end.node()) {
      this.updateEndpoints(p, 1)
    } else {
      this.offset(offset)
    }

    this.renderDrawing()

    return this
  }

  remove () {
    super.remove()

    this.keyPoints = []
  }
}
