
import { computed, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import state, { isLogged } from '@/state'

export default defineComponent({
  name: 'TheDepositTip',
  components: { Icon },
  setup () {
    return {
      levelModel: computed(() => state.account.levelModel),
      isLogin: computed(() => isLogged()),
      showBonus: false,
    }
  },
})
