/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/16
 * @description
 *   useLoadMore.ts of FastTradeV3
 */
import * as R from 'ramda'
import { shallowRef, Ref } from 'vue'

const defaultToArray = <T = unknown> (arr: Array<T>): Array<T> => Array.isArray(arr) ? arr : []

const useLoadMore = <T = any, U = T> (
  load: (data: Data) => Promise<U>,
  pageSize = 10,
  parse = (x: U) => x as unknown as Array<T>,
) => {
  const loaded = shallowRef<T[] | null>(null)
  const response = shallowRef<U | null>(null)

  let page = 1
  const pageCount = pageSize
  let haveMore = true
  let loading = false

  const updateRecordState = (list: Array<T>) => {
    page += 1
    if (list.length < pageCount) haveMore = false
  }

  const request = (page: number, next: (list: Array<T>) => void) => {
    if (loading || !haveMore) {
      return Promise.reject('loading is busy or no more data')
    }

    const updateResp = (resp: U) => response.value = resp

    const processResp = R.pipe(R.tap(updateResp), parse, defaultToArray, R.juxt([updateRecordState, next]))

    loading = true
    return load({ page, pageCount })
      .then(processResp)
      .finally(() => {
        loading = false
      })
  }

  const append = (list: Array<T>) => {
    if (loaded.value) loaded.value = loaded.value.concat(list)
  }

  const loadMore = () => {
    return request(page, append)
  }

  const replace = (list: Array<T>) => {
    loaded.value = list
  }

  const refresh = () => {
    // loaded.value = null
    haveMore = true
    page = 1
    return request(page, replace)
  }

  return {
    response,
    loaded,
    loadMore,
    refresh,
  }
}

export default useLoadMore
