/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/22
 * @description
 *   format.ts of WeTrade
 */
import * as R from 'ramda'

const MONTH_EN = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
]
const WEEK_EN = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

// yy => 1996  Y => 96 MM => 03 M => 3 (时分秒同理)
export const formatDateTime = (format: string, t?: number) => {
  const date = t ? new Date(t) : new Date()
  const time: Data = {
    YY: date.getFullYear(), // 年份
    'y+': date.getFullYear(), // 年份
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日期
    'H+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'EN-w': WEEK_EN[date.getDay() - 1], // 星期 英
    'EN-m': MONTH_EN[date.getMonth()], // 月份 英
  }

  for (const key in time) {
    if (new RegExp(`(${key})`).test(format)) {
      const t = (time[key] as string).toString()
      if (RegExp.$1 === 'YY') {
        format = t.slice(2)
      } else {
        const reg = RegExp.$1.length === 1 ? t : t.length === 1 ? `0${t}` : t
        format = format.replace(RegExp.$1, reg)
      }
    }
  }
  return format
}

const fromUTCTime = (hour: number, minute: number, second: number) => {
  const d = new Date()
  return Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), hour, minute, second)
}

export const UTC2local = (time: string) => formatDateTime(
  'HH:mm', R.apply(fromUTCTime, time.split(':').map(Number)))

const identity = (v: unknown) => String(v)

export const cd = (
  left: number, refresh?: (day: number, hour: number, minute: number, second: number, duration: number) => void, step = 1000) => {
  const oneSecond = 1000
  const oneMinute = oneSecond * 60
  const oneHour = oneMinute * 60
  const oneDay = oneHour * 24

  const getMinute = R.memoizeWith(identity, (left: number) => Math.floor(left / oneMinute))
  const getMinuteRemainder = R.memoizeWith(identity, (left: number) => left % oneMinute)

  let timer = 0

  function tickTok (left: number) {
    const milliSecond = left % oneSecond
    const day = Math.floor(left / oneDay)
    const dayRemainder = left % oneDay
    const hour = Math.floor(dayRemainder / oneHour)
    const hourRemainder = dayRemainder % oneHour
    const minute = getMinute(hourRemainder)
    const minuteRemainder = getMinuteRemainder(hourRemainder)
    const second = Math.ceil(minuteRemainder / oneSecond)

    const interval = milliSecond || R.max(step, oneSecond)

    timer = window.setTimeout(() => {
      if (typeof refresh === 'function') {
        refresh(day, hour, minute, second, interval)
      }
      if (left > 0) {
        tickTok(left - interval)
      }
    }, interval)
  }

  tickTok(left)

  return () => {
    clearTimeout(timer)
  }
}
