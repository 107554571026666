/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/9 14:25
 * @description
 *   MasterData.ts of FAST
 */
import { Bar, NumberTuple } from '../../types'
import Monitor from '../Monitor'

export type DataMasterValues = [init: Bar[], candidate: Bar | null, start: number, end: number]

export enum MonitorType {
  EXTENT = 'extent', // low/high有更新
  DATA = 'data', // masterData有更新
  TICK = 'tick', // 最新价变化
}

type MonitorTypes = `${MonitorType}`

abstract class AbstractDataMaster extends Monitor<MonitorTypes> implements Monitor<MonitorTypes> {
  /**
   * 历史总量最大值k
   */
  private maxSize = 300
  private paddingSize = 0

  protected renderSize = 0 // 渲染总数
  /**
   * 预览模式，用户有滑动或缩放操作
   */
  protected inReview = false

  /**
   * 不包含最新值的历史数据
   * 顺序存储，即越新的数据索引值越大(在数组的尾部)
   */
  protected init: Bar[] = []

  /**
   * inReview模式下新增的数据
   */
  protected cached: Bar[] = []

  protected candidateOverflow = false // 最新数据的x轴溢出右侧时为真
  protected renderOffset = 0 // 最右侧数据偏移量
  protected dirty = true

  private candidateTime = NaN // 最新时间

  rendered: [dataArray: Bar[], fillCount: number] = [[], 0] // 渲染数组以及右侧空白数据量
  high = NaN // 可视区域内最大值
  low = NaN // 可视区域内最小值
  candidate: Bar | null = null // 最新数据

  /**
   * @implements initMonitorPool
   */
  initMonitorPool () {
    return {
      [MonitorType.EXTENT]: [],
      [MonitorType.DATA]: [],
      [MonitorType.TICK]: [],
    }
  }

  abstract emitExtentChange (extent: NumberTuple): void

  /**
   * y轴范围计算
   * @param low
   * @param high
   */
  private updateExtent ([low, high]: NumberTuple) {
    if (low && high && (this.low !== low || this.high !== high)) {
      this.low = low
      this.high = high
      this.emitExtentChange([low, high])
    }
  }

  abstract emitRangeChange (now: number): void

  // 通知实时更新
  // @todo 这里存在相同的值重复的触发的情况
  protected emitLatest () {
    this.emitChange(MonitorType.TICK, this.candidate)
  }

  /**
   * x轴范围计算
   * @param next
   * @todo 处理范围 目前只处理了最新值
   */
  private updateRange (next?: number) {
    if (next && next !== this.candidateTime) {
      this.candidateTime = next
      // 候选数据更新
      this.dirty = true
      this.emitRangeChange(next)
    }
  }

  abstract extent (bars: Bar[]): NumberTuple

  private calcRenderStopIndex (list: Bar[], offset: number) {
    return Math.max(this.renderSize, (list ?? this.init).length + this.paddingSize - offset)
  }

  private updateRendered (list: Bar[], offset: number) {
    const all = list.concat(this.candidate ?? [])
    const endIndex = this.calcRenderStopIndex(all, offset)
    const renderArray = all.slice(Math.max(0, endIndex - this.renderSize), endIndex)
    this.rendered = [renderArray, Math.max(0, this.renderSize - renderArray.length)]
  }

  /**
   * 用户指定偏移位置将不会自动更新渲染数据的起始坐标
   */
  protected organizeData (newBars: Bar[] = []) {
    /**
     * 将数据放到一个数组中方便划片操作
     */
    const nextInit = this.init.concat(newBars)

    /**
     * 移除左侧较老的数据（超出历史数据长度的最大值）
     */
    const excess = Math.max(0, nextInit.length - this.renderSize - this.maxSize)

    this.init = nextInit.slice(excess)

    this.updateRendered(this.init, 0)

    return true
  }

  /**
   * 区别于一般情况 在消费缓存数据后 并不会清空
   * @param newBars
   * @param offset
   * @param isZoom 是否缩放
   */
  protected organizeTransformed (newBars: Bar[] = [], offset = this.renderOffset, isZoom = false) {
    this.candidateOverflow = offset > this.paddingSize
    /**
     * 更新缓存
     */
    this.cached = this.cached.concat(newBars)

    /**
     * 更新渲染数据
     */
    this.updateRendered(this.init.concat(this.cached), offset)

    /**
     * 更新移动偏移量
     */
    this.renderOffset = offset

    return true
  }

  protected reScale () {
    /**
     * 计算新增数据的范围
     * 候选bar也需要参与计算
     */
    const nextExtent = this.extent(this.rendered[0])

    // 更新y轴范围
    this.updateExtent(nextExtent)

    // 更新x轴范围
    this.updateRange(this.candidate?.t)
  }

  /**
   * @param newBars 候选bar之前的bar
   * @param candidate 候选bar
   */
  protected push (newBars: Bar[], candidate: Bar) {
    // 更新候选bar
    this.candidate = candidate

    this.inReview ? this.organizeTransformed(newBars) : this.organizeData(newBars)

    this.reScale()
  }

  protected reset () {
    this.init = []
    this.rendered = [[], 0]
    this.cached = []
    this.candidate = null
    this.low = NaN
    this.high = NaN
    this.candidateTime = NaN
    this.dirty = true
  }

  protected setRenderSize (size: number) {
    this.renderSize = size

    if (this.renderSize >= this.maxSize) {
      this.maxSize = this.renderSize * 2
    }
  }

  protected paddingRight (count: number) {
    this.paddingSize = count
  }

  private updateCandidate (bar: Bar) {
    if (this.candidate) {
      for (const i in bar) {
        this.candidate[i] = bar[i]
      }
    } else {
      this.candidate = bar
    }
  }

  /**
   * 计算low，high的更新
   * @param bar
   * @private
   */
  private calcExtentChanged (bar: Bar): NumberTuple | null {
    if (bar.c >= this.low && bar.c <= this.high) return null
    return [
      bar.c > this.low ? this.low : bar.c,
      bar.c < this.high ? this.high : bar.c,
    ]
  }

  protected update (bar: Bar) {
    this.updateCandidate(bar)

    /**
     * 数据向右溢出 忽略无效计算
     */
    if (!this.candidateOverflow) {
      const changed = this.calcExtentChanged(bar)
      if (changed !== null) {
        this.updateExtent(changed)
      }
    }
  }

  abstract emitDataChange (): void

  protected cachedValue () {
    let value: DataMasterValues = [[], null, 0, 0]

    return () => {
      if (this.dirty) {
        this.dirty = false

        const init = this.init.concat(this.cached)
        const stopIndex = this.calcRenderStopIndex(init.concat(this.candidate ?? []), this.renderOffset)
        const renderFrom = stopIndex - this.renderSize

        value = [
          init,
          this.candidateOverflow ? null : this.candidate,
          renderFrom,
          stopIndex,
        ]
      }

      return value
    }
  }
}

export default AbstractDataMaster
