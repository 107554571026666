
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import {
  RookieQuiz,
  finishTest,
  checkTestFinish,
  completeRookieQuiz,
} from '@/modules/home/home.api'
import { YesOrNo } from '@/types'
import { computed, defineComponent, shallowRef } from 'vue'
import useRequest from '@/hooks/useRequest'
import Loading from 'common/loading/Donut.vue'

export default defineComponent({
  name: 'TheQuizBoard',
  components: { Line, Icon, Pic, Loading },
  props: {
    qaList: {
      type: Array,
      required: true,
    },
    taskType: {
      type: Boolean,
      default: false,
      required: false,
    },
    eventId: {
      type: Number,
      required: false,
    },
  },
  setup (props, ctx) {
    const choice = shallowRef<YesOrNo | null>(null)
    const answered = computed(() => choice.value !== null)
    const questionIndex = shallowRef(0)
    const isLastQuestion = shallowRef(props.qaList.length === 1)
    const question = computed(() => (props.qaList as RookieQuiz[])[questionIndex.value])
    const doneStatus = shallowRef()
    const finishCheck = shallowRef(false)

    const [check, loading] = useRequest(checkTestFinish)
    finishCheck.value = !computed(() => loading.value).value

    if (props.taskType) {
      doneStatus.value = 0
    } else {
      check({
        eventId: props.eventId,
      }).then(res => {
        doneStatus.value = res.status
      })
    }

    const makeChoice = (_choice: YesOrNo) => {
      if (choice.value === null) {
        choice.value = _choice
      }

      // complete task after the last question answered
      if (isLastQuestion.value) {
        if (props.taskType) {
          completeRookieQuiz().then(() => {
            ctx.emit('complete')
          })
        } else {
          finishTest().then(() => {
            ctx.emit('complete')
          })
        }
      }
    }

    const close = () => {
      ctx.emit('close')
    }

    const next = () => {
      if (questionIndex.value < props.qaList.length - 1) {
        questionIndex.value += 1
        choice.value = null
      } else {
        close()
      }
      isLastQuestion.value = questionIndex.value >= props.qaList.length - 1
    }

    const showAnsweredTip = (_choice: YesOrNo) => answered.value && (_choice === choice.value || question.value.answer === _choice)

    return {
      question,
      questionIndex,
      isLastQuestion,
      answered,
      doneStatus,
      finishCheck,
      makeChoice,
      next,
      close,
      showAnsweredTip,
    }
  },
})
