/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/8
 * @description
 *   event.ts of js-essential
 */
import EventEmitter from 'events'
import * as R from 'ramda'

type EventType = string | symbol

export interface Handler {
  (...args: any[]): void;
}

const emitter = new EventEmitter()

export const on = <T> (
  type: EventType | Array<EventType>, handler: Handler) => {
  if (!handler) {
    return
  }

  const events = Array.isArray(type) ? type : [type]

  R.map((event) => {
    emitter.on(event, handler)
  }, events)
}

export const off = (
  type: EventType | Array<EventType>, handler?: Handler,
) => {
  // 删除所有事件
  if (type === '*' && !handler) {
    R.map(() => emitter.removeAllListeners(), emitter.eventNames())
    return
  }

  const events = Array.isArray(type) ? type : [type]

  R.map((event) => {
    if (handler) {
      emitter.removeListener(event, handler)
    } else {
      emitter.removeAllListeners(event)
    }
  }, events)
}

export const emit = emitter.emit.bind(emitter)

export type EventHandler<T> = Handler

export const once = emitter.once.bind(emitter)

export const count = (e: string) => emitter.listenerCount(e)
