/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/20 14:42
 * @description
 *   reducer.ts of WeTrade
 */
import { events } from '@/config'
import { emit } from 'essential/tools/event'
import { showAlert } from '@/components/popup/popup'

type QUICKNOTI<T> = {
  category: T
}

enum NotifyType {
  FASTCLOSE = 5,
  ISCREDIT = 8
}

function fastCloseNotify() {
  emit(events.quickClose)
  showAlert('A quick order has been closed, Please check detai')
}

function useCredit() {
  emit(events.usecredit)
  emit(events.transactionUpdate)
}

const quickPlayReducer = (broadcast: any) => {
  switch (broadcast.category) {
    case NotifyType.FASTCLOSE:
      if (broadcast.msgType === 313) {
        fastCloseNotify()
      }
      break
    case NotifyType.ISCREDIT:
      if ([20, 21, 22, 24].includes(broadcast.msgType)) {
        useCredit()
      }
      break
  }
}

export default quickPlayReducer
