/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   pageTransition.ts of WeTrade
 */
import * as R from 'ramda'
import { NavigationHookAfter } from 'vue-router'

enum TransitionType {
  FORWARD = 'page-in',
  BACK = 'page-out'
}

const getRouteIndex = R.pathOr(NaN, ['meta', 'routeIndex'])

const prepareTransition: NavigationHookAfter = (to, from) => {
  let transitionType = TransitionType.FORWARD

  const toRouteIndex = getRouteIndex(to)
  const fromRouteIndex = getRouteIndex(from)
  if (R.any(Number.isNaN, [toRouteIndex, fromRouteIndex])) {
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    if (toDepth < fromDepth) {
      transitionType = TransitionType.BACK
    }
  }

  if (toRouteIndex > fromRouteIndex) {
    transitionType = TransitionType.FORWARD
  }

  if (toRouteIndex < fromRouteIndex) {
    transitionType = TransitionType.BACK
  }

  to.meta.transition = transitionType
}

export default prepareTransition
