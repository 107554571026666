
import state from '@/state'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePositionLines',
  props: {
    code: String,
    fy: {
      type: Function,
      required: true,
    },
  },
  setup (props) {
    return {
      orders: computed(() => state.account.holdList?.filter(order => order.code === props.code)),
    }
  },
})
