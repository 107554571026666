
import Nullable from '@/components/Nullable.vue'
import ThePositionItem from '@/modules/trade/components/ThePositionItem.vue'
import TheTradeGroup from '@/modules/trade/components/TheTradeGroup.vue'
import Holder from '@/provider/Holder.vue'
import state from '@/state'
import List from 'common/List/List.vue'
import * as R from 'ramda'
import { computed, defineComponent } from 'vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { Position } from '@/modules/trade/trade.api'
import { TradeDirection } from '@/types'
import { div, mul, sub, add } from 'essential/tools/math'
import { emit } from 'essential/tools/event'

export default defineComponent({
  name: 'ThePositions',
  components: { RealTimeQuote, Nullable, Holder, List, TheTradeGroup, ThePositionItem },
  emits: ['togglePlaceHolder'],
  props: {
    code: String,
  },
  setup (props) {
    const leverage = ['', 'transactionhistory_26', 'transactionhistory_27', 'transactionhistory_28']
    let profitList = {}

    const positions = computed(() => {
      const all = state.account.holdList?.map(item => {
        if (item.levelType && item.levelType > 0) {
          item.lever = leverage[item.levelType]
        }
        item.weight = String(Number(item.weight))
        return item
      })
      if (!props.code) return all
      return R.filter(R.propEq('code', props.code), all)
    })

    const profit = (order: Position, priceSell: string, priceBuy: string) => {
      const { yk, orderNumber, productPlFormulaModel, type, code, calculatePoint, pointStep, createPrice, orderId } = order
      const { multiplyNum, plSymbol, reverse, sell, buy } = productPlFormulaModel
      const isBuy = Number(type) === TradeDirection.BUY
      const priceChange = isBuy ? sub(priceSell, createPrice) : sub(createPrice, priceBuy)
      const changePoint = div(priceChange, mul(calculatePoint, pointStep))
      // console.log('priceChange', priceChange, changePoint)
      const temp = mul(changePoint, yk, orderNumber, multiplyNum)
      let pl = 0
      if (plSymbol) {
        if (plSymbol === code) {
          pl = div(temp, isBuy ? priceSell : priceBuy)
        } else {
          if (reverse === 1) {
            pl = mul(temp, isBuy ? (sell || 0) : (buy || 0))
          } else {
            pl = div(temp, isBuy ? (sell || 0) : (buy || 0))
          }
        }
      } else {
        pl = temp
      }
      const plJuan = order.isJuan && pl < 0 ? 0 : pl
      const holds = state.account.holdList
      const tempIds = R.pluck('orderId')(holds)
      const tempPl = R.pick(tempIds, profitList)
      profitList = { ...tempPl, [orderId]: plJuan }
      const plTotal = R.sum(R.values(profitList))
      const buyMoneyList = R.filter(item => item.isJuan !== 1, holds)
      const useMargin = R.sum(R.map(item => Number(item), R.pluck('buyMoney', buyMoneyList)))
      const deferredMoney = R.sum(R.map(item => Number(item), R.pluck('deferred', holds)))
      emit('quoteUpdateAccount', plTotal, useMargin, deferredMoney)
      return pl.toFixed(2)
    }

    return {
      showAll: false,
      positions,
      profit,
    }
  },
})
