
import TheToggleStateButton from '@/modules/market/components/TheToggleStateButton.vue'
import { useWatchState } from '@/modules/market/market'
import { MarketItem } from '@/modules/market/market.api'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheWatchSymbolButton',
  components: { TheToggleStateButton },
  props: {
    marketItem: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    return useWatchState((props.marketItem as MarketItem).code)
  },
})
