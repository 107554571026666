/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/7/23
 * @description
 *   chart.ts of main
 */
import { keymap } from '@/config'
import { localGet } from 'essential/store/localStore'
import { StudyOptions } from 'happychart/study/StudyRenderer'
import { Periodicity, StudyType } from 'happychart/types'

export enum BarTypes {
  K_4H = 9,
  K_1,
  L_1S,
  K_5S,
  K_15S,
  K_30S,
  K_5 = 2, // 5M
  K_15,
  K_30,
  K_60,
  K_1D, // 1D
  K_1W,
}

export const precisions = {
  XAUUSD: 2,
  USOIL: 2,
  EURUSD: 5,
  USDJPY: 3,
  GBPUSD: 5,
  AUDUSD: 5,
  XADUSD: 3,
  COPPER: 3,
  NICKEL: 2,
  USDCAD: 5,
  USDCHF: 5,
  NZDUSD: 5,
  BTCUSD: 2,
  ETHUSD: 2,
}

export type StudyConfig = {
  label: string;
  elite?: boolean;
  default?: boolean,
  value: StudyType;
  input: StudyOptions[StudyType];
  class?: string;
}

export const overlayStudies: Array<StudyConfig> = [
  // {
  //   label: 'Trend',
  //   default: true,
  //   elite: true,
  //   value: StudyType.TRENDPRO,
  //   input: {
  //     period: 3,
  //     fillColor: '#CCF4DD',
  //     fillColor2: '#FCDBD8',
  //   },
  // },
  {
    label: 'MA',
    value: StudyType.SMA,
    input: {
      series: [
        {
          period: 5,
          lineColor: '#04ff00',
        }, {
          period: 10,
          lineColor: '#ff00d3',
        }, {
          period: 20,
          lineColor: '#8600ff',
        }, {
          period: 30,
          lineColor: '#19e6df',
        }, {
          period: 60,
          lineColor: '#2026ff',
        },
      ],
    },
  },
  {
    label: 'EMA',
    value: StudyType.EMA,
    input: {
      series: [
        {
          period: 5,
          lineColor: '#04ff00',
        }, {
          period: 8,
          lineColor: '#ff00d3',
        }, {
          period: 13,
          lineColor: '#8600ff',
        }, {
          period: 21,
          lineColor: '#19e6df',
        }, {
          period: 34,
          lineColor: '#2026ff',
        },
      ],
    },
  },
  {
    label: 'BOLL',
    value: StudyType.BOLL,
    input: {
      period: 20,
      deviation: 2,
      lineColor: '#8600ff',
      fillColor: 'rgba(16, 43, 137, 0.1)',
      borderColor: '#326bef',
    },
  },
  {
    label: 'SAR',
    value: StudyType.SAR,
    input: {
      lineColor: '#12aba6',
      fillColor: '#fff',
    },
  },
]

export const panelStudies: StudyConfig[] = [
  {
    label: 'MACD',
    default: true,
    value: StudyType.MACD,
    input: {
      signalPeriod: 9,
      lineColor: '#46AFFF',
      lineColor2: '#F2863A',
    },
  }, {
    label: 'KDJ',
    value: StudyType.KDJ,
    input: {
      period: 14,
      lineColor: '#46AFFF',
      lineColor2: '#F2863A',
    },
  }, {
    label: 'RSI',
    value: StudyType.RSI,
    input: {
      period: 14,
      lineColor: '#46AFFF',
    },
  }, {
    label: 'CCI',
    value: StudyType.CCI,
    input: {
      period: 20,
      lineColor: '#46AFFF',
    },
  },
]

export type ResolutionConfig = {
  default?: boolean;
  label: string;
  value: BarTypes;
  period: Periodicity;
}

export const resolutions: ResolutionConfig[] = [
  {
    label: 'M1',
    value: BarTypes.K_1,
    period: {
      interval: 1,
      timeUnit: 'minute',
      offset: 1,
    },
  },
  {
    label: 'M5',
    value: BarTypes.K_5,
    period: {
      interval: 5,
      timeUnit: 'minute',
      offset: 1,
    },
  },
  {
    label: 'M15',
    value: BarTypes.K_15,
    period: {
      interval: 15,
      timeUnit: 'minute',
      offset: 1,
    },
  },
  {
    label: 'M30',
    value: BarTypes.K_30,
    period: {
      interval: 30,
      timeUnit: 'minute',
      offset: 1,
    },
  },
  {
    label: 'H1',
    value: BarTypes.K_60,
    period: {
      interval: 60,
      timeUnit: 'minute',
      offset: 1,
    },
  },
  {
    label: 'H4',
    value: BarTypes.K_4H,
    period: {
      interval: 60 * 4,
      timeUnit: 'minute',
      offset: 0.5,
    },
  },
  {
    label: 'D1',
    value: BarTypes.K_1D,
    period: {
      interval: 1,
      timeUnit: 'day',
    },
  },
  {
    label: 'W1',
    value: BarTypes.K_1W,
    period: {
      interval: 7,
      timeUnit: 'day',
    },
  },
]

export const readResolutions = (): ResolutionConfig[] => resolutions.map(item => ({
  ...item,
  default: item.value === Number(localGet(keymap.label.resolution) ?? BarTypes.K_30),
}))
