
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ForGuest',
  emits: ['forbiddenClose'],
  components: { Pic },
  setup (props, ctx) {
    ctx.emit('forbiddenClose')
  },
})
