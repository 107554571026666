/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/8
 * @description
 *   shared.ts of essential
 */
import * as R from 'ramda'

const partialGuid = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)

export const shortId = partialGuid

export const guid = () => R.join(
  '', [
    partialGuid(), R.join('-', R.times(partialGuid, 5)), R.join('', R.times(partialGuid, 2)),
  ])
