/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/20 14:42
 * @description
 *   reducer.ts of WeTrade
 */
import { parseToJson } from '@/common/format'
import { openDialog as showDialog } from '@/components/popup/popup'
import { events } from '@/config'
import { Position } from '@/modules/trade/trade.api'
import { Broadcast } from '@/types'
import CopyTradeResult from '@/modules/copyTrade/notification/CopyTradeResult.vue'
import { emit } from 'essential/tools/event'

enum NotifyType {
  COPYCREATE = 15,
  COPYCLOSE,
}

function copyCloseNotify (message: string) {
  const order = parseToJson(message).json as Position
  if (order) {
    showDialog(CopyTradeResult, { order })
  }
}

function copyCreateNotify (message: string) {
  const order = parseToJson(message).json as Position
  if (order) {
    showDialog(CopyTradeResult, { order, type: 'create' })
    emit(events.transactionUpdate)
  }
}

const copyTradeReducer = (broadcast: Broadcast<NotifyType>) => {
  switch (broadcast.cmd) {
    case NotifyType.COPYCREATE:
      return copyCreateNotify(broadcast.message)
    case NotifyType.COPYCLOSE:
      return copyCloseNotify(broadcast.message)
  }
}

export default copyTradeReducer
