
import Button from '@/components/Button.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import { creditRuleInsert } from '@/modules/trade/trade.api'

export default defineComponent({
  name: 'TheCreditRuleDialog',
  components: { Button, DialogTemplate },
  props: {
    title: String,
    content: String,
    type: Number,
    contentId: Number,
    code: String
  },
  setup (props, ctx) {
    return {
      close: () => {
        const { type, code } = props
        creditRuleInsert({ type, code }).finally(() => ctx.emit('close'))
      }
    }
  }
})
