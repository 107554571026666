
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheDrawTextDialog',
  components: { DialogTemplate },
  props: {
    oldVal: String,
  },
  emits: ['close', 'cancel', 'ok'],
  setup (props, ctx) {
    const text = shallowRef(props.oldVal)
    return {
      text,
      cancel: () => {
        ctx.emit('close')
        ctx.emit('cancel')
      },
      ok: () => {
        if (text.value === '') {
          return false
        }
        ctx.emit('close')
        ctx.emit('ok', text.value)
      },
    }
  },
})
