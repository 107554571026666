/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/4/1
 * @description
 *   dynamicAlive.ts of WeTrade
 */
import * as R from 'ramda'
import { shallowRef } from 'vue'

const cacheList = shallowRef<Array<string>>([])

export const cancelCachePage = (name: string) => {
  cacheList.value = R.reject(R.equals(name), cacheList.value)
}

export const cachePage = (name: string) => {
  cancelCachePage(name)
  cacheList.value = R.append(name, cacheList.value)
}

export const dynamicAliveList = cacheList
