
import TransitionProvider from 'common/providers/TransitionProvider.vue'
import { onBeforeUnmount } from 'vue'
import usePopup from './popup'

export default {
  name: 'Popup',
  components: { TransitionProvider },
  setup () {
    const [widgets, close, remove, clean] = usePopup()

    onBeforeUnmount(clean)

    return {
      widgets,
      close,
      remove,
    }
  },
}
