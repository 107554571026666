import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { ref: "alertDom" }
const _hoisted_2 = { class: "px-20 py-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "body", {}, () => [
      _createVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 512))
}