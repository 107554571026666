/**
 * @author ayou[beica1@outook.com]
 * @date 2022/2/28 19:00
 * @description
 *   calculator.ts of WeTrade
 */
import Transformation from './Transformation'
import { Delta, Pointer, Point } from './types'
import * as R from 'ramda'

function calcDelta (points: Pointer[]): Point {
  if (points.length === 0) return [0, 0]

  if (points.length === 1) return [points[0].clientX, points[0].clientY]

  return [
    Math.abs(points[0].clientX - points[1].clientX),
    Math.abs(points[0].clientY - points[1].clientY),
  ]
}

function calcHypotenuse (pointers: Pointer[]) {
  let point = calcDelta(pointers)

  return Math.sqrt(point[0] ** 2 + point[1] ** 2)
}

function calcSingularPoint (pointers: Pointer[]): Point {
  return [
    (pointers[0].clientX + pointers[1].clientX) / 2,
    (pointers[0].clientY + pointers[1].clientY) / 2,
  ]
}

const makeDeltaCalculator = (starts: Pointer[] = [{ clientX: 0, clientY: 0 }]) => {
  let start: Pointer | null = null // 平移参考点
  let P: Point | null = null // 缩放奇点(缩放参考点)
  let L = NaN // 缩放系数参考线

  return (pointers: Pointer[]): Delta => {
    // 平移操作
    if (pointers.length === 1) {
      const target = pointers[0]
      if (start === null) {
        start = R.find(
          R.propSatisfies(v => Boolean(v) && v === target.identifier, 'identifier'),
          starts,
        ) ?? starts[0]
      }

      return {
        x: target.clientX - start.clientX,
        y: target.clientY - start.clientY,
        k: 0,
      }
      // 缩放操作
    } else {
      // 计算两个操作点之间的距离来作为缩放的参考
      if (Number.isNaN(L)) {
        L = calcHypotenuse(pointers)
      }

      const k = Number((calcHypotenuse(pointers) / L).toFixed(2))

      if (P === null) {
        P = calcSingularPoint(pointers)
      }

      const p1 = calcSingularPoint(pointers)

      return {
        x: 0,
        y: 0,
        k: k - 1,
      }
    }
  }
}

export default makeDeltaCalculator
