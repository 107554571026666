
import { goSpeed } from '@/common/goSpeed'
import Pic from '@/components/Pic.vue'
import { showAlert } from '@/components/popup/popup'
import OnlyApp from '@/provider/OnlyApp.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Header',
  components: { OnlyApp, Pic },
  setup() {
    return {
      get() {
        showAlert('jojo')
      },
      goSpeed
    }
  }
})
