/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/5 14:48
 * @description
 *   helper.ts of FAST
 */
import {
  Quote,
  Bar,
  ConfirmFunctionalKey,
  TimeUnit,
  NumberTuple,
  Periodicity, Point,
} from './types'
import * as R from 'ramda'

export const quoteToBar = (quote: Quote): Bar => ({
  c: quote.last,
  t: Date.now(),
})

export const randomString = (prefix = '') => prefix + (Math.random() + Math.random()).toString(
  36).slice(2)

export const minMove = (d: number | string) => 10 ** -(d.toString().split('.')[1] ?? '').length

export const extend: (...args: Record<string, any>[]) => any = R.mergeDeepRight

// 将可以为函数的属性转换为函数
export const confirmFunctionalProps = <T extends Record<string, unknown>> (
  obj: T, keys: (keyof T)[]): ConfirmFunctionalKey<T> => {
  const notFunctionPart = R.pipe(R.pick(keys), R.pickBy(v => typeof v !== 'function'))(obj) as T
  return extend(obj, R.mapObjIndexed(R.always, notFunctionPart))
}

const timeMSMap = {
  [TimeUnit.TICK]: 0,
  [TimeUnit.MILLISECOND]: 1,
  [TimeUnit.SECOND]: 1000,
  [TimeUnit.MINUTE]: 1000 * 60,
  [TimeUnit.DAY]: 1000 * 60 * 60 * 24,
  [TimeUnit.WEEK]: 1000 * 60 * 60 * 24 * 7,
  [TimeUnit.MONTH]: 1000 * 60 * 60 * 24 * 30,
}

export const msOf = (periodicity: Periodicity) => {
  return (timeMSMap[periodicity.timeUnit] ?? timeMSMap[TimeUnit.MINUTE]) *
    (periodicity.period ?? 1) *
    (periodicity.interval ?? 1)
}

const timePatternMap = {
  [TimeUnit.TICK]: ':%L',
  [TimeUnit.MILLISECOND]: ':%L',
  [TimeUnit.SECOND]: '%H:%M:%S',
  [TimeUnit.MINUTE]: '%H:%M',
  [TimeUnit.DAY]: '%m/%d',
  [TimeUnit.WEEK]: '%m/%d',
  [TimeUnit.MONTH]: '%x',
}

export const patternOf = (periodicity: Periodicity) => {
  return timePatternMap[periodicity.timeUnit]
}

// 这里包含边界重叠的情况
export const isInRange = (
  range: NumberTuple, span: NumberTuple): boolean => span[0] >= range[0] && span[1] <= range[1]

// 这里包含边界重叠的情况
export const isOutRange = (
  target: NumberTuple, sub: NumberTuple): boolean => sub[0] <= target[0] || sub[1] >= target[1]

interface ValueOf<T> {
  (value: T, index: number, values: T[]): number;
}

/**
 * 数据量较大时 比下方的循环要快
 * @param arr
 * @param valueOfMin
 * @param valueOfMax
 */
export const quickExtent = <T = number> (
  arr: T[],
  valueOfMin = (n: T): number => n as unknown as number,
  valueOfMax = (n: T): number => n as unknown as number,
) => {
  const length = arr.length
  const firstLow = valueOfMin(arr[0])
  const firstHigh = valueOfMax(arr[0])
  const secondLow = valueOfMin(arr[1])
  const secondHigh = valueOfMax(arr[1])

  let max: number
  let min: number
  let start: number
  let end = length - 1

  if (length % 2 === 0) {
    min = Math.min(firstLow, secondLow)
    max = Math.max(firstHigh, secondHigh)
    start = 2
  } else {
    min = max = firstLow
    start = 1
  }

  while (start < end) {
    const startLow = valueOfMin(arr[start])
    const startHigh = valueOfMax(arr[start])
    const endLow = valueOfMin(arr[end])
    const endHigh = valueOfMax(arr[end])

    min = Math.min(startLow, endLow, min)
    max = Math.max(startHigh, endHigh, max)

    start++
    end--
  }

  return [min, max]
}

export const extent = <T extends (number | Record<string, unknown>)> (
  values: T[],
  valueOfMin: ValueOf<T> = x => x as number,
  valueOfMax: ValueOf<T> = valueOfMin,
): NumberTuple => {
  let min = Infinity
  let max = -Infinity
  let index = -1
  for (const value of values) {
    index++
    const _min = valueOfMin(value, index, values)
    const _max = valueOfMax(value, index, values)
    if (_min < min) min = _min
    if (_max > max) max = _max
  }
  return [min, max]
}

export const spaceToBarCapacity = (space: number, barWidth: number) => Math.floor(space / barWidth)

// eslint-disable-next-line no-self-compare
export const isNumeric = (d?: number | null) => typeof d === 'number' && d === d

const min0 = R.max<number>(0)

export const adjustBarTime = (periodicity: Periodicity) => {
  let interval = 0
  let offset = 0

  const config = (periodicity: Periodicity) => {
    interval = msOf(periodicity)
    offset = periodicity.offset ?? 0
  }

  config(periodicity)

  const fn = (time: number) => time - (time % (interval ?? time)) + offset * interval

  fn.config = config

  return fn
}

/**
 * 自动溢出的 定长的 队列
 */
export class FixedArray<T = number> {
  private _arr: T[]

  readonly size: number // 数组大小

  valueFrom: number // 有效起始位置-第一初始化的元素的位置

  constructor (size: number, initial?: T[]) {
    this.size = size
    this._arr = new Array<T>(size)
    this.valueFrom = size

    if (initial) {
      this.push(initial)
    }
  }

  count () {
    return this.size - this.valueFrom
  }

  value () {
    return this._arr
  }

  identityValue () {
    return this._arr.slice(this.valueFrom)
  }

  /**
   * 在数组的末尾（append的数据之前）追加新元素并移除(溢出)头部同等个数的元素
   * @param data
   */
  push (data: T[]) {
    const [arr, append] = R.splitAt(this.size, this._arr)
    this._arr = arr.slice(data.length).concat(data, append)
    this.valueFrom = min0(this.valueFrom - data.length)
    return this
  }

  /**
   * 追加的数据不改变数组的内的数据
   * @param data
   */
  append (data: T[]) {
    this._arr = this._arr.concat(data)
    return this
  }

  /**
   * 忽略之前append的数据
   */
  origin () {
    this._arr = this._arr.slice(0, this.size)
    return this
  }

  /**
   * 根据有效起始位置截取数据
   * @param from
   * @param to
   */
  slice (from: number, to: number) {
    return this._arr.slice(R.max(from, this.valueFrom), R.max(to, this.valueFrom))
  }
}

export const resolvePrecision = R.o(
  R.apply(Math.max), R.map(R.pipe(String, R.split('.'), R.nth(1), R.propOr(0, 'length'))))

// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
export const intersect: (l0: [Point, Point], l1: [Point, Point]) => Point | false = ([[x1, y1], [x2, y2]], [[x3, y3], [x4, y4]]) => {
  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false
  }

  const denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

  // Lines are parallel
  if (denominator === 0) {
    return false
  }

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false
  }

  // Return a point with the x and y coordinates of the intersection
  const x = x1 + ua * (x2 - x1)
  const y = y1 + ua * (y2 - y1)

  return [x, y]
}
