/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/2
 * @description
 *   money.ts of FastTrade
 */
import { h, defineComponent, computed } from 'vue'
import { formatMoney } from '@/common/format'

export default defineComponent(
  {
    name: 'Money',
    props: {
      as: {
        type: String,
        default: 'span',
      },
      v: {
        required: true,
        type: [String, Number],
        default: 0,
      },
      font: {
        type: String,
        default: 'font-mon',
      },
    },
    setup (props, ctx) {
      const child = computed(
        () => {
          const result = formatMoney(props.v)

          return typeof ctx.slots?.default === 'function'
            ? ctx.slots.default(result)
            : result.text
        },
      )

      return () => h(props.as, { class: props.font }, child.value)
    },
  },
)
