/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/31
 * @description
 *   redirect.ts of WeTrade
 */
import prepareTransition from '@/router/pageTransition'
import redirect from '@/router/redirect'
import { createRouter, createWebHashHistory } from 'vue-router'
import publicRoutes from '@/router/routes.public'
import privateRoutes from '@/router/routes.private'
import blankRoutes from '@/router/rotuers.blank'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    ...blankRoutes,
    ...publicRoutes,
    ...privateRoutes,
  ],
})

router.beforeEach(redirect(router))

// router.beforeEach(custom)

router.afterEach(prepareTransition)

export default router
