
import { goSpeed } from '@/common/goSpeed'
import { openDialog } from '@/components/popup/popup'
import GetApp from '@/pages/GetApp.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OnlyApp',
  props: {
    tag: {
      type: [String, Object, Function],
      default: 'div'
    }
  },
  setup() {
    return {
      check(e: MouseEvent) {
        e.stopPropagation()
        goSpeed('0-1', true)
        // openDialog(GetApp, null, {
        //   wrapperClass: 'operation'
        // })
      }
    }
  }
})
