/**
 * @author zjc[beica1@outook.com]
 * @date 2021/9/14 16:16
 * @description
 *   MA.ts of FAST
 */
import AbstractStudy from '../core/AbstractStudy'
import { Bar, ISelection } from '../types'
import { calcMA } from './formulas'
import * as R from 'ramda'

type Series = {
  period: number;
  lineColor: string;
  disabled?: boolean;
}

export type SMAStudyInputs = {
  series: Series[]
}

class SMA extends AbstractStudy<SMAStudyInputs> {
  private readPeriods () {
    const usableSeries = R.reject(
      R.propSatisfies(v => v === true, 'disabled'), this.options.series)
    return R.pluck('period', usableSeries)
  }

  slice (from: number, to: number): Bar[][] {
    return this.cached.map(d => d.slice(from, to))
  }

  calcInitStudy (quotes: Bar[]): Bar[][] {
    return calcMA(quotes, this.readPeriods())
  }

  calcCandidateStudy (quotes: Bar[]): Bar[][] {
    const periods = this.readPeriods()
    const maxPeriod = Math.max(...periods) + 1
    return calcMA(quotes.slice(-maxPeriod), periods)
  }

  render (g: ISelection<SVGGElement>, paths: Bar[][]) {
    g
      .selectAll('path')
      .data(paths)
      .join('path')
      .attr('class', (d, i) => `sma_${this.options.series[i].period}`)
      .attr('fill', 'none')
      .attr('stroke', (d, i) => this.options.series[i].lineColor)
      .attr('stroke-width', this.options.lineWidth)
      .attr('d', this.defaultLineRenderer)
  }
}

export default SMA
