/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/2 13:42
 * @description
 *   Chart.ts of FAST
 */
import { MonitorType } from '../core/dataMaster/AbstractDataMaster'
import { DrawingOptions } from '../drawing/Drawing'
import Drawings from '../drawing/Drawings'
import GraphRenderer from '../graph/GraphRenderer'
import IChart from '../interface/IChart'
import Study from '../study/Study'
import { StudyOptions } from '../study/StudyRenderer'
import { Bar, Periodicity, StudyType, SymbolDescriber } from '../types'
import { ChartOptions } from './Base'
import Gesture from './Gesture'

class Chart extends Gesture implements IChart {
  readonly drawings: Drawings
  readonly graph: GraphRenderer | null = null

  private onTickCallback: ((bar: Bar, x: number, y: number) => void) = () => undefined

  symbol: SymbolDescriber | null = null
  study: Study | null = null

  constructor (options: ChartOptions['call']) {
    super(options)

    this.drawings = new Drawings(this)

    this.dataMaster.monitor([MonitorType.EXTENT, MonitorType.DATA], () => {
      this.drawings.draw()
    })

    if (this.options.showChart) {
      this.graph = new GraphRenderer(this, {
        dataMaster: this.dataMaster,
        type: this.options.type,
        ...this.options.style,
      })
    }

    this.dataMaster.monitor([MonitorType.TICK, MonitorType.EXTENT], () => {
      const bar = this.dataMaster.candidate
      if (bar) {
        this.onTickCallback(bar, this.xAxis.fx(bar.t) ?? 0, this.yAxis.fy(bar.c))
      }
    })
  }

  config (periodicity: Periodicity) {
    this.xAxis.config(periodicity)
  }

  changeSymbol (symbol: SymbolDescriber | null) {
    this.symbol = symbol
  }

  createDrawing (drawing: DrawingOptions) {
    this.drawings.add(drawing)
  }

  /**
   * 实时数据钩子
   * @param callback
   */
  onTick (callback: (bar: Bar, x: number, y: number) => void) {
    this.onTickCallback = callback
  }

  setCandleWidth (width: number) {
    this.options.layout.candleWidth = width
    // this.xAxis.updateRenderContext(width)
  }

  addStudy<T extends StudyType> (type: T, options: StudyOptions[T]) {
    if (this.study) {
      this.study.addStudy(type, options)
    } else {
      this.study = new Study<T>(this, {
        dataMaster: this.dataMaster,
        ...options,
      }, [type, options])
    }
  }

  removeStudy (id: number) {
    if (id) {
      this.study?.removeStudy(id)
    }
  }

  removeAllStudies () {
    this.study?.clean()
  }

  destroy () {
    console.log('[Destroy] Chart')
    this.xAxis.destroy()
    this.yAxis.destroy()

    this.graph?.remove()

    this.study?.clean()
    this.study = null

    this.drawings.remove()

    document.querySelector(`#${this.id}`)?.remove()
  }
}

export default Chart
