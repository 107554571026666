
import Pic from '@/components/Pic.vue'
import { tagUser } from '@/modules/user/user.api'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ExperienceSelect',
  components: { Pic },
  setup (props, ctx) {
    const tag = (hasExp: boolean) => {
      tagUser({ tradeExpFlg: hasExp })
      ctx.emit('close')
    }

    return {
      tag,
    }
  },
})
