import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "d-f t-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
      return _renderSlot(_ctx.$slots, "default", {
        key: item.value,
        item: item,
        select: _ctx.select,
        active: item.value === _ctx.value
      })
    }), 128))
  ]))
}