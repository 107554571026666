/**
 * 监听服务端通知推送
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/22
 * @description
 *   notification.ts of main
 */
import { parseToJson } from '@/common/format'
import { server } from '@/config'
import Socket from 'essential/net/websocket/WebSocket'
// import idMaker from 'essential/tools/idMaker'
import * as R from 'ramda'
import { getSpeedAc } from '@/common/goSpeed'
import MD5 from 'crypto-js/md5.js'

// const ID = idMaker()

type Message = {
  cmd: number
  id: number
  success: boolean
  message: string
}

export interface Reducer {
  (message: Message): void
}

const HEART_BEAT_INTERVAL = 10
const MAX_NO_RESPONSE_INTERVAL = 2

class NotificationClient {
  private readonly callback
  private readonly reducers: Array<Reducer> = []
  private respTimer = 0

  readonly socket

  constructor(server: string, connectCallback: (ok: boolean) => void) {
    this.callback = connectCallback
    // const now = Date.now()

    this.socket = new Socket(server, {
      heartInterval: HEART_BEAT_INTERVAL,
      retryLimit: 3,
      heartSignal: () => '-1'
    })
      .on('message', this.dispatch.bind(this))
      .on('heartbeat', this.retryOnAbort.bind(this))
      .on('open', this.bind.bind(this))
      .connect()
  }

  /**
   * 2秒内心跳无响应，则强制重连
   */
  retryOnAbort() {
    this.respTimer = window.setTimeout(() => {
      // this.socket.retry(true)
    }, Math.min(HEART_BEAT_INTERVAL, MAX_NO_RESPONSE_INTERVAL) * 1000)
  }

  // onHeartbeatResp(betaId: string) {
  //   if (betaId === '-1') {
  //     clearTimeout(this.respTimer)
  //   } else {
  //     this.socket.retry(true)
  //   }
  // }

  dispatch(message: Blob) {
    const tempCallback = this.callback
    const tempReducers = this.reducers
    // const tempHeartbeat = this.onHeartbeatResp
    message.arrayBuffer().then(function (buffer) {
      const uint8Array = new Uint8Array(buffer)
      const tempStr = new TextDecoder('utf-8').decode(uint8Array)
      if (tempStr !== '-1') {
        // tempHeartbeat(tempStr)
        const messageObj = parseToJson(tempStr.split('$_')[0]).json
        if (messageObj) {
          tempCallback(messageObj.success || messageObj.msgType)
          R.map(reducer => reducer(messageObj), tempReducers)
        }
      }
    })
  }

  // @todo token验证失败处理
  bind() {
    const now = Date.now()
    this.socket.send(
      JSON.stringify({
        type: 'connect',
        p: {
          token: getSpeedAc('tradeToken'),
          uuid: getSpeedAc('uuid'),
          device: 5,
          auth: MD5(`connect_${now}_0VSqWn!xvA`).toString(),
          t: now
        }
      })
    )
  }

  reduce(reducers: Array<Reducer>) {
    this.reducers.push(...reducers)
  }

  destroy() {
    clearTimeout(this.respTimer)
    if (this.socket) {
      this.socket.release()
    }
  }
}

type ServerConfigItem = {
  protocol: 1 | 2
  ip: string
  port: number
}

const SOCKET_PROTOCOLS = ['ws', 'ws', 'wss']

declare const connectConfig: Partial<{
  ws: Array<ServerConfigItem>
}>

export const getNotificationServer = () =>
  new Promise<Array<ServerConfigItem>>((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.onload = () => setTimeout(resolve, 0, connectConfig.ws)
    script.onerror = reject
    script.src = server.pushSeed
    document.body.appendChild(script)
  })

export const getSocketServerViaGlobalConfig = (server: ServerConfigItem) =>
  `${SOCKET_PROTOCOLS[server.protocol]}://${server.ip}:${server.port}/ws`

const connectSocket = (): Promise<NotificationClient> =>
  new Promise((resolve) => {
    function connect() {
      // const server = new NotificationClient(
      //   getSocketServerViaGlobalConfig(servers[serverIndex]), ok => {
      //     if (ok) resolve(server)
      //     else {
      //       if (server) server?.destroy()
      //       if (++serverIndex < servers.length) {
      //         connect()
      //       } else reject()
      //     }
      //   })
      const newServer = new NotificationClient(server.noticeWs, ok => {
        if (ok) {
          resolve(newServer)
        } else {
          if (newServer) newServer?.destroy()
          connect()
        }
      })
    }

    connect()
  })

const connect = async () => {
  // 获取服务器列表
  // const servers = await getNotificationServer()
  return connectSocket()
  // const count = servers.length
  // const serverIndex = count > 1 ? randomInt(0, servers.length - 1) : 0
  // // 随机服务器地址
  // const server = servers[serverIndex]
  // return new NotificationClient(getSocketServerViaGlobalConfig(server))
}

export default connect
