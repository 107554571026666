
import throttle from 'lodash.throttle'
import { defineComponent, onActivated, ref } from 'vue'

export default defineComponent(
  {
    name: 'Scroller',
    emits: ['bottomTouched', 'scroll'],
    props: {
      bottomThreshold: {
        type: Number,
        default: 40,
      },
      propagate: Boolean,
      cssOnly: Boolean,
    },
    setup (props, ctx) {
      const el = ref<Element | null>(null)

      if (props.cssOnly) {
        return {
          el,
          events: {},
        }
      }

      let lastPos = 0

      onActivated(() => {
        if (lastPos !== 0) {
          const el$ = el.value as HTMLElement
          const origin = window.getComputedStyle(el$).scrollBehavior
          el$.style.scrollBehavior = 'unset'
          el$.scrollTop = lastPos
          el$.style.scrollBehavior = origin
        }
      })

      function getBoundaryOffset (offset: number, least: number) {
        const dir = Math.abs(least) / least
        return offset <= 0 ? least : (dir * offset)
      }

      function onScroll (e: Event) {
        const { scrollHeight, scrollTop, clientHeight } = e.target as Element
        if (scrollTop > lastPos) { // scroll down
          const offset = getBoundaryOffset(
            scrollHeight - (scrollTop + clientHeight), 0.1,
          )
          if (offset <= props.bottomThreshold) {
            ctx.emit('bottomTouched')
          }
        }

        if (lastPos !== scrollTop) {
          lastPos = scrollTop
        }
      }

      const debouncedOnScroll = throttle(onScroll, 300)

      const onTouch = (e: Event) => {
        // 外围的scroll事件 阻止内部的 滑动事件
        const target = e.target as HTMLElement
        const parentOffset = target.offsetParent as HTMLElement
        const isSwiper = parentOffset.classList.contains('swipe__item')
        if (lastPos !== 0 && !isSwiper) {
          e.stopPropagation()
        }
      }

      return {
        el,
        events: {
          scroll (e: Event) {
            debouncedOnScroll(e)
            if (props.propagate) ctx.emit('scroll', (e.target as Element).scrollTop)
          },
        },
        onTouch,
      }
    },
  },
)
