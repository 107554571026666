import requestResult from '@/common/request/request'
import { YesOrNo } from '@/types'
import { AnalysisSchema } from '@/modules/community/analysis/analysis.api'
import { NewsSchema } from '@/modules/community/news/news.api'
import { Post } from '@/modules/community/communities/community.api'

export type BannerSchema = {
  appId: number;
  contentId: number;
  imageurl: string;
  url: string;
}

export const readBanner = requestResult<Array<BannerSchema>>('/api/banner/list')

export type Topic = {
  label: string;
  content: string;
}

export enum TaskType {
  ROOKIE = 1,
  STOCK,
  DEPOSIT$50,
  FORMAL_TRADE,
  REPORT,
  DOUBLE,
}

export enum RookieTaskType {
  DEMO_ORDER = 1,
  QUIZ,
  FIRST_DEPOSIT,
  FAST_TRADE_ORDER,
  EXCHANGE_COUPON,
  LEVERAGE,
}

export type RookieTask = {
  tag: RookieTaskType;
  icon: string;
  title: string;
  award: number;
  status: YesOrNo;
}

export type RecommendStock = {
  code: string;
  description: string;
  icon: string;
}

export type Task = {
  type: TaskType.DEPOSIT$50;
} | {
  type: TaskType.ROOKIE;
  noviceTasks: RookieTask[];
} | {
  type: TaskType.STOCK;
  stockInfoForms: RecommendStock[];
} | {
  type: TaskType.FORMAL_TRADE;
  title: string;
} | {
  type: TaskType.REPORT;
  days: number;
} | {
  type: TaskType.DOUBLE;
  amount: number;
  targetAmount: number;
  voucherAmount: number;
  averateAmount: number;
}

export const readTasks = requestResult<Task[]>('/api/task/list')

export type RookieQuiz = {
  title: string;
  question: string;
  answer: YesOrNo;
  description: string;
}

export type EventItem = {
  title: string;
  icon: string;
  startTime: number;
  endTime: number;
  eventType: number; // 1,"抽卡" 2,"非农" 3,"EIA" 4,"小测验" 5,"直播")
  timeType: number; // 1,"时间点" 2,"时间段"
  eventId: number; // 事件ID
  channelId: number; // eventType =5 有值
}

export type NewsAndAnalysis = {
  type: number; // 0 帖子 1 文章 2 交易建议
  userPostModel?: Post;
  appcontentArticleModel?: NewsSchema;
  tradOpptortunitiesModel?: AnalysisSchema;
}

export type CheckEvent = {
  eventType: number; // 1,"抽卡" 4,"小测验"
  chance: number; // 1 有机会 0 没有机会
  eventId: number; // 事件ID
}

export type LiveInfo = {
  channelId: number;
  chatRoomId: string;
  backgroundPicture: string;
  onlineNumber: number;
  announcement: string;
  liveLevel: number;
  liveTimeTwo: string;
  tips: string;
}

export enum RecommendTag {
  BEARISH,
  BULLISH,
}

export type Recommend = {
  tag: RecommendTag;
  advantage: string;
  tagDesc: string;
  code: string;
}

export type ChartItem = {
  c: string;
  h: string;
  l: string;
  o: string;
  u: number;
}

export const readQA = requestResult<RookieQuiz[]>('/api/task/qa')

export const completeRookieQuiz = requestResult('/api/task/qa/done')

export const readEventsList = requestResult<EventItem[]>('/api/event/list')

export const entryLive = requestResult<LiveInfo>('/api/live/entry') // 根据channelId查询直播间详情

export const readNewsAndAnalysis = requestResult<NewsAndAnalysis[]>('/api/content/list')

export const checkEvent = requestResult<CheckEvent>('/api/event/check')

export const readUpComing = requestResult<EventItem>('/api/event/upcoming')

export const checkTestFinish = requestResult<{status: number}>('/api/event/test/check')

export const finishTest = requestResult('/api/event/test/answer') // 提交测试答案

export const readEventQa = requestResult<RookieQuiz[]>('/api/event/qa')

export const doneTask = requestResult('/api/task/done')

export const checkScore = requestResult<{status: number}>('/api/customer/score/get') // 是否需要评分

export const submitEvaluate = requestResult('/api/customer/score/update')

export const readRecommend = requestResult<Recommend[]>('/api/product/mt/recommend') // 获取首页推荐品种列表

export const readKchart = requestResult<ChartItem[]>('/quotation/kChart/info')
