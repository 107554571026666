/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/7 19:13
 * @description
 *   defaults.ts of FAST
 */
import { ShapeOptionalOptions } from './core/AbstractShape'
import { AxisDisplay, LineStyle, Periodicity, ShapeStyle } from './types'

const defaultColor = 'black'

export const defaultLineStyle: LineStyle = {
  lineColor: defaultColor,
  lineStyle: 'solid',
  lineWidth: 1,
}

export const defaultShapeStyle: ShapeStyle = {
  ...defaultLineStyle,
  fillColor: 'none',
  joinStyle: 'round',
  capStyle: 'round',
  riseColor: '#00B167',
  fallColor: '#F24A3A',
}

export const defaultShapeOptions: ShapeOptionalOptions = {
  mountType: 'g',
}

export const defaultAxisDisplay: AxisDisplay = {
  display: true,
  tickSize: 20,
  showTicks: true,
  labelFormat: label => label,
  showBorder: true,
  borderColor: defaultColor,
  borderWidth: 1,
  showGrid: true,
  lineWidth: 1,
  lineColor: defaultColor,
}

export const defaultPeriodicity: Required<Periodicity> = {
  period: 1,
  interval: 1,
  timeUnit: 'minute',
  offset: 0,
}
