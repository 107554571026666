
import { defineComponent, onBeforeUnmount } from 'vue'

export default defineComponent(
  {
    name: 'Bouncing',
    props: {
      pause: Boolean,
    },
  })
