/**
 * @description
 *   useArray.ts of WeTrade
 */
import { shallowRef } from 'vue'

/**
 * 维护一个array
 */
const useArray = <T = string> (initial: Array<T> = []) => {
  const arr = shallowRef<Array<T>>(initial)

  /**
   * 操作一个数组
   * @param {Number|Array<T>|T} mix - 替换/删除数据元素起始位置索引或者需要新增的一个或者多个元素
   *              如果新增的元素类型为数字，则mix类型必须为数组才可以新增
   * @param {Number} deleteCount - 需要替换/删除的元素个数
   * @param {Array} replacement - 替换后新的元素
   */
  const mutate = (
    mix: number | Array<T> | T,
    deleteCount = 1,
    replacement?: Array<T>,
  ) => {
    const next = [...arr.value]
    if (typeof mix === 'number') {
      if (replacement) next.splice(mix, deleteCount, ...replacement) // replace
      else next.splice(mix, deleteCount) // delete
    } else if (Array.isArray(mix)) {
      next.push(...mix) // push all
    } else {
      next.push(mix) // push one
    }
    arr.value = next
  }

  const clean = () => {
    arr.value = []
  }

  return {
    arr,
    mutate,
    clean,
  }
}

export default useArray
