/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/31
 * @description
 *   useDelay.ts of WeTrade
 */
import { onBeforeUnmount, onDeactivated } from 'vue'

const useDelay = (delay = 0) => {
  let timer = 0

  onDeactivated(() => {
    clearTimeout(timer)
  })

  onBeforeUnmount(() => {
    clearTimeout(timer)
  })

  return (fn: () => void) => {
    const from = Date.now()
    return () => {
      const now = Date.now()
      const waste = now - from
      if (waste < delay) {
        timer = window.setTimeout(fn, delay - waste)
      } else fn()
    }
  }
}

export default useDelay
