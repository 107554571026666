/**
 * @author ayou[beica1@outook.com]
 * @date 2022/3/3 18:09
 * @description
 *   createLoadingSegment.ts of WeTrade
 */
const imgB64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAPFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADQLyYwAAAAFHRSTlMCCjNNwmr+26JdghEehESQevG3JWKc05AAAAFMSURBVEjH3ZTtkoMgDEW5SF0Fxa/3f9eNaRBdY4udndmZPf6p7T0kgSnm/xKsDbcES/y+AH5uCGKgVAC6AII+/RSg5/t6asU4CF1VLVo7XU2IIYLkCShGmLIhguQJdeC2ToYcXM536gzZ4Cnf5TdjImFD8hpAMsJOkLxegI2pHmEyC+ehGUIIwHmxi/UDGIOzgKv1Ja+UwLmAoMx3miK6L8ZFKBMu7YNp83ZRXgxtPygvxqcCDi3BFLSUh1ZmSO+lu4T0KEo+Bj2vFwnH37jJF23F2bX7tqMfrRiqUDki7L7zBBsKKT9j6xoY+76/MGCeeRcBbBtqvRgKiJyvsGKHwXLVFwbmnMfQNAMINrwf1Xtpl0dDpDvNE+rUDzdHHIXnHKOP+j0g10UW5J/GhXRF9lMEfn9PFgrzHFsFjpcK67aaO/DB3QHmM/6kyDeoMQrjreQ8cQAAAABJRU5ErkJggg=='

const style = document.createElement('style')

style.textContent = `
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

style.type = 'text/css'
document.head.prepend(style)

const img$ = new Image(16, 16)
img$.src = imgB64
img$.style.cssText = `
  display: inline-block;
  vertical-align: middle;
  padding: 4px;
  animation: rotate 1s linear infinite;
`

const block$ = document.createElement('div')
block$.style.cssText = `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 6px;
`
/**
 * background: #FFFFFF;
 * box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
 */

export default (id: string) => {
  const mask$ = document.createElement('div')

  mask$.style.cssText = `
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  `

  const c_block$ = block$.cloneNode()
  c_block$.appendChild(img$.cloneNode())
  mask$.appendChild(c_block$)
  mask$.id = id

  return mask$
}
