/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   redirect.ts of WeTrade
 */
import { openDialog } from '@/components/popup/popup'
import { keymap } from '@/config'
import HoldOn from '@/provider/HoldOn.vue'
import { isLogged } from '@/state'
import { AsyncComponentLoader } from '@vue/runtime-core'
import { localGet } from 'essential/store/localStore'
import * as R from 'ramda'
import { defineAsyncComponent, h } from 'vue'
import { NavigationGuard, RouteLocationRaw, Router } from 'vue-router'
import { goSpeed } from './../common/goSpeed'

const dialogRoute: string[] = []

const redirect = (router: Router): NavigationGuard => (to, from, next) => {
  let dest: RouteLocationRaw = to

  // 测试环境不做限制
  // if (flag.isDevMode) return next()

  // 限制未登录用户访问私有页面
  const isPrivate = R.pathEq(['meta', 'private'], true, to)
  if (isPrivate && !isLogged()) {
    dest = to.meta.useRoute ? '/' : (to.meta.routeFallback as string) ?? { name: 'login' }
    // return next((to.meta.routeFallback as string) ?? { name: 'login' })
  }

  // 限制登录用户访问仅漫游模式可见的页面
  const isForbiddenForLoggedUser = R.pathEq(['meta', 'roamOnly'], true, to)
  // 限制已登录用户访问登录页面
  if (
    isLogged() &&
    isForbiddenForLoggedUser
  ) {
    dest = { name: 'home' }
    // return next({ name: 'home' })
  }

  // 根据用户的countryCode重定向
  const isNeedRedirect = R.pathEq(['meta', 'redirectWithCC'], true, to)
  if (isNeedRedirect) {
    const cc = localGet(keymap.user.countryCode) ?? '*'
    dest = {
      path: `${to.path}/${cc}`,
      query: to.query,
    }
    // return next({
    //   path: `${to.path}/${cc}`,
    //   query: to.query,
    // })
  }

  const { meta, params, query, matched, path } = router.resolve(dest)

  if (path === '/login') {
    return goSpeed('0-0')
  }

  if (!meta.useRoute) {
    // 阻止相同路由的多次弹窗
    if (path === dialogRoute[0]) return

    dialogRoute.unshift(path)
    // 将route的params和query映射到props中
    const props = {
      ...params,
      ...query,
      onClose() {
        dialogRoute.shift()
      },
    }

    const options = {
      wrapperClass: 'operation',
      ...meta,
    }

    const component = matched[0]?.components.default
    if (typeof component === 'function') {
      openDialog(defineAsyncComponent({
        loader: component as AsyncComponentLoader,
        loadingComponent: h(HoldOn, { height: 100 }),
      }), props, options)
    } else if (component) {
      openDialog(component, props, options)
    }

    return
  }

  if (dest !== to) {
    return next(dest)
  }

  next()
}

export default redirect
