/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/20 23:19
 * @description
 *   AbstractGraph.ts of WeTrade
 */
import { Bar, ISelection } from '../types'
import AbstractShape from './AbstractShape'

abstract class AbstractGraph<T = unknown> extends AbstractShape<T, Bar> {
  drawInit (all: Bar[], from: number, to: number) {
    this.render(this.initD3El, all.slice(from, to))
    return this
  }

  drawCandidate (latest: Bar, init: Bar[]) {
    this.render(this.d3El, init.slice(-1).concat(latest))
    return this
  }

  abstract render (g: ISelection<SVGGElement>, quotes: Bar[]): void
}

export default AbstractGraph
