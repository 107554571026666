import requestResult from '@/common/request/request'
import { YesOrNo } from '@/types'

export enum PostType {
  POST= 1,
  COMMENT,
}

export type Post = {
  postId: number;
  type: PostType;
  avatarPic: string;
  nickName: string;
  createTime: number;
  topStatus: YesOrNo;
  likeStatus: boolean;
  likeCount: number;
  commentCount: number;
  content: string;
  userId: number;
  imageUrls: Array<string> | null;
}

export type PostsResult = {
  picEnable: boolean;
  posts: Array<Post>;
}

export const readPosts = requestResult<PostsResult>('/api/group/post/list')

export type PostResult = {
  post: Post;
}

export const readPost = requestResult('/api/group/post/detail')

export const create = requestResult('/api/group/post/insert')

export const like = requestResult('/api/group/post/like')

export type Comment = {
  commentId: number;
  avatarPic: string;
  nickName: string;
  likeStatus: YesOrNo;
  likeCount: number;
  content: string;
  postId: number;
  parentId: number;
  parentContent: string;
  createTime: number;
  fromUserId: number;
}

export type CommentsResp = {
  commentList: Comment[];
  size: number;
}

export const readComments = requestResult<CommentsResp>('/api/group/post/comment/list')

export const readHotComments = requestResult('/api/group/post/hotcomments')

export const createComment = requestResult('/api/group/post/insert/comment')

export type BannerSchema = {
  appId: number;
  contentId: number;
  imageurl: string;
  url: string;

}

export const readBanner = requestResult<Array<BannerSchema>>('/api/banner/list')

export type Topic = {
  label: string;
  content: string;
}

export const readTopics = requestResult<Array<Topic>>('/api/group/label/list')
