
import { formatDateTime } from '@/common/format'
import { defineComponent } from 'vue'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheCrosshair',
  props: {
    crosshair: Object,
  },
  setup (props) {
    const formatTime = R.curryN(2, formatDateTime)('MM-dd-yyyy HH:mm')

    const getColorClass = (n: number) => {
      return n > props.crosshair?.bar?.pc ? 'c-success' : 'c-danger'
    }

    return {
      formatTime,
      getColorClass,
    }
  },
})
