
import Button from '@/components/Button.vue'
import { openDialog } from '@/components/popup/popup'
import { events } from '@/config'
import ThePositionEditResultDialog from '@/modules/trade/components/ThePositionEditResultDialog.vue'
import TheStopLoss from '@/modules/trade/components/TheStopLoss.vue'
import TheTakeProfit from '@/modules/trade/components/TheTakeProfit.vue'
import Order from '@/modules/trade/Order'
import { useEdit } from '@/modules/trade/trade'
import { Position, Product, readProduct } from '@/modules/trade/trade.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { emit } from 'essential/tools/event'
import { defineComponent, provide } from 'vue'
import { useQuote } from '@/modules/market/market'
import QuoteBar from '@/components/QuoteBar.vue'

export default defineComponent({
  name: 'ThePositionEditDialog',
  components: {
    QuoteBar,
    TheTakeProfit,
    TheStopLoss,
    Button,
    DialogTemplate,
  },
  emits: ['close'],
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const position = props.position as Position
    const symbol = useQuote(position.code)

    const model = new Order(position.type, {
      orderId: position.orderId,
      price: position.createPrice,
      isJuan: position.isJuan,
      stopProfit: Number(position.stopProfitPoint),
      stopLoss: Number(position.stopLossPoint),
      isTrackSt: position.isTrackSt,
      createPrice: position.createPrice,
    })

    provide('order', model)

    readProduct({ codes: position.productId })
      .then((resp: any) => {
        model.updateProduct(resp as Product)
        model.updateProductQuantity(Number(position.orderNumber).toString())
      })

    const [commit, progress] = useEdit()

    return {
      progress,
      symbol,
      product: model.getProduct(),
      saveEdit () {
        commit(model.getValue()).then(() => {
          emit(events.transactionUpdate)
          openDialog(ThePositionEditResultDialog, {
            top: model.takeProfit.value,
            bottom: model.stopLoss.value,
          })
          ctx.emit('close')
        })
      },
    }
  },
})
