
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ForStock',
  components: { Pic },
  props: {
    days: {
      type: Number,
      required: true,
    },
  },
})
