/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/18 10:40
 * @description
 *   CCI.ts of WeTrade
 */
import * as d3 from 'd3'
import AbstractStudy, { ExtendStudyOptions, StudyConfig } from '../core/AbstractStudy'
import IChart from '../interface/IChart'
import { Bar, ISelection } from '../types'
import { calcCCI } from './formulas'

export type CCIStudyInputs = {
  period: number;
}

class CCI extends AbstractStudy<CCIStudyInputs, Bar, ISelection<SVGPathElement>> {
  slice (from: number, to: number) {
    return this.cached.slice(from, to)
  }

  constructor (chart: IChart, options: ExtendStudyOptions<CCIStudyInputs>, config?: StudyConfig) {
    super(chart, options, { ...config, mountType: 'path' })

    this.defaultLineFy = d3.scaleLinear().domain([-300, 300]).range([this.chart.height, 0])
  }

  calcInitStudy (quotes: Bar[]) {
    return calcCCI(quotes, this.options.period)
  }

  calcCandidateStudy (quotes: Bar[]): Bar[] {
    return calcCCI(quotes.slice(-(this.options.period + 1)), this.options.period)
  }

  render (g: ISelection<SVGPathElement>, cci: Bar[]) {
    g
      .call(this.style.bind(this))
      .datum(cci)
      .attr('d', this.defaultLineRenderer)
    return this
  }
}

export default CCI
