/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/8
 * @description
 *   log.ts of js-essential
 */
import * as R from 'ramda'

const isDev = () => process.env.NODE_ENV === 'development'

const log = (...rest: Array<string | boolean | number | Data>) => {
  if (isDev()) {
    console.log(...rest)
  }
}

log.badge = (module: string, style: string) => R.unapply(
  R.pipe(
    R.adjust(0, R.pipe(String, R.concat(`%c${module}%c`))),
    R.insert(1, `color:white;padding:0 8px;background:${style}`),
    R.insert(2, 'background:#ffd900;padding:0 8px;'),
    R.apply(log),
  ),
);

export default log
