/**
 * @author zjc[beica1@outook.com]
 * @date 2021/7/29 14:21
 * @description
 *   HappyChart.ts of FAST
 */
import { ChartOptionalOptions, ChartOptions } from './chart/Base'
import Chart from './chart/Chart'
import DataMaker from './core/DataMaker'
import DataMaster, { DataMasterOptions } from './core/dataMaster/DataMaster'
import { defaultPeriodicity } from './defaults'
import { DefineOptions } from './define/Defines'
import { extend, randomString } from './helper'
import { StudyOptions } from './study/StudyRenderer'
import { Bar, IDatafeed, OptionsOf, Periodicity, Quote, SymbolDescriber, StudyType } from './types'
import * as R from 'ramda'

// 缺省配置声明
type HappyChartOptionalOptions = {
  periodicity: Periodicity;
  autoMarket: boolean;
  datafeed?: IDatafeed;
  dataSource?: DataMasterOptions;
} & Partial<ChartOptionalOptions>

// 必要配置项声明
type HappyChartRequiredOptions = {
  container: string | Element;
}

export type HappyChartOptions = OptionsOf<HappyChartOptionalOptions, HappyChartRequiredOptions>

// 缺省配置
const defaultHappyOptions: HappyChartOptionalOptions = {
  periodicity: defaultPeriodicity,
  autoMarket: true,
}

class HappyChart {
  private readonly version = '0.0.1'

  private readonly options: HappyChartOptions['define']
  private readonly id: string

  /**
   * 目前没有禁止 数据加载过程中的 symbol change
   * 而由于data loading的异步性 会导致 用户选择的symbol与加载中
   * 的symbol不一致的情况  这里通过两个变量来表现这中情况
   * @private
   */
  private symbolCode = '' // sync
  private symbolDescriber: SymbolDescriber | null = null // async

  // 数据粒度配置信息
  private periodicity: Periodicity

  private busy = false

  // 数据接口
  datafeed: IDatafeed | null = null

  // 数据管理器
  dataMaster: DataMaster

  // 数据发生器
  dataMaker: DataMaker

  // 数据格式化器
  format = (d: Bar[]) => d

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unwatch = () => {}

  chart: Chart | null = null
  charts: Chart[] = []

  constructor (options: HappyChartOptions['call']) {
    this.id = randomString('hc')

    this.options = extend(defaultHappyOptions, options)

    let container: Element | string | null = this.options.container

    if (typeof this.options.container === 'string') {
      container = document.querySelector('#' + container as string)

      if (container) this.id = this.options.container
    }

    if (container === null || !(container instanceof Element)) {
      throw new ReferenceError('options["container"] 不是合法的DOM元素或有效的css选择器')
    }

    container.id = this.id

    this.periodicity = this.options.periodicity

    this.datafeed = this.options.datafeed ?? null

    this.dataMaster = new DataMaster(this.options.dataSource ?? {})

    this.dataMaker = new DataMaker(this.options.periodicity, this.options.autoMarket)
      .onEmit(this.dataMaster.onEmit.bind(this.dataMaster))
  }

  private useChart (chart?: Chart) {
    return chart ?? this.chart ?? this.charts.slice(-1)[0]
  }

  private setChartData (data?: Bar[], chart?: Chart) {
    if (this.symbolDescriber && data) {
      this.useChart(chart)?.restore()
      this.dataMaster.setData(this.format(data) ?? data)
      this.dataMaker.setup(this.dataMaster.candidate, true)
    }
    return data
  }

  async loadChartData (chart?: Chart) {
    if (this.symbolDescriber) {
      const from = this.useChart(chart)?.xAxis.fx.domain()[0]

      this.loading(true)

      const nextData = await this.datafeed?.read(this.symbolDescriber, this.periodicity, from)

      this.loading(false)

      this.setChartData(nextData)

      return nextData
    }

    return []
  }

  private resolveSymbol () {
    if (this.datafeed) {
      return this.datafeed.resolveSymbol(this.symbolCode)
    }
    return null
  }

  /**
   * @todo 缓存实时数据
   * @param quote
   * @param now
   */
  streamData (quote: Quote, now: number) {
    this.dataMaker.stream({
      c: quote.last,
      t: now ?? Date.now(),
    })
  }

  /**
   * 更新数据
   * @param symbol
   * @param data
   * @param chart
   */
  async changeSymbol (symbol: string, data?: Bar[], chart?: Chart) {
    if (!(this.symbolCode = symbol)) {
      return
    }

    if (this.busy) {
      return
    }

    this.busy = true

    const symbolDesc = await this.resolveSymbol()

    this.useChart(chart)?.changeSymbol(symbolDesc)

    this.symbolDescriber = symbolDesc ?? null

    this.unwatch()

    if (data) {
      this.setChartData(data)
    } else {
      const d = await this.loadChartData(chart)
      this.setChartData(d)
    }

    if (this.datafeed && this.symbolDescriber) {
      this.unwatch = this.datafeed.watch(this.symbolDescriber, this.streamData.bind(this))
    }

    this.busy = false

    if (symbolDesc?.code !== this.symbolCode) {
      this.changeSymbol(this.symbolCode, data, chart)
    }
  }

  /**
   * 新建
   * @param symbol
   * @param options
   * @param data
   * @private
   */
  private createChart (symbol: string, options?: ChartOptions['partial'], data?: Bar[]) {
    const chart = new Chart(extend({
      ...R.omit(['datafeed', 'container'], this.options),
      container: this.id,
      symbol,
      dataMaster: this.dataMaster,
      periodicity: this.periodicity,
    }, options ?? {}))
    this.changeSymbol(symbol, data)
    this.chart = chart
    this.charts.push(chart)
    return chart
  }

  /**
   * 加载chart
   * @param symbol
   * @param mix
   * @param options
   */
  loadChart (
    symbol: string,
    mix?: ChartOptions['partial'] | Bar[],
    options?: ChartOptions['partial'],
  ) {
    let _data: Bar[] | undefined = mix as Bar[]
    let _options = options

    if (!Array.isArray(mix)) {
      _data = undefined
      _options = mix
    }

    return this.createChart(symbol, _options, _data)
  }

  /**
   * 设置x时间区间
   * @param domain
   * @param chart
   * @todo setSpan 指定区间长度
   */
  setRange (domain: number[], chart?: Chart) {
    this.useChart(chart)?.xAxis.fixedDomain(domain)
  }

  /**
   * 绑定数据源
   * @param feed
   */
  attachFeed (feed: IDatafeed) {
    this.datafeed = feed
  }

  define (define: DefineOptions, chart?: Chart) {
    return this.useChart(chart)?.defines.add(define)
  }

  addStudy<T extends StudyType> (type: T, options: StudyOptions[T], chart?: Chart) {
    this.useChart(chart)?.addStudy(type, options)
  }

  removeStudy (id: number, chart?: Chart) {
    this.useChart(chart)?.removeStudy(id)
  }

  removeAllStudies (chart?: Chart) {
    this.useChart(chart)?.removeAllStudies()
  }

  setPeriodicity (periodicity: Periodicity) {
    this.periodicity = periodicity

    this.dataMaker.config(periodicity)
    R.map(chart => chart.config(periodicity), this.charts)

    if (this.symbolDescriber) {
      this.loadChartData()
    }
  }

  loading (state = false) {
    this.busy = state

    this.charts.map(chart => chart.loading(state))
  }

  destroy () {
    console.log('[Destroy] HappyChart')
    this.symbolDescriber = null
    this.unwatch?.()
    this.datafeed = null
    this.dataMaster.destroy()
    R.map(chart => chart.destroy, this.charts)
    this.charts = []
    this.chart?.destroy()
    this.chart = null
  }
}

export default HappyChart
