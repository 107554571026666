/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/18 10:39
 * @description
 *   TrendPro.ts of WeTrade
 */
import { ISelection } from '../../types'
import { TrendDescriber } from '../formulas'
import Trend from './Trend'

class TrendPro extends Trend {
  render (g: ISelection<SVGGElement>, shapes: TrendDescriber[]) {
    super.render(g, shapes)

    const r = this.chart.xAxis.fx.bandwidth() / 2

    g
      .selectAll('circle')
      .data(shapes.filter(d => d.turn))
      .join('circle')
      .attr('fill', d => d.turn > 0 ? this.options.riseColor : this.options.fallColor)
      .attr('cx', d => this.chart.xAxis.fx(d.t) ?? 0)
      .attr(
        'cy',
        d => {
          const price = d.turn > 0 ? d.l : d.h
          const tweak = r * (d.turn > 0 ? 1 : -1)
          return this.chart.yAxis.fy(price) + tweak
        },
      )
      .attr('r', r)
      .raise()
  }
}

export default TrendPro
