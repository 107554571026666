
import SymbolList from '@/modules/market/SymbolList.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheWatchSymbolButton from '@/modules/market/components/TheWatchSymbolButton.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddToWatchList',
  components: { PageWithHeader, SymbolList, TheWatchSymbolButton },
})
