
import { defineComponent, onActivated, watchEffect } from 'vue'

export default defineComponent({
  name: 'TabPane',
  props: {
    tab: {
      type: [String, Object],
    },
    key: {
      type: String,
      required: true,
    },
  },
})
