
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
export default defineComponent({
  name: 'TheScoreItem',
  components: {
    Pic,
  },
  props: {
    score: {
      type: Number,
      default: 0,
    },
  },
  emits: ['change'],
  setup (props, ctx) {
    const starNum = shallowRef(props.score)

    const handlePoint = (index: number) => {
      starNum.value = index
      ctx.emit('change', index)
    }
    return {
      starNum,
      handlePoint,
    }
  },
})
