/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/1/6
 * @description
 *   withUserContext.ts of FastTradeV3
 */
import { isLogged } from '@/state'

const withUserContext = <T = void> (fn: () => T, defaultResult?: T) => {
  return () => {
    if (isLogged()) {
      return fn() as T
    }
    return defaultResult as T
  }
}

export default withUserContext
