/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/3
 * @description
 *   request.apis.ts of FastTrade
 */
import { AccountType } from '@/state'

const apis = {
  createCommission: {
    [AccountType.REAL]: '/web/trade/limit/create',
    [AccountType.DEMO]: '/api/entrust/mt/demo/create',
  },
  readCommission: {
    [AccountType.REAL]: '/web/trade/limit/position/list',
    [AccountType.DEMO]: '/api/entrust/mt/demo/hold/list',
  },
  ofCommission: {
    [AccountType.REAL]: '/web/trade/limit/detail',
    [AccountType.DEMO]: '/api/entrust/mt/demo/hold/detail',
  },
  cancelCommission: {
    [AccountType.REAL]: '/web/trade/limit/close',
    [AccountType.DEMO]: '/api/entrust/mt/demo/cancel',
  },
  editCommission: {
    [AccountType.REAL]: '/web/trade/limit/update',
    [AccountType.DEMO]: '/api/entrust/mt/demo/update',
  },
  read: {
    [AccountType.REAL]: '/web/trade/position/list',
    [AccountType.DEMO]: '/api/account/mt/demo/info',
  },
  create: {
    [AccountType.REAL]: '/web/trade/create',
    [AccountType.DEMO]: '/api/demo/order/mt/create',
  },
  edit: {
    [AccountType.REAL]: '/web/trade/update',
    [AccountType.DEMO]: '/api/demo/order/mt/update',
  },
  close: {
    [AccountType.REAL]: '/web/trade/close',
    [AccountType.DEMO]: '/api/demo/order/mt/close',
  },
  history: {
    [AccountType.REAL]: '/web/trade/history/list',
    [AccountType.DEMO]: '/api/demo/order/mt/history/list',
  },
  fastHistory: {
    [AccountType.REAL]: '/api/order/new/option/history/list',
    [AccountType.DEMO]: '/api/demo/order/new/option/history/list',
  },
  fastPositions: {
    [AccountType.REAL]: '/api/order/new/option/hold/list',
    [AccountType.DEMO]: '/api/demo/order/new/option/hold/list',
  },
  fastCreate: {
    [AccountType.REAL]: '/web/trade/quick/create',
    [AccountType.DEMO]: '/api/demo/order/new/option/create',
  },
}

export type APINames = keyof typeof apis

export type API = Record<AccountType, string>

export default apis as Record<APINames, API>
