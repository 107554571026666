/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/7 17:28
 * @description
 *   Line.ts of FAST
 */
import AbstractDrawing, { DrawCallback, OptionalDrawingOptions } from '../core/AbstractDrawing'
import { defaultLineStyle } from '../defaults'
import { extend } from '../helper'
import IChart, { ChartPointer } from '../interface/IChart'
import { ISelection, LineStyle, OptionsOf, Point, TickPoint } from '../types'

type RequiredLineOptions = {
  container: string;
  id: string;
}

type LineKeyPoint = TickPoint & {
  sc?: string;
}

export type OptionalLineOptions = {
  dir?: 'vertical' | 'horizontal' | number;
  point?: LineKeyPoint;
  formatLabel?: (c: number | string) => string | number
} & Partial<LineStyle> & OptionalDrawingOptions

export type LineOptions = OptionsOf<OptionalLineOptions, RequiredLineOptions>

const defaultLineOptions: OptionalLineOptions = {
  formatLabel: v => v,
  dir: 'horizontal',
  ...defaultLineStyle,
}

class Line extends AbstractDrawing implements AbstractDrawing {
  private readonly isH: boolean
  private readonly dashArray: string
  private readonly options: LineOptions['define']
  private readonly pointerToTransform: (p: Point) => Point
  private readonly line: ISelection<SVGLineElement>
  private readonly text: ISelection<SVGTextElement>

  private keyPoint?: LineKeyPoint

  constructor (chart: IChart, options: LineOptions['call'], callback?: DrawCallback) {
    super({
      chart,
      pointsCount: 1,
      ...options,
      callback,
    })

    this.options = extend(defaultLineOptions, options)
    this.keyPoint = this.options.point
    this.isH = this.options.dir === 'horizontal'

    this.el_d3.attr('class', 'drawing line')

    this.line = this.el_d3.append('line')

    const label = this.el_d3.append('g')
      .attr('class', 'label')

    label.append('line')
      .attr('class', 'bg')
      .attr('x1', 0)
      .attr('y1', -7)
      .attr('x2', 60)
      .attr('y2', -7)
      .attr('stroke-width', 13)

    this.text = label.append('text')
      .attr('dy', -3)
      .attr('font-size', 11)
      .attr('stroke', 'white')
      .attr('fill', 'white')
      .attr('stroke-width', 0.1)
      .text('--')

    this.pointerToTransform = ([x, y]) => this.isH ? [0, y] : [x, 0]

    this.dashArray = this.options.lineStyle === 'dashed'
      ? '4'
      : (
        this.options.lineStyle === 'dotted'
          ? '1'
          : '0'
      )
  }

  private expandLine () {
    let x1 = 0
    let y1 = this.chart.height

    if (this.isH) {
      x1 = this.chart.width
      y1 = 0
    }

    this
      .line
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', x1)
      .attr('y2', y1)

    return this
  }

  private transform (point?: ChartPointer) {
    if (point) {
      this
        .el_d3
        // .transition()
        .attr('transform', `translate(${this.pointerToTransform([point.x, point.y])})`)
    }
  }

  style (color?: string) {
    if (color) {
      this.options.lineColor = color
      this.line.attr('stroke', color)
      this.el_d3.select('.bg').attr('stroke', color)
    } else {
      this.line
        .attr('stroke-width', this.options.lineWidth)
        .attr('stroke', this.options.lineColor)
        .attr('stroke-dasharray', this.dashArray)
      this.el_d3.select('.bg').attr('stroke', this.options.lineColor)
    }

    return this
  }

  highlight () {
    this.line
      .attr('stroke-width', 2) // @todo general the line width

    return this
  }

  locate (): ChartPointer[] | undefined {
    return this.endpoints().length ? this.endpoints() : undefined
  }

  parseEndpoints (): ChartPointer[] {
    if (this.keyPoint) {
      return [
        {
          t: this.keyPoint.t,
          c: this.keyPoint.c,
          sc: this.chart.fix(this.keyPoint.c),
          x: this.chart.x(this.keyPoint.t) ?? 0,
          y: this.chart.y(this.keyPoint.c),
        },
      ]
    }

    return []
  }

  renderDrawing (init = false) {
    const keyPoint = this.endpoints(0)

    if (keyPoint) {
      if (init) {
        this.expandLine()
        this.style()
      }

      this.transform(keyPoint)
      this.text.text(this.options.formatLabel(keyPoint.sc ?? keyPoint.c))
    }

    return this
  }

  create () {
    this.expandLine()
      .style()
      .draw(
        p => {
          this.transform(p)
          this.text.text(p.sc)
        },
        this.transform.bind(this),
      )

    return this
  }

  updateLabel (text: (t: string) => string) {
    this.text.text(text(this.text.text()))

    return this
  }

  syncEndpoints (points: ChartPointer[]) {
    this.keyPoint = {
      c: points[0].c,
      sc: points[0].sc,
      t: points[0].t,
    }

    return this
  }

  dragStart () {
    return this
  }

  drag (e: Event, p: ChartPointer) {
    this.updateEndpoints([p])

    this.renderDrawing()

    return this
  }
}

export default Line
