/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/7 21:34
 * @description
 *   Graph.ts of FAST
 */
import DataMaster from '../core/dataMaster/DataMaster'
import RendererMaster from '../core/RendererMaster'
import Area from './Area'
import Candle from './Candle'
import Line from './Line'
import { ChartType, ShapeStyle } from '../types'

type GraphOptions = {
  dataMaster: DataMaster;
  type: ChartType;
} & Partial<ShapeStyle>

class GraphRenderer extends RendererMaster<GraphOptions> {
  render () {
    const graphOptions = {
      ...this.options,
      container: this.chart.id,
    }

    switch (this.options.type) {
      case ChartType.AREA:
        return new Area(this.chart, graphOptions)
      case ChartType.CANDLE:
        return new Candle(this.chart, graphOptions)
      default:
        return new Line(this.chart, graphOptions)
    }
  }
}

export default GraphRenderer
