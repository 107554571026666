
import Loading from 'common/loading/Donut.vue'
import Holder from '@/provider/Holder.vue'

export default {
  name: 'HoldOn',
  components: { Holder, Loading },
  props: {
    height: {
      type: Number,
      default: 20,
      validator (value: number) {
        return value >= 20
      },
    },
  },
}
