/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/4/7
 * @description
 *   sort.ts of WeTrade
 */
import * as R from 'ramda'
import transform, { Point, Axis, Transformer, TransformHooks } from 'essential/dom/transform'

const Y = Axis.Y

const getOriginTransformPoint = (el: HTMLElement): Point => {
  const transformMatrix = window.getComputedStyle(el).transform
  if (transformMatrix === 'none') return [0, 0]
  return transformMatrix.split(',').slice(-2).map(parseFloat) as Point
}

interface SwipeFn {
  (transformer: Transformer): Point
}

const sort = (el: HTMLElement, onChange: (dir: number) => void) => {
  let lastBreakPoint = 0
  let swipeFrom: Point = getOriginTransformPoint(el)
  const copy = el.cloneNode(true) as HTMLElement

  const swipeInYAxis: SwipeFn = (transformer) => [
    0,
    swipeFrom[Y] + (transformer.delta[Y]),
  ]

  const applyTransformStyle = (point: Point) => {
    copy.style.transform = `translate3d(0, ${point[Y]}px, 0)`
  }

  const onWillTransform = () => {
    const boundary = el.getBoundingClientRect()
    copy.style.position = 'absolute'
    copy.style.left = boundary.left + 'px'
    copy.style.width = boundary.width + 'px'
    copy.style.top = boundary.top + 'px'
    copy.style.transform = 'none'
    copy.style.background = 'white'
    el.parentElement?.append(copy)
    // 重置动画
    el.style.transition = 'none'
    // 更新起始点
    swipeFrom = getOriginTransformPoint(el)
  }

  const onTransform: TransformHooks['onTransform'] = transformer => {
    const next = swipeInYAxis(transformer)
    applyTransformStyle(next)
    const offset = transformer.delta[Y] - lastBreakPoint
    // 56 为sort-item高度 这里简单化处理
    const breakPoint = Math.round(offset / 56)
    if (breakPoint !== lastBreakPoint) {
      onChange(breakPoint - lastBreakPoint)
      lastBreakPoint = breakPoint
    }
  }

  const onDidTransform: TransformHooks['onDidTransform'] = () => {
    lastBreakPoint = 0
    el.parentElement?.removeChild(copy)
  }

  return transform(el, {
    onWillTransform,
    onTransform,
    onDidTransform,
  })
}

const sortable = (selector: string, onChange: (index: number, dir: number) => void) => {
  const el = document.querySelector(selector) as HTMLElement
  if (!el) {
    return () => {
      console.error('No sortable container provided')
    }
  }

  const children = el.children
  if (children.length === 0) {
    return () => {
      console.error('Nothing to sort')
    }
  }

  const cleaners: Array<() => void> = []
  for (let i = 0; i < children.length; i++) {
    (index => {
      const child = children.item(index) as HTMLElement
      cleaners.push(sort(child, (dir: number) => {
        const preSibling = child.previousElementSibling as HTMLElement
        const nextSibling = child.nextElementSibling as HTMLElement
        if (dir === 1 && nextSibling) {
          nextSibling.insertAdjacentElement('afterend', child)
        }
        if (dir === -1 && preSibling) {
          preSibling.insertAdjacentElement('beforebegin', child)
        }
        onChange(index, dir)
      }))
    })(i)
  }

  return () => {
    R.map(clean => clean(), cleaners)
  }
}

export default sortable
