/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/9
 * @description
 *   common.ts of WeTrade
 */
import * as R from 'ramda'
import { request } from '@/config'

export const randomInt = (_min: number, _max: number) => {
  const min = Math.ceil(_min)
  const max = Math.floor(_max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const defaultToArray:
  <T = unknown>(arr: T) => T extends Array<infer P> ? Array<P> : [] =
  R.ifElse(R.is(Array), R.identity, R.always([]))

export const isNewVersionThan = (least: string): boolean => request.staticRequestData.version >= least
