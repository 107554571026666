/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/6 10:19
 * @description
 *   DataMaster.ts of FAST
 *   数据管理器
 *
 *   input: 计算y轴范围的逻辑函数
 *
 *   根据指定的渲染宽度和起始位置（或相关值）
 *   来从现有的数组中截取符合条件的数据
 *   附带计算其y轴的范围
 */
import { extend, extent } from '../../helper'
import { Bar, NumberTuple, RecursivePartial, RecursiveRequired } from '../../types'
import * as R from 'ramda'
import { Transformation } from '../../xAxis/zoomBandScale'
import AbstractDataMaster, { MonitorType } from './AbstractDataMaster'

export type DataMasterOptionalOptions = {
  determineMinimum: (bar: Bar) => number,
  determineMaximum: (bar: Bar) => number,
}

export type DataMasterOptions = RecursivePartial<DataMasterOptionalOptions>

export const defaultDataMasterOptions: DataMasterOptionalOptions = {
  determineMinimum: bar => bar.l ?? bar.c,
  determineMaximum: bar => bar.h ?? bar.c,
}

class DataMaster extends AbstractDataMaster {
  private readonly options: RecursiveRequired<DataMasterOptionalOptions>

  constructor (options: RecursivePartial<DataMasterOptionalOptions>) {
    super()
    this.options = extend(defaultDataMasterOptions, options)
  }

  /**
   * @implements extent
   * @param bars
   */
  extent (bars: Bar[]) {
    return extent<Bar>(bars, this.options.determineMinimum, this.options.determineMaximum)
  }

  /**
   * @implements emitExtentChange
   * y轴范围计算
   * @param low
   * @param high
   */
  emitExtentChange ([low, high]: NumberTuple) {
    this.emitChange(MonitorType.EXTENT, [low, high])
  }

  /**
   * @implements emitDataChange
   */
  emitDataChange () {
    this.emitChange(MonitorType.DATA)
  }

  /**
   * @implements emitRangeChange
   * x轴范围计算
   * @todo 处理范围 目前只处理了最新值
   */
  emitRangeChange () {
    this.emitDataChange()
  }

  /**
   * @param latest
   */
  onEmit (latest: Bar) {
    this.applyStream(latest)
    this.emitLatest()
  }

  applyStream (latest: Bar) {
    // 在插入现有数据队列中之前，确保时间序是正确的
    if (this.candidate && latest.t > this.candidate.t) {
      // 新增
      this.push([this.candidate], latest)
    } else {
      this.update(latest)
    }
  }

  /**
   * 数据重置
   * @param data
   */
  setData (data: Bar[]) {
    // 再设置新的数据源之前，清空旧数据源的计算结果
    // 否则会引数据越界的情况
    this.reset()

    // 更新配置
    // this.emitConfigChange()

    if (data.length) {
      const [init, [candidate]] = R.splitAt(-1, data)

      // 更新历史数据（候选bar之前的数据）
      this.push(init, candidate)

      // // 开始数据生成
      // this.emitter.setup(candidate, true)

      // this.emitLatest()
    }
  }

  /**
   * 设置显示的数据宽度
   * @param renderSize 需要渲染的数据宽度不包括candidate
   * @param paddingSize 右边留白的数据宽度
   */
  restrict (renderSize: number, paddingSize = 0) {
    // 数据长度有更新
    this.dirty = true
    this.setRenderSize(Math.max(0, renderSize))
    this.paddingRight(paddingSize)
    // this.organizeData()
  }

  value = this.cachedValue()

  index (index: number) {
    return R.nth(index, this.rendered[0]) ?? {
      t: NaN,
      c: NaN,
    }
  }

  first () {
    return this.index(0)
  }

  last () {
    return this.index(-1)
  }

  review () {
    this.inReview = true
    // this.rendererSize += this.paddingSize
    this.renderOffset = 0
  }

  restore () {
    if (this.inReview) {
      this.inReview = false

      // 恢复留白空间
      // this.rendererSize -= this.paddingSize
      this.renderOffset = 0

      // 恢复候选数据的可见性
      this.candidateOverflow = false

      // 消费缓存数据
      this.init = this.init.concat(this.cached)

      // 清除缓存数据
      this.cached = []

      this.organizeData()
      this.reScale()
      this.emitDataChange()
    }
  }

  /**
   * offset为正表示向右溢出的数据量 在数组中的所以标识为更小的值
   * @param transformation
   * @param fallback
   */
  transform (transformation: Transformation, fallback?: () => void) {
    if (!this.inReview) {
      this.review()
    }

    if (this.renderOffset !== transformation.offset || this.renderSize !== transformation.renderSize) {
      this.restrict(transformation.renderSize, transformation.paddingSize)
      const result = this.organizeTransformed([], transformation.offset, transformation.isZoom)
      if (!result) {
        fallback?.()
        return
      }
      this.reScale()
    }
    this.emitDataChange()
  }

  destroy () {
    this.reset()
    this.removeMonitor(MonitorType.DATA)
    this.removeMonitor(MonitorType.EXTENT)
    this.removeMonitor(MonitorType.TICK)
  }
}

export default DataMaster
