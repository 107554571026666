/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/3
 * @description
 *   accordion.ts of WeTrade
 */
const accordion = (el: HTMLElement, isExpand = false) => {
  const contentElement = el.querySelector('.accordion__content')
  if (!contentElement) throw new Error('No suitable accordion content provided')
  el.style.overflow = 'hidden'

  const transition = 'height 0.3s ease'

  let nextIsExpand = !isExpand

  const expand = () => {
    requestAnimationFrame(() => {
      el.style.transition = transition
      el.style.height = contentElement.scrollHeight + 'px'
    })
  }

  const collapse = () => {
    requestAnimationFrame(() => {
      el.style.transition = transition
      el.style.height = '0'
    })
  }

  const toggle = () => {
    if (nextIsExpand) expand()
    else collapse()
    nextIsExpand = !nextIsExpand
  }

  // el.addEventListener('click', toggle)

  if (!isExpand) {
    el.style.height = '0'
  }

  return (prepare?: (isExpand: boolean, done: Fn) => void) => {
    if (typeof prepare === 'function') {
      prepare(!nextIsExpand, toggle)
    } else {
      toggle()
    }
  }
}

export default accordion
