import { renderSlot as _renderSlot, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _mergeProps({
    ref: "el",
    class: "scroll-y"
  }, _toHandlers(_ctx.events), {
    onTouchstartCapture: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onTouch && _ctx.onTouch(...args)))
  }), [
    _renderSlot(_ctx.$slots, "default")
  ], 16))
}