import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")
  const _component_Holder = _resolveComponent("Holder")

  return (_openBlock(), _createBlock(_component_Holder, _ctx.$attrs, {
    holder: _withCtx(() => [
      _createVNode("div", {
        class: "df-cross-center",
        style: {height: `${$props.height}px`}
      }, [
        _createVNode(_component_Loading)
      ], 4)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16))
}