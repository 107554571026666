import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "df-middle mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pic = _resolveComponent("Pic")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_Fragment, null, _renderList(5, (item, index) => {
      return _createVNode("div", {
        class: "s-item",
        key: index,
        onClick: ($event: any) => (_ctx.handlePoint(item))
      }, [
        _createVNode(_component_Pic, {
          src: item > _ctx.starNum ? 'home/starEmpty': 'home/starSolid',
          width: "32",
          height: "32",
          class: "mr-16",
          style: {"cursor":"pointer"}
        }, null, 8, ["src"])
      ], 8, ["onClick"])
    }), 64))
  ]))
}