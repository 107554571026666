
import Button from '@/components/Button.vue'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCreditInsufficientDialog',
  components: { Pic, Button, Icon, DialogTemplate },
})
