
import Icon from '@/components/Icon.vue'
import withUserContext from '@/decorators/withUserContext'
import AccountRelated from '@/provider/AccountRelated.vue'
import state from '@/state'
import Button from '@/components/Button.vue'
import { computed, defineComponent, shallowRef, watchEffect, ref, watch } from 'vue'
import { demoBalanceRecharge } from '@/modules/trade/trade'
import { format } from '@/config'
import { isDemoAccount } from '@/state/accountType'
import { readDemoAccountInfo } from '@/modules/trade/trade.api'
import { on } from 'essential/tools/event'
import { add, div, sub, mul } from 'essential/tools/math'

export default defineComponent({
  name: 'TheAsset',
  components: { Icon, AccountRelated, Button },
  setup () {
    const account = computed(() => {
      return state.account
    })

    const accountNew = ref(state.account)
    const showTopUp = shallowRef(false)

    // todo 暂时改为每次切换demo重新请求接口获取最新freeMargin
    watchEffect(withUserContext(() => {
      if (isDemoAccount.value) {
        // readDemoAccountInfo().then(res => {
        //   showTopUp.value = Number(res.freeMargin) < 500 * format.moneyFactor
        // })
      }
    }))

    const [topUpClick, progress] = demoBalanceRecharge(() => {
      showTopUp.value = Number(state.account.freeMargin) < 500 * format.moneyFactor
    })

    on('quoteUpdateAccount', (plTotal, useMargin, deferredMoney) => {
      // console.log(plTotal, useMargin)
      const equity = add(state.account.balance, plTotal, deferredMoney)
      accountNew.value = {
        ...state.account,
        equity: equity.toFixed(2),
        freeMargin: sub(equity, useMargin).toFixed(2),
        useMargin: useMargin.toFixed(2),
        marginRatio: mul(useMargin > 0 ? div(equity, useMargin) : 0, 100).toFixed(2),
        plFeeAmount: plTotal.toFixed(2)
      }
    })

    watch(() => state.account, (val) => {
      accountNew.value = { ...val }
    })

    return {
      stickAlertFlag: Number(state.account.marginRatio) < 100 && Number(state.account.marginRatio) > 0,
      account: accountNew,
      showTopUp,
      topUpClick,
      progress,
    }
  },
})
