/**
 * 账户切换
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/1
 * @description
 *   useAccountState.ts of FastTrade
 */
import { events, keymap } from '@/config'
import { AccountType, DEFAULT_ACCOUNT_TYPE } from '@/state/index'
import { localSet } from 'essential/store/localStore'
import { emit, off, on } from 'essential/tools/event'
import * as R from 'ramda'
import { computed, onBeforeUnmount, shallowReadonly, shallowRef } from 'vue'

const noop = () => {
  console.log('empty function')
}

const accountType = shallowRef<AccountType>(DEFAULT_ACCOUNT_TYPE)

export const useAccountType = () => shallowReadonly(accountType)

export const isDemoAccount = computed(() => accountType.value === AccountType.DEMO)

export const changeAccountType = (type: AccountType, onChange = noop) => {
  if (accountType.value !== type) {
    accountType.value = type
    localSet(keymap.label.accountType, String(type))
    emit(events.accountChanged)
    onChange()
  }
}

export const accounts = [
  {
    text: 'Live account',
    value: AccountType.REAL,
    label: 'trade_2',
  }, {
    text: 'Demo account',
    value: AccountType.DEMO,
    label: 'trade_3',
  },
]

export const useAccounts = (onChange = noop) => {
  const index = computed(() => R.findIndex(R.propEq('value', accountType.value), accounts))

  return {
    // accounts list
    accounts,
    // current index
    index,
    // update selected
    change (option: Unpacked<typeof accounts>) {
      changeAccountType(option.value, onChange)
    },
  }
}

export const useAccountChange = (onChange = noop) => {
  on(events.accountChanged, onChange)

  onBeforeUnmount(() => {
    off(events.accountChanged, onChange)
  })
}
