/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   market.ts of WeTrade
 */
import datafeed from '@/common/datafeed'
import { openDialog, openFullscreenList } from '@/components/popup/popup'
import { events, keymap, market } from '@/config'
import { isShield } from '@/decorators/withoutCryptos'
import useRequest from '@/hooks/useRequest'
import AddToWatchList from '@/modules/market/AddToWatchList.vue'
import { overlayStudies, StudyConfig } from '@/modules/market/components/chart/chartConfig'
import EditWatchList from '@/modules/market/EditWatchList.vue'
import GuideOrderDialog from '@/modules/market/GuideOrderDialog.vue'
import {
  MarketCategory,
  MarketItem,
  readMarketList, readMarketListWithoutCryptos,
  readSymbol,
  readWatchlist,
  requestSaveWatchlist,
  SymbolSnapshot,
  readWatchListSp,
  readWatchListAllSp,
  MarketItemSp,
} from '@/modules/market/market.api'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import Order from '@/modules/trade/Order'
import {
  createPosition,
  Product,
  // readProducts,
} from '@/modules/trade/trade.api'
import { getProductsByCode } from '@/modules/trade/trade'
import { readHistory } from '@/pages/history/history.api'
import state, { isLogged } from '@/state'
import { isDemoAccount } from '@/state/accountType'
import { MarketState, TradeDirection, YesOrNo } from '@/types'
import { localGet, localSet } from 'essential/store/localStore'
import { emit, off, on } from 'essential/tools/event'
import * as R from 'ramda'

import {
  onActivated,
  onBeforeUnmount,
  shallowRef,
  computed,
  Ref,
  unref,
  isRef,
  watchEffect,
} from 'vue'
import { reqLoginOut } from '@/common/goSpeed'

/**
 * 同步自选列表到本地
 * @param list
 */
const syncLocalWatchlist = (list: string) => {
  localSet(keymap.label.watchList, list)
}

/**
 * 添加/移除自选列表
 * @param codeRef
 */
export const useWatchState = (codeRef: string | Ref<string>) => {
  let code = unref(codeRef)
  const added = shallowRef(false)
  const toggleAddState = () => added.value = !added.value

  const [save, progress] = useRequest(requestSaveWatchlist)

  if (isRef(codeRef)) {
    watchEffect(() => {
      code = unref(codeRef)
      added.value = code ? R.includes(code, localGet(keymap.label.watchList) ?? '') : false
    })
  } else {
    code = codeRef
    added.value = code ? R.includes(code, localGet(keymap.label.watchList) ?? '') : false
  }

  const toggle = () => {
    let nextList = (localGet(keymap.label.watchList) ?? '').split(',')
    if (added.value) {
      nextList = R.reject(R.equals(code), nextList)
    } else {
      nextList = R.prepend(code, nextList)
    }

    const next = nextList.toString()
    save({ watchList: next }).then(() => {
      toggleAddState()
      syncLocalWatchlist(next)
    })
  }

  return {
    toggle,
    progress,
    added,
  }
}

export const useSpeedWatchList = () => {
  const read = (d?: Data) => reqLoginOut(readWatchListSp)(d).then((resp: any) => {
    const list = resp?.productList ? R.pluck('name', (resp as MarketItemSp).productList).toString() : R.pluck('code', resp as Data[]).toString()
    localSet(keymap.label.watchList, list)
    return resp
  })

  const all = (d?: Data) => reqLoginOut(readWatchListAllSp)(d).then(resp => resp)
  return {
    read,
    all
  }
}

/**
 * 自选列表修改信息保存在本地，并保持更新
 */
export const useWatchlist = () => {
  const read = () => readWatchlist().then(resp => {
    localSet(keymap.label.watchList, R.pluck('code', resp).toString())
    return resp
  })

  const done = () => {
    emit(events.watchListUpdate)
  }

  const add = () => openFullscreenList(AddToWatchList, { onSave: done })

  const edit = () => openFullscreenList(EditWatchList, { onSave: done })

  return {
    read,
    add,
    edit,
  }
}

/**
 * 自选列表排序
 */
export const useSortWatchlist = () => {
  const list = shallowRef<Array<MarketItem>>([])
  let editList: Array<MarketItem> = []

  readWatchlist().then(resp => {
    list.value = resp
    editList = resp
  })

  /**
   * 置顶
   * @param code
   */
  const toTop = (code: string) => {
    const pos = R.findIndex(R.propEq('code', code), editList)
    if (~pos) {
      editList = R.move(pos, 0, editList)
    }
  }

  const sort = (code: string, dir: number) => {
    const index = R.findIndex(R.propEq('code', code), editList)
    editList = R.move(index, index + dir, editList)
  }

  const removeItems = (codes: Array<string>) => {
    const next = R.reject(x => R.includes(x.code, codes), editList)
    list.value = next
    editList = next
  }

  const save = () => {
    const watchList = R.pluck('code', editList).toString()
    return requestSaveWatchlist({ watchList })
      .then(() => syncLocalWatchlist(watchList))
  }

  return {
    list,
    toTop,
    sort,
    removeItems,
    save,
  }
}

export enum SORT_STATE {
  DESC = -1,
  NONE,
  ESC,
}

/**
 * 用户读取自选，市场行情，并编辑自选列表
 * @param defaultTab
 */
export const useMarket = (defaultTab = 0) => {
  const tabIndex = shallowRef(defaultTab)

  const filter = (type: MarketCategory = MarketCategory.ALL) => () => {
    return readMarketList(
      {
        excode: market.excode,
        type,
      })
  }

  const tabs = shallowRef([
    {
      label: 'market_3',
      value: 1,
      load: filter(MarketCategory.FOREX),
      labelDefault: 'Forex',
    }, {
      label: 'market_26',
      value: 5,
      load: filter(MarketCategory.STOCK),
      labelDefault: 'Stocks',
    }, {
      label: 'market_6',
      value: 4,
      load: filter(MarketCategory.INDEX),
      labelDefault: 'Indices',
    }, {
      label: 'market_4',
      value: 2,
      load: filter(MarketCategory.COMMODITIES),
      labelDefault: 'Commodities',
    },
  ])

  isShield().then(resp => {
    if (!resp) {
      tabs.value.splice(1, 0, {
        label: 'market_5',
        value: 3,
        load: filter(MarketCategory.CRYPTO),
        labelDefault: 'Crypto',
      })
    }
  })

  const switchTab = (index: number) => {
    if (index !== tabIndex.value) {
      tabIndex.value = index
    }
  }

  return {
    index: tabIndex,
    tabs,
    switchTab,
  }
}

export const useOverlayStudies = (changeStudy?: (config: StudyConfig) => void): [
  StudyConfig[],
  StudyConfig,
  (config: StudyConfig, done: (item: StudyConfig) => void) => void
] => {
  const isPrime = computed(() => state.prime.member === YesOrNo.YES)

  const dft = R.find(s => (isPrime.value ? !!s?.elite : !s?.elite), overlayStudies) as StudyConfig

  const change = (config: StudyConfig, done: (item: StudyConfig) => void) => {
    if (config.elite ? isPrime.value : !config.elite) {
      changeStudy?.(config)
      done(config)
    } else {
      openDialog(LimitDialogTemplate, {
        i18nPath: 'symbol_72',
      })
    }
  }

  return [overlayStudies, dft, change]
}

export const readQuote = (() => {
  /**
   * 相同code的请求复用
   */
  const pending: Record<string, Promise<SymbolSnapshot>> = {}

  return async (code: string, realTime = false) => {
    if (!code) {
      throw new ReferenceError('No "symbol code" provide!')
    }

    const cache = datafeed.getLatestQuote(code)
    /**
     * cache优先
     */
    if (cache && !realTime) return cache

    if (pending[code]) return pending[code]

    return pending[code] = readSymbol(
      { codes: `${market.spcode}|${code}` },
    )
      .then(resp => {
        /**
         * 反向更新缓存
         */
        datafeed.updateLatestQuote(resp[0])
        return resp[0]
      })
      .finally(() => {
        delete pending[code]
      })
  }
})()

// 行情订阅/引用
export const useQuote = (codeRef: string | Ref<string>, realTime = false) => {
  let code = unref(codeRef)

  const symbol = shallowRef<SymbolSnapshot | null>(null)

  const read = () => {
    code = unref(codeRef)

    if (code) {
      readQuote(code, realTime)
        .then(resp => symbol.value = resp as SymbolSnapshot)
    }
  }

  if (isRef(codeRef)) {
    watchEffect(read)
  } else {
    read()
  }

  return symbol
}

const mostActiveSymbolsList = [
  'USDJPY', 'EURUSD', 'GBPUSD', 'AUDUSD', 'XAUUSD',
  'XAGUSD', 'USOIL', 'UKOIL', 'BTCUSD', 'ETHUSD',
]

function isWeekend() {
  const day = new Date().getDay()
  return day === 0 || day === 6
}

export const readMostActiveProduct = async () => {
  const symbols = await (isWeekend() ? readMarketList : readMarketListWithoutCryptos)({
    excode: market.excode,
  }).then(resp => R.filter(
    R.both(
      R.propSatisfies(R.includes(R.__, mostActiveSymbolsList), 'code'),
      R.complement(R.pathEq(['realTimeMicroQuotationBean', 'isClosed'], MarketState.CLOSED)),
    ),
    resp,
  ))

  // console.log('jojo', symbols)

  // 已经存在建仓历史 不提示
  if (R.length(symbols) === 0) return

  // 获取涨跌幅最大的品种
  let theMostActiveSymbol = symbols[0]
  for (let i = 1; i < symbols.length; i++) {
    if (
      Math.abs(parseFloat(symbols[i].realTimeMicroQuotationBean.mp)) >
      Math.abs(parseFloat(theMostActiveSymbol.realTimeMicroQuotationBean.mp))
    ) {
      theMostActiveSymbol = symbols[i]
    }
  }

  // 品种获取失败
  if (!theMostActiveSymbol) return

  // 获取匹配的产品
  // const products = await readProducts(
  //   { code: theMostActiveSymbol.realTimeMicroQuotationBean.code })
  const products = await getProductsByCode(
    theMostActiveSymbol.realTimeMicroQuotationBean.code, YesOrNo.NO)

  const product = R.find(R.propEq('price', '2000000'), products)

  // 产品获取失败
  if (!product) return

  return {
    product,
    symbol: theMostActiveSymbol,
  }
}

/**
 * 引导新用户用券下单
 */
export const guideToPlaceOrder = () => {
  let checking = false

  const check = async () => {
    // 只针对虚拟账户
    if (!isDemoAccount.value) return

    // 争对登录用户
    if (!isLogged()) return

    // 登陆跳转后只执行一次check
    if (checking) return
    checking = true

    // 已经存在持仓单 不提示
    if (R.length(state.account.holdList) !== 0) return

    const orderHistory = await readHistory({
      page: 1,
      pageCount: 1,
    })

    const most = await readMostActiveProduct()

    if (!most) return

    const { product, symbol } = most

    if (R.length(orderHistory) === 0) {
      checking = false
      openDialog(GuideOrderDialog, {
        symbol: symbol.realTimeMicroQuotationBean,
        tips: symbol.tips,
        product,
      }, {
        sync: true,
      })
    }
  }

  on(events.login, check)

  onBeforeUnmount(() => {
    off(events.login, check)
  })

  onActivated(check)
}

/**
 * 下单
 * @param product
 * @param isBuy
 */
export const placeOrder = (product: Product, isBuy: boolean) => {
  const model = new Order(isBuy ? TradeDirection.BUY : TradeDirection.SELL)
  model.setProduct(product)
  return createPosition(model.getValue())
}

export const SpAllTab = [
  { name: 'market_3', productType: 2 },
  { name: 'market_5', productType: 3 },
  { name: 'market_4', productType: 4 },
  { name: 'market_6', productType: 5 },
  { name: 'market_44', productType: 6, subType: 1 },
  { name: 'market_45', productType: 6, subType: 2 },
  { name: 'market_46', productType: 6, subType: 3 },
]
