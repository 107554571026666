/**
 * @author ayou[beica1@outook.com]
 * @date 2022/2/14 15:28
 * @description
 *   zoom.ts of WeTrade
 */
import * as d3 from 'd3'

/**
 * 平移缩放x轴
 * @param axis 轴
 * @param viewWidth 可渲染的最大宽度
 * @param range
 * @param padding 右边留白
 */
const zoomBandScale = (
  axis: d3.ScaleBand<number>,
  viewWidth: number,
  padding = 0,
  range = [0, viewWidth],
) => {
  const PI = axis.paddingInner()
  const PO = axis.paddingOuter()
  // 计算左右间距的系数
  const POFactor = PO * axis.align() * 2
  // const ax = axis.copy()

  let K = NaN
  let currentStep = NaN

  /**
   * 思路
   * 1.引用变换
   * 2.以right - padding的值计算paddingSize
   * 3.将right以step为因子平移到右边界(viewWidth)的右边并记录偏移量offset
   * 4.将right补齐为step的整数倍
   * 5.以right与step推算left以及renderSize
   * 6.取消domain的补齐操作
   */
  return (transform: d3.ZoomTransform, _step?: number) => {
    // 1.应用变换
    const ax = axis.copy().range(range.map(d => transform.applyX(d)))
    let step = currentStep
    let isZoom = false

    /**
     * 第一次调用 初始化 缩放系数K以及step
     */
    if (!step) {
      step = Math.round(_step ?? ax.step())
      K = transform.k
      currentStep = step
    } else if (step || K !== transform.k) {
      /**
       * 避免k重复对x值的计算 而导致step指数级更新
       * x1 = x + kx
       */
      step = Number((step / K * transform.k).toFixed(2))
      // alert((step === currentStep) + ' ' + currentStep + ' ' + step)
      K = transform.k
      currentStep = step
      isZoom = true
    }

    const halfBand = Math.round(step * (1 - PI) / 2)

    let [, right] = ax.range()

    right = Math.round(right)

    // 外边距
    const sidePad = step * POFactor
    // 内边距
    const innerPad = step * PI

    // 2.计算paddingSize
    const paddingSize = Math.floor(padding / step)

    right = Math.round(right - padding + paddingSize * step)

    // 3.平移right到viewWidth的右边最近的band中心线上
    const diff = right - viewWidth

    const offset = Math.floor(diff / step)

    // 减去平移的倍数部分
    right = right - offset * step

    // 4.将right补齐为step的整数倍
    right = right - sidePad + innerPad

    // 5.计算renderSize以及left
    let renderSize = Math.ceil(right / step)

    let left = Math.round(right - renderSize * step)

    if (left < -halfBand) {
      left += step
      renderSize--
    }

    // 移除最右侧的内边距并增加外边距
    left += sidePad - halfBand
    right = right - innerPad + sidePad + halfBand

    return {
      step,
      offset,
      isZoom,
      renderSize,
      paddingSize,
      range: [left, right],
    }
  }
}

export type Transformation = ReturnType<ReturnType<typeof zoomBandScale>>

export default zoomBandScale
