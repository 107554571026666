/**
 * 全局状态管理
 * @author 贝才[beica1@outook.com]
 * @date 2020/9/29
 * @description
 *   index.ts of FastTrade
 */
import { keymap } from '@/config'
import { Position } from '@/modules/trade/trade.api'
import { TradeFlag, YesOrNo } from '@/types'
import { localGet } from 'essential/store/localStore'
import * as R from 'ramda'
import { shallowReactive } from 'vue'
import { getSpeedAc } from './../common/goSpeed'

export type LevelModel = {
  validPoints: number;
  levelName: string;
  level: number;
  rebateRate: number;
  totalExp: number;
  totalPoints: number;
}

export type UserAccount = {
  balance: string;
  equity: string;
  freeMargin: string;
  useMargin: string;
  marginRatio: string;
  marginRatioWarn: number;
  plFeeAmount: string;
  holdList: Array<Position>;
  levelModel: LevelModel;
  tradeExpFlg: TradeFlag;
  isShowBubbles: number;
  validCreditAmount: string;
  list?: any
}

export const initAccount = {
  balance: '0',
  equity: '0',
  freeMargin: '0',
  useMargin: '0',
  marginRatio: '0',
  marginRatioWarn: 0,
  plFeeAmount: '0',
  holdList: [],
  levelModel: {
    validPoints: 0,
    levelName: 'V0',
    level: 0,
    rebateRate: 1,
    totalExp: 0,
    totalPoints: 0,
  },
  tradeExpFlg: 0,
  isShowBubbles: 0,
  validCreditAmount: '0'
}

export type PrimeDescriber = {
  member: YesOrNo;
  renew: YesOrNo;
  startIssue: string;
  endIssue: string;
  renewIssue: string;
  prise: number;
}

const defaultPrime: PrimeDescriber = {
  member: YesOrNo.NO,
  renew: YesOrNo.NO,
  startIssue: 'unknown',
  endIssue: 'unknown',
  renewIssue: 'unknown',
  prise: 39900,
}

export type GlobalState = {
  guestMode: boolean;
  login: boolean;
  account: UserAccount;
  prime: PrimeDescriber;
  countryCode: string;
}

export enum AccountType {
  REAL,
  DEMO,
}

export const DEFAULT_ACCOUNT_TYPE = Number(localGet(keymap.label.accountType) ?? AccountType.REAL)

const state: GlobalState = shallowReactive({
  login: false,
  guestMode: false,
  account: initAccount,
  prime: defaultPrime,
  countryCode: '',
})

export default state

export const isLogged = () => Boolean(getSpeedAc('uuid'))

export const isPrime = () => state.prime.member === YesOrNo.YES

// export const isNewLogin = () => R.isNil(localGet(keymap.user.accountNum))
export const isNewLogin = () => R.isNil(localGet(keymap.speed.user))

export const isRoam = () => state.guestMode
