/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/8
 * @description
 *   util.ts of WeTrade
 */
import * as R from 'ramda'

export const getQueryParam: (param: string) => string | null = R.pipe(
  R.concat('(^|&)'),
  R.concat(R.__, '=([^&]*)(&|$)'),
  R.constructN(1, RegExp),
  r => R.match(r, window.location.search.slice(1)),
  R.nth(2),
  R.ifElse(R.identity, decodeURIComponent, R.always(null)),
)

export const isInRange = R.curry(
  (min: number, max: number, val: number) => R.and(R.gte(val, min), R.lte(val, max)))

export const parseQueryString = R.pipe(
  R.replace(/(^|^\?)([^?]*)(&$|&)/, '$2'),
  R.split('&'),
  R.map(R.split('=')),
)

export const uuidV4 = () => ('' + [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
  (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
)

export const nextLoop = (fn: Fn, ...args: any[]) => setTimeout(fn, 0, ...args)

export const like: (ref: string | number, n: string | number) => string = R.useWith(
  R.invoker(1, 'toFixed'),
  [
    R.pipe(
      String,
      R.split('.'),
      R.nth(1),
      R.propOr(0, 'length'),
    ),
    Number,
  ],
)
