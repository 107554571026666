
import Line from '@/components/Line.vue'
import { defineComponent, ref, watch } from 'vue'
import TheAnalysisItem from '@/modules/home/components/items/TheAnalysisItem.vue'
import TheNewsItem from '@/modules/home/components/items/TheNewsItem.vue'
import ThePostItem from '@/modules/home/components/items/ThePostItem.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import TheHot from '@/modules/community/communities/TheHot.vue'
import { renderTopics } from '@/modules/community/communities/community'

export default defineComponent({
  name: 'TheNews',
  props: {
    list: {
      type: Array,
    },
    refresh: Function,
  },
  emits: ['refresh'],
  components: { Line, TheAnalysisItem, TheNewsItem, ThePostItem, List, Holder, TheHot },
  setup (props) {
    const listData = ref(props.list || [])
    watch(() => props.list, () => {
      listData.value = props.list || []
    })
    return {
      listData,
      topics: renderTopics(),
    }
  },
})
