
import * as R from 'ramda'
import { defineComponent, computed } from 'vue'
import { randomInt } from '@/common/common'

const height = [10, 20]
const col = [1, 2]

export default defineComponent(
  {
    name: 'DefaultSkeleton',
    setup () {
      const getRandomHeight = computed(() => R.apply(randomInt, height))
      const getRandomCol = computed(() => R.apply(randomInt, col))

      return {
        getRandomHeight,
        getRandomCol,
      }
    },
  },
)
