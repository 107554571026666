
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'TheCommissionCreatedDialog',
  components: { Icon, ResultDialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const router = useRouter()

    setTimeout(() => {
      ctx.emit('close', confirm)
    }, 2000)
    const confirm = () => {
      router.back()
    }
    return {
      confirm
    }
  },
})
