/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/9/30
 * @description
 *   bridge.ts of FastTrade
 */
import * as R from 'ramda'
import idMaker from '../idMaker'
import Inject, { CODES, InjectConfig } from './Inject'
import { once, emit, off, on } from '../event'

export type BridgeCallback = ((error: boolean, data?: Data) => void)

const id = idMaker(v => `i_${v}`)

const PERSIST_FLAG = '~'

const MAX_SIZE_OF_CALLBACK_QUEUE = 20

const customWindow = window as any

export type EmitType = 'callback' | 'push' | 'wakeup'

export interface CallbackResponse<T = any> {
  event: string;
  result: typeof CODES[keyof typeof CODES];
  data?: T;
  type?: EmitType;
}

export type Callback = (error: boolean, data?: Data) => void

/**
 * 解析用户传入的参数与回调函数
 * @param mixed
 * @param callback
 */
export const resolveOverloadParams = (
  mixed?: Data | BridgeCallback,
  callback?: BridgeCallback,
) => {
  if (typeof mixed === 'function') {
    return {
      data: {},
      callback: mixed,
    }
  }
  return { data: mixed, callback }
}

export type CallParams = Parameters<typeof resolveOverloadParams>

const getCallbackEventNameById = (id: string) => `callback_${id}`

class Bridge {
  private readonly injectConfig: Required<InjectConfig>
  // 回调队列
  private callbackQueue: Array<string> = []

  constructor (config: InjectConfig) {
    this.injectConfig = R.mergeRight({
      nativeCallbackName: '$__emit_back__',
      nativeActivatedName: '$__event_activated__',
    }, config)
    this.registerJSAdaptor()
  }

  private static response (callback: Callback, resp?: CallbackResponse) {
    callback(resp?.result !== CODES.RESULT_OK, resp?.data)
  }

  /**
   * ~前的callbackId为可多次调用的标记
   * @param callbackId
   * @param persist
   * @private
   */
  private recordCallbackId (callbackId: string, persist = false) {
    this.callbackQueue.unshift((persist ? PERSIST_FLAG : '') + callbackId)
    if (this.callbackQueue.length > MAX_SIZE_OF_CALLBACK_QUEUE) {
      this.callbackQueue.length = MAX_SIZE_OF_CALLBACK_QUEUE
    }
  }

  /**
   * 如果事件窜在事件队列中则为合法的事件
   * @param eventId
   * @param persist
   * @private
   */
  private dropRecordedCallback (eventId: string, persist = false) {
    const index = R.findIndex(R.includes(eventId), this.callbackQueue)
    if (~index) {
      const isPersistId = R.startsWith(PERSIST_FLAG, this.callbackQueue[index])
      if (!isPersistId || (isPersistId && persist)) {
        this.callbackQueue.splice(index, 1)
      }
      return true
    }
    return false
  }

  registerJSAdaptor () {
    customWindow[this.injectConfig.nativeCallbackName]
      = (resp: CallbackResponse) => {
      if (this.dropRecordedCallback(resp.event)) {
        emit(getCallbackEventNameById(resp.event), resp)
      }
    }
  }

  onActivated (cb: () => void) {
    customWindow[this.injectConfig.nativeActivatedName] = () => {
      cb()
    }
  }

  call (name: string, ...rest: CallParams) {
    const callbackId = id.next()
    const { data, callback } = resolveOverloadParams(...rest)
    const inject = new Inject(callbackId, name, this.injectConfig)
    if (typeof callback === 'function') {
      inject.execute(data, (done: Noop) => {
        this.recordCallbackId(callbackId)
        once(getCallbackEventNameById(callbackId), (resp?: CallbackResponse) => {
          Bridge.response(callback, resp)
          done()
        })
      })
    } else {
      inject.execute(data)
    }
  }

  /**
   * 回调可多次调用的hook
   * @param name
   * @param rest
   */
  callWithPersistentCallback (name: string, ...rest: CallParams) {
    const callbackId = id.next()
    const { data, callback } = resolveOverloadParams(...rest)
    const inject = new Inject(callbackId, name, this.injectConfig)
    const eventName = getCallbackEventNameById(callbackId)
    inject.execute(data, (done: Noop) => {
      this.recordCallbackId(callbackId, true)
      on(eventName, (resp?: CallbackResponse) => {
        Bridge.response(callback as () => void, resp)
        done()
      })
    })
    return () => {
      off(eventName)
      this.dropRecordedCallback(callbackId, true)
    }
  }
}

export default Bridge
