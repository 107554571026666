
import Page from '@/provider/Page.vue'
import TransitionProvider from '@/provider/TransitionProvider.vue'
import Tab from '@/views/appTab/AppTab.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TabPage',
  components: { Page, TransitionProvider, Tab },
})
