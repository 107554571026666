/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/6 18:19
 * @description
 *   Drawings.ts of FAST
 */
import AbstractDrawing, { DrawCallback } from '../core/AbstractDrawing'
import IChart from '../interface/IChart'
import { DrawingOptions } from './Drawing'
import Line from './Line'
import { randomString } from '../helper'
import * as d3 from 'd3'
import Segment from './Segment'
import Text from './Text'
import Arrow from './Arrow'

class Drawings {
  el_d3
  id = randomString('ds')
  drawings: Record<string, AbstractDrawing> = {}
  chart: IChart

  constructor (chart: IChart) {
    this.chart = chart

    this.el_d3 = d3
      .select(`#${this.chart.id}`)
      .append('g')
      .attr('class', 'drawings')
      .attr('id', this.id)
  }

  /**
   * 直接添加
   * @param drawingOptions
   * @param callback
   */
  add (drawingOptions: DrawingOptions, callback?: DrawCallback) {
    const id = randomString()

    const options = {
      ...drawingOptions,
      container: this.id,
      id,
    }

    switch (drawingOptions.type) {
      case 'line':
        return this.drawings[id] = new Line(this.chart, options, callback).render(true)
      case 'segment':
        return this.drawings[id] = new Segment(this.chart, options, callback).render(true)
      case 'text':
        return this.drawings[id] = new Text(this.chart, options, callback).render(true)
      case 'up':
      case 'down':
        return this.drawings[id] = new Arrow(this.chart, options, callback).render(true)
      default:
        return false
    }
  }

  /**
   * 按步骤创建
   * @param drawingOptions
   * @param callback
   */
  create (drawingOptions: DrawingOptions, callback?: DrawCallback) {
    const id = randomString('d')

    switch (drawingOptions.type) {
      case 'line':
        return this.drawings[id] = new Line(this.chart, {
          id,
          container: this.id,
          ...drawingOptions,
        }, callback).create()
      case 'segment':
        return this.drawings[id] = new Segment(this.chart, {
          id,
          container: this.id,
          ...drawingOptions,
        }, callback).create()
      case 'text':
        return this.drawings[id] = new Text(this.chart, {
          id,
          container: this.id,
          ...drawingOptions,
        }, callback).create()
      case 'up':
      case 'down':
        return this.drawings[id] = new Arrow(this.chart, {
          id,
          container: this.id,
          ...drawingOptions,
        }, callback).create()
      default:
        return false
    }
  }

  draw () {
    // 微任务简易版
    // @todo 改进重复任务执行
    setTimeout(() => {
      for (const drawingId in this.drawings) {
        this.drawings[drawingId].render()
      }
    }, 0)
  }

  remove (id?: string) {
    if (id) {
      this.drawings[id]?.remove()
      delete this.drawings[id]
      return
    }

    for (const drawingId in this.drawings) {
      this.drawings[drawingId].remove()
    }

    this.drawings = {}
  }
}

export default Drawings
