
import Switch from '@/modules/membership/signal/components/Switch.vue'
import TheLimitRangeInput from '@/modules/trade/components/TheLimitRangeInput.vue'
import Order from '@/modules/trade/Order'
import { useOrderLimit } from '@/modules/trade/trade'
import { computed, defineComponent, inject } from 'vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { add } from 'essential/tools/math'
import { TradeDirection } from '@/types'

export default defineComponent({
  name: 'TheTakeProfit',
  components: { RealTimeQuote, TheLimitRangeInput, Switch },
  props: {
    showDistance: Boolean,
    symbol: Object,
    isCommission: Boolean,
    createPrice: String,
  },
  setup () {
    const order = inject('order') as Order
    const { pips, limit, profit, estQuote, distance, minPips, maxPips } = useOrderLimit(order.takeProfit.value)
    const noLimit = computed(() => pips.value === 0)

    const toggleLimit = (state: boolean) => {
      limit(state ? Number(maxPips.value) : 0)
    }

    return {
      order,
      profit,
      estQuote,
      noLimit,
      pips,
      minPips,
      maxPips,
      distance,
      limit,
      toggleLimit,
      calcProfitLossPrice (v1: number, v2: string) {
        const v2Arr = v2.split('.')
        let fixedNum = 0
        if (v2Arr.length > 1) {
          fixedNum = v2Arr[1].length
        }
        return add(v1 * (Number(order.type.value) === TradeDirection.BUY ? 1 : -1), v2).toFixed(fixedNum)
      },
      exceed () {
        // showAlert(translate('toast_17'))
      },
    }
  },
})
