import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_List = _resolveComponent("List")

  return (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.groupedList, (group, code) => {
    return (_openBlock(), _createBlock("div", {
      key: code,
      class: "trade__group"
    }, [
      _createVNode(_component_List, {
        "list-data": group,
        identify: v => v.orderId
      }, {
        default: _withCtx(({item}) => [
          _renderSlot(_ctx.$slots, "default", { item: item })
        ]),
        _: 2
      }, 1032, ["list-data", "identify"])
    ]))
  }), 128))
}