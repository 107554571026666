/**
 * @author zjc[beica1@outook.com]
 * @date 2021/9/17 17:25
 * @description
 *   Studies.ts of FAST
 */
import { ExtendStudyOptions, StudyConfig } from '../core/AbstractStudy'
import IChart from '../interface/IChart'
import IRenderer from '../interface/IRenderer'
import { Bar, StudyType } from '../types'
import BOLL, { BOLLStudyInputs } from './BOLL'
import CCI, { CCIStudyInputs } from './CCI'
import EMA, { EMAStudyInputs } from './EMA'
import { TrendStudyInputs } from './formulas'
import KDJ, { KDJStudyOptions } from './KDJ'
import MACD, { MACDStudyOptions } from './MACD'
import RSI, { RSIStudyInputs } from './RSI'
import SAR, { SARStudyInputs } from './SAR'
import SMA, { SMAStudyInputs } from './SMA'
import * as R from 'ramda'
import Trend, { TrendOptions } from './trend/Trend'
import TrendPro from './trend/TrendPro'

export type StudyOptions = {
  [StudyType.SMA]: ExtendStudyOptions<SMAStudyInputs>;
  [StudyType.BOLL]: ExtendStudyOptions<BOLLStudyInputs>;
  [StudyType.SAR]: ExtendStudyOptions<SARStudyInputs>;
  [StudyType.EMA]: ExtendStudyOptions<EMAStudyInputs>;
  [StudyType.TREND]: ExtendStudyOptions<TrendOptions>;
  [StudyType.TRENDPRO]: ExtendStudyOptions<TrendStudyInputs>;
  [StudyType.MACD]: ExtendStudyOptions<MACDStudyOptions>;
  [StudyType.KDJ]: ExtendStudyOptions<KDJStudyOptions>;
  [StudyType.RSI]: ExtendStudyOptions<RSIStudyInputs>;
  [StudyType.CCI]: ExtendStudyOptions<CCIStudyInputs>;
}

type StudyRecord = {
  id: number;
  study: IRenderer;
}

let id = -1

class StudyRenderer implements IRenderer {
  studies: StudyRecord[] = []

  create<T extends StudyType> (
    chart: IChart, type: T, inputs: StudyOptions[T], config?: StudyConfig) {
    switch (type) {
      case StudyType.SMA:
        return new SMA(chart, inputs as SMAStudyInputs, config)
      case StudyType.BOLL:
        return new BOLL(chart, inputs as BOLLStudyInputs, config)
      case StudyType.SAR:
        return new SAR(chart, inputs as SARStudyInputs, config)
      case StudyType.EMA:
        return new EMA(chart, inputs as EMAStudyInputs, config)
      case StudyType.MACD:
        return new MACD(chart, inputs as MACDStudyOptions, config)
      case StudyType.KDJ:
        return new KDJ(chart, inputs as KDJStudyOptions, config)
      case StudyType.RSI:
        return new RSI(chart, inputs as RSIStudyInputs, config)
      case StudyType.CCI:
        return new CCI(chart, inputs as CCIStudyInputs, config)
      case StudyType.TREND:
        return new Trend(chart, inputs as TrendOptions, config)
      case StudyType.TRENDPRO:
        return new TrendPro(chart, inputs as TrendOptions, config)
      default:
        return null
    }
  }

  add (study: IRenderer | null) {
    if (study) {
      id++
      this.studies.push({
        id,
        study,
      })
      return id
    }
    return null
  }

  draw (all: Bar[], candidate: Bar | null, from: number, to: number): this {
    R.map(item => {
      item.study.draw(all, candidate, from, to)
    }, this.studies)
    return this
  }

  drawInit (): this {
    return this
  }

  /**
   * @implements drawCandidate
   * @param candidate
   * @param init
   */
  drawCandidate (candidate: Bar, init: Bar[]): this {
    R.map(item => {
      item.study.drawCandidate(candidate, init)
    }, this.studies)
    return this
  }

  remove (id?: number) {
    if (id) {
      const studyIdx = R.findIndex(R.propEq('id', id), this.studies)
      if (~studyIdx) {
        this.studies[studyIdx].study.remove()
        this.studies.splice(studyIdx, 1)
      }
    } else {
      R.map(item => {
        item.study.remove()
      }, this.studies)
      this.studies = []
    }
    return this
  }
}

export default StudyRenderer
