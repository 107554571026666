
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { usePerformTask } from '@/modules/home/home'
import { RookieTask, TaskType } from '@/modules/home/home.api'
import RedPointAlert from '@/provider/RedPointAlert.vue'
import Donut from 'common/loading/Donut.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'ForRookie',
  components: { RedPointAlert, Donut, Icon, Pic },
  emits: ['done'],
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const loading = shallowRef(false)

    const done = () => {
      ctx.emit('done')
    }

    const _perform = usePerformTask(done)

    const perform = (task: RookieTask | null) => {
      loading.value = true
      _perform(task).finally(() => {
        loading.value = false
      })
    }

    const isCurrentTask = (tag: number) => tag === (props.task as RookieTask).tag

    return {
      isCurrentTask,
      loading,
      perform,
      TaskType,
    }
  },
})
