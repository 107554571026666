/**
 * @author zjc[beica1@outook.com]
 * @date 2021/9/16 11:27
 * @description
 *   AbstractRenderer.ts of FAST
 */
import { Bar } from '../types'
import IRenderer from '../interface/IRenderer'

abstract class AbstractRenderer implements IRenderer {
  abstract drawInit (init: Bar[], from: number, to: number): this

  abstract drawCandidate (candidate: Bar, init: Bar[]): this

  abstract showCandidate (): this

  abstract hideCandidate (): this

  draw (init: Bar[], candidate: Bar | null, sliceFrom: number, sliceTo: number) {
    this.drawInit(init, sliceFrom, sliceTo)

    if (candidate) {
      this.drawCandidate(candidate, init)
      this.showCandidate()
    } else {
      this.hideCandidate()
    }

    return this
  }

  abstract remove (): void
}

export default AbstractRenderer
