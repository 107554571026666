/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/20 16:26
 * @description
 *   BOLL.ts of WeTrade
 */
import * as R from 'ramda'
import AbstractStudy from '../core/AbstractStudy'
import { Bar, ISelection } from '../types'
import { BOLLState, calcBOLL } from './formulas'

export type BOLLStudyInputs = {
  period: number;
  deviation: number;
  borderColor: string;
}

class BOLL extends AbstractStudy<BOLLStudyInputs> {
  state: BOLLState | null = null

  slice (from: number, to: number): Bar[][] {
    return this.cached.map(d => d.slice(from, to))
  }

  calcInitStudy (quotes: Bar[]): Bar[][] {
    const { state, value } = calcBOLL(quotes, this.options.period, this.options.deviation)
    this.state = state
    return value
  }

  calcCandidateStudy (quotes: Bar[]): Bar[][] {
    if (this.state) {
      const { value } = calcBOLL(quotes, this.options.period, this.options.deviation, this.state)
      return R.zipWith((cache, value) => cache.slice(-1).concat(value), this.cached, value)
    }
    return []
  }

  render (g: ISelection<SVGGElement>, paths: Bar[][]) {
    g
      .selectAll('path')
      .data(paths)
      .join('path')
      .attr('fill', 'none')
      .attr('stroke', (d, i) => (i % 2) ? this.options.lineColor() : this.options.borderColor)
      .attr('stroke-width', this.options.lineWidth)
      .attr('d', this.defaultLineRenderer)
  }
}

export default BOLL
