/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/18 10:39
 * @description
 *   MACD.ts of WeTrade
 */
import AbstractStudy, { ExtendStudyOptions, StudyConfig } from '../core/AbstractStudy'
import IChart from '../interface/IChart'
import { Bar, ISelection, NumberTuple } from '../types'
import { calcMACD, MACDState, MACDStudyInputs } from './formulas'
import * as R from 'ramda'
import * as d3 from 'd3'

export type MACDStudyOptions = MACDStudyInputs & {
  lineColor2: number;
}

class MACD extends AbstractStudy<MACDStudyOptions> {
  barFy
  state: MACDState | null = null

  constructor (
    chart: IChart, options: ExtendStudyOptions<MACDStudyOptions>,
    config?: StudyConfig,
  ) {
    super(chart, options, config)

    const defaultDomain = this.chart.yAxis.fy.domain()

    this.defaultLineFy = d3.scaleLinear().domain(defaultDomain).range([this.chart.height, 0])

    this.barFy = d3.scaleLinear().domain(defaultDomain).range([this.chart.height, 0])
  }

  slice (from: number, to: number): Bar[][] {
    return this.cached.map(d => d.slice(from, to))
  }

  calcInitStudy (quotes: Bar[]): Bar[][] {
    const { value, state } = calcMACD(quotes, this.options)
    this.state = state
    return value
  }

  calcCandidateStudy (quotes: Bar[]): Bar[][] {
    if (this.state) {
      const { value } = calcMACD(quotes, this.options, this.state)
      return value
    }
    return []
  }

  /**
   * @override
   * @param latest
   * @param history
   */
  drawCandidate (latest: Bar, history: Bar[]) {
    const append = this.calcCandidateStudy(history.concat(latest))
    if (this.cached) {
      const latestPaths = R.zipWith((c, a) => c.slice(-1).concat(a), this.cached, append)
      if (latestPaths.length === 0) {
        this.d3El.selectChildren().remove()
        return this
      }
      this.render(this.d3El, latestPaths, true)
    }
    return this
  }

  render (g: ISelection<SVGGElement>, paths: Bar[][], extend = false) {
    const [lines, [bars]] = R.splitAt(-1, paths)

    if (!extend) {
      const extent = d3.extent(lines[0] ?? [], d => d.c)
      this.defaultLineFy.domain(extent as NumberTuple)
    }

    if (bars) {
      let extent = this.barFy.domain()
      let median = d3.median(extent) ?? 0
      const bandWith = this.chart.xAxis.fx.bandwidth()

      if (!extend) {
        extent = d3.extent(bars ?? [], d => d.c * 2) as NumberTuple
        median = d3.median(extent) ?? 0
        this.barFy.domain(extent)
      }

      g
        .selectAll('line')
        .data(bars)
        .join('line')
        .attr('transform', d => {
          return `translate(${this.chart.xAxis.fx(d.t) ?? 0}, 0)`
        })
        .attr('y1', d => d.c ? this.barFy(median) : 0)
        .attr('y2', d => this.barFy(median + d.c))
        .attr('stroke', d => d.c > 0 ? this.options.riseColor : this.options.fallColor)
        .attr('stroke-width', bandWith)
    }

    g
      .selectAll('path')
      .data(lines)
      .join('path')
      .attr('fill', 'none')
      .attr('stroke', (d, i) => i ? this.options.lineColor2 : this.options.lineColor())
      .attr('stroke-width', this.options.lineWidth)
      .attr('d', this.defaultLineRenderer)
  }
}

export default MACD
