
import { localGet, localSet } from 'essential/store/localStore'
import { computed, defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'RedPointAlert',
  props: {
    display: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: 'span',
    },
    id: {
      type: String,
    },
    trigger: {
      type: Boolean,
      default: true,
    },
    middle: Boolean, // 红点位置 放在中间
  },
  setup (props) {
    const noRecord = shallowRef(props.id ? !localGet(props.id) : false)
    const unread = computed(() => props.display && Boolean(props.id) && noRecord.value)
    const className = computed(() => unread.value ? (props.middle ? 'middle' : 'unread') : '')

    const read = () => {
      if (props.trigger && props.display && props.id) {
        noRecord.value = false
        localSet(props.id, '1')
      }
    }

    return {
      unread,
      className,
      read,
    }
  },
})
