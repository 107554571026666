
import Icon from '@/components/Icon.vue'
import WTTabs from '@/components/Tabs.vue'
import TheSymbolCollection from '@/modules/market/components/TheSymbolCollection.vue'
import TheWatchList from '@/modules/market/components/TheWatchList.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import { defineComponent } from 'vue'
import Page from '@/provider/Page.vue'
import TheDepositTip from '@/modules/market/components/TheDepositTip.vue'
// import TheRadar from '@/modules/market/radar/TheRadar.vue'
import TheTopMovers from '@/modules/market/components/TheTopMovers.vue'
import TheWatchListSp from './components/TheWatchListSp.vue'

export default defineComponent({
  name: 'Market',
  components: {
    TheSymbolCollection,
    // TheRadar,
    TheDepositTip,
    TheWatchList,
    WTTabs,
    DemoAccountTip,
    Icon,
    AccountRelated,
    Page,
    TheTopMovers,
    TheWatchListSp
  }
})
