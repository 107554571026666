/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/9 16:52
 * @description
 *   DataEmitter.ts of FAST
 *   数据发生器
 *
 *   input: 依赖数据粒度配置项
 *
 *   根据配置项在指定间隔后通过对时间的更新来达到新增数据的目的
 */
import * as R from 'ramda'
import { adjustBarTime, msOf } from '../helper'
import { Bar, Periodicity } from '../types'

type Callback = (bar: Bar, isNew?: boolean) => void

class DataMaker {
  private readonly adjust
  private readonly autoMarket

  private frozen = false

  private periodicity: Periodicity
  private interval = 1000 // 周期时长
  private offset = 0
  private nextBarTime: number = Date.now()
  private bar = {
    o: NaN,
    h: -Infinity,
    l: Infinity,
    c: NaN,
    pc: NaN,
    chg: 0,
    'chg%': 0,
  }

  private preInterval = 0 // 上个周期剩余时间
  // 定时器
  private preIntervalTimer = 0
  private intervalTimer = 0
  private timeAligned = false
  private callback: Callback | null = null

  constructor (periodicity: Required<Periodicity>, autoMarket = false) {
    this.autoMarket = autoMarket
    this.periodicity = periodicity
    this.adjust = adjustBarTime(periodicity)
  }

  config (periodicity: Periodicity) {
    this.stop()

    this.periodicity = periodicity

    this.interval = msOf(this.periodicity)

    this.offset = this.interval * (this.periodicity.offset ?? 0)

    this.adjust.config(this.periodicity)

    this.timeAligned = false

    return this
  }

  private createBar (bar: Bar) {
    this.nextBarTime = this.adjust(bar.t)

    this.bar = {
      o: bar.o ?? bar.c,
      h: bar.h ?? bar.c,
      l: bar.l ?? bar.c,
      c: bar.c,
      pc: bar.pc ?? bar.c,
      chg: bar.chg ?? 0,
      'chg%': bar['chg%'] ?? 0,
    }
  }

  /**
   * 当createBar是以最新的推送为元数据创建时
   * update的源数据可能来自历史数据的最新一条数据
   * 因此可以根据update的数据重写相关字段以达到纠正的目的
   * @param bar
   */
  private updateBar (bar: Bar) {
    const before = JSON.stringify(this.bar)
    const precision = bar.c.toString().split('.')[1]?.length ?? 0
    const chg = +(bar.c - this.bar.pc).toFixed(precision)

    this.bar = {
      ...this.bar,
      h: R.max(this.bar.h, bar.h ?? bar.c),
      l: R.min(this.bar.l, bar.l ?? bar.c),
      c: bar.c,
      chg,
      'chg%': +(chg / (bar.c || 1) * 100).toFixed(2),
    }

    if (JSON.stringify(this.bar) !== before) {
      this.emit()
    }
  }

  private nextBar () {
    if (this.nextBarTime) {
      this.nextBarTime += this.interval
      this.createBar({
        c: this.bar.c,
        t: this.nextBarTime,
        pc: this.bar.c,
        chg: 0,
        'chg%': 0,
      })
      this.emit()
    }
  }

  /**
   * 触发更新
   */
  private emit () {
    if (this.nextBarTime) {
      this.callback?.({
        ...this.bar,
        t: this.nextBarTime,
      })
    }
  }

  stop () {
    this.frozen = true
    clearTimeout(this.preIntervalTimer)
    this.preIntervalTimer = 0
    clearInterval(this.intervalTimer)
    this.intervalTimer = 0
  }

  private startMakeMachine () {
    this.preIntervalTimer = window.setTimeout(() => {
      this.nextBar()

      clearTimeout(this.preInterval)

      this.intervalTimer = window.setInterval(() => {
        if (!this.nextBarTime) {
          this.stop()
          return
        }
        // 生成新的bar
        this.nextBar()
      }, this.interval)
    }, this.preInterval)
  }

  setup (latest: Bar | null, reset = false) {
    if (!latest) return

    this.config(this.periodicity)

    this.createBar(latest)

    /**
     * 首次配置 不对时间格式化
     */
    if (reset) {
      this.nextBarTime = latest.t
    }

    this.frozen = false

    if (this.autoMarket) {
      this.preInterval = this.interval - (this.nextBarTime % this.interval)

      this.startMakeMachine()
    }
  }

  /**
   * 将源追加事件转换为更新或追加事件
   * @param latest
   */
  stream (latest: Bar) {
    if (this.frozen) return

    if (this.adjust(latest.t) <= this.nextBarTime) {
      /**
       * 如果数据属于同一个周期并且已经校正过数据精度则直接更新
       */
      if (this.timeAligned) {
        this.updateBar(latest)
      } else if (latest.t !== this.nextBarTime) {
        /**
         * 历史数据的时间值可能为Bar点值[只保留到到分，时，日等的时间值]而不是时间戳
         * 因此这里利用第一次推送来对时间纠偏
         * 以此来保证客户端的定时器与服务端时间尽可能一致
         */
        this.setup({
          ...this.bar,
          t: this.nextBarTime,
        }, true)

        this.timeAligned = true
      }
    } else {
      /**
       * 推送数据与服务端历史数据精度不匹配 以推送数据为准 重启生成器
       */
      this.setup(latest)
    }

    return this
  }

  /**
   * 监听
   * @param callback
   */
  onEmit (callback: (bar: Bar) => void) {
    this.callback = callback
    return this
  }

  destroy () {
    console.log('[Destroy] DataEmitter')
    this.stop()
    this.callback = null
  }
}

export default DataMaker
