
import { defineComponent, shallowRef } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { isDemoAccount } from '@/state/accountType'
import Pic from '@/components/Pic.vue'
import { doneTask } from '@/modules/home/home.api'
import { useRouter } from 'vue-router'
import Button from '@/components/Button.vue'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'TheLeverageDialog',
  components: {
    DialogTemplate,
    Pic,
    Button,
  },
  props: {
    lever: {
      type: Number,
      required: true,
    },
  },
  emits: ['close'],
  setup (props, ctx) {
    const leverArr = ['transactionhistory_26', 'transactionhistory_27', 'transactionhistory_28']
    const curLever = shallowRef(translate(leverArr[props.lever]))
    const router = useRouter()
    const closeLoading = shallowRef(false)
    const okLoading = shallowRef(false)

    const handleClose = () => {
      closeLoading.value = true
      doneTask({
        type: 1,
        tag: 6,
      }).then(() => {
        closeLoading.value = false
        ctx.emit('close')
      })
    }

    const handleDetail = () => {
      okLoading.value = true
      doneTask({
        type: 1,
        tag: 6,
      }).finally(() => {
        okLoading.value = false
        ctx.emit('close')
        setTimeout(() => {
          router.push('/user/leverage')
        }, 300)
      })
    }
    return {
      isDemoAccount,
      closeLoading,
      okLoading,
      curLever,
      handleClose,
      handleDetail,
    }
  },
})
