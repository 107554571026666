/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/11/27
 * @description
 *   popup.ts of FastTrade
 */
import * as R from 'ramda'
import { ComponentPublicInstance, ComponentOptions, shallowReactive } from 'vue'
import SyncManager from '../../essential/tools/SyncManager'
import useHashChange from '../hooks/useHashChange'

const makeId = (() => {
  let i = 1
  return () => i++
})()

export type PopupPublicOptions = {
  transition: string;
  class: string | Array<string>;
  wrapperClass: string | Array<string>;
  sync?: boolean;
  backToClose?: boolean;
  onClose?: () => void;
  touchToClose: boolean;
}

type PopupOptions = {
  component: ComponentPublicInstance | ComponentOptions;
} & Partial<PopupPublicOptions>

export type Widget = {
  id: number;
  render: boolean;
  next: () => void; // 同步组件关闭时，队列出队新的组件
} & PopupOptions

const widgets: Array<Widget> = shallowReactive([])

const sync = new SyncManager<Widget>(w => widgets.push(w))

const remove = (id: string) => {
  const pos = R.findIndex(R.propEq('id', id), widgets)
  if (~pos) {
    widgets[pos].next()
    widgets.splice(pos, 1)
  }
}

const push = (options: PopupOptions) => {
  const isSyncWidget = options.sync
  const id = makeId()
  const widget = {
    ...options,
    id,
    render: true,
    next () {
      if (isSyncWidget) {
        sync.next()
      }
    },
  }

  if (isSyncWidget) {
    sync.push(widget)
  } else {
    widgets.push(widget)
  }

  return id
}

const { bindHashChange, unbindHashChange } = useHashChange()

export const popup = (options: PopupOptions) => {
  const component = options.component

  if (!component) throw new TypeError('No "component" provide to pop out')

  const id = push(options)

  const dismiss = () => {
    close(id)
    // options.onClose?.()
  }

  if (options.backToClose) bindHashChange(id, dismiss)

  return () => {
    if (options.backToClose) unbindHashChange(id)

    dismiss()
  }
}

const close = (id: number) => {
  const pos = R.findIndex(R.propEq('id', id), widgets)
  if (~pos) {
    const widget = widgets[pos]
    const newWidget = R.assoc('render', false, widget)
    widgets.splice(pos, 1, newWidget)
    widget.onClose?.()
    if (widget.touchToClose) {
      unbindHashChange(id)
    }
  }
}

const clean = () => {
  widgets.length = 0
}

const usePopup = (): [Array<Widget>, (id: number) => void, (id: string) => void, Noop] =>
  [widgets, close, remove, clean]

export default usePopup
