
import { defineComponent, onBeforeUnmount } from 'vue'

export default defineComponent(
  {
    name: 'Donut',
    emits: ['tick'],
    setup (props, ctx) {
      const timer = setInterval(() => {
        ctx.emit('tick')
      }, 1000)

      onBeforeUnmount(() => {
        clearInterval(timer)
      })
    },
  })
