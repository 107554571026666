import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "header df-middle f-sm" }
const _hoisted_2 = { class: "flex-1 ml-16 f-sm" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "my-16 df-middle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pic = _resolveComponent("Pic")
  const _component_Time = _resolveComponent("Time")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `/news/${_ctx.news.articleId}`,
    class: "py-16 list-item d-b"
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_Pic, {
          src: _ctx.news.authorUrl,
          fallback: "/img/user/Avatar@2x.png",
          width: "32",
          height: "32",
          style: {"border-radius":"50%"}
        }, null, 8, ["src"]),
        _createVNode("div", _hoisted_2, [
          _createVNode("p", _hoisted_3, _toDisplayString(_ctx.news.authorName), 1),
          _createVNode(_component_Time, {
            time: _ctx.news.createTime,
            format: "MM-dd-yyyy HH:mm:ss",
            class: "c-tip"
          }, null, 8, ["time"])
        ]),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createVNode("div", _hoisted_4, [
        (_ctx.news.bannerUrl)
          ? (_openBlock(), _createBlock(_component_Pic, {
              key: 0,
              src: _ctx.news.bannerUrl,
              alt: "thumb",
              class: "mr-16",
              width: "64"
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        _createVNode("p", null, _toDisplayString(_ctx.news.articleTitle), 1)
      ])
    ]),
    _: 3
  }, 8, ["to"]))
}