/**
 * Stop And Reverse
 * @author zjc[beica1@outook.com]
 * @date 2021/9/22 14:19
 * @description
 *   SAR.ts of FAST
 */
import AbstractStudy from '../core/AbstractStudy'
import { Bar, ISelection } from '../types'
import { calcSAR, SARBar } from './formulas'
import * as R from 'ramda'

export type SARStudyInputs = {
  radius?: number;
  minAF?: number;
  maxAF?: number;
}

class SAR extends AbstractStudy<SARStudyInputs, SARBar> {
  state: SARBar | null = null

  priorLatest: SARBar | null = null

  slice (from: number, to: number) {
    return this.cached.slice(from, to)
  }

  calcInitStudy (quotes: Bar[]): SARBar[] {
    const result = calcSAR(quotes, null, this.options.minAF, this.options.maxAF)
    this.state = R.last(result) ?? null
    return result
  }

  calcCandidateStudy (quotes: Bar[]): SARBar[] {
    if (this.state) {
      return calcSAR(quotes.slice(-2), this.state, this.options.minAF, this.options.maxAF)
    }
    return []
  }

  render (g: ISelection<SVGGElement>, paths: SARBar[]) {
    g
      .selectAll('circle')
      .data(paths)
      .join('circle')
      .attr('stroke', this.options.lineColor())
      .attr('fill', this.options.fillColor())
      .attr('cx', d => this.chart.xAxis.fx(d.t) ?? 0)
      .attr('cy', d => this.chart.yAxis.fy(d.c))
      .attr('r', this.options.radius ?? 3)
  }

  // drawLatest (latest: Bar | null): this {
  //   if (this.priorLatest && latest) {
  //     const points = calcSAR([this.priorLatest, latest as Candle], this.priorLatest)
  //     this.render(this.d3El, points)
  //   }
  //   return this
  // }
}

export default SAR
