/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/4 0:35
 * @description
 *   Area.ts of FAST
 */
import { ExtendShapeOptions } from '../core/AbstractShape'
import IChart from '../interface/IChart'
import Line from './Line'
import { Bar, ISelection } from '../types'
import * as d3 from 'd3'

class Area extends Line {
  areaRenderer

  constructor (
    chart: IChart,
    options: ExtendShapeOptions<unknown>,
  ) {
    super(chart, options)

    this.areaRenderer = d3
      .area<Bar>()
      .x(d => this.chart.xAxis.fx(d.t) ?? 0)
      .y0(this.chart.yAxis.fy(-1))
      .y1(d => this.chart.yAxis.fy(d.c))
  }

  private drawArea (g: ISelection<SVGGElement>, data: Bar[]) {
    g
      .selectAll('path.area')
      .data([data])
      .join('path')
      .attr('class', 'area')
      .attr('fill', this.options.fillColor())
      .attr('d', this.areaRenderer)
  }

  render (g: ISelection<SVGGElement>, quotes: Bar[]) {
    super.render(g, quotes)
    this.drawArea(g, quotes)
  }
}

export default Area
