/**
 *  AbstractDrawing.ts of project wetrade
 *  @date 2022/5/15 16:49
 *  @author 阿佑[ayooooo@petalmail.com]
 */
import { IDrawing } from '../interface/IDrawing'
import { ISelection, Point } from '../types'
import IChart, { ChartPointer, DrawHandler } from '../interface/IChart'
import * as d3 from 'd3'

export enum DrawingState {
  DEFAULT,
  DRAW, // ready to draw
  DRAWING, // mouse move
  STEP, // mouse pick
  COMPLETE, // drawn
  CANCELED,
  FOCUS, // pointer down
  DRAGGING, // drag move
  ACTIVE, // click
  UPDATED // drop
}

export type DrawCallback = (drawing: IDrawing, state: DrawingState, points?: ChartPointer[]) => void

export type RequiredDrawingOptions = {
  chart: IChart;
  container: string;
  id: string;
  pointsCount: number;
}

export type OptionalDrawingOptions = Partial<{
  readonly?: boolean;
  callback?: DrawCallback;
}>

type DrawingOptions = RequiredDrawingOptions & OptionalDrawingOptions

export default abstract class AbstractDrawing<T extends Element = SVGGElement> implements IDrawing {
  /**
   * 绘制图形需要的端点个数
   * @private
   */
  private readonly pointsRequired: number

  private readonly readonly: boolean

  /**
   * 图形绘制状态
   * 不同的状态的切换过程都会出发回调的调用
   * @private
   */
  private drawingState: DrawingState = DrawingState.DEFAULT

  /**
   * 拖动操作的起始点
   * @private
   */
  private dragFrom: Point = [0, 0]

  /**
   * d3 元素
   * @protected
   */
  protected el_d3: ISelection<T>

  /**
   * 关联的chart对象
   * @protected
   */
  protected readonly chart: IChart

  readonly id: string

  /**
   * 用户交互的回调函数
   * 通过在不同的状态对回调的触发来与用户交互
   */
  readonly callback?: DrawCallback

  /**
   * 描述动态的价格，时间及其对应的坐标信息 主要用于参与图形绘制
   * 派生类中的keyPoints描述静态的价格与时间信息 主要用于参数逻辑计算
   */
  private _endpoints: ChartPointer[] = []

  protected constructor (options: DrawingOptions) {
    this.chart = options.chart
    this.id = options.id
    this.el_d3 = d3.select(`#${options.container}`).append('g') as unknown as ISelection<T>
    this.pointsRequired = options.pointsCount
    this.readonly = Boolean(options.readonly)
    this.callback = options.callback
  }

  /**
   * 拦截图形绘制过程中的右键操作
   * 阻止默认的右键菜单 并取消绘制
   * @param e
   * @private
   */
  private cancelContextMenu (e: Event) {
    e.preventDefault()
    e.stopImmediatePropagation()

    this.cancel()

    this.chart.off('contextmenu.drawing')

    return false
  }

  /**
   * 调用回调
   * @param state
   * @param points
   * @private
   */
  private emitCallback (state: DrawingState, points?: ChartPointer[]) {
    this.drawingState = state
    this.callback?.(this, state, points)
  }

  /**
   * 计算拖拽偏移量
   * @param p
   * @private
   */
  private calcDragOffset (p: ChartPointer): Point {
    return [p.x - this.dragFrom[0], p.y - this.dragFrom[1]]
  }

  /**
   * 拖拽结束
   * @protected
   */
  protected dragEnd () {
    this.emitCallback(DrawingState.UPDATED, this._endpoints)

    this.syncEndpoints(this._endpoints)

    return this
  }

  /**
   * 拖拽支持
   * @private
   */
  private supportDrag () {
    /**
     * 可拖动
     */
    this.el_d3.style('cursor', 'hand')

    /**
     * 准备 并阻止chart平移
     */
    this.el_d3.on('pointerdown.drawing', (e: Event) => {
      e.preventDefault()
      e.stopPropagation()

      this.dragFrom = this.chart.pointer(e)

      this.dragStart(this.dragFrom)

      const onDrag = (p: ChartPointer) => {
        if (this.drawingState !== DrawingState.DRAGGING) {
          this.chart.on('pointerup.dragEnd', () => {
            this.chart.off('pointerup.dragEnd')
            this.dragEnd()
          })
        }

        this.drag(e, p, this.calcDragOffset(p))
        this.emitCallback(DrawingState.DRAGGING, [p])
      }

      this.emitCallback(DrawingState.FOCUS)

      this.el_d3.style('cursor', 'grabbing')

      this.chart.trackPointer('move', onDrag)

      this.chart.on('pointerup.drag', () => {
        this.chart.off('pointerup.drag')
        this.chart.stopTrackPointer('move', onDrag)
      })
    })
  }

  /**
   * 编辑支持
   * @private
   */
  private supportEdit () {
    this.supportDrag()

    /**
     * highlight
     */
    this.el_d3.on('mouseover.drawing', this.highlight.bind(this))

    /**
     * style restore
     */
    this.el_d3.on('mouseleave.drawing', () => {
      this.style()
      this.el_d3.style('cursor', 'hand')
    })

    /**
     * focus
     */
    this.el_d3.on('click.drawing', () => this.emitCallback(DrawingState.ACTIVE, this.locate()))
  }

  protected updateEndpoints (points: ChartPointer[]): void

  protected updateEndpoints (point: ChartPointer, index: number): void

  /**
   * 更新图形端点信息
   * @param mixed
   * @param index
   * @protected
   */
  protected updateEndpoints (mixed: ChartPointer[] | ChartPointer, index?: number) {
    if (Array.isArray(mixed)) {
      this._endpoints = mixed
    } else if (undefined !== index) {
      this._endpoints[index] = mixed
    }
  }

  protected endpoints (): ChartPointer[]

  protected endpoints (index: number): ChartPointer

  /**
   * 读取图形指定(单个)或所有(数组)的端点
   * @param index
   * @protected
   */
  protected endpoints (index?: number) {
    if (undefined !== index) {
      return this._endpoints[index]
    }

    return this._endpoints
  }

  /**
   * 绘制完成
   * @protected
   */
  protected done () {
    this.emitCallback(DrawingState.COMPLETE, this._endpoints)
    // 绘制成功 更新端点
    this.syncEndpoints(this._endpoints)

    this.chart.off('contextmenu.drawing')

    this.supportEdit()
  }

  /**
   * 通过端点的拾取来绘制图形
   * @param onMove
   * @param onPick
   * @param done
   */
  draw (onMove: DrawHandler, onPick: DrawHandler, done?: () => void) {
    this.emitCallback(DrawingState.DRAW)

    this._endpoints = []
    let endpointIndex = 0

    const move = (m: ChartPointer) => {
      // 持续更新荡当前的端点
      this._endpoints[endpointIndex] = m

      onMove(m, endpointIndex)

      this.emitCallback(DrawingState.DRAWING, [m])
    }

    const pick = (m: ChartPointer) => {
      // 收集端点
      this._endpoints[endpointIndex] = m

      onPick(m, endpointIndex)

      this.emitCallback(DrawingState.STEP, [m])

      if (this._endpoints.length >= this.pointsRequired) {
        done?.()
        this.done()
        this.chart.stopTrackPointer('move', move)
        this.chart.stopTrackPointer('click', pick)
      }

      // 追踪下一个端点
      endpointIndex++
    }

    this.chart.trackPointer('move', move)
    this.chart.trackPointer('click', pick)
    // 鼠标右键删除
    this.chart.on('contextmenu.drawing', this.cancelContextMenu.bind(this))
  }

  /**
   * 卸载相关事件并移除dom节点
   */
  remove () {
    this.el_d3.on('mouseover.drawing', null)
    this.el_d3.on('mouseleave.drawing', null)
    this.el_d3.on('pointerdown.drawing', null)
    this.el_d3.on('click.drawing', null)

    this.el_d3.remove()
  }

  /**
   * 绘制取消
   * 当且仅当绘制的状态为DRAW或者DRAWING时可以取消绘制
   */
  cancel () {
    if (
      this.drawingState === DrawingState.DRAW ||
      this.drawingState === DrawingState.DRAWING
    ) {
      this.remove()
      this.emitCallback(DrawingState.CANCELED)
    }
  }

  /**
   * 根据给定的端点还原图形
   */
  render (init = false) {
    this._endpoints = this.parseEndpoints()

    this.renderDrawing(init)

    if (!this.readonly && init) this.supportEdit()

    return this
  }

  /**
   * 解析点到坐标
   */
  abstract parseEndpoints (): ChartPointer[]

  /**
   * 同步端点到派生类
   * @param points
   */
  abstract syncEndpoints (points: ChartPointer[]): this

  /**
   * 派生类的渲染函数
   * @param init
   */
  abstract renderDrawing (init?: boolean): this;

  // abstract render (init?: boolean): this

  /**
   * 创建端点并绘制
   */
  abstract create (): this

  /**
   * 样式化
   */
  abstract style (color?: string): this

  /**
   * 选中高亮
   */
  abstract highlight (): this

  /**
   * 定位图形
   * 返回一个坐标来标识该图新的中点或起点
   */
  abstract locate (): ChartPointer[] | undefined

  /**
   * 更新图形上的文本信息
   * @param update
   */
  abstract updateLabel (update: (t: string) => string): this

  updateText? (text: string): this

  /**
   * 拖拽开始
   * @param p
   */
  abstract dragStart (p: Point): this

  /**
   * 拖拽
   * @param e
   * @param p
   * @param offset
   */
  abstract drag (e: Event, p: ChartPointer, offset: Point): this
}
