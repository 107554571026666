
import * as R from 'ramda'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Nullable',
  components: { Pic },
  props: {
    content: [Array, Object, String, Number],
  },
  computed: {
    isNull (): boolean {
      return R.or(R.isNil(this.content), R.isEmpty(this.content))
    },
  },
})
