
import feed, { QuoteMessage, savePoint } from '@/common/datafeed'
import { defineComponent } from 'vue'
import * as R from 'ramda'
const NotANumber = 'NaN'

const defaultNumberDisplay = (x: string) => (Number.isNaN(parseFloat(x)) ? NotANumber : x)

const stepZero = (l = 1) => Number(1 + R.repeat(0, l < 1 ? 1 : l).join(''))

const calcSpread = (m: QuoteMessage) => {
  const { sell, buy, calculatePoint, pointStep } = m
  const point =
    calculatePoint && pointStep
      ? 1 / calculatePoint / Number(pointStep)
      : stepZero(sell?.split('.').pop()?.length)
  return (Math.abs(Number(sell) - Number(buy)) * point).toFixed(1)
}

const { get, save } = savePoint()

export default defineComponent({
  name: 'RealTimeQuote',
  emits: ['turn'],
  props: {
    code: String,
    symbol: Object
  },
  data() {
    return {
      price: NotANumber,
      buy: NotANumber,
      spread: NotANumber,
      margin: NotANumber,
      mp: NotANumber,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      unwatch() {}
    }
  },
  computed: {
    symbolCode(): string {
      const code = this.code || this.symbol?.code || 'N/A'
      return code
    },

    target(): QuoteMessage {
      return (
        (this.symbol as QuoteMessage) || (this.symbolCode && feed.getLatestQuote(this.symbolCode))
      )
    },

    // triple state flag - null, true, false
    isRise(): boolean | null {
      const numberMargin = parseFloat(String(this.margin))
      if (Number.isNaN(numberMargin)) return null
      return numberMargin >= 0
    }
  },
  watch: {
    isRise(val: boolean) {
      // console.log('turn', this.symbolCode, val)
      this.$emit('turn', val)
    },
    symbol() {
      this.subscribe()
    },
    code() {
      this.subscribe()
    }
  },
  created() {
    this.subscribe()
  },
  beforeUnmount() {
    this.unwatch()
  },
  methods: {
    watch(code: string, symbol?: QuoteMessage) {
      if (symbol) save(symbol)
      this.unwatch = feed.watch(code, this.update, { from: symbol, immediate: true })
    },
    subscribe() {
      this.unwatch()
      if (this.target) {
        this.watch(this.symbolCode, this.target)
      }
    },
    update(message?: QuoteMessage) {
      if (message) {
        this.price = defaultNumberDisplay(message.sell)
        this.buy = defaultNumberDisplay(message.buy)
        this.spread = message.pointDiff
          ? defaultNumberDisplay(message.pointDiff)
          : calcSpread({ ...message, ...get(message.code) })
        this.margin = defaultNumberDisplay(message.margin)
        this.mp = defaultNumberDisplay(message.mp)
      }
    }
  }
})
