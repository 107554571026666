/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/5
 * @description
 *   trade.ts of WeTrade
 */
import { openDialog, openFullscreenList, showAlert } from '@/components/popup/popup'
import { events } from '@/config'
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import TheCommissionCreatedDialog from '@/modules/trade/components/TheCommissionCreatedDialog.vue'
import ThePositionCreateResultDialog
  from '@/modules/trade/components/ThePositionCreateResultDialog.vue'
import TheTradeLimitList from '@/modules/trade/components/TheTradeLimitList.vue'
import Order from '@/modules/trade/Order'
import {
  addDemoBalance,
  createCommission,
  createPosition, creditRuleCheck,
  edit,
  editCommission as requestEditCommission, leversSchema,
  Product, readProduct,
  readProducts,
} from '@/modules/trade/trade.api'
import { readVoucherList, VoucherStatus } from '@/pages/coupons/coupons.api'
import { YesOrNo } from '@/types'
import { emit } from 'essential/tools/event'
import { div, mul, sub } from 'essential/tools/math'
import * as R from 'ramda'
import { computed, inject, shallowRef } from 'vue'
import { useRouter } from 'vue-router'
import TheCreditRuleDialog from '@/modules/trade/credit/TheCreditRuleDialog.vue'
import TheCreditInsufficientDialog from '@/modules/trade/credit/TheCreditInsufficientDialog.vue'

export const createPositionOrder = (from?: Order) => {
  const [commit, progress] = useRequest(createPosition)

  const order = from ?? inject('order') as Order
  const router = useRouter()

  // 协议 ---> >0.5  ---> 余额不足
  const submit = (subValue: any) => {
    commit(subValue).then(resp => {
      resp.weight = String(Number(resp.weight))

      emit(events.transactionUpdate)

      // openDialog(ThePositionCreateResultDialog, {
      //   order: resp,
      // })
      showAlert(translate('credit_12'), { wrapperClass: 'toast-dark', closeTime: 1500, clickDisable: true })
      setTimeout(() => router.back(), 1000)
    }).catch(err => {
      if (err.c === '80812' || err.c === '20200') {
        openDialog(TheCreditInsufficientDialog, {}, { wrapperClass: 'creditPopDialog', transition: 'slide-down' })
      } else {
        showAlert(err.m)
      }
    })
  }
  const post = () => {
    const subValue = order.getSpeedValue()

    if (subValue?.isJuan) {
      creditRuleCheck({ type: 9 }).then((resp: any) => {
        if (resp.list) {
          const rule = resp.list[0]
          const content = rule.content.replace(/\n/g, '<br>')
          openDialog(TheCreditRuleDialog, { ...rule, content }, { wrapperClass: 'creditPopDialog', transition: 'slide-down' })
        } else {
          if (Number(subValue.number) > 0.5) {
            showAlert(translate('credit_8', { value: 0.5 }))
          } else {
            submit(subValue)
          }
        }
      })
    } else {
      submit(subValue)
    }
  }

  return [post, progress]
}

export const demoBalanceRecharge = (cb: Fn) => {
  const [commit, progress] = useRequest(addDemoBalance)
  const post = () => {
    commit().then(() => {
      cb()
      showAlert(translate('toast_12', '$10000 has been topped up to your demo account!'))
    })
  }

  return [post, progress]
}

export const createCommissionOrder = () => {
  const [commit, progress] = useRequest(createCommission)

  const order = inject('order') as Order

  const post = () => {
    commit(order.getSpeedValue()).then(resp => {
      resp.weight = String(Number(resp.weight))

      emit('transactionUpdateCommission')
      emit('commissionCreated')

      openDialog(TheCommissionCreatedDialog, {
        order: resp,
      })
    })
  }

  return [post, progress]
}

export const editCommission = () => {
  const router = useRouter()
  const [commit, progress] = useRequest(requestEditCommission)

  const order = inject('order') as Order

  const post = () => {
    commit(order.getSpeedValue()).then(() => {
      showAlert(translate('toast_5', 'Limit Order has been updated!'))
      emit('transactionUpdateCommission')
      router.back()
    })
  }

  return [post, progress]
}

export const useEdit = () => useRequest(edit)

export enum LimitType {
  LOSS,
  PROFIT,
}

export const useSetTradeLimit = (type: LimitType, value = 0, onChange?: Fn) => {
  const choice = shallowRef(value)
  const makeChoice = (product: Product) => {
    if (product?.productId) {
      openFullscreenList(TheTradeLimitList, {
        value: choice.value,
        type,
        product,
        onChange (value: number) {
          choice.value = value
          if (typeof onChange === 'function') onChange(value)
        },
      })
    } else {
      showAlert(translate('toast_8', 'No Product provide'))
    }
  }
  return [choice, makeChoice]
}

/**
 * 维护止盈止损选项列表
 */
export const generateLimitList = (limitType: LimitType, product: Product) => {
  const min = Number(limitType === LimitType.LOSS ? product.minStopLoss : product.minStopProfit)
  const max = Number(limitType === LimitType.PROFIT ? product.maxStopProfit : product.maxStopLoss)

  const limitFactor = limitType === LimitType.PROFIT ? 1 : -1

  const chgOfPerPip = mul(product.yk, limitFactor)

  let step = 1

  if (max - min > 100) {
    step = Math.ceil((max - min) / 100)
  }

  const a = []
  for (let i = min; i <= max; i += step) {
    a.push({
      pip: i,
      value: mul(i, chgOfPerPip),
    })
  }

  return a
}

export const checkUsableCoupon = () => {
  return readVoucherList({
    type: 1,
    status: VoucherStatus.USABLE,
    page: 1,
    pageCount: 1000,
  })
}

export const calcProduct = (mulNum: number, dot: number, product: Product) => {
  product.choiceId = product.contract + mul(product.weight, mulNum) + '-' + dot
  const times = mul(mulNum, dot)
  product.orderNumber = times
  product.weight = String(mul(product.weight, times))
  product.price = String(mul(product.price, times))
  product.yk = String(mul(product.yk, times))
  product.sxf = String(mul(product.sxf, times))
  product.deferred = String(mul(product.deferred, times))
  return product
}

export const getProductsByCode = async (code: string, isOften: YesOrNo) => {
  const product = await readProducts({ code })
  const products = []
  const often = [1, 10, 20, 50, 100, 200, 500, 1000]

  if (!isOften) {
    for (let i = 0; i < 4; i++) {
      for (let j = 1; j < (i < 3 ? 10 : 11); j++) {
        const copy = R.clone(product)
        const mulNum = Math.pow(10, i)
        copy.isOften = often.indexOf(mul(mulNum, j)) > -1 ? YesOrNo.YES : YesOrNo.NO
        products.push(calcProduct(mulNum, j, copy))
      }
    }
  } else {
    often.map(item => {
      const tempBase = JSON.parse(JSON.stringify(product))
      tempBase.isOften = YesOrNo.YES
      const dot = Number(String(item).substring(0, 1))
      const mulNum = Math.pow(10, item.toString().length - 1)
      products.push(calcProduct(mulNum, dot, tempBase))
    })
  }

  return products
}

export const useSelectProduct = (code: string) => {
  const order = inject('order') as Order
  const products = shallowRef<Product[]>([])

  getProductsByCode(code, YesOrNo.YES).then(resp => {
    order.setProduct(resp[0])
    products.value = resp
  })

  return {
    products,
    choice: order?.getProduct(),
    update (product: Product) {
      order?.updateProduct(product)
    },
  }
}

/**
 *
 * @param dftPoint
 * @param boundFactor 1 为止盈 -1 为止损
 */
export const useOrderLimit = (dftPoint = 0, boundFactor: 1 | -1 = 1) => {
  const order = inject('order') as Order

  const product = order.getProduct()

  const userInput = shallowRef<null | number>(null)

  // 最新报价
  const quote = shallowRef('')

  const pips = computed({
    get: () => {
      if (!product.value) return 0

      /**
       * 根据默认的points初始化percent
       */
      if (userInput.value === null && dftPoint !== 0) {
        // const profit = mul(product.value?.yk, dftPoint)
        //
        // return mul(100, div(profit, product.value?.price))
        return Math.round(dftPoint)
      }

      return userInput.value ?? 0
    },
    set: v => {
      userInput.value = v
    },
  })

  const profit = computed(() => {
    if (!product.value) return 0
    // 盈亏金额=手数*点位*pointStep
    const { specs } = product.value
    const { quantity } = order
    // return pips.value * Number(quantity.value) * (specs?.length ? specs[0].yk : 0 )
    // * 100 传统模式   * 1 经典模式
    return mul(pips.value, quantity.value, specs?.length ? specs[0].yk : 0, 100)
  })

  // const pips = computed(() => {
  //
  //   if (!product.value) return 0
  //
  //   // if (product.value?.ykUnit === 'USD') {
  //   //   return Math.ceil(div(profit.value, product.value?.yk))
  //   // }
  //
  //   if (!quote.value) return 0
  //   // return Math.ceil(div(profit.value, div(product.value?.yk, quote.value)))
  //   // console.log(percent.value, product.value, quote.value)
  //
  //   // pip计算点位=(1/(calculatePoint*pointStep) * 行情)  去除小数点
  //   return 10
  // })
  const minPips = computed(() => {
    if (!product.value) return 0
    return boundFactor === 1 ? Number(product.value.minStopProfit) : Number(product.value.minStopLoss)
  })

  const maxPips = computed(() => {
    if (!product.value) return 0
    const { specs } = product.value
    if (specs?.length) return Number(R.apply(Math.max, specs[0].stopPlRange))
    return 200
  })

  const code = computed(() => product.value?.contract)

  // 预估报价
  const estQuote = computed(() => {
    if (!product.value) return 0
    const { calculatePoint, pointStep } = product.value

    // return add(mul(mul(calculatePoint, pointStep), pips.value * boundFactor), quote.value)
    return calculatePoint * Number(pointStep) * pips.value * boundFactor
  })

  const distance = computed(() => {
    if (!product.value) return 0

    return Math.abs(div(sub(estQuote.value, order.orderPosition.value), product.value?.pointStep))
  })

  const limit = (limitPercent: number) => {
    if (!product.value) return 0

    pips.value = limitPercent

    if (boundFactor === 1) {
      order.takeProfit.value = pips.value
    } else {
      order.stopLoss.value = pips.value
    }
  }

  /**
   * 获取最新报价
   */
  // watchEffect(() => {
  //   if (code.value) {
  //     readQuote(code.value)
  //       .then(resp => {
  //         quote.value = resp.sell
  //       })
  //   }
  // })

  return {
    // percent,
    profit,
    estQuote,
    distance,
    limit,
    maxPips,
    minPips,
    pips,
  }
}

export const speedProductByCode = (codes: string, type?: number) => {
  const order = inject('order') as Order
  const minNumbers = shallowRef<string[]>([])
  const levers = shallowRef<leversSchema[]>([])
  const choiceQuantity = shallowRef<string>('')
  const choiceLeverage = shallowRef<leversSchema | null>(null)

  readProduct({ codes }).then((resp: any) => {
    order.setProduct(resp)
    minNumbers.value = resp?.minNumbers ?? []
    levers.value = resp?.levers
    if (type === 1) {
      choiceQuantity.value = order.quantity.value
      choiceLeverage.value = { lever: order.leverage.value, choosed: 1, creditChoosed: 1 }
    } else {
      choiceQuantity.value = minNumbers.value[0]
      if (levers.value?.length) {
        const defaultLevers = R.find(R.propEq('choosed', 1), levers.value)
        const defaultLeversCredit = R.find(R.propEq('creditChoosed', 1), levers.value)
        choiceLeverage.value = order.useCoupon.value ? defaultLeversCredit ?? levers.value[0] : defaultLevers ?? levers.value[0]
      }
      order?.updateProductQuantity(choiceQuantity.value)
      order?.updateProductLeverage(choiceLeverage.value)
    }
  })
  return {
    product: order?.getProduct(),
    minNumbers,
    levers,
    choiceQuantity,
    choiceLeverage,
    selectQuantity (quantity: string) {
      choiceQuantity.value = quantity
      order?.updateProductQuantity(quantity)
    },
    selectLeverage (leverage: leversSchema) {
      choiceLeverage.value = leverage
      order?.updateProductLeverage(leverage)
    },
    changeLevers (newLevers: leversSchema[]) {
      levers.value = newLevers
    }
  }
}
