
// import { readTradeTrend } from '@/modules/market/market.api'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheTrend',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const trend = shallowRef({ up: 50 })

    // watchEffect(() => {
    //   if (props.code) {
    //     readTradeTrend({
    //       contract: props.code,
    //     }).then(resp => trend.value = resp)
    //   }
    // })

    return {
      trend,
    }
  },
})
