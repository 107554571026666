
import TheResolutionBar from '@/modules/market/components/chart/TheResolutionBar.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheResolutionArea',
  components: {
    Pic,
    TheResolutionBar,
  },
  setup () {
    const listExpand = shallowRef(false)

    const drawTypes = [{
      title: 'Horizontal line',
      lable: '',
      icon: 'icons/line',
      key: 'line',
    }, {
      title: 'Trend line',
      lable: '',
      icon: 'icons/segment',
      key: 'segment',
    }, {
      title: 'Arrow Up',
      lable: '',
      icon: 'icons/arrow_up',
      key: 'up',
    }, {
      title: 'Arrow Down',
      lable: '',
      icon: 'icons/arrow_down',
      key: 'down',
    }, {
      title: 'Text',
      lable: '',
      icon: 'icons/text',
      key: 'text',
    }]

    document.addEventListener('click', () => {
      if (listExpand.value) {
        listExpand.value = false
      }
    })

    return {
      listExpand,
      drawTypes,
    }
  },
})
