
// import TheBanner from '@/modules/home/components/TheBanner.vue'
import TheRecommend from '@/modules/home/components/TheRecommend.vue'
import TheNews from '@/modules/home/components/TheNews.vue'
// import TheEntries from '@/modules/home/components/TheEntries.vue'
// import TheEvents from '@/modules/home/components/TheEvents.vue'
import TheBuoy from '@/modules/home/components/TheBuoy.vue'
import TheMissionCard from '@/modules/home/components/TheMissionCard.vue'
import { sayHello, checkEvaluateStatus } from '@/modules/home/home'
import { defineComponent, onActivated, onMounted, shallowRef, watch } from 'vue'
import Scroller from 'common/providers/Scroller.vue'
import useLoadMore from 'common/hooks/useLoadMore'
import { readNewsAndAnalysis, NewsAndAnalysis } from '@/modules/home/home.api'
import { useRouter } from 'vue-router'
import state from '@/state'

export default defineComponent({
  name: 'Home',
  components: { TheNews, TheMissionCard, Scroller, TheBuoy, TheRecommend },
  setup () {
    const router = useRouter()
    const showMissionCard = shallowRef(true)

    const { loaded, refresh, loadMore } = useLoadMore<NewsAndAnalysis, NewsAndAnalysis[]>(
      readNewsAndAnalysis, 10, (resp) => resp)
    refresh()

    onMounted(() => {
      sayHello(router)
    })

    onActivated(() => {
      watch(() => state.login, () => {
        if (state.login) {
          // checkEvaluateStatus()
        }
      }, {
        immediate: true,
      })
    })

    return {
      showMissionCard,
      loaded,
      loadMore,
      refresh (done: () => void) {
        refresh().then(done)
      },
    }
  },
})
