
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import { events } from '@/config'
import useRequest from '@/hooks/useRequest'
import { useQuote } from '@/modules/market/market'
import { close, Position } from '@/modules/trade/trade.api'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { emit } from 'essential/tools/event'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'ThePositionCloseConfirmDialog',
  components: { RealTimeQuote, Icon, Button, DialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    let order: Position | null = null
    const done = shallowRef(false)
    const [commit, progress] = useRequest(close)
    const symbol = useQuote(props.order.code)

    return {
      done,
      close () {
        ctx.emit('close')
        ctx.emit('closed', order)
      },
      submit () {
        return commit({ orderId: props.order.orderId })
          .then(resp => {
            order = resp
            // emit(events.transactionUpdate)
            done.value = true
            ctx.emit('closed', order)
            setTimeout(() => {
              ctx.emit('close')
            }, 2000)
          })
      },
      progress,
      symbol
    }
  },
})
