import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    (_ctx.as)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as), { key: 0 }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "prepend"),
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.listData, (item, index) => {
              return _renderSlot(_ctx.$slots, "default", {
                key: _ctx.identify(item, index),
                index: index,
                item: item
              })
            }), 128)),
            _renderSlot(_ctx.$slots, "append")
          ]),
          _: 3
        }))
      : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
          _renderSlot(_ctx.$slots, "prepend"),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.listData, (item, index) => {
            return _renderSlot(_ctx.$slots, "default", {
              key: _ctx.identify(item, index),
              index: index,
              item: item
            })
          }), 128)),
          _renderSlot(_ctx.$slots, "append")
        ], 64))
  ]))
}