/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/8 12:53
 * @description
 *   LinearGradient.ts of FAST
 */
import { GradientStop } from './Defines'
import { randomString } from '../helper'
import * as d3 from 'd3'

export type LinearGradientOptions = {
  container: string;
  type: 'linearGradient';
  stops: GradientStop[];
}

class LinearGradient {
  id = randomString('lg')

  constructor (options: LinearGradientOptions) {
    d3.select(`#${options.container}`)
      .select('defs')
      .append('linearGradient')
      .attr('id', this.id)
      .attr('x1', '0')
      .attr('y1', '0')
      .attr('x2', '0')
      .attr('y2', '100%')
      .selectAll('stop')
      .data<GradientStop>(options.stops)
      .join('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color)
      .attr('stop-opacity', d => d.opacity ?? 1)
  }
}

export default LinearGradient
