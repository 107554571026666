/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/18 10:40
 * @description
 *   RSI.ts of WeTrade
 */
import * as d3 from 'd3'
import * as R from 'ramda'
import AbstractStudy, { ExtendStudyOptions, StudyConfig } from '../core/AbstractStudy'
import IChart from '../interface/IChart'
import { Bar, ISelection } from '../types'
import { calcRSI, RSIState } from './formulas'

export type RSIStudyInputs = {
  period?: number;
}

class RSI extends AbstractStudy<RSIStudyInputs> {
  state: RSIState | null = null

  constructor (chart: IChart, options: ExtendStudyOptions<RSIStudyInputs>, config?: StudyConfig) {
    super(chart, options, config)

    this.defaultLineFy = d3.scaleLinear().domain([0, 100]).range([this.chart.height, 0])
  }

  slice (from: number, to: number): Bar[][] {
    return this.cached.map(d => d.slice(from, to))
  }

  calcInitStudy (quotes: Bar[]): Bar[][] {
    const { state, value } = calcRSI(quotes, this.options.period)
    this.state = state
    return value
  }

  calcCandidateStudy (quotes: Bar[]): Bar[][] {
    if (this.state) {
      const { value } = calcRSI(quotes, this.options.period, this.state)
      return R.zipWith((cache, value) => cache.slice(-1).concat(value), this.cached, value)
    }
    return []
  }

  render (g: ISelection<SVGGElement>, shapes: Bar[][]): void {
    g
      .selectAll('path')
      .data(shapes)
      .join('path')
      .call(path => this.style(path as any))
      .attr('d', this.defaultLineRenderer)
  }
}

export default RSI
