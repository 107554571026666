
import ColorText from '@/components/ColorText.vue'
import Date from '@/components/Date'
import Icon from '@/components/Icon.vue'
import { openDialog } from '@/components/popup/popup'
import TheCommissionCancelConfirmDialog from '@/modules/trade/components/TheCommissionCancelConfirmDialog.vue'
import TheTradeLimitDisplay from '@/modules/trade/components/TheTradeLimitDisplay.vue'
import accordion from 'essential/dom/accordion'
import { computed, defineComponent, onMounted, ref, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheCommissionItem',
  components: { Date, TheTradeLimitDisplay, ColorText, Icon },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const toggle = shallowRef(() => {
      console.log('accordion effect not work')
    })

    const show = ref(false)

    const acc = shallowRef<HTMLElement | null>(null)
    const byCoupon = computed(() => props.order.isJuan)

    const cancel = () => openDialog(TheCommissionCancelConfirmDialog, {
      order: props.order,
    })

    onMounted(() => {
      toggle.value = accordion(acc.value as HTMLElement)
    })

    const toggleSlide = () => {
      show.value = !(show.value)
      toggle.value()
    }

    return {
      acc,
      cancel,
      byCoupon,
      show,
      toggleSlide,
    }
  },
})
