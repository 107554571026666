
import Icon from '@/components/Icon.vue'
import GuideTarget from '@/components/userGuide/GuideTarget.vue'
import GuideContent from '@/components/userGuide/GuideContent.vue'
import { useWatchState } from '@/modules/market/market'
import { readQuickAllSp } from '@/modules/market/market.api'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent, ref, watch } from 'vue'
import * as R from 'ramda'
import { localSet, localRemove } from 'essential/store/localStore'
import { keymap } from '@/config'
import { getSpeedAc } from '@/common/goSpeed'
import { readProduct } from '@/modules/trade/trade.api'
import { showAlert } from '@/components/popup/popup'

export default defineComponent({
  name: 'TheSymbolActions',
  components: { Pic, RealTimeQuote, GuideTarget, GuideContent, Icon },
  props: {
    symbol: Object,
    code: String
  },
  setup(props) {
    const list = ref<string[]>([])
    const product = ref(null)

    if (getSpeedAc('uuid')) {
      readQuickAllSp().then(res => {
        if (res?.products) {
          localSet(keymap.speed.quicklist, JSON.stringify(res.products))
          list.value = R.pluck('contract', res.products)
        } else localRemove(keymap.speed.quicklist)
      })
    }

    useWatchState(props.symbol?.code)

    const getProduct = () => {
      readProduct({ codes: props.code }).then((resp: any) => {
        product.value = resp
      })
    }

    watch(() => props.code, () => {
      if (getSpeedAc('uuid')) {
        getProduct()
      }
    })

    return {
      list,
      product,
      getSpeedAc,
      clickAlert () {
        showAlert('Your account does not support trading, please download the APP to log in to view trading')
      }
    }
  }
})
