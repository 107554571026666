/**
 * @author ayou[beica1@outook.com]
 * @date 2022/4/5 21:00
 * @description
 *   code.ts of WeTrade
 */
const server_code = {
  UNKNOWN: '1',
  NO_RESPONSE: '2',
  EMPTY_RESPONSE: '3',
  RESPONSE_TYPE_ERROR: '4',
}

export const SERVER_ERROR = new Proxy(server_code, {
  get (target, prop: keyof typeof server_code) {
    return ('000000' + target[prop]).slice(-6)
  },
})

const client_code = {
  UNKNOWN: '1',
}

export const CLIENT_ERROR = new Proxy(client_code, {
  get (target, prop: keyof typeof client_code) {
    const value = target[prop]
    return ('100000'.slice(0, -value.length) + value).slice(-6)
  },
})
