/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   config.ts of WeTrade
 */
import * as R from 'ramda'
import { localGet } from 'essential/store/localStore'
import { getQueryParam } from 'essential/util'
import { DEFAULT_LANG, SUPPORTED_LANGS } from '@/i18n'

const BRAND = {
  MAIN: '1'
}

const DEVICES = {
  OTHER: 0,
  ANDROID: 1,
  IOS: 2,
  H5: 4,
  WEB: 5
}

export const keymap = {
  user: {
    accountNum: 'f0',
    avatar: 'bi',
    countryCode: 'l9',
    faceBookBindStatus: 'i2',
    fbId: 'jt',
    invitationCode: 'j4',
    mobile: 'a3',
    mobileBindStatus: 'h9',
    nickName: 'ea',
    token: 'cj',
    userId: 'd2',
    email: 'e1',
    islamFlag: 'i62'
  },
  label: {
    uuid: 'u2',
    session: {
      dismissMissionCard: 'x0'
    },
    accountType: 'n5',
    watchList: 'i3',
    monitorList: 'z2',
    posts: 'o3',
    language: 's0',
    color: 'x9',
    resolution: 's2'
  },
  search: {
    deviceType: 'd',
    appVersion: 'v',
    sdkVersion: 'av',
    imei: 'i',
    channel: 'c',
    deviceInfo: 'f',
    appId: 'ai',
    inviteUser: 'ii',
    pushMessage: 'p',
    updateVersion: 'uv',
    vestBagId: 'vbi'
  },
  guide: {
    marketList: 'li',
    position: 'k3',
    create: 'q9',
    switchAccount: 'l3',
    depositTip: 'dt',
    membershipHelp: 'mh',
    remindPlay: 'rp',
    fastGuideDialog: 'fg',
    copyGuideDialog: 'cg',
    quickPlayTips: 'qp',
    drawLine: 'dl',
    drawSegment: 'ds',
    drawText: 'dtx',
    drawArrow: 'daw',
    specialTips: 'spt'
  },
  cup: {
    mainTab: 'mt',
    subTab: 'st'
  },
  speed: {
    user: 'ac',
    language: 'lg',
    quicklist: 'qk',
    clear: 'clear'
  }
}

export const market = {
  excode: 'EX',
  spcode: 'XTREND'
}

export const request = {
  staticRequestData: {
    // static
    appId: getQueryParam(keymap.search.appId) || BRAND.MAIN,
    device: getQueryParam(keymap.search.deviceType) || DEVICES.WEB,
    version: getQueryParam(keymap.search.appVersion) || '10.0.0',
    market: getQueryParam(keymap.search.channel) || 'pc',
    updateVersion: getQueryParam(keymap.search.updateVersion) || '',
    vestBagId: getQueryParam(keymap.search.vestBagId) || 4,
    excode: market.excode,
    // adId: 'dev',
    registeredSource: 1, // pc特有的参数
    get adId() {
      return localGet(keymap.label.uuid)
    }
  },

  get dynamicRequestData() {
    return {
      countryCode: localGet(keymap.user.countryCode) || '91',
      mobile: localGet(keymap.user.mobile), // mobile
      token: localGet(keymap.user.token) || '3d9a8b3c-48cb-4cd1-ac5b-fcf3bdd697cf',
      userId: localGet(keymap.user.userId) || '22',
      // uuid: getSpeedAc('uuid'),
      // tradeToken: getSpeedAc('tradeToken'),
      accountNum: localGet(keymap.user.accountNum),
      language: getLangIndex()
    }
  },

  baseUrl: '/api/'
}

export const events = {
  login: 'login',
  logout: 'logout',
  tokenExpired: 'expired.token',
  accountChanged: 'change.account',
  postRefresh: 'refresh.post', // 更新帖子
  transactionUpdate: 'update.transaction',
  watchListUpdate: 'update.watchlist',
  fastTradeProductChange: 'change.product.fast', // 这里因为tab的props响应式有问题，暂时用event代替
  quickClose: 'close.quick',
  activated: 'activated',
  symbolChange: 'change.symbol',
  usecredit: 'trade.credit'
}

export const isDevMode = () => process.env.NODE_ENV === 'development'

const isMainServer = () =>
  ['webtrader.wetrade.one', 'webtrader.we-trade.app'].indexOf(window.location.host) !== -1

const isDevContext = () => isDevMode() || process.env.VUE_APP_SERVER_CONTEXT === 'development'

export const format = {
  // moneyFactor: 10000
  moneyFactor: 1
}

export const flag = {
  get isIOS() {
    return !!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  },
  get isDevMode() {
    return isDevMode()
    // return false
  },
  get isDevContext() {
    return isDevContext()
  }
}

export const server = {
  translate: 'https://m.xtrendspeed.com/news/api/translate/google/jsonp',
  ...(isDevContext()
    ? {
      // api: 'http://wetrade-web-api.thebestdeveloperever.com',
      // quotation: 'ws://wetrade-quo-ws-new.thebestdeveloperever.com/ws',
      // pushSeed: 'https://fast-trade-test.oss-cn-hongkong.aliyuncs.com/wetrade-pc-c.js',
      // prod server config
      api: 'https://pc-api.wetrade.one/',
      speedapi: 'https://speed-web.yinyu.tech/',
      quotation: 'wss://quote-push-cf.xtrendspeed.com/ws',
      pushSeed: 'https://static.wetrade.one/wetrade-pc.app.js',
      path: 'https://speedwww.yinyu.tech/',
      noticeWs: 'wss://test-pcapi.xtrendspeed.com/ws'
    }
    : isMainServer()
      ? {
        api: 'https://pc-api.wetrade.one/',
        speedapi: 'https://webtrader-api.xtrendspeed.com/',
        quotation: 'wss://quote-push-cf.xtrendspeed.com/ws',
        pushSeed: 'https://static.wetrade.one/wetrade-pc.app.js',
        path: 'https://www.xtrendspeed.com/',
        noticeWs: 'wss://pc-push.xtspd.com/ws'
      }
      : {
        api: 'https://pc-api.wetrade.one/',
        speedapi: 'https://webtrader-api.xtrendspeed.com/',
        quotation: 'wss://quote-push-cf.xtrendspeed.com/ws',
        pushSeed: 'https://static.wetrade.one/wetrade-pc.app.js',
        path: 'https://www.xtrendspeed.com/',
        noticeWs: 'wss://pc-push.xtspd.com/ws'
      })
}

export const regExps = {
  topic: /#[^#\W]+/g,
  emoji: /:[^:]+:/g
}

export const interval = {
  // settings
  ledRefreshInterval: 1000 * 3,
  accountRefreshInterval: 1000 * 2,
  postCheckInterval: 1000 * 5
}

export default {
  keymap,
  market,
  request,
  events,
  server,
  format,
  flag,
  interval,
  regExps
}

/**
 * 把language code转换为 index
 */
export const getLangIndex = () =>
  Math.max(
    0,
    R.findIndex(R.equals(localGet(keymap.label.language || DEFAULT_LANG)), SUPPORTED_LANGS)
  )
