
import { computed, defineComponent, h } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Translate',
  props: {
    path: String,
    args: {
      type: Object,
      default: () => ({}),
    },
    as: {
      type: String,
      default: 'span',
    },
    custom: {
      type: [Boolean, String],
      default: false,
    },
    multiline: Boolean,
    eof: {
      type: String,
      default: ';',
    },
  },
  setup (props, ctx) {
    const { t, te, locale } = useI18n()

    const replace: string[] = []

    const translated = computed(() => {
      let text = props.path && t(props.path, props.args)

      if (typeof props.custom === 'string') {
        const params = props.custom.split(',')
        const args: {[key in string]: string} = {}
        params.forEach((item: string) => {
          const val = '$' + item.trim() + '$'
          args[item.trim()] = val
          replace.push(val)
        })

        text = props.path && t(props.path, args)
      }

      const td = props.path && te(props.path, locale.value) && text
      if (props.multiline && td) {
        return td.split(props.eof)
      }

      return td
    })

    const dft = ctx.slots.default || (() => props.path)
    const components: any[] = []

    if (typeof props.custom === 'string') {
      let td = translated.value as string
      if (!td) {
        return components.push((ctx.slots.default || (() => props.path))?.())
      }
      for (let i = 0; i < replace.length; i++) {
        const temp = td.split(replace[i])
        td = temp[1]
        components.push(temp[0])
        components.push(ctx.slots[replace[i].replace(/\$/g, '')]?.({ t }))
        if (i === replace.length - 1) {
          components.push(temp[1])
        }
      }
    }

    // 测试args结合custom 传slot 测试用
    // if (props.path === 'home_59') {}
    // slot变量参照 plstats_19

    return () => props.custom
      ? (typeof props.custom === 'string' ? h(props.as, components) : dft({ t, td: translated.value }))
      : h(props.as, [translated.value || dft()])
  },
})
