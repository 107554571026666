/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/10
 * @description
 *   Inject.ts of WeTrade
 */
import * as R from 'ramda'

const customWindow = window as any

export type InjectConfig = {
  injectObject: string;
  injectMethod: string;
  nativeCallbackName?: string;
  nativeActivatedName?: string;
}

export const CODES = {
  ERROR: '-0',
  RESULT_OK: 'ok',
  RESULT_NOT_OK: '!ok',
  NO_BRIDGE_CONTEXT: -1,
} as const

class Inject {
  private id: null | string
  private injection: null | InjectConfig
  private name: null | string

  constructor (id: string, name: string, injection: InjectConfig) {
    this.id = id
    this.injection = injection
    this.name = name
  }

  getRequestParams (data?: Data) {
    return R.mergeRight(data ?? {}, {
      id: this.id,
      _event: this.id,
    })
  }

  executeInWKWebView (data: Data) {
    let executed = true
    try {
      customWindow
        .webkit
        .messageHandlers
        .emit
        .postMessage(
          JSON.stringify(
            {
              type: this.name,
              data,
            },
          ),
        )
    } catch (e) {
      executed = false
    }
    return executed
  }

  executeDefault (data: Data) {
    const target = customWindow[this.injection?.injectObject ?? ''] || {}

    const fn = target[this.injection?.injectMethod ?? ''] || (() => {
      // console.error(`[${this.name}]`, 'Lack of Injection execute context')
      const name = this.injection?.nativeCallbackName
      if (name && (typeof customWindow[name] === 'function')) {
        customWindow[name]({
          event: this.id,
          data: {},
          result: CODES.RESULT_OK, // web端 自动ok
          // result: CODES.RESULT_NOT_OK,
        })
      }
      this.destroy()
    })

    // @warn 这里用call 否则 target.fn 的方式
    fn.call(target, this.name as string, JSON.stringify(data))
  }

  execute (data?: Data, onResolve?: (teardown: Noop) => void) {
    const params = this.getRequestParams(data)

    if (typeof onResolve === 'function') {
      onResolve(() => this.destroy())
    }

    // IOS webview context
    const executed = this.executeInWKWebView(params)

    if (!executed) {
      this.executeDefault(params)
    }
  }

  destroy () {
    this.id = null
    this.name = null
    this.injection = null
  }
}

export default Inject
