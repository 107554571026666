/**
 *  chart.ts of project wetrade
 *  @date 2022/5/18 17:28
 *  @author 阿佑[ayooooo@petalmail.com]
 */
import { BarTypes } from '@/modules/market/components/chart/chartConfig'
import {
  AlertState,
  drawLine,
  Line,
  LineType,
  readLines,
  removeLine,
} from '@/modules/market/market.api'
import { DrawingState } from 'happychart/core/AbstractDrawing'
import { DrawingOptions } from 'happychart/drawing/Drawing'
import IChart, { ChartPointer } from 'happychart/interface/IChart'
import { IDrawing } from 'happychart/interface/IDrawing'
import * as R from 'ramda'
import { shallowRef } from 'vue'

export type Feedback = (type: DrawingOptions['type'], state: DrawingState) => void

export const useDrawing = (modify: (state: boolean) => void) => {
  let activeDrawing: IDrawing | null = null

  const lines: Record<string, Line> = {}

  const lineTypes = ['', 'line', 'segment', 'up', 'down', 'text']

  const drawingBindings = shallowRef<{ y: number, x?: number, alertOn: boolean | null, color: string | null, type?: number, currentMsg?: string }>({
    color: '#000',
    y: 0,
    alertOn: false,
  })

  const emptyLines = (chart: IChart | null) => {
    for (const id of R.keys(lines)) {
      chart?.drawings.remove(id)
      delete lines[id]
    }
  }

  const getPonitParams = (type: number|undefined, endpoints: ChartPointer[]) => {
    let params = {}
    switch (type) {
      case LineType.LINE:
        params = {
          horizontalLinePrice: endpoints[0].sc,
        }
        break
      case LineType.TREND:
        params = {
          slashStartPrice: endpoints[0].sc,
          slashStartTime: endpoints[0].t,
          slashEndPrice: endpoints[1].sc,
          slashEndTime: endpoints[1].t,
        }
        break
      case LineType.ARROW_UP:
      case LineType.ARROW_DOWN:
        params = {
          arrowPrice: endpoints[0].sc,
          arrowTime: endpoints[0].t,
        }
        break
      case LineType.TEXT:
        params = {
          textPrice: endpoints[0].sc,
          textTime: endpoints[0].t,
        }
        break
      default:
        return false
    }
    return params
  }

  const saveLine = (
    drawingId: string,
    endpoints: ChartPointer[],
    line: Line | null,
    kLineType?: BarTypes,
    type?: LineType,
    code?: string,
    color?: string,
    msg?: string,
  ) =>
    drawLine({
      code,
      type,
      color: color || line?.color,
      kLineType,
      ...line,
      ...getPonitParams(type || line?.type, endpoints),
      msg: msg || line?.msg,
    }).then(resp => {
      lines[drawingId] = resp
    })

  const makeFormatLabel = (alert = false) => (c: number | string) => (alert ? '🔔' : '') + c

  const processDrawingState = (drawing: IDrawing, state: DrawingState, points?: ChartPointer[]) => {
    const line = lines[drawing.id]

    activeDrawing = drawing

    if (state === DrawingState.ACTIVE) {
      modify(true)
      drawingBindings.value = {
        y: (points as ChartPointer[])[0].y,
        x: (points as ChartPointer[])[0].x,
        alertOn: line.type !== LineType.LINE ? null : line.warning === AlertState.ON,
        color: line.color,
        type: line.type,
        currentMsg: line.msg,
      }
    } else if (state === DrawingState.UPDATED) {
      if (points) return saveLine(drawing.id, points, line)
    }
  }

  /**
   * 用户画线
   * @param chart
   * @param options
   * @param code
   * @param feedback
   */
  const create = (
    chart: IChart | null,
    options: DrawingOptions & { kLineType: BarTypes, text?: string },
    code: string,
    feedback?: Feedback,
  ) => {
    activeDrawing?.cancel()
    feedback?.(options.type, DrawingState.DRAW)

    chart?.drawings.create(options, (drawing, state, points) => {
      feedback?.(options.type, state)
      if (state === DrawingState.COMPLETE && points) {
        saveLine(
          drawing.id,
          points,
          null,
          options.kLineType,
          lineTypes.indexOf(options.type),
          code,
          '#000',
          options.text,
        ).catch(() => {
          chart?.drawings.remove(drawing.id)
        })
      } else {
        processDrawingState(drawing, state, points)
      }
    })
  }

  /**
   * 绘制已有的直线
   * @param chart
   * @param ls
   */
  const draw = (chart: IChart | null, ls: Line[]) => {
    emptyLines(chart)

    R.map(l => {
      const drawing = chart?.drawings.add({
        type: lineTypes[l.type] as DrawingOptions['type'],
        lineColor: l.color,
        formatLabel: makeFormatLabel(l.warning === AlertState.ON),
        point: {
          t: 0,
          c: l.horizontalLinePrice,
        },
        points: [
          {
            c: l.slashStartPrice,
            t: l.slashStartTime,
          },
          {
            c: l.slashEndPrice,
            t: l.slashEndTime,
          },
        ],
        textPoint: [{
          t: l.textTime,
          c: l.textPrice,
        }],
        text: l.msg,
        arrowPoint: [{
          t: l.arrowTime,
          c: l.arrowPrice,
        }],
        ardir: lineTypes[l.type] as DrawingOptions['type'],
      }, processDrawingState)

      if (drawing) lines[drawing.id] = l
    }, ls)
  }

  const style = (color: string) => {
    if (activeDrawing) {
      const line = lines[activeDrawing.id]

      if (line) {
        line.color = color
        drawLine(line).then(() => {
          activeDrawing?.style(color)
        })
      }
    }
  }

  const toggleAlert = (done: () => void) => {
    if (activeDrawing) {
      const line = lines[activeDrawing.id]

      if (line) {
        line.warning = line.warning === AlertState.OFF ? AlertState.ON : AlertState.OFF
        drawLine(line).then(() => {
          activeDrawing?.updateLabel(
            t => line.warning === AlertState.ON ? `🔔${t}` : t.replace('🔔', ''))
          drawingBindings.value = {
            ...drawingBindings.value,
            alertOn: line.warning === AlertState.ON,
            type: line.type,
            currentMsg: line.msg,
          }
          done()
        })
      }
    }
  }

  const editText = (newVal: string) => {
    if (activeDrawing) {
      const line = lines[activeDrawing.id]
      line.msg = newVal

      if (line) {
        drawLine(line).then(() => {
          if (activeDrawing?.updateText) {
            activeDrawing?.updateText(newVal)
          }
          drawingBindings.value = {
            ...drawingBindings.value,
            type: line.type,
          }
        })
      }
    }
  }

  const remove = (chart: IChart | null) => {
    if (activeDrawing) {
      const line = lines[activeDrawing.id]

      if (line) {
        removeLine({ id: line.id }).then(() => {
          delete lines[line.id]
          if (activeDrawing) {
            chart?.drawings.remove(activeDrawing.id)
          }
          modify(false)
        })
      }
    }
  }

  const read = (code: string, type: BarTypes) => readLines({
    code,
  }).then(R.filter(R.propEq('kLineType', type)))

  return {
    drawingBindings,
    draw,
    create,
    style,
    toggleAlert,
    editText,
    remove,
    read,
  }
}
