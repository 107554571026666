/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/8 12:29
 * @description
 *   Define.ts of FAST
 */
import LinearGradient from './LinearGradient'
import * as d3 from 'd3'

export type GradientStop = {
  offset: number | string;
  color: string;
  opacity?: number;
}

export type DefineOptions = {
  type: 'linearGradient';
  stops: GradientStop[];
}

class Defines {
  container

  constructor (container: string) {
    this.container = container

    d3.select(`#${container}`).append('defs')
  }

  add (define: DefineOptions) {
    if (define.type === 'linearGradient') {
      return new LinearGradient({
        ...define,
        container: this.container,
      }).id
    }
    return ''
  }
}

export default Defines
