
import { isRoam } from '@/state'
import { isDemoAccount } from '@/state/accountType'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AccountRelated',
  props: {
    useInDemo: Boolean,
    useInRoam: Boolean,
    useInReal: Boolean,
  },
  computed: {
    need (): boolean {
      if (isRoam()) {
        return this.useInRoam
      }
      if (isDemoAccount.value) {
        return this.useInDemo
      }
      return this.useInReal
    },
  },
})
