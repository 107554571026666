/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/8
 * @description
 *   request.ts of essential
 */
import * as R from 'ramda'
import { guid } from '../../shared'

export type RequestConfig = Partial<{
  timeout: number;
  private: boolean;
  continue: boolean;
  cancelable: boolean;
  cache: true | number;
  silent: boolean; // 静默执行
}>

export type RequestPayload = {
  id: string;
  url: string;
  data: Data;
  config: RequestConfig;
}

export type RequestParams<P> = [data?: Data & P | null, config?: RequestConfig]

// 缓存请求
const cache = R.identity

// 添加时间戳
const attachTimestamp = R.over<RequestPayload, unknown>(R.lensPath(['data', '_t']), Date.now)

export interface Processor {
  (payload: RequestPayload): RequestPayload;
}

/**
 * 内奸数据处理程序
 */

// const processRequestParams = exeProcessor([R.last(preProcessors) as Processor])
// const processRequestParams = exeProcessor(preProcessors)

const processRequestParams = () => {
  const preProcessors: Array<Processor> = [
    cache,
    attachTimestamp,
  ]

  const exeProcessor = (processors: Array<Processor>) => {
    return (payload: RequestPayload) => {
      return R.reduce((payload, process) => {
        const result = process(payload)
        return result ?? payload
      }, payload, processors)
    }
  }
  return {
    fc: exeProcessor(preProcessors),
    c: preProcessors
  }
}

/**
 * s - is success
 * c - response code
 * m - response message
 * d - response data
 */
export type ResponseResult<T = any> = {
  s: boolean;
  c: string | number;
  m: string;
  d?: T;
}

export type Response<T> = Promise<ResponseResult<T>>

export interface RequestExecutor {
  <T>(payload: RequestPayload): Response<T>
}

/**
 * 借助第三方的工具定义统一格式的xhr客户端方法
 * @param exe
 * @param [middlewares]
 * @example
 *   const request = makeRequire((url, data, config) => axios.post(url, data, config), [R.identity])
 *   const readList = request<Array<{ id: string }>>('/api/example/list')
 *   readList({ page: 1, pageSize: 10}, { private: true }).then(resp => console.log(resp[0].id))
 */
const makeRequestBy = (exe: RequestExecutor, middlewares?: Array<Processor>) =>
  <T, P>(url: string) =>
    (...args: RequestParams<P>) => {
      const payload = {
        id: guid(),
        url,
        data: args[0] ?? {},
        config: args[1] ?? {},
      }
      const { c, fc } = processRequestParams()
      c.push(...(middlewares ?? []))
      const params = fc(payload)
      return exe<T>(params)
    }

export default makeRequestBy
