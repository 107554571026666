
import * as R from 'ramda'
import { defineComponent, ref, watch } from 'vue'
import TheImgGallery from './TheImgGallery.vue'
import TheLikeButton from './TheLikeButton.vue'
import ThePostTemplate from './ThePostTemplate.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'ThePostItem',
  components: { Icon, TheImgGallery, TheLikeButton, ThePostTemplate },
  props: {
    post: {
      type: Object,
      required: true,
    },
    noPin: Boolean,
  },
  setup (props) {
    const postData = ref(props.post)
    watch(() => props.post, () => {
      postData.value = props.post
    }, { deep: true, immediate: true })
    return {
      hasImgs: R.complement(R.isNil)(props.post.imageUrls),
      postData,
    }
  },
})
