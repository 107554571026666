/**
 * @author 阿佑[ayooooo@petalmail.com]
 * @date 2022/4/14 16:33
 * @description
 *   withoutCryptos.ts of WeTrade
 */
import requestResult from '@/common/request/request'
import { MarketCategory, MarketItem } from '@/modules/market/market.types'
import { shallowRef, watch } from 'vue'
import * as R from 'ramda'
import { market } from '@/config'
// import { ifShieldCryptos } from '@/modules/app/app.api'
// import state from '@/state'
// import { YesOrNo } from '@/types'

const shield = true
const flagChecked = shallowRef(true)

let shieldList: string[] = []
const listLoaded = shallowRef(false)

export const isCrypto = async (code: string) => {
  await loadShieldList()

  return R.contains(code, shieldList)
}

export const isShield = async (code?: string) => {
  await checkFlag()

  if (!code) return shield

  await loadShieldList()

  return shield && R.contains(code, shieldList)
}

// watchEffect(() => {
//   flagChecked.value = false

//   ifShieldCryptos({ isLogin: state.login })
//     .then(resp => {
//       shield = resp.flg === YesOrNo.YES
//       // shield = true
//     })
//     .catch(() => {
//       shield = true
//     })
//     .finally(() => {
//       flagChecked.value = true
//     })
// })

const checkFlag = () => {
  if (flagChecked.value) return shield
  else {
    return new Promise(resolve => {
      const stop = watch(flagChecked, () => {
        if (flagChecked.value) {
          stop()
          resolve(shield)
        }
      })
    })
  }
}

const loadShieldList = async () => {
  if (listLoaded.value) return shieldList
  else {
    return requestResult<Array<MarketItem>>('/api/contactproduct/list')({
      excode: market.excode,
      type: MarketCategory.CRYPTO
    }).then(resp => {
      shieldList = R.pluck('code', resp)
      listLoaded.value = true
      return shieldList
    })
  }
}

export default <
  F extends (...args: any[]) => Promise<any>,
  P extends Parameters<F>,
  L = ReturnType<F> extends Promise<infer P> ? P : any
>(
  fn: F,
  field = 'code',
  reach: (list: L) => any = R.identity
) => async (...args: P): Promise<L> => {
  // 确认屏蔽标志
  await checkFlag()

  // 确认之后再判断是否不过滤调用
  if (!shield) return fn(...args)

  const [resp] = await Promise.all([fn(...args), loadShieldList()])

  const list = reach(resp)

  return Array.isArray(list)
    ? R.reject(R.propSatisfies(R.includes(R.__, shieldList), field), list)
    : list
}
