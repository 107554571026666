
import * as R from 'ramda'
import { defineComponent, shallowRef, watch } from 'vue'
import DefaultSkeleton from '@/provider/DefaultSkeleton.vue'

const isNotNil = R.both(R.complement(R.isNil), R.complement(R.isEmpty))

export default defineComponent(
  {
    name: 'Holder',
    components: { DefaultSkeleton },
    props: {
      content: [Array, Object, String],
      sync: Boolean,
      skeletonRepeat: {
        type: Number,
        default: 2,
      },
      holderClass: String,
    },
    setup (props) {
      const loaded = shallowRef(isNotNil(props.content) || props.sync)

      watch(() => props.content, () => {
        loaded.value = true
      })

      return { loaded }
    },
  },
)
