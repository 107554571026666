/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/23
 * @description
 *   swipe.ts of WeTrade
 */
import * as R from 'ramda'
import idMaker from '../tools/idMaker'
import transform, { Point, Axis, Transformer, TransformHooks } from './transform'

const id = idMaker()

const X = Axis.X
const Y = Axis.Y

interface SwipeFn {
  (transformer: Transformer): Point
}

interface SwipeOptions {
  direction?: 'horizontal' | 'vertical';
  resistanceRatio?: number;
  onWillSwipe?: () => void,
  onSwiped?: (transformer: Transformer, longTransform: boolean, release: () => void) => void
  range?: [min: number, max: number];
}

const defaultSwipeOptions: Required<SwipeOptions> = {
  direction: 'horizontal',
  resistanceRatio: 0.85,
  onWillSwipe () {
  },
  onSwiped: (transformer, longTransform, release) => release(),
  range: [-Infinity, Infinity],
}

const getSwipeStartPoint = (el: HTMLElement): Point => {
  const transformMatrix = window.getComputedStyle(el).transform
  if (transformMatrix === 'none') return [0, 0]
  return transformMatrix.split(',').slice(-2).map(parseFloat) as Point
}

// 多个动画实例之间的互斥锁
let checkoutId: null | number = null

const swipe = (el: HTMLElement, opts: SwipeOptions = defaultSwipeOptions) => {
  let lock = false // 耽搁动画实例的动画同步锁

  let swipeFrom: Point = getSwipeStartPoint(el)

  const swipeId = id.next()

  const originTransition = el.style.transition

  const options = R.mergeRight(defaultSwipeOptions, opts)

  const checkoutSwipe = (transformer: Transformer) => {
    if (checkoutId !== null) return
    if (
      (
        options.direction === 'horizontal'
        && R.pipe(R.map(Math.abs), R.apply(R.gt))(transformer.delta))
      || (
      options.direction === 'vertical'
      && R.pipe(R.map(Math.abs), R.apply(R.lt))(transformer.delta))
    ) {
      checkoutId = swipeId
    }
  }

  const resistanceRatio = options.resistanceRatio ?? defaultSwipeOptions.resistanceRatio

  const toResistance = (n: number) => Math.abs(n) ** resistanceRatio * (n / Math.abs(n))

  const swipeInXAxis: SwipeFn = (transformer) => [
    R.min( // 上边界判断
      options.range[1],
      R.max( // 下边界判断
        options.range[0],
        swipeFrom[X] + toResistance(transformer.delta[X]),
      ),
    ),
    0,
  ]

  const swipeInYAxis: SwipeFn = (transformer) => [
    0,
    R.min(
      options.range[1],
      R.max(
        options.range[0],
        swipeFrom[Y] + toResistance(transformer.delta[Y]),
      ),
    ),
  ]

  const applyTransformStyle = (point: Point) => {
    el.style.transform = `translate3d(${point[X]}px, ${point[Y]}px, 0)`
  }

  const onWillTransform = () => {
    if (!lock) {
      // 重置动画
      el.style.transition = 'none'
      // 更新起始点
      swipeFrom = getSwipeStartPoint(el)
      options.onWillSwipe()
    }
  }

  let swipeFn: SwipeFn = options.direction === 'horizontal' ? swipeInXAxis : swipeInYAxis

  const onTransform: TransformHooks['onTransform'] = transformer => {
    checkoutSwipe(transformer)
    if (checkoutId !== swipeId) return
    if (!lock) {
      const next = swipeFn(transformer)
      applyTransformStyle(next)
    }
  }

  const onDidTransform: TransformHooks['onDidTransform'] = (transformer, longTransform) => {
    if (!lock) {
      el.style.transition = originTransition
      const release = () => {
        lock = false
        checkoutId = null
      }
      if (checkoutId === swipeId) {
        lock = true
        options.onSwiped(transformer, longTransform, release)
      }
    }
  }

  return transform(el, {
    onWillTransform,
    onTransform,
    onDidTransform,
  })
}

export default swipe
