
import { update } from '@/common/jsBridge.api'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UpdateDialog',
  components: { Icon, Pic },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    return {
      update () {
        update(props.config)
        ctx.emit('close')
      },
    }
  },
})
