import { getQueryParam } from 'essential/util'
import { keymap, server } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import { requestResultSpeed } from './request/request'
import * as R from 'ramda'

export const getCookie = (name: string) => {
  if (document.cookie) {
    const s = document.cookie.match(RegExp(`(^| )${name}=([^;]*)(;|$)`))
    return s ? s[2] : s
  }
}

export const setCookie = (k: string, v: string, expires?: number) => {
  if (document) {
    const exp = expires ? new Date(new Date().getTime() + expires * 1000) : ''
    document.cookie = `${k}=${v}${exp ? ';expires=' + exp : exp};path=/`
  }
}

export const isSpeedSource = () => {
  const { user, language, clear } = keymap.speed
  const v = R.filter(Boolean, R.map(getQueryParam, [user, language])) as [string, string]
  if (v.length === 2) {
    history.pushState({}, '', '/')
    localSet(user, v[0])
    localSet(language, v[1])
  } else if (getQueryParam(clear) === '1') {
    localStorage.clear()
  }
}

export type SpeedACSchema = {
  uuid: string
  tradeToken: string
}

export const encodeJSON = (s: string) => {
  const utf8Bytes = new TextEncoder().encode(s)
  return Array.from(utf8Bytes)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('')
}

export const decodeJSON = (s: string) => {
  let str = ''
  for (let i = 0; i < s.length; i += 2) {
    str += String.fromCharCode(parseInt(s.substr(i, 2), 16))
  }
  return str
}

export const getSpeedAc = (k: keyof SpeedACSchema) => {
  const ac = localGet(keymap.speed.user)
  if (ac) {
    const u = JSON.parse(decodeJSON(ac)) as SpeedACSchema
    return u ? u[k] : ''
  }
  return ''
}

const speedwww = {
  all: ['login?source=webtrade', 'trade/download'],
  login: ['selacc/cz', 'selacc/tx', 'ay']
}

const findPos = (s = '') => {
  if (s) {
    const [a, b] = s.split('-').map(Number)
    if (a >= 0 && b >= 0 && a < 2) {
      const { all, login } = speedwww
      return a
        ? getSpeedAc('uuid')
          ? `speedpcstatic/pcmanage/index.html#/${login[b]}`
          : all[0]
        : all[b]
    }
    return s
  }
  return s
}

export const goSpeed = (s = '', isnew = false) => {
  const p = server.path + findPos(s)
  if (isnew) window.open(p)
  else window.location.href = p
}

export const speedParam = () => ({
  uuid: getSpeedAc('uuid'),
  tradeToken: getSpeedAc('tradeToken'),
  language: 'en-US',
  timeZoneOffset: new Date().getTimezoneOffset(),
  device: 5
})

export const reqLoginOut = <T = typeof requestResultSpeed>(ob: { in: T; out: T }) =>
  getSpeedAc('uuid') ? ob.in : ob.out

export const getSpeedPostInfo = () => {
  const msg = (d: MessageEvent) => {
    console.log(d, '消息来源')
    if (d.origin === server.path) {
      console.log(d.data, 'xxxxxxxxxxxxxxxx收到')
    }
  }
  return {
    addSpMsg: () => {
      window.addEventListener('message', msg)
    },
    removeSpMsg: () => {
      window.removeEventListener('message', msg)
    }
  }
}
