// import { keymap } from '@/config'
// import { checkIP } from '@/modules/app/app.api'
// import { localSet, localRemove, localGet } from 'essential/store/localStore'
import * as R from 'ramda'
import { createApp } from 'vue'
// import jsBridge from '@/common/jsBridge'
import { i18nInstance } from '@/i18n'
import { YesOrNo } from '@/types'
import Money from '@/components.global/Money'
import T from '@/components.global/T.vue'
// import { open, readAll } from 'essential/store/indexedDB'
import '@/theme/index.scss'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
// import AppShield from './AppShield.vue'
import { isSpeedSource } from './common/goSpeed'

// jsBridge.registerJSAdaptor()
isSpeedSource()

const app = createApp(App)

app
  .component('t', T)
  .component('money', Money)
  .mixin({
    // 防止魔法数字的便捷变量注入
    data() {
      return {
        DONE: YesOrNo.YES,
        YES: YesOrNo.YES,
        NO: YesOrNo.NO,
      }
    },
    // 便捷缺省变量函数注入
    methods: {
      prop(key: string, data: Data) {
        return R.propOr('--', key, data)
      },
      object(target: Record<string, unknown>) {
        return target ?? {}
      },
      array(target: Array<unknown>) {
        return target ?? []
      },
    },
  })
  .use(i18nInstance)
  .use(router)

const start = () => app.mount('#app')

start()

// const identifyAdjustUser = async () => {
//   const dbName = 'adjust-sdk'
//   const reloadFlag = 'adjust-sdk_reload_flag'

//   if (typeof (window as any).Adjust === 'object') {
//     const db = await open(dbName)

//     try {
//       const result = await readAll<{ u: string }>(db, 'as')

//       if (result.length !== 0) {
//         localSet(keymap.label.uuid, result[0].u)
//       }

//       db.close()
//       localRemove(reloadFlag)
//     } catch (e) {
//       window.indexedDB.deleteDatabase(dbName)

//       /**
//        * 获取id错误 尝试重新加载一次
//        */
//       if (!localGet(reloadFlag)) {
//         localSet(reloadFlag, '1')
//         location.reload()
//       } else {
//         localRemove(reloadFlag)
//       }
//     }
//   }

//   return Promise.resolve()
// }

// checkIP()
//   .then(res => {
//     if (res.flg === YesOrNo.YES) {
//       createApp(AppShield)
//         .mount('#app')
//     } else {
//       identifyAdjustUser().finally(start)
//     }
//   })
//   .catch(() => {
//     createApp(AppShield)
//       .mount('#app')
//   })
