/**
 * @author ayo[beica1@outook.com]
 * @date 2022/4/5 21:30
 * @description
 *   jsonp.ts of WeTrade
 */
import * as R from 'ramda'

const serializeObj = R.pipe(
  R.toPairs,
  R.map(
    R.pipe(
      R.over(
        R.lensIndex(1),
        R.o(
          encodeURIComponent,
          R.defaultTo(''),
        ),
      ),
      R.join('='),
    ),
  ),
  R.join('&'),
)

export const jsonp = <T> (url: string, data?: Data) => {
  return new Promise<T>((resolve, reject) => {
    const callback = 'jsonp_' + Math.random().toString(36).slice(2)

    const script = document.createElement('script')

    const remove = () => {
      delete (window as any)[callback]
      try {
        document.body.removeChild(script)
      } catch {
      }
    }

    (window as any)[callback] = (resp: T) => {
      resolve(resp)
      remove()
    }

    script.src = url + '?callback=' + callback + '&' + serializeObj(data ?? {})
    script.type = 'text/javascript'
    script.async = true

    script.onerror = () => {
      reject()
      remove()
    }

    document.body.appendChild(script)
  })
}
