/**
 * @author ayou[beica1@outook.com]
 * @date 2022/2/28 18:47
 * @description
 *   Transformation.ts of WeTrade
 */
import { Delta } from './types'

class Transformation {
  private readonly min: number
  private readonly max: number

  x: number
  y: number
  k: number
  next: Transformation | null = null

  constructor (x = 0, y = 0, k = 1, min = 0.1, max = 10) {
    this.min = min
    this.max = max
    this.x = x
    this.y = y
    this.k = this.restrictK(k)
  }

  private restrictK (k: number) {
    return Math.min(this.max, Math.max(this.min, k))
  }

  apply (next?: Delta) {
    const n = next ?? this.next?.valueOf()

    if (n) {
      this.x = n.x
      this.y = n.y
      this.k = this.restrictK(n.k)
    }

    this.next = null
  }

  transform (delta: Delta) {
    return this.next = new Transformation(
      this.x + delta.x,
      this.y + delta.y,
      this.k + delta.k,
    )
  }

  valueOf () {
    return {
      x: this.x,
      y: this.y,
      k: this.k,
    }
  }

  toString () {
    return `translate(${this.x}px, ${this.y}px) scale(${this.k})`
  }
}

export default Transformation
