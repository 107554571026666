import * as R from 'ramda'
import { sub, div } from 'essential/tools/math'

export interface QuoteObject {
  o: string;
  h: string;
  l: string;
  c: string;
}

export interface Point {
  x: number;
  y: number;
}

export interface ChartOption {
  container: HTMLElement;
  color: number[];
}

class Chart {
  private container
  private canvas: HTMLCanvasElement
  private ctx: CanvasRenderingContext2D
  private color: number[]
  private ratio
  private width
  private height
  private high
  private low
  private fx
  private fy
  constructor (options: ChartOption) {
    // init
    this.container = Chart.getContainer(options.container)
    this.canvas = Chart.createCanvas()
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D //  this.getContext('2d')
    this.color = options.color
    this.ratio = window.devicePixelRatio || 2
    this.width = 0
    this.height = 0
    this.high = 1
    this.low = 0
    this.fx = 1 // x axis 浮动精度
    this.fy = 1 // y axis 浮动精度

    this.resize()
    this.append()
  }

  static getContainer (c: HTMLElement) {
    return R.ifElse(R.is(String), document.querySelector, R.identity)(c)
  }

  static createCanvas () {
    return document.createElement('canvas')
  }

  getContext (id: string) {
    return this.canvas.getContext(id)
  }

  resize () {
    const { width, height } = this.container.getBoundingClientRect()
    if (width * height === 0) {
      throw new Error('chart container size should not be 0')
    }

    this.height = height
    this.width = width

    const { ratio, canvas, ctx } = this

    canvas.height = height * ratio
    canvas.width = width * ratio
    canvas.style.height = `${height}px`
    canvas.style.width = `${width}px`
    ctx.scale(ratio, ratio)
  }

  colorBg () {
    const lineGrad = this.ctx.createLinearGradient(0, 0, 0, this.height)
    lineGrad.addColorStop(0, `rgba(${this.color.toString()}, 0.8)`)
    lineGrad.addColorStop(1, `rgba(${this.color.toString()}, 0)`)

    this.ctx.fillStyle = lineGrad
    this.ctx.fill('nonzero')
  }

  getMeanPoint (from: Point, to: Point) {
    return R.mergeWith(R.pipe(R.add, R.divide(R.__, 2)), from, to)
  }

  curve (control: Point, next: Point) {
    const end = this.getMeanPoint(control, next || control)
    this.ctx.quadraticCurveTo(control.x, control.y, end.x, end.y)
  }

  draw (points: Point[]) {
    this.ctx.strokeStyle = `rgb(${this.color.toString()})`
    this.ctx.lineWidth = 2
    this.ctx.beginPath()
    this.ctx.moveTo(0, 0)

    const first = R.head(points) || { x: 0, y: 0 }
    this.ctx.moveTo(first.x, first.y)
    const data = R.aperture(2, R.tail(points))
    const last = R.last(points) || { x: 0, y: 0 }
    data.push([last, last])
    R.map(R.apply(this.curve.bind(this)), data)
    this.ctx.stroke()

    // fill
    this.ctx.strokeStyle = 'transparent'
    this.ctx.lineTo(last.x, this.height)
    this.ctx.lineTo(0, this.height)

    this.ctx.closePath()
    this.colorBg()
  }

  append () {
    this.container.appendChild(this.canvas)
  }

  static getFloatRange (start: number, end: number) {
    return sub(start, end)
  }

  static getPPI (range: number, size: number) {
    return div(size, range)
  }

  updateBoundsAndPrecision (high: number, low: number, size: number) {
    this.high = high
    this.low = low
    this.fx = Chart.getPPI(size, this.width)
    const floatRange = Chart.getFloatRange(high, low)
    this.fy = Chart.getPPI(floatRange, this.height - 2)
    // log('floatRange', floatRange, 'high', this.high, 'low', this.low, 'fy', this.fy)
  }

  getPricePoint (price: number, index: number) {
    return {
      x: this.fx * index,
      y: Chart.getFloatRange(this.high, price) * this.fy + 1,
    }
  }

  transfer (data: QuoteObject[]) {
    const indexedMap = R.addIndex(R.map)
    return indexedMap(R.useWith(this.getPricePoint.bind(this), [R.prop('c'), R.identity]), data)
  }

  setData (data: QuoteObject[]) {
    const size = data.length
    if (size) {
      const first = R.head(data)
      const [high, low] = R.reduce(
        ([max, min], value) => [R.max(max, parseFloat(value.c)), R.min(min, parseFloat(value.c))],
        [parseFloat(first ? first.c : '0'), parseFloat(first ? first.c : '0')],
        data,
      )
      this.updateBoundsAndPrecision(high, low, size)
      this.draw(this.transfer(data) as Point[])
    }
  }

  remove () {
    this.container.removeChild(this.canvas)
  }
}

export default (options: ChartOption) => new Chart(options)
