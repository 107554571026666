
import { events, keymap } from '@/config'
import { emit } from 'essential/tools/event'
import { defineComponent, onBeforeUnmount, onMounted, reactive } from 'vue'
import Pic from '@/components/Pic.vue'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import Icon from '@/components/Icon.vue'
import { readChangeProductSp, TopMoverSc } from '@/modules/market/market.api'
import datafeed from '@/common/datafeed'
import * as R from 'ramda'
import Swipe from '@/components/Swipe.vue'
import { getSpeedAc } from '@/common/goSpeed'
import { localGet } from 'essential/store/localStore'

export default defineComponent({
  name: 'TheTopMovers',
  components: {
    Pic,
    Icon,
    CurrentQuote,
    RealTimeQuote,
    Swipe
  },
  setup() {
    const tops = reactive<Array<TopMoverSc[]>>([])

    const defaultI3 = () => {
      const i3 = localGet(keymap.label.watchList)
      const d = i3 ? i3.split(',').map(x => ({ contract: x })) : []
      return { productList: d }
    }

    const getList = async () => {
      const res = await (getSpeedAc('uuid')
        ? readChangeProductSp({ subType: 1, productType: 103 })
        : (defaultI3() as any))

      if (res?.productList?.length) {
        const d_ = res.productList as TopMoverSc[]
        emit(events.symbolChange, d_[0].contract, true)

        R.splitEvery(3, d_).map(item => {
          tops.push(item)
        })

        datafeed.subscribe(R.pluck('contract', d_), true)
      }
    }

    onMounted(() => {
      setTimeout(() => {
        getList()
      }, 500)
    })

    onBeforeUnmount(() => {
      datafeed.unSubscribe([], true)
    })

    return {
      tops,
      change(code: string) {
        emit(events.symbolChange, code)
      }
    }
  }
})
