
import { h, defineComponent } from 'vue'
import { formatDateTime } from '@/common/format'

export default defineComponent(
  {
    props: {
      tag: {
        type: String,
        default: 'span',
      },
      format: {
        required: true,
        type: String,
        default: 'MM/dd/yy',
      },
      time: {
        type: Number,
        default: new Date().getTime(),
      },
    },
    setup (props) {
      return () => {
        return h(props.tag, formatDateTime(props.format, props.time))
      }
    },
  },
)
