/**
 * @author ayou[beica1@outook.com]
 * @date 2022/3/1 10:36
 * @description
 *   transform.touch.ts of WeTrade
 */
import * as R from 'ramda'
import makeDeltaCalculator from './calculator'
import Transformation from './Transformation'
import { TransformHooks } from './types'

function extractPointer (touches: TouchList) {
  return [].slice.call(touches)
}

const transform = (el: HTMLElement, hooks: TransformHooks) => {
  if (!(el instanceof HTMLElement)) throw new TypeError(
    'HTMLElement typed "el" should be supplied for transform')

  const transformation = new Transformation()

  let calcDelta = makeDeltaCalculator()

  let longTransform = false

  /**
   * 记录元素平移动作的起始点信息
   * @param e {TouchEvent}
   */
  const start = (e: TouchEvent) => {
    el.addEventListener('touchend', end)
    el.addEventListener('touchcancel', end)
    el.addEventListener('touchmove', move)

    calcDelta = makeDeltaCalculator(extractPointer(e.targetTouches))

    longTransform = false

    setTimeout(() => {
      longTransform = true
    }, 250)

    hooks.onWillTransform?.(transformation)
  }

  /**
   * 持续追踪元素的平移信息
   * @param e {TouchEvent}
   */
  const move = (e: TouchEvent) => {
    e.preventDefault()
    e.stopImmediatePropagation()

    const delta = calcDelta(extractPointer(e.targetTouches))

    hooks.onTransform(transformation.transform(delta))
  }

  /**
   * 记录平移结束时时的触摸点信息
   */
  const end = () => {
    el.removeEventListener('touchend', end)
    el.removeEventListener('touchcancel', end)
    el.removeEventListener('touchmove', move)

    transformation.apply()

    hooks.onDidTransform?.(transformation, longTransform)
  }

  el.addEventListener('touchstart', start)

  return () => el.removeEventListener('touchstart', start)
}

export default transform
