/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/8
 * @description
 *   request.ts of WeTrade
 */
import nativeExe from '@/common/request/exe.native'
import { jsonp } from '@/common/request/jsonp'
import apis, { APINames } from '@/common/request/request.apis'
import { showAlert } from '@/components/popup/popup'
import { events, flag, isDevMode, market, request as requestConfig, server } from '@/config'
import { useAccountType } from '@/state/accountType'
import axios, { AxiosResponse } from 'axios'
import makeRequestBy, {
  RequestConfig,
  RequestPayload,
  ResponseResult
} from 'essential/net/http/makeRequestBy'
import { emit } from 'essential/tools/event'
import * as R from 'ramda'
import jsonpExe from './exe.jsonp'
import { goSpeed, speedParam } from './../goSpeed'

const attachGlobalParams = (payload: RequestPayload) => ({
  ...payload,
  data: {
    isTest: Number(flag.isDevMode),
    ...requestConfig.staticRequestData,
    ...requestConfig.dynamicRequestData,
    ...payload.data
  }
})

const attachExchangeParams = (payload: RequestPayload) =>
  R.mergeDeepRight(payload, {
    data: R.startsWith('/quotation', payload.url) ? { excode: market.excode } : {}
  })

const exeAxiosPost = (payload: RequestPayload) =>
  axios.post(payload.url, payload.data, payload.config)

interface ServerResponse<T = unknown> {
  code: string
  data: T
  msg: string
}

const SERVER_RESPONSE_CODE = {
  SUCCESS: '0',
  ERROR: '-0',
  TOKEN_EXPIRED: '100014',
  TOKEN_RESET: '100013',
  ILLEGAL_MOBIL: '100002',
  MAINTAIN: '500018',
  OUT_FORBIDDEN: '300019',
  NO_NAME: '400002'
}

const parseAxiosResponse = (response: AxiosResponse) => {
  const serverResponse: ServerResponse = response.data

  return {
    s: serverResponse.code === SERVER_RESPONSE_CODE.SUCCESS,
    d: serverResponse.data,
    m: serverResponse.msg,
    c: serverResponse.code
  }
}

const emitError = (resp: AxiosResponse) => {
  return {
    s: false,
    m: resp.statusText,
    c: resp.status
  }
}

const axiosRequest = makeRequestBy(
  R.pipe(exeAxiosPost, R.andThen(parseAxiosResponse), R.otherwise(emitError)),
  [attachGlobalParams, attachExchangeParams]
)

const nativeRequest = makeRequestBy(
  R.pipe(nativeExe, R.andThen(R.identity), R.otherwise(R.identity)),
  [attachGlobalParams, attachExchangeParams]
)

const request = makeRequestBy(jsonpExe, [attachGlobalParams, attachExchangeParams])

const requestSpeed = makeRequestBy(jsonpExe, [
  (d: RequestPayload) => ({
    ...d,
    data: {
      ...speedParam(),
      ...d.data
    }
  })
])

export const LoginOut = () => {
  let once = 1 // 0触发一次 1正常 2退出 3资料
  const code_ = {
    login: ['00020', '00019'],
    ay: ['20241']
  }

  const hasCode = (c: string | number, name: keyof typeof code_) => R.includes(c, code_[name])

  return {
    isOut: (c: string | number) => {
      once = once === 2 ? 0 : hasCode(c, 'login') ? 2 : hasCode(c, 'ay') ? 3 : 1
    },
    done: (next: () => void) => {
      if (once === 2) {
        localStorage.clear()
        location.reload()
        return
      }
      if (once === 3) {
        next()
        setTimeout(() => {
          goSpeed('1-2')
        })
        return
      }
      next()
    }
  }
}

/**
 * 显示异常信息
 * @param resp
 * @param url
 */

const { isOut, done } = LoginOut()

const bubbleException = (resp: ResponseResult, url: string) => {
  const message = R.cond<ResponseResult, string>([
    [R.propEq('c', 404), R.always('Server is not available[10002]')],
    [R.T, R.propOr('Uncaught server error occurred![10003]', 'm')]
  ])(resp)
  const showMsg = () => {
    const extra = flag.isDevContext ? `[${url}]` : ''
    showAlert(isDevMode() ? `${message}[${resp.c ?? -1}]${extra}` : message)
  }
  isOut(resp.c)
  done(showMsg)
}

const isTokenError = R.o(
  R.contains(R.__, [SERVER_RESPONSE_CODE.TOKEN_EXPIRED, SERVER_RESPONSE_CODE.TOKEN_RESET]),
  R.prop<string, any>('c')
)

/**
 * 响应特定的错误码
 * @param resp
 * @todo 异常去重处理 避免重复的逻辑调用
 */
const respondException = R.cond<ResponseResult, void>([
  [isTokenError, () => emit(events.tokenExpired)]
])

const handleResponseError = (url: string, e: Error | ResponseResult, setting?: RequestConfig) => {
  const mute = !flag.isDevMode && setting?.silent
  if (!mute) {
    bubbleException(e as ResponseResult, url)
    respondException(e as ResponseResult)
  }
}

const assumePath = (s: string) =>
  R.pipe(R.replace(/^\/*/, ''), R.concat('/'), R.concat(s.replace(/\/*$/, '')))

export const requestResult = <T, P = unknown>(url: string) => {
  const exe = request<T, P>(assumePath(server.api)(url)) // jsonp地址前拼上域名
  // const exe = request<T, P>(url)
  return (...args: Parameters<typeof exe>) =>
    new Promise<T>((resolve, reject) => {
      const [, setting] = args
      exe(...args)
        .then(resp => {
          if (resp.s) resolve(resp.d as T)
          else {
            handleResponseError(url, resp, setting)
            reject(resp)
          }
        })
        .catch(e => {
          handleResponseError(url, e, setting)
          reject(e)
        })
    })
}

export const requestResultSpeed = <T, P = unknown>(url: string, flag?: number) => {
  const exe = requestSpeed<T, P>(assumePath(server.speedapi)(url)) // jsonp地址前拼上域名
  // const exe = request<T, P>(url)
  return (...args: Parameters<typeof exe>) =>
    new Promise<T>((resolve, reject) => {
      const [, setting] = args
      exe(...args)
        .then(resp => {
          if (resp.s) resolve(resp.d as T)
          else {
            if (flag !== 1) {
              handleResponseError(url, resp, setting)
            }
            reject(resp)
          }
        })
        .catch(e => {
          if (flag !== 1) {
            handleResponseError(url, e, setting)
          }
          reject(e)
        })
    })
}

export default requestResult

export const jsonpRequest = <T>(url: string) => async (data?: Data) => {
  const result = await jsonp<ServerResponse<T>>(url, data)
  if (result.code === SERVER_RESPONSE_CODE.SUCCESS) {
    return result.data
  } else {
    if (result.msg) {
      showAlert(result.msg)
    }
    throw new Error(result.msg ?? 'uncaught jsonp error')
  }
}

const getRequestUrlByAccountType = (name: APINames) => {
  const type = useAccountType()
  return apis[name][type.value]
}

type U = Parameters<ReturnType<typeof request>>

/**
 * 根据不同的账户类型调用不同的接口
 * @param name
 */
export const requestByAccountType = <T>(name: APINames, flag?: number) => (...args: U) => {
  const url = getRequestUrlByAccountType(name)
  return requestResultSpeed<T>(url, flag)(...args)
}

export const get = axios.get
