
import { refreshAccount, setupNewSession, speedLogin } from '@/modules/app/app'
import Market from '@/modules/market/Market.index.vue'
import Symbol from '@/modules/market/Symbol.vue'
import Account from '@/views/Account.vue'
import Header from '@/views/Header.vue'
import TransitionProvider from '@/provider/TransitionProvider.vue'
import Popup from 'common/popout/Popup.vue'
import { computed, onBeforeUnmount } from 'vue'
import { dynamicAliveList } from '@/features/dynamicAlive'
import { off, on } from 'essential/tools/event'
import { events } from '@/config'
// import { useRouter } from 'vue-router'

export default {
  components: { Account, Header, TransitionProvider, Symbol, Market, Popup },
  setup() {
    // const router = useRouter()
    setupNewSession()
    speedLogin()
    on(events.transactionUpdate, refreshAccount)

    onBeforeUnmount(() => {
      off(events.transactionUpdate, refreshAccount)
    })

    return {
      cacheList: computed(() => ['TabPage', 'CopyTrade', ...dynamicAliveList.value])
    }
  }
}
