
import { TradeDirection } from '@/types'
import { computed, defineComponent, shallowRef } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'CopyTradeResult',
  components: { Icon, DialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
    type: String,
  },
  setup (props) {
    const isBuy = computed(() => props.order.type === TradeDirection.BUY)
    const trantext = shallowRef('')
    const price = computed(() => props.type ? props.order.createPrice : props.order.closePrice)
    const code = computed(() => props.order.contactCode || props.order.code)
    if (props.type) {
      trantext.value = isBuy.value ? 'trader_35' : 'trader_36'
    } else {
      trantext.value = 'trader_38'
    }
    return {
      code,
      trantext,
      price,
      isBuy,
    }
  },
})
