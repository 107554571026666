
import { computed, defineComponent } from 'vue'
import Time from '@/components/Time.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheNewsItem',
  components: { Pic, Time },
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const post = computed(() => {
      return {
        avatarPic: props.news.authorUrl,
        nickName: props.news.authorName,
        createTime: props.news.createTime,
      }
    })
    return {
      post,
    }
  },
})

