/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/8
 * @description
 *   task.queue.ts of essential
 */
import makeMicroTask from './task.micro'

const queue = (flushedCallback: Noop) => {
  let pending = false
  const actions: Array<Noop> = []

  const run = makeMicroTask(() => {
    pending = false
    const copies = actions.slice(0)
    actions.length = 0
    copies.forEach(fn => fn())
    flushedCallback()
  })

  return (action: Noop) => {
    actions.push(action)

    if (!pending) {
      pending = true
      run()
    }
  }
}

export default queue
