/**
 * @author 阿佑[ayooooo@petalmail.com]
 * @date 2022/4/14 18:32
 * @description
 *   market.types.ts of WeTrade
 */

import { YesOrNo } from '@/types'

export enum MarketCategory {
  ALL,
  COMMODITIES,
  FOREX,
  CRYPTO,
  INDEX,
  STOCK
}

export enum MarketState {
  OPEN = '1',
  CLOSED = '2'
}

export type SymbolSnapshot = {
  code: string;
  excode: string;
  last_close: string;
  updatetime: string;
  isSelect: YesOrNo;
  isClosed: MarketState;
  margin: string;
  mp: string;
  name: string;
  sell: string;
  buy: string;
  pointDiff: string;
}

export type MarketItem = {
  code: string;
  realTimeMicroQuotationBean: SymbolSnapshot;
  typeId: MarketCategory;
  tips: string;
  industryType: string;
  profile: string;
}
