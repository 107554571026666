
import { computed, defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'Switch',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    value: String,
    disabled: Boolean,
  },
  emits: ['change'],
  setup (props, ctx) {
    const iChecked = shallowRef<boolean | null>(null)
    const isChecked = computed({
      get: () => {
        return iChecked.value === null ? props.checked : iChecked.value
      },
      set: v => {
        iChecked.value = v
      },
    })

    const toggle = () => {
      if (!props.disabled) {
        isChecked.value = !(isChecked.value)
        ctx.emit('change', isChecked.value, props.value)
      }
    }

    return {
      isChecked,
      toggle,
    }
  },
})
