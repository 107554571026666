import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "deck d-ib v-middle mx-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")
  const _component_Donut = _resolveComponent("Donut")

  return (_openBlock(), _createBlock("button", {
    class: ['btn', { progress: _ctx.loading, sm: _ctx.sm, lg: _ctx.lg }]
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          name: _ctx.icon,
          class: { sm: _ctx.sm, lg: _ctx.lg },
          version: _ctx.iconVersion
        }, null, 8, ["name", "class", "version"]))
      : _createCommentVNode("", true),
    _createVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Donut, {
          key: 1,
          onTick: _ctx.done,
          sm: ""
        }, null, 8, ["onTick"]))
      : _renderSlot(_ctx.$slots, "noLoading", { key: 2 })
  ], 2))
}