
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheEditListItem',
  components: { Icon },
  emits: ['toTop'],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
})
