/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/5
 * @description
 *   trade.api.ts of WeTrade
 */
import requestResult, { requestByAccountType, requestResultSpeed } from '@/common/request/request'
import withAccountUpdate from '@/decorators/withAccountUpdate'
import { MarketState, TradeDirection, YesOrNo } from '@/types'
import { UserAccount } from '@/state'

export type leversSchema = {
  lever: string;
  isDefault?: number;
  choosed: number;
  creditChoosed: number;
}

export type MarginFormulaSchema = {
  marginIsMultiply: number;
  marginReverse: number;
  marginSymbol?: string;
  buy?: string;
  sell?: string;
}

export type Product = {
  calculatePoint: number;
  code: string;
  contractVolume: string;
  deferred: string;
  isClosed: MarketState;
  isOften: YesOrNo;
  maxStopLoss: string;
  maxStopProfit: string;
  minStopLoss: string;
  minStopProfit: string;
  pointStep: string;
  price: string;
  productId: string;
  sxf: string;
  unit: string;
  weight: string;
  yk: string;
  ykUnit: string;
  dotCount: number;
  contract: string;
  choiceId: string;
  orderNumber: number;
  specs?: any[];
  levers?: leversSchema[];
  minNumbers?: string[];
  entrustMinOffset?: number;
  entrustOffset?: number;
  marginFormula?: MarginFormulaSchema;
  buy?: string;
  sell?: string;
}

export type OrderSchema = {
  deferred: YesOrNo;
  type: TradeDirection;
  isJuan: YesOrNo;
  offset?: number;
  orderId?: number;
  orderNumber: number;
  price: string;
  productId: string;
  stopProfit: number;
  stopLoss: number;
  isTrackSt: YesOrNo;
}

export type ProductPlFormulaModel = {
  multiplyNum: string;
  plSymbol?: string;
  reverse: number;
  sell?: string;
  buy?: string;
}

export type Position = Required<Product & Omit<OrderSchema, 'deferred'>> & {
  profitability: number;
  profitLoss: number;
  createPrice: string;
  stopProfitPoint: number;
  stopLossPoint: number;
  realTimeProfitLoss: string;
  levelType: number;
  lever: string;
  productPlFormulaModel: ProductPlFormulaModel;
  buyMoney: string;
  orderNum: string;
}

export type Commission = Position & {
  offset: number;
  entrustPrice: string;
  entrustTime: number;
  leverage: string
}

export const readCommissions = requestByAccountType<Array<Commission>>('readCommission')

export const readCommission = requestByAccountType<Commission>('ofCommission')

export const createPosition = requestByAccountType<Position>('create', 1)

export const createCommission = requestByAccountType<Commission>('createCommission')

export const close = requestByAccountType<Position>('close')

export const cancel = requestByAccountType('cancelCommission')

export const edit = requestByAccountType('edit')

export const editCommission = requestByAccountType('editCommission')

// export const readProducts = requestResult<Array<Product>>('/api/product/mt/code')
export const readProducts = requestResult<Product>('/api/product/mt/user/product')

export const read = requestByAccountType('history')

export const readPositionDetail = requestResult<Position>('/api/order/mt/history/detail')

export const readProduct = requestResultSpeed('/web/trade/product/detail')

export const addDemoBalance = withAccountUpdate(requestResult('/api/account/balance/demo/add'))

export const readDemoAccountInfo = requestResult<UserAccount>('/api/account/mt/demo/info')

export const checkIsFirstDeposit = requestResult<{ first: number, recipients: number }>('/api/recharge/first/info')

export const creditRuleCheck = requestResultSpeed('web/trade/user/list/v2')
export const creditRuleInsert = requestResultSpeed('web/trade/user/insert')
export const productLeverChange = requestResultSpeed('web/trade/product/lever/change')
export const checkWallet = requestResultSpeed('web/trade/wallet/v4')
