/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   public.ts of WeTrade
 */
import Home from '@/modules/home/Home.index.vue'
import { markPrivate } from '@/router/helper'
import TabPage from '@/views/TabPage.vue'
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

const publicRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: TabPage,
    meta: {
      useRoute: true,
    },
    children: [
      {
        path: '',
        name: 'home',
        alias: '/home',
        component: Home,
        meta: {
          routeIndex: 0,
        },
      },
      markPrivate({
        name: 'prime',
        path: 'prime',
        meta: {
          routeIndex: 1,
          immersive: '#CFD8E3',
          routeFallback: '/prime/detail',
        },
        component: () => import('@/pages/prime/Prime.index.vue'),
      }),
      {
        path: '/me',
        name: 'me',
        component: () => import('@/modules/user/Me.vue'),
        meta: {
          routeIndex: 2,
        },
      },
    ],
  },
  {
    name: 'primeDetail',
    path: '/prime/detail',
    component: () => import('@/pages/prime/Detail.vue'),
    meta: {
      immersive: '#CFD8E3',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login/Login.index.vue'),
    meta: {
      roamOnly: true,
      routeIndex: -1,
      wrapperClass: 'login',
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/pages/reset/Reset.vue'),
    meta: {
      routeIndex: -1,
    },
  },
  {
    path: '/bindMobile',
    name: 'bindMobile',
    component: () => import('@/pages/reset/Reset.vue'),
    props: {
      bindMode: true,
    },
  },
  {
    path: '/qa',
    name: 'help',
    component: () => import('@/pages/help/Help.vue'),
  },
  {
    path: '/common/link',
    name: 'link',
    component: () => import('@/pages/Link.vue'),
    props: (to: RouteLocationNormalized) => to.query,
  },
  {
    path: '/advice/:id',
    name: 'advice',
    component: () => import('@/modules/community/analysis/Advice.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/news/:id',
    name: 'news',
    component: () => import('@/modules/community/news/Detail.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/topic/:topic',
    name: 'topic',
    component: () => import('@/modules/community/communities/Topic.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/post/:postId',
    name: 'post',
    component: () => import('@/modules/community/communities/Post.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/membership/about',
    name: 'about',
    component: () => import('@/modules/membership/AboutMember.vue'),
    meta: {
      immersive: '#326BFE',
    },
  },
  {
    path: '/setting/locale',
    name: 'locale',
    component: () => import('@/pages/Locale.vue'),
  },
  {
    path: '/link/invite',
    name: 'shareLink',
    component: () => import('@/pages/invite/InviteLink.vue'),
    meta: {
      roamOnly: true,
    },
  },
  {
    path: '/link/profit',
    name: 'profitLink',
    component: () => import('@/pages/invite/ShareLink.vue'),
    meta: {
      roamOnly: true,
    },
  },
  {
    path: '/eventsList',
    name: 'eventsList',
    component: () => import('@/modules/home/EventsList.vue'),
  },
  {
    path: '/eventsList/bigNews',
    name: 'bigNews',
    component: () => import('@/pages/bigNews/BigNews.vue'),
  },
  {
    name: 'emailSignup',
    path: '/signup/email',
    component: () => import('@/pages/register/RegisterWithEmail.vue'),
  },
  {
    name: 'emailLogin',
    path: '/login/email',
    component: () => import('@/pages/login/LoginWithEmail.vue'),
  },
  {
    name: 'lessons',
    path: '/help/lessons/:index',
    component: () => import('@/pages/help/Lesson.vue'),
  },
  {
    name: 'aboutus',
    path: '/user/aboutus',
    component: () => import('@/pages/aboutus/AboutUs.vue'),
  },
  {
    name: 'customerService',
    path: '/customerService',
    component: () => import('@/pages/customerService/CustomerService.vue'),
  },
  {
    path: '/events/happyHoli',
    name: 'happyHoli',
    component: () => import('@/events/happyHoli/HappyHoli.index.vue'),
  },
  {
    path: '/profitRank',
    name: 'profitRank',
    component: () => import('@/pages/profitRank/ProfitRank.vue'),
  },
  {
    path: '/events/happyEaster',
    name: 'happyEaster',
    component: () => import('@/events/happyEaster/HappyEaster.index.vue'),
  },
  {
    path: '/cup/wt',
    name: 'weTradeCup',
    component: () => import('@/modules/weTradeCup/WeTradeCup.index.vue'),
  },
  {
    path: '/cup/wt/detail',
    name: 'weTradeCupDetail',
    component: () => import('@/modules/weTradeCup/PrizeDetail.vue'),
  },
  {
    path: '/in/visaMasterIntro',
    name: 'visaMasterIntro',
    component: () => import('@/pages/visaMasterIntro/VisaMasterIntro.vue'),
  },
  {
    path: '/events/firstDeposit',
    name: 'firstDeposit',
    component: () => import('@/events/firstDeposit/FirstDeposit.vue'),
  },
  {
    path: '/events/happyDiwali',
    name: 'happyDiwali',
    component: () => import('@/events/happyDiwali/HappyDiwali.index.vue'),
  },
]

export default publicRoutes
