import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "guide-content p-r" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.show)
      ? _renderSlot(_ctx.$slots, "top", { key: 0 })
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.show)
      ? _renderSlot(_ctx.$slots, "bottom", { key: 1 })
      : _createCommentVNode("", true)
  ]))
}