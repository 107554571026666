/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/8
 * @description
 *   task.micro.ts of essential
 */
const makeMicroTask = (done: Noop): Noop => {
  // 判断是否兼容 Promise
  if (typeof Promise !== 'undefined') {
    return () => {
      Promise.resolve().then(done)
    }
    // 判断是否兼容 MutationObserver
    // https://developer.mozilla.org/zh-CN/docs/Web/API/MutationObserver
  } else if (typeof MutationObserver !== 'undefined') {
    let counter = 1
    const observer = new MutationObserver(done)
    const textNode = document.createTextNode(String(counter))

    observer.observe(textNode, {
      characterData: true,
    })

    return () => {
      counter = (counter + 1) % 2
      textNode.data = String(counter)
    }
    // 判断是否兼容 setImmediate
    // 该方法存在一些 IE 浏览器中
  } else {
    if (typeof setImmediate !== 'undefined') {
      // 这是一个宏任务，但相比 setTimeout 要更好
      return () => {
        setImmediate(done)
      }
    } else {
      // 如果以上方法都不知道，使用 setTimeout 0
      return () => {
        setTimeout(done, 0)
      }
    }
  }
}

export default makeMicroTask
