
import { events } from '@/config'
import withUserContext from '@/decorators/withUserContext'
import { off, on } from 'essential/tools/event'
import * as R from 'ramda'
import { readCommissions } from '@/modules/trade/trade.api'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'

export default defineComponent({
  name: 'CommissionBarWithBadge',
  props: {
    code: String,
  },
  setup (props) {
    const count = shallowRef(0)
    const load = withUserContext(() => readCommissions().then(resp => {
      count.value = resp ? props.code ? R.length(R.filter(R.propEq('code', props.code), resp)) : R.length(resp) : 0
    }))

    load()

    on(['transactionUpdateCommission', events.accountChanged], load)

    onBeforeUnmount(() => {
      off(['transactionUpdateCommission', events.accountChanged], load)
    })

    return {
      count,
    }
  },
})
