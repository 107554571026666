
import HoldOn from '@/provider/HoldOn.vue'
import TheRecommendItem from '@/modules/home/components/items/TheRecommendItem.vue'
import { onActivated, onBeforeUnmount, shallowRef, watch } from 'vue'
import { readRecommend, Recommend } from '@/modules/home/home.api'
import datafeed from '@/common/datafeed'
import * as R from 'ramda'
import swipe from 'essential/dom/swipe'

export default {
  name: 'TheRecommend',
  components: {
    HoldOn,
    TheRecommendItem,
  },
  setup () {
    const list = shallowRef<Recommend[]>([])
    const domref = shallowRef()
    const domitem = shallowRef()

    onActivated(() => {
      readRecommend().then(res => {
        list.value = res || []
        datafeed.subscribe(R.pluck('code', res), true)
      })
    })

    onBeforeUnmount(() => {
      datafeed.unSubscribe([], true)
    })

    watch(() => domref.value, () => {
      if (domref.value) {
        const itemViewWidth = parseFloat(window.getComputedStyle(domitem.value).width)
        swipe(domref.value, {
          direction: 'horizontal',
          range: [-((itemViewWidth + 12) * (list.value.length - 1)), 0],
          resistanceRatio: 1.1,
          onSwiped (transformer, long, done) {
            console.log(transformer)
            // console.log(long)
            done()
          },
        })
      }
    })
    return {
      list,
      domref,
      domitem,
    }
  },
}
