
import Positions from '@/modules/trade/components/ThePositions.vue'
import TheCommissions from '@/modules/trade/components/TheCommissions.vue'
import OnlyApp from '@/provider/OnlyApp.vue'
import CommissionBarWithBadge from '@/views/CommissionBarWithBadge.vue'
import PositionBarWithBadge from '@/views/PositionBarWithBadge.vue'
import TheAsset from '@/modules/trade/components/TheAsset.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import TheFirstDepositCard from '@/modules/trade/components/TheFirstDepositCard.vue'
import { AccountType } from '@/state'
import { changeAccountType, isDemoAccount } from '@/state/accountType'
import { defineComponent, shallowRef } from 'vue'
import { goSpeed } from '@/common/goSpeed'
import { on } from 'essential/tools/event'

enum OrderType {
  position,
  commissions
}

export default defineComponent({
  name: 'Account',
  components: {
    OnlyApp,
    Positions,
    AccountRelated,
    TheAsset,
    TheCommissions,
    CommissionBarWithBadge,
    PositionBarWithBadge,
    TheFirstDepositCard
  },
  setup() {
    const useDemo = (demo = false) => {
      changeAccountType(demo ? AccountType.DEMO : AccountType.REAL)
    }

    const active = shallowRef(OrderType.position)

    const changeClick = () => {
      active.value =
        active.value === OrderType.position ? OrderType.commissions : OrderType.position
    }

    on('commissionCreated', () => {
      active.value = OrderType.commissions
    })

    return {
      useDemo,
      isDemoAccount,
      goSpeed,
      active,
      OrderType,
      changeClick
    }
  }
})
