/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/18 10:41
 * @description
 *   EMA.ts of WeTrade
 */
import * as R from 'ramda'
import AbstractStudy from '../core/AbstractStudy'
import { Bar, ISelection } from '../types'
import { calcEMA, EMAState } from './formulas'

type Series = {
  period: number;
  lineColor: string;
}

export type EMAStudyInputs = {
  series: Series[]
}

class EMA extends AbstractStudy<EMAStudyInputs> {
  state: EMAState | null = null

  slice (from: number, to: number): Bar[][] {
    return this.cached.map(d => d.slice(from, to))
  }

  calcInitStudy (quotes: Bar[]) {
    const { state, value } = calcEMA(quotes, this.options.series)
    this.state = state
    return value
  }

  calcCandidateStudy (quotes: Bar[]): Bar[][] {
    if (this.state) {
      const { value } = calcEMA(quotes, this.options.series, this.state)
      return R.zipWith((cache, value) => cache.slice(-1).concat(value), this.cached, value)
    }
    return []
  }

  render (g: ISelection<SVGGElement>, paths: Bar[][]) {
    g
      .selectAll('path')
      .data(paths)
      .join('path')
      .attr('class', (d, i) => `ema_${this.options.series[i].period}`)
      .attr('fill', 'none')
      .attr('stroke', (d, i) => this.options.series[i].lineColor)
      .attr('stroke-width', this.options.lineWidth)
      .attr('d', this.defaultLineRenderer)
  }
}

export default EMA
