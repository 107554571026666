
import { usePositionCount } from '@/modules/user/user'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PositionBarWithBadge',
  props: {
    code: String,
  },
  setup (props) {
    return {
      count: usePositionCount(props.code),
    }
  },
})
