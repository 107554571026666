/**
 * @author ayou[beica1@outook.com]
 * @date 2022/1/28 16:37
 * @description
 *   helper.ts of WeTrade
 */
export const monitorNetState = (onStateChange?: (state: boolean) => void) => {
  const onOnline = () => {
    onStateChange?.(true)
  }

  const onOffline = () => {
    onStateChange?.(false)
  }

  window.addEventListener('online', onOnline)
  window.addEventListener('offline', onOffline)

  return () => {
    window.removeEventListener('online', onOnline)
    window.removeEventListener('offline', onOffline)
  }
}
