/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/27 21:10
 * @description
 *   axis.ts of WeTrade
 */
import { AxisScale } from 'd3'
import * as d3 from 'd3'
import { patternOf } from '../helper'
import { ISelection } from '../types'
import { XAxisOptions } from './XAxis'

export default (
  g: ISelection<SVGGElement>,
  fx: AxisScale<number>,
  options: XAxisOptions['define'],
) => {
  const domain = fx.domain()

  const ticks: number[] = []
  const step = Math.round(domain.length / 5)
  for (let i = domain.length - 1; i > 0; i -= step) {
    ticks.push(domain[i])
  }

  g
    .call(
      d3.axisBottom<number>(fx)
        // .tickSizeOuter(0)
        // @ts-expect-error 后面需要修改
        .tickFormat(d3.timeFormat(patternOf(options.periodicity)))
        .tickValues(ticks),
    )
    .call(
      g => g
        .select('.domain')
        .attr('stroke', options.borderColor)
        .attr('stroke-width', options.borderWidth),
    )

  if (options.showTicks) {
    g
      .attr(
        'transform', `translate(0, ${options.canvasHeight - (options?.height)})`)
  }

  if (options.showGrid) {
    g.call(g => g
      .selectAll('.tick')
      // @todo 研究默认的fx计算存在偏差的原因
      .attr('transform', d => `translate(${fx(d as number)}, 0)`)
      .select('line')
      .attr('stroke', options.lineColor)
      .attr('stroke-width', options.lineWidth)
      .attr('y1', -options.canvasHeight),
    )
  }

  return g
}
