/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/1
 * @description
 *   usePromise.ts of WeTrade
 */
import { shallowRef, Ref } from 'vue'

/**
 * @param action
 * @param done
 */
const useRequest = <
  F extends (...args: any[]) => Promise<any>,
  P extends Parameters<F>,
  R extends ReturnType<F> extends Promise<infer P> ? P : never
> (
    action: F,
    done?: (result: R) => void,
  ): [
  request: (...args: P) => Promise<R>,
  progress: Ref<boolean>
] => {
  const progress = shallowRef(false)

  const request = async (...args: P): Promise<R> => {
    if (progress.value) return Promise.reject('[useRequest] Please be patient for the Promise to be settle')
    progress.value = true
    try {
      const result = await action(...args)
      if (typeof done === 'function') done(result)
      return result
    } finally {
      progress.value = false
    }
  }

  return [request, progress]
  // return { request, progress, }
}

export default useRequest
