
import { defineComponent, h } from 'vue'
import CommonTabs from 'common/tab/Tabs.vue'

export default defineComponent({
  name: 'WTTabs',
  components: { CommonTabs },
  props: {
    navClass: String
  },
  setup(props, ctx) {
    return () =>
      h(
        CommonTabs,
        {
          name: 'WTTabs',
          class: 'df-col',
          navClass: `d-f ${props.navClass || ''}`,
          tabClass: 'flex-1 wt-tab-nav',
          contentClass: 'flex-1 scroll-y'
        },
        typeof ctx.slots.default === 'function' ? ctx.slots.default() : 'TabPane Needed'
      )
  }
})
