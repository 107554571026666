/**
 * @author ayou[beica1@outook.com]
 * @date 2022/2/28 18:48
 * @description
 *   index.ts of WeTrade
 */
import touchTransform from './transform.touch'
import mouseTransform from './transform.mouse'

const isTouchCapable = 'ontouchstart' in document && navigator.maxTouchPoints > 0

const transform = isTouchCapable ? touchTransform : mouseTransform

export default transform
