/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/8
 * @description
 *   WebSocket.ts of essential
 */
import * as R from 'ramda'
import log from '../../tools/log'
import { monitorNetState } from '../helper'

type SocketEventHandler = (...rest: any[]) => void

type SocketEvents =
  'open'
  | 'connecting'
  | 'error'
  | 'close'
  | 'closed'
  | 'message'
  | 'online'
  | 'offline'
  | 'heartbeat'

interface SocketOptions {
  autoRetry: boolean;
  forceRetry: boolean;
  retryLimit: number;
  retryInterval: number;
  heartInterval: number; // 单位秒
  heartSignal: string | (() => string);
  binaryType: 'blob' | 'arraybuffer';
}

const defaultOptions: SocketOptions = {
  autoRetry: true,
  forceRetry: false,
  retryLimit: 10,
  retryInterval: 1,
  heartInterval: 10,
  heartSignal: '-1',
  binaryType: 'blob',
}

type T = Parameters<ReturnType<typeof log.badge>>

const print = log.badge('websocket', '#c596f2')

export default class Socket {
  private readonly server: string
  private readonly config: SocketOptions
  private socket: WebSocket | null = null
  private events: { [p: string]: SocketEventHandler } = {}
  private retryTime = 0
  private retryTimer: number | null = null
  private heartTimer: number | null = null
  private stopNetMonitor = monitorNetState(this.onNetworkChange.bind(this))

  /**
   * @param {string} server  服务器地址
   * @param {object} options  配置
   * @param {number} options.heartInterval  单位秒
   */
  constructor(server: string, options?: Partial<SocketOptions>) {
    this.server = server
    this.config = R.mergeRight(defaultOptions, options || {})
  }

  onNetworkChange(online: boolean) {
    if (online) {
      if (this.config.autoRetry) {
        this.retry()
      }
      this.emit('online')
    } else {
      this.emit('offline')
    }
  }

  on(_events: SocketEvents | SocketEvents[], handler: SocketEventHandler) {
    let events = _events
    if (typeof events === 'string') {
      events = [events]
    }

    events.forEach(event => {
      this.events[event] = handler
    })

    return this
  }

  private emit(event: SocketEvents, ...rest: unknown[]) {
    const handler = this.events[event]
    if (typeof handler === 'function') {
      handler(...rest)
    }
  }

  send(message: string) {
    if (this.isOpen()) {
      (this.socket as WebSocket).send(message)
    } else {
      this.emit('closed')
    }
  }

  private heartbeat() {
    const { heartInterval, heartSignal } = this.config
    if (!heartInterval || !heartSignal) return
    this.heartTimer = window.setTimeout(() => {
      this.send(typeof heartSignal === 'function' ? heartSignal() : heartSignal)
      this.emit('heartbeat')
      this.heartbeat()
    }, heartInterval * 1000)
  }

  private stopHeartbeat() {
    clearTimeout(this.heartTimer as number)
  }

  connect(server: string = this.server) {
    this.emit('connecting')

    this.socket = new window.WebSocket(server)
    this.socket.binaryType = this.config.binaryType

    this.socket.onopen = () => {
      print('connected', this.server)
      this.emit('open', this)
      clearTimeout(this.retryTimer as number)
      this.resetRetry()
      this.heartbeat()
    }

    this.socket.onclose = () => {
      print('closed', this.server)
      this.emit('close')
      // this.retry()
      this.stopHeartbeat()
    }

    this.socket.onerror = err => {
      print('error', this.server, err)
      this.emit('error', err)
      this.retry()
      this.stopHeartbeat()
    }

    this.socket.onmessage = event => {
      this.emit('message', event.data)
    }

    return this
  }

  private stopRetryTimer() {
    clearTimeout(this.retryTimer as number)
  }

  private releaseSocket() {
    this.socket?.close()
    this.socket = null
    this.stopHeartbeat()
    this.stopRetryTimer()
  }

  private resetRetry() {
    this.retryTime = 0
  }

  retry(forceRetry = this.config.forceRetry) {
    // 强制重连，忽略连接检查
    if (!forceRetry && this.isOpen()) return

    const { retryLimit, retryInterval } = this.config

    if (!retryInterval || !retryLimit) return

    if (this.retryTime < retryLimit) {
      // 关闭已有的链接并释放资源
      this.releaseSocket()

      // 间隔事件随着尝试次数而增大
      const timeout = forceRetry && this.retryTime === 0 ? 0 : retryInterval * 1000 * this.retryTime

      this.retryTime += 1
      this.retryTimer = window.setTimeout(() => {
        print('retry', this.server, `${this.retryTime} of ${retryLimit}`)
        // 创建新连接
        this.connect()
      }, timeout)
    } else {
      this.releaseSocket()
      this.emit('closed')
    }
  }

  release() {
    this.events = {}
    this.releaseSocket()
    this.stopNetMonitor()
  }

  isOpen() {
    return this.socket?.readyState === WebSocket.OPEN
  }
}
