
import { useQuote } from '@/modules/market/market'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
// @todo 和 TheTradeGroupBar.vue有些雷同
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'QuoteBar',
  components: { RealTimeQuote },
  props: {
    symbol: Object,
    code: String,
  },
  setup (props) {
    if (props.symbol) {
      return
    }

    if (props.code) {
      return {
        symbol: useQuote(props.code),
      }
    }
  },
})
