/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/4/27
 * @description
 *   SyncManager.ts of WeTrade
 */
class SyncManager<T> {
  private busy = false
  private readonly queue: Array<T> = []
  private readonly onNext: (task: T) => void = t => t

  constructor (onNext?: (task: T) => void) {
    if (typeof onNext === 'function') {
      this.onNext = onNext
    }
  }

  private isFree () {
    return !this.busy
  }

  private readyNext () {
    if (this.isFree()) {
      this.next()
    }
  }

  private lock () {
    this.busy = true
  }

  private release () {
    this.busy = false
  }

  push (task: T) {
    this.queue.push(task)
    this.readyNext()
  }

  /**
   * 这里只保证入队与出队的顺序的一致性，不保证业务的一致性
   */
  next () {
    const nextTask = this.queue.shift()
    if (nextTask) {
      this.lock()
      this.onNext(nextTask)
    } else {
      this.release()
    }
  }
}

export default SyncManager
