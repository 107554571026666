
import TransitionPic from '@/views/appTab/TransitionPic.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

const tabs = [
  {
    label: 'tab_2',
    path: ['/', '/home'],
    icon: 'home',
    labelDefault: 'Home'
  },
  // {
  //   label: 'tab_5',
  //   path: ['/prime', '/prime/detail'],
  //   icon: 'prime',
  //   labelDefault: 'Prime'
  // },
  {
    label: 'tab_6',
    path: ['/me'],
    icon: 'me',
    labelDefault: 'Me'
  }
]

export default defineComponent({
  name: 'AppTab',
  components: { TransitionPic },
  setup() {
    const route = useRoute()

    const isMatch = (path: string) => path.indexOf(route.path) !== -1

    return {
      tabs,
      isMatch
    }
  }
})
