/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   market.api.ts of WeTrade
 */
import { requestResult, requestResultSpeed } from '@/common/request/request'
import { market } from '@/config'
import withoutCryptos from '@/decorators/withoutCryptos'
import { MarketState, YesOrNo } from '@/types'

export enum MarketCategory {
  ALL = '',
  COMMODITIES = 1,
  FOREX,
  CRYPTO,
  INDEX,
  STOCK
}

export type SymbolSnapshot = {
  code: string
  excode: string
  last_close: string
  updatetime: string
  isSelect: YesOrNo
  isClosed: MarketState
  margin: string
  mp: string
  name: string
  sell: string
  buy: string
  pointDiff: string
}

export type MarketItem = {
  code: string
  realTimeMicroQuotationBean: SymbolSnapshot
  typeId: MarketCategory
  tips: string
  industryType: string
  profile: string
}

export const requestSaveWatchlist = requestResult('/api/contactproduct/watch/save')

export const readWatchlist = withoutCryptos(() =>
  requestResult<Array<MarketItem>>('/api/contactproduct/watch/list')({
    excode: market.excode
  })
)

export type MkItSpProductList = {
  contract: string,
  isClosed: MarketState
  margin: string
  mp: string
  sell: string
  buy: string
  fullName: string
  name: string
}

export type MkItSpSub = {
  name: string,
  productType: number
  isDefault?: number
  subType?: number
}

export type MarketItemSp = {
  productList: MkItSpProductList[],
  subTypes: MkItSpSub[]
}

export const readWatchListSp = {
  out: requestResultSpeed('/web/trade/product/default/list'),
  in: requestResultSpeed('/web/trade/product/watch/list'),
}

export const readWatchListAllSp = {
  out: requestResultSpeed('/web/trade/product/default/list'),
  in: requestResultSpeed('/web/trade/product/all/list'),
}

export type QKAllNumSp = {
  amounts: string[]
}

export type QkAllSp = {
  products?: (MkItSpProductList & QKAllNumSp)[]
}

export const readQuickAllSp = requestResultSpeed<QkAllSp>('/web/trade/quick/product/list')

export const readMarketList = requestResult<Array<MarketItem>>('/api/contactproduct/list')

export const readMarketListWithoutCryptos = withoutCryptos(readMarketList)

export enum BarTypes {
  M1 = 10,
  S1, // 1 second
  S5,
  S15,
  S30,
  M5 = 2, // 5 minutes
  M15,
  M30,
  M60,
  D1 // 1 day
}

type QuoteObject = {
  o: string
  h: string
  l: string
  c: string
  u: number
}
export type ChangeProductItem = {
  code: string
  icon: string
  price: number
  ratio: number
  type: number
}

export const readBars = requestResultSpeed<QuoteObject[]>('/quotation/kChart')

export const readSymbol = requestResultSpeed<SymbolSnapshot[]>('/quotation/realTime')

export const readTheSymbol = async (code: string) => {
  const symbols = await requestResultSpeed<SymbolSnapshot[]>('/quotation/realTime')({
    codes: `${market.spcode}|${code}`
  })
  return symbols[0]
}

export const readTradeTrend = requestResult<{ up: number }>('/api/order/mt/contract/ratio')

export const productDetail = requestResult<MarketItem>('/api/product/mt/contract/detail')

export const readChangeProduct = withoutCryptos(
  requestResult<ChangeProductItem[]>('/api/changeProduct/data')
)

export type TopMoverSc = {
  contract: string
  sell: string
  buy: string
  mp: string
}

export const readChangeProductSp = withoutCryptos(
  requestResultSpeed<{ productList: TopMoverSc[] }>('/web/trade/product/top/movers')
)

export enum LineType {
  LINE = 1,
  TREND,
  ARROW_UP,
  ARROW_DOWN,
  TEXT
}

export enum AlertState {
  ON = 1,
  OFF
}

export type Line = {
  code: string
  color: string
  horizontalLinePrice: number
  id: number
  slashEndPrice: number
  slashEndTime: number
  slashStartPrice: number
  slashStartTime: number
  type: LineType
  warning: AlertState
  kLineType: BarTypes
  msg: string
  textPrice: number
  textTime: number
  arrowPrice: number
  arrowTime: number
}

export enum ESpecialType {
  NORMAL,
  REVERSE,
  DOUBLE
}

export const readLines = requestResult<Line[]>('/api/line/list')

export const drawLine = requestResult<Line>('/api/line/draw')

export const removeLine = requestResult('/api/line/delete')

export const checkIsSpecial = requestResult<{
  breedFlag: number
  specialIdentification: ESpecialType
}>('/api/contactproduct/special')
