import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("img", {
    src: _ctx.path,
    style: {objectFit: _ctx.fit},
    alt: "image",
    onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onError && _ctx.onError(...args)))
  }, null, 44, ["src"]))
}