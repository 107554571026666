
import { defineComponent, onActivated, shallowRef, watch } from 'vue'
import { mul, div } from 'essential/tools/math'
import { format } from '@/config'

export default defineComponent({
  name: 'ForDoubleTrade',
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const persent = shallowRef(0)
    watch(() => props.info, () => {
      persent.value = mul(div(props.info.averateAmount, props.info.targetAmount), 100)
      persent.value = persent.value > 100 ? 100 : persent.value
    }, {
      immediate: true,
      deep: true,
    })
    return {
      persent,
      format,
      div,
    }
  },
})
