/**
 * @author zjc[beica1@outook.com]
 * @date 2021/9/17 17:17
 * @description
 *   Study.ts of FAST
 */
import { StudyType } from '../types'
import DataMaster from '../core/dataMaster/DataMaster'
import RendererMaster from '../core/RendererMaster'
import StudyRenderer, { StudyOptions } from './StudyRenderer'

class Study<T extends StudyType = any> extends RendererMaster<StudyOptions[T] & { dataMaster: DataMaster }> {
  /**
   * init虽然在构造函数中执行却不能被正确的类型推断
   */
  // @ts-expect-error 初始化语句位置问题
  renderer: StudyRenderer

  init () {
    this.renderer = new StudyRenderer()
    return this
  }

  addStudy<T extends StudyType> (type: StudyType, options: StudyOptions[T]) {
    const study = this.renderer.create(this.chart, type, options)
    if (study) {
      study.draw(...this.dataMaster.value())
      return this.renderer.add(study)
    }
    return null
  }

  removeStudy (id: number) {
    this.renderer.remove(id)
  }

  clean () {
    this.renderer.remove()
  }

  render<T extends StudyType> (type: T, options: StudyOptions[T]) {
    this.addStudy(type, options)
    return this.renderer
  }
}

export default Study
