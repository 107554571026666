/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/2 13:43
 * @description
 *   YAxis.ts of FAST
 */
import { ScaleLinear } from 'd3'
import * as d3 from 'd3'
import { MonitorType } from '../core/dataMaster/AbstractDataMaster'
import { defaultAxisDisplay } from '../defaults'
import { extend, minMove } from '../helper'
import { AxisDisplay, Bar, ISelection, OptionsOf, Whitespace } from '../types'
import DataMaster from '../core/dataMaster/DataMaster'

type ExtentExtender = (data: Bar[], low: number, high: number) => [number, number]

type MedianBy =
  | 'extent' // 保持图的所有部分在可见范围内 default
  | 'left' // 始终保持起始价格在垂直中点
  | 'custom' // 支持最新价的浮动在指定的范围内

type YAxisOptionalOptions = {
  width: number;
  medianBy: MedianBy;
  extent?: ExtentExtender;
} & AxisDisplay

type YAxisRequiredOptions = {
  dataMaster: DataMaster;
  container: string;
  canvasWidth: number;
  canvasHeight: number;
  whitespace: Whitespace;
}

export type YAxisOptions = OptionsOf<YAxisOptionalOptions, YAxisRequiredOptions>

const defaultYAxisOptions: YAxisOptionalOptions = {
  width: 48,
  medianBy: 'extent',
  ...defaultAxisDisplay,
}

class YAxis {
  private readonly el_d3
  readonly fy: ScaleLinear<number, number>
  private readonly axis: (g: ISelection<SVGGElement>) => void
  private readonly dataMaster: DataMaster
  private readonly options: YAxisOptions['define']
  private readonly marginTop = 10
  private readonly marginBottom
  private readonly innerHeight

  constructor (options: YAxisOptions['call']) {
    this.options = extend(defaultYAxisOptions, options)
    this.dataMaster = this.options.dataMaster

    this.marginBottom = this.options.display && this.options.showTicks ? 20 : 10

    this.innerHeight = this.options.canvasHeight - this.marginTop - this.marginBottom

    this.el_d3 = d3
      .select(`#${this.options.container}`)
      .append('g')
      .attr('class', 'axis y-axis') as ISelection<SVGGElement>

    this.fy = d3
      .scaleLinear()
      .domain([-1, 1])
      .rangeRound([this.options.canvasHeight - this.marginBottom, this.marginTop])

    this.axis = (g: ISelection<SVGGElement>) => {
      g
        .call(
          d3.axisLeft(this.fy)
            .ticks(this.options.canvasHeight / 40)
            .tickSizeOuter(0),
        )
        .call(
          g => {
            if (this.options.showBorder) {
              g
                .select('.domain')
                .attr('stroke', this.options.borderColor)
                .attr('stroke-width', this.options.borderWidth)
            } else {
              g.select('.domain').remove()
            }
          },
        )

      if (this.options.showTicks) {
        g
          .attr('transform', `translate(${this.options.width}, 0)`)
      }

      if (this.options.showGrid) {
        g.call(
          g => g
            .selectAll('.tick line')
            .attr('x1', this.options.canvasWidth)
            .attr('stroke', this.options.lineColor)
            .attr('stroke-width', this.options.lineWidth),
        )
      }
    }

    this.monitorDataMaster()
  }

  render () {
    if (this.options.display) {
      this.el_d3.call(this.axis, this.fy)
    }
  }

  private scaleWithLeftRef (low: number, high: number) {
    const median = d3.median([low, high])
    // 如果中位数不存在则说明范围不合法
    if (!median) throw new ReferenceError('Y Axis计算参数错误')
    const begin = this.dataMaster?.rendered[0][0]?.c
    if (begin) {
      if (begin > median) {
        high = begin * 2 - low
      } else if (begin < median) {
        low = begin * 2 - high
      } else {
        const fakeDiff = minMove(begin) * 10
        high += fakeDiff
        low -= fakeDiff
      }
    }
    return [low, high]
  }

  private paddingDomain (low: number, high: number) {
    const { top, bottom } = this.options.whitespace
    let nextHeight = this.innerHeight - (top + bottom)
    if (nextHeight < 20) {
      nextHeight = 20
    }
    const pipsPerPixel = (high - low) / nextHeight
    return [
      (low - bottom * pipsPerPixel),
      (high + top * pipsPerPixel),
    ]
  }

  private update (low: number, high: number) {
    this.fy.domain(this.paddingDomain(low, high))
    this.render()
  }

  scale () {
    if (this.dataMaster) {
      let { low, high } = this.dataMaster
      if (this.options.medianBy === 'left') { // 以最高最低点自适应
        [low, high] = this.scaleWithLeftRef(low, high)
      }
      this.update(low, high)
    }
  }

  monitorDataMaster () {
    const { extent, medianBy } = this.options
    if (medianBy === 'custom' && typeof extent === 'function') {
      this.dataMaster.monitor(MonitorType.TICK, () => {
        const { rendered, high: _high, low: _low } = this.dataMaster
        const [low, high] = this.options.extent(
          rendered[0], _low, _high,
        )
        this.update(low, high)
      })
    } else {
      this.dataMaster.monitor(MonitorType.EXTENT, this.scale.bind(this))
    }
  }

  destroy () {
    console.log('y axis destroy')
  }
}

export default YAxis
