import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    onBeforeEnter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ready = false)),
    onAfterEnter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ready = true)),
    onAfterLeave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ready = false))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}