/**
 * @author ayou[beica1@outook.com]
 * @date 2022/4/5 20:31
 * @description
 *   exe.jsonp.ts of WeTrade
 */
import { SERVER_ERROR } from '@/common/request/code'
import { jsonp } from '@/common/request/jsonp'
import * as R from 'ramda'
import { RequestPayload, ResponseResult } from 'essential/net/http/makeRequestBy'

const SUCCESS_CODE = '0'

type RespData = {
  code: string;
  data: null | Data;
  msg: string;
}

const parseResponse = <T> (resp: RespData): ResponseResult<T> => {
  const success = resp?.code === SUCCESS_CODE
  return {
    s: success,
    c: resp?.code ?? SERVER_ERROR.UNKNOWN,
    d: resp?.data as T,
    m: resp?.msg ?? (!success ? `Server Response Error [${SERVER_ERROR.UNKNOWN}]` : 'OK'),
  }
}

const jsonpExe = <T> ({ url, data }: RequestPayload) => new Promise<ResponseResult<T>>(
  (resolve, reject) => {
    jsonp<RespData>(url + '/jsonp', data).then(R.o(resolve, parseResponse)).catch(
      () => reject(parseResponse({
        code: SERVER_ERROR.UNKNOWN,
        data: null,
        msg: SERVER_ERROR.NO_RESPONSE,
      })))
  })

export default jsonpExe
