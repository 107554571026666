/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/8
 * @description
 *   localStore.ts of WeTrade
 */
export const localGet = (key: string) => localStorage.getItem(key)

export const localSet = (key: string, value: string) => localStorage.setItem(key, value)

export const localRemove = (key: string) => localStorage.removeItem(key)
