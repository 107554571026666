import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("span", {
    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($setup.toggle && $setup.toggle(...args)), ["prevent","stop"]))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}