/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/5 13:29
 * @description
 *   types.ts of FAST
 */
import { BaseType, Selection } from 'd3'

export type Resolution = '1S' | '5S' | '15S' | 1 | 5 | 15 | 30 | 60 | '1D' | '1W' | '1M' | '1Y'

export type Point = [x: number, y: number]

export type TickPoint = {
  t: number;
  c: number;
}

export type Bar = TickPoint & Partial<{
  o: number;
  h: number;
  l: number;
  v: number;
  pc: number; // 昨收
  chg: number; // change
  'chg%': number;
}> & {
  [p in string]: number;
}

export type Candle = Required<Omit<Bar, 'v'>>

export type ISelection<T extends BaseType, D = Bar> = Selection<T, D, HTMLElement, Bar>

export type Quote = {
  last: number;
  volume?: number;
  bid?: number;
  ask?: number;
}

export interface SymbolDescriber {
  code: string;
  exchange: string;
  priceScale: number;
  minMove: number;
  type: string;
  /**
   * An array of resolutions which should be enabled in resolutions picker for this symbol.
   */
  resolutions: Resolution[];
  currencyCode?: string;
  description?: string;
  /**
   * @example "1700-0200"
   */
  session?: string;
  /**
   * @example "20181105,20181107,20181112"
   */
  holidays?: string;
}

export enum ChartType {
  LINE,
  AREA,
  CANDLE,
  MOUNTAIN
}

export enum TimeUnit {
  TICK = 'tick',
  MILLISECOND = 'millisecond',
  SECOND = 'second',
  MINUTE = 'minute',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month'
}

export type TimeUnitUnion = `${TimeUnit}`

/**
 * 举例说明，如果服务端提供的数据为5M 但要显示10M的chart则可以表述
 * period: 2, interval: 5, timeUnit: 'minute'
 */
export type Periodicity = {
  timeUnit: TimeUnitUnion
  period?: number;
  interval?: number;
  offset?: number,
}

export type Whitespace = {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export type LineStyle = {
  lineColor: string | (() => string);
  lineStyle: 'solid' | 'dashed' | 'dotted';
  lineWidth: number | (() => number);
}

export type ShapeStyle = {
  riseColor: string;
  fallColor: string;
  fillColor: string | (() => string);
  joinStyle: 'round';
  capStyle: 'round';
} & LineStyle

export type RecursiveRequired<T> = {
  [P in keyof T]-?:
  // T[P] extends (infer U)[] ? RecursiveRequired<U>[] : // 忽略数组的情况 tuple
  T[P] extends Record<string, unknown> ? RecursiveRequired<Required<T[P]>> :
    T[P]
}

export type RecursivePartial<T> = {
  [P in keyof T]?:
  // T[P] extends (infer U)[] ? RecursivePartial<U>[] :
  T[P] extends Record<string, unknown> ? RecursivePartial<T[P]> :
    T[P]
}

export interface OptionsOf<O, R> {
  define: RecursiveRequired<R> & RecursiveRequired<O>;
  call: R & RecursivePartial<O>;
  partial: RecursivePartial<O> & RecursivePartial<R>;
}

export type ConfirmFunctionalKey<O> = {
  [K in keyof O]: O[K] extends ((string | number | Date) | infer P) ? P : O[K]
}

export interface IDatafeed {
  resolveSymbol (symbol: string): Promise<SymbolDescriber>;

  read (symbol: SymbolDescriber, periodicity: Periodicity, from: number): Promise<Bar[]>;

  watch (
    symbol: SymbolDescriber,
    stream: (bar: Quote, now: number) => void,
  ): () => void;

  unwatch: () => void;
}

export interface TextStyle {
  textSize: number;
  textColor: string;
}

export type AxisDisplay = {
  display: boolean;
  tickSize: number;
  showTicks: boolean;
  labelFormat: (label: number | string) => typeof label;
  showBorder: boolean;
  borderColor: string;
  borderWidth: number;
  showGrid: boolean;
  lineColor: string;
  lineWidth: number;
}

export type NumberTuple = [min: number, max: number]

export type Values<T> = T[keyof T]

export enum StudyType {
  TRENDPRO,
  TREND,
  MACD,
  BOLL,
  SMA,
  SAR,
  EMA,
  KDJ,
  RSI,
  CCI
}
