
import Button from '@/components/Button.vue'
import useGoogleTranslate from '@/hooks/useGoogleTranslate'
import { renderProgram } from '@/modules/community/communities/community'
import { computed, defineComponent, reactive, watch } from 'vue'
import Pic from 'common/Pic.vue'
import Time from '@/components/Time.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ThePostTemplate',
  components: { Button, Time, Pic },
  props: {
    post: {
      type: Object,
      required: true,
    },
    detail: Boolean,
    useTranslate: Boolean,
  },
  setup (props) {
    const router = useRouter()
    const postData = reactive(props.post)

    postData.content = renderProgram(postData.content)

    watch(() => props.post, () => {
      postData.avatarPic = props.post.avatarPic
      postData.nickName = props.post.nickName
      postData.createTime = props.post.createTime
      postData.content = renderProgram(props.post.content)
    }, { deep: true })
    const { translate, content, progress, isOrigin } = useGoogleTranslate(postData.content)

    const formatText = computed(() => {
      return renderProgram(content.value)
    })

    return {
      progress,
      translate,
      isOrigin,
      formatText,
      postData,
      handleClick (e: Event) {
        if ((e.target as Element)?.tagName === 'A') {
          e.preventDefault()
          const path = (e.target as HTMLAnchorElement).getAttribute('href')?.slice(1)
          if (path) router.push(path)
        }
      },
    }
  },
})
