/**
 * @author ayou[beica1@outook.com]
 * @date 2022/2/16 15:48
 * @description
 *   Monitor.ts of WeTrade
 */
interface IMonitor<T = any> {
  (...args: T[]): void
}

abstract class Monitor<T extends string> {
  monitors: Record<T, IMonitor[]>

  constructor () {
    this.monitors = this.initMonitorPool()
  }

  abstract initMonitorPool (): Record<T, IMonitor[]>

  /**
   * aka event on
   * @param types
   * @param handler
   */
  monitor (types: T | T[], handler: IMonitor) {
    let _types = types as T[]

    if (!Array.isArray(types)) {
      _types = [types]
    }

    _types.map(_type => {
      if (this.monitors[_type].some(f => f === handler)) {
        return
      }
      this.monitors[_type].push(handler)
    })
  }

  /**
   * aka event off
   * @param type
   * @param handler
   */
  removeMonitor (type: T, handler?: IMonitor) {
    if (handler) {
      this.monitors[type] = this.monitors[type].filter(f => f !== handler)
    } else {
      this.monitors[type] = []
    }
  }

  /**
   * aka event emit
   * @param type
   * @param args
   */
  emitChange (type: T, ...args: any[]) {
    const handlers = this.monitors[type]
    for (let i = 0; i < handlers.length; i++) {
      handlers[i]?.(...args)
    }
  }
}

export default Monitor
